import { LikeOutlined, MessageOutlined, StarOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { AutoComplete, Avatar, Button, Input, List, message, Space } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import PageBreadcrumb from '../../component/PageBreadcrumb';
import { AddToSubscriptionBundle, GetAllCourse, GetSubscriptionBundle, RemoveFromSubscriptionBundle } from '../../redux/actions/CoursesAction';
const data = Array.from({
  length: 23,
}).map((_, i) => ({
  href: 'https://ant.design',
  title: `ant design part ${i}`,
  avatar: 'https://joeschmoe.io/api/v1/random',
  description: 'Ant Design, a design language for background applications, is refined by Ant UED Team.',
  content: 'We supply a series of design principles, practical patterns and high quality design resources (Sketch and Axure), to help people create their product prototypes beautifully and efficiently.',
}));

const IconText = ({ icon, text }) => (
    <Space>
      {React.createElement(icon)}
      {text}
    </Space>
  );  



const CourseBundle = () => {

    const [bundleData, setBundleData] = useState([]);
    const [allCourses, setAllCourses] = useState([]);
    const [query, setQuery] = useState("");
    const dispatch = useDispatch();

    useEffect(()=>{
       fetchBundle();
       fetchAllCourses();
    },[]);

    const fetchBundle = () => {
        const onSuccess = (data) => {
            console.log("bundle", data);
            setBundleData(data.courses);
        }
        const onError = (err) => {
            message.error(err);
        }

        dispatch(GetSubscriptionBundle(onSuccess, onError));
    }

    const fetchAllCourses = () => {
        const onSuccess = (data) => {
            console.log(data)
            // setLoading(false)
            const filteredData = data.filter(data => data.status!=="Deleted");
            setAllCourses(filteredData);
          };
      
          const onError = (error) => {
            // setLoading(false)
            message.error(error, 8);
          };
      
          dispatch(GetAllCourse(1, 100, onSuccess, onError));
    }

    const removeCourseFromBundle = (courseId) => {
        const onSuccess = (msg) => {
            message.success(msg);
            setBundleData((prev)=>prev.filter(item=>item._id!==courseId));
        }
        const onError = (err) => {
            message.error(err);
        }
        dispatch(RemoveFromSubscriptionBundle({courseId}, onSuccess, onError));
    }

    const addCourseToBundle = (option) => {
        const onSuccess = (msg) => {
            message.success(msg);
            setBundleData((prev)=> {
                return [...prev, {courseName: option.value, _id: option.courseId, thumbnail: option.thumbnail}]; 
                
            });
        }
        const onError = (err) => {
            message.error(err);
        }
        dispatch(AddToSubscriptionBundle({courseId: option.courseId}, onSuccess, onError));
    }

    

    const renderLabel = (item) => {
console.log("rendering...");
        let label = item;
        let searchString = query;

        if (searchString) {

            let index = label.toLowerCase().indexOf(searchString.toLowerCase());

            if (index !== -1) {

                let length = searchString.length;

                let prefix = label.substring(0, index);
                let suffix = label.substring(index + length);
                let match = label.substring(index, index + length);

                return (
                    <span style={{flex: 1}}>
                        {prefix}<span className="searchString" style={{fontWeight: "bold"}}>{match}</span>{suffix}
                    </span>
                );
            }
        }

        return (
            <span style={{flex: 1}}>
                {label}
            </span>
        );
    }

    // let options = [];

    //  options = useMemo(() => {
    //     return allCourses.map(item => ({ value: item.courseName, label: (<div
    //       style={{
    //         display: 'flex',
    //         justifyContent: 'space-between',
    //       }}
    //     >
    //       {renderLabel(item.courseName)}
    //       <span>
    //         <DeleteOutlined />
    //       </span>
    //     </div>
    // ) })).slice(0,7)
    //   }, [allCourses]);

    //   options = useMemo(() => {
    //     return options.map(item => ({ value: item.value, label: (<div
    //       style={{
    //         display: 'flex',
    //         justifyContent: 'space-between',
    //       }}
    //     >
    //       {renderLabel(item.value)}
    //       <span>
    //         <DeleteOutlined />
    //       </span>
    //     </div>
    // ) }));
    //   }, [query]);

    let options = useMemo(() => {
        return allCourses.map(item => ({ value: item.courseName, courseId: item._id, thumbnail: item.thumbnail }))
      }, [allCourses]);
            

  return (
  <div>
    <PageBreadcrumb route={["dashboard", "Course Bundle"]} />
    <AutoComplete
    style={{
      width: 600,
      marginBottom: 10
    }}
    placeholder="search course"
    size="large"
    onSearch={(search)=>setQuery(search)}
    listHeight="400px"
  >
    {options.filter(option=>option.value.toUpperCase().indexOf(query.toUpperCase()) !== -1).slice(0, 6).map((option, index)=><AutoComplete.Option key={option.value + index}>
    <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: 10
          }}
        >
            <img
                width={50}
                alt="logo"
                src={option.thumbnail}
                style={{borderRadius: 8}}
            />
          {renderLabel(option.value)}
          <Button type="primary" icon={<PlusOutlined />} onClick={()=>addCourseToBundle(option)}>
                Add
            </Button>
        </div>
    </AutoComplete.Option>)}
  </AutoComplete>


    <List
        itemLayout="vertical"
        size="large"
        pagination={{
        onChange: (page) => {
            console.log(page);
        },
        pageSize: 4,
        }}
        dataSource={bundleData}
        renderItem={(item) => (
        <List.Item
        style={{backgroundColor: "#fff", marginBottom: '10px'}}
            key={item.courseName}
            actions={[
                <Button type="danger" icon={<DeleteOutlined />} onClick={()=>removeCourseFromBundle(item._id)}>
                Delete
            </Button>
            ]}
            extra={
            <img
                width={100}
                alt="logo"
                src={item.thumbnail}
                style={{borderRadius: 8}}
            />
            }
        >
            <List.Item.Meta
            title={item.courseName}
            />
        </List.Item>
        )}
    />
  </div>)
};
export default CourseBundle;