
import {
  Button,
  Form,
  message,
  Modal,
  Space,
  Table,
  TimePicker,
  Input,
  Spin,
} from "antd";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PageBreadcrumb from "../../component/PageBreadcrumb";
import { localToutcTimeConverter } from "../../helper/TimeDate";
import { Tabs } from "antd";

import {
  FetchAllDisputes,
  GetMeetingRecording,
  markSessionAttendence__,
  RejectDispute,
  ResolveDispute,
} from "../../redux/actions/SessionAction";
import { UTCToAsiaTimeConverter } from "../../helper/helper";


const AllDisputes = () => {
  const dispatch = useDispatch();
  const [disputes, setDisputes] = useState([]);
  const [joiningTime, setJoiningTime] = useState(null);
  const [disputeModal, setDisputeModal] = useState(false);

  const [rejectModal, setRejectModal] = useState(false);
  const [rejectReason, setRejectReason] = useState(false);
  const [recordingLoader, setRecordingLoader] = useState(false);

  const [tabValue, setTabValue] = useState("All");
  const [loader, setLoader] = useState(false);
  const { TextArea } = Input;
  const httpValidator = (url) => {
    if (!/^https?:\/\//i.test(url)) {
      return "https://" + url;
    } else {
      return url;

    }
  };

  useEffect(() => {
    const getDisputes = (data) => {
      console.log("disputes", data);
      setDisputes(data);
      setLoader(false);
    };

    const onError = () =>{
      setLoader(false);
    }

   let resolved=false, closed=false;
    if(tabValue==="Resolved"){
      resolved=true;
      closed=false;
    }
    if(tabValue==="Rejected"){
      resolved=false;
      closed=true;
    }
    setLoader(true);
    dispatch(FetchAllDisputes(getDisputes, onError, resolved, closed));
  }, [tabValue]);

  const updateRows = (disputeId) => {
    const newDisputeData = disputes.filter(dispute=>dispute._id!==disputeId);
    setDisputes(newDisputeData);
  }

  const resolveDispute = (disputeId) => {
    let onSuccess = (msg) => {
      updateRows(disputeId);
      message.success(msg, 8);
    };
    let onError = (msg) => {
      message.error(msg, 8);
    };

    const onMSASuccess = (data) => {
      console.log("msa", data);
      dispatch(ResolveDispute(disputeId, onSuccess, onError));
      setDisputeModal(false);
    };

    const onMSAError = (err) => {
      console.log("msa error", err);
      message.error(err.response.data.message, 8);
      setDisputeModal(false);
    };

    if (!joiningTime) {
      message.error("Please enter joining time", 8);
      return;
    }

    const jTime = localToutcTimeConverter(joiningTime);

    const msaData = {
      sessionId: disputeId,
      joiningTime: jTime,
      forLearner: false,
      absent: false,
    };


    console.log("check msaData", msaData);


    dispatch(markSessionAttendence__(msaData, onMSASuccess, onMSAError));
  };

  const rejectDispute = (disputeId) => {
    if (!rejectReason) {
      message.error("Please enter a reason");
    }

    const data = {
      rejectedReason: rejectReason,
    };
    const onSuccess = (data) => {
      message.success(data);
      setRejectModal(false);
      updateRows(disputeId);
      console.log(data);
    };

    const onError = (err) => {
      message.error(err);
      setRejectModal(false);
      console.log(err);
    };


    dispatch(RejectDispute(disputeId, data, onSuccess, onError));
  };

  const disputeData = disputes?.map((data, i) => ({
    key: i,
    slNo: i + 1,
    learnerName: data.learner?.name,
    teacherName: data.teacher?.name,
    teacherPhoneNumber: data.teacher?.phoneNumber,
    meetingNo: data.meetingNo,
    disputeType: data.penaltyDispute?.disputeType,
    resolved: data.penaltyDispute?.isResolved,
    disputeId: data._id,
    sessionTime: UTCToAsiaTimeConverter(data.startTime),
    meetingRecording: data.meetingRecording ? data.meetingRecording!=="na"
      ? `https://clapingo-testing.netlify.app/recordings?link=${httpValidator(
          data.meetingRecording
        )}` : "na" 
      : null,
  }));

  const recording = (id) => {
    setRecordingLoader(id);
    const data = {
      meetingId: id,
    };
    const onSuccess = (data) => {
      setRecordingLoader(false);
      if(data?.recording_files){
        window.open(data.recording_files[0].play_url, '_blank');
      }else{
        message.error('recording not found', 8);
      }
    };
    const onError = (err) => {
      setRecordingLoader(false);
      message.error(err, 8);
    };
    dispatch(GetMeetingRecording(data, onSuccess, onError));

  };

  const openMeetingRecording = (url) => {
    if(url!=="na"){
      window.open(url, '_blank');
    }else{
      message.error('recording not found', 8);
    }
  }


  const columns = [
    {
      title: "Sl No",
      dataIndex: "slNo",
      key: "slNo",
      width: 10,
    },
    {
      title: "Learner name",
      width: 20,
      dataIndex: "learnerName",
      key: "learnerName",
    },
    {
      title: "Teacher name",
      width: 20,
      dataIndex: "teacherName",
      key: "teacherName",
    },
    {
      title: "Teacher's phone number",
      width: 20,
      dataIndex: "teacherPhoneNumber",
      key: "teacherPhoneNumber",

    },

    {
      title: "Meeting no",
      width: 20,
      dataIndex: "meetingNo",
      key: "meetingNo",
    },

    {
      title: "Session Time",
      width: 20,
      dataIndex: "sessionTime",
      key: "sessionTime",
    },

    {

      title: "Dispute Type",
      width: 20,
      dataIndex: "disputeType",
      key: "disputeType",
    },
    {
      title: "Resolve Dispute",
      width: 20,
      dataIndex: "resolved",
      key: "resolved",
      // fixed: "right",
      render: (_, data) => (
        <>
          <Modal
            title="Resolve Dispute"
            visible={disputeModal===data.disputeId}
            closable={true}
            footer={null}
            onCancel={() => {
              setDisputeModal(false);
            }}
          >
            <Form layout="vertical">
              <Form.Item label="Teacher's joining time" name="session">
                <TimePicker
                  onChange={(time) => setJoiningTime(time)}
                  value={joiningTime}
                />
              </Form.Item>
              <Button
                type="primary"
                block
                onClick={() => resolveDispute(data.disputeId)}
              >
                Resolve and close
              </Button>
            </Form>
          </Modal>

          <Button type="primary" onClick={() => setDisputeModal(data.disputeId)}>
            Resolve
          </Button>
        </>
      ),
    },
  
    {
      title: "Reject Dispute",
      width: 20,
      dataIndex: "reject",
      key: "reject",
      // fixed: "right",
      render: (_, data) => (
        <>
          <Modal
            title="Reject Dispute"
            visible={rejectModal===data.disputeId}
            closable={true}
            footer={null}
            onCancel={() => {
              setRejectModal(false);
            }}
          >
            <Form layout="vertical">
              <TextArea
                rows={4}
                onChange={(e) => {
                  setRejectReason(e.target.value);
                }}
                placeholder="Enter reason of rejection"
              />

              <Button
                type="primary"
                block
                style={{ marginTop: "10px" }}
                onClick={() => rejectDispute(data.disputeId)}
              >
                Reject and close
              </Button>
            </Form>
          </Modal>


          <Button type="primary" onClick={() => setRejectModal(data.disputeId)}>

            Reject
          </Button>
        </>
      ),
    },
    {
      title: "Recording",
      key: "meetingRecording",

      // fixed: "right",
      width: 20,
      render: ({ meetingNo, meetingRecording }) => (
        <>
          {meetingRecording === null ? (
            <a onClick={() => recording(meetingNo)}>
              {recordingLoader === meetingNo ? "Fetching..." : "Recording"}
            </a>
          ) : (
            <a onClick={()=>openMeetingRecording(meetingRecording)}>
              Recording
            </a>
          )}

        </>
      ),
    },
  ].filter(col => {
    if(tabValue!=="All") return !["resolved", "reject"].includes(col.key);
    else return true;
  });

  const onTabChange = (key) => {
    setTabValue(key);
  }


  const tabs=["All", "Resolved", "Rejected"];

  console.log("check moment", joiningTime, localToutcTimeConverter(joiningTime));


  return (
    <div>
      <PageBreadcrumb route={["Dashboard", "Disputes"]} />
      <div className="card-container">
        <Tabs onChange={onTabChange}>
        {tabs.map((tab)=>
        (
          <Tabs.TabPane tab={tab} key={tab}>
            {loader ? <div className="spin-center">
                  <Spin size="large" />
                </div> : <Table
              columns={columns}
              dataSource={disputeData}
              pagination={false}
            />}
          </Tabs.TabPane>
        ))}
          {/* <Tabs.TabPane tab="All" key="All">
            <Table
              columns={columns}
              dataSource={disputeData}
              pagination={false}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Resolved" key="Resolved">
            <Table
              columns={columns}
              dataSource={disputeData}
              pagination={false}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Rejected" key="Rejected">
            <Table
              columns={columns}
              dataSource={disputeData}
              pagination={false}
            />
          </Tabs.TabPane> */}
        </Tabs>
      </div>
    </div>
  );
};

export default AllDisputes;
