import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Card,
  Row,
  Col,
  Spin,
  Upload,
  message,
  Button,
  Divider,
  Radio,
  Tabs,
  Table,
  Modal,
  Input,
  Space,
  Popconfirm,
  Select,
} from "antd";
import {
  AddBacklogSessions,
  AddKidsSession,
  CancelSession,
  ExtendSubscriptionLimit,
  ExtendWeeklyLimit,
  GetMeetingRecording,
  markSessionAttendence__,
  markSessionQualityIssue_,
  StudentPastSession,
  StudentUpcomingSession,
} from "../../redux/actions/SessionAction";
import moment from "moment";
import {
  currentPlan,
  getP2PPlans,
  PausePlan,
  ResumePlan,
  subscribeAPlan,
} from "../../redux/actions/PlanAction";

import {
  assignTeacher,
  getTeacherList_,
} from "../../redux/actions/TeacherAction";

import { Form, Switch } from "antd";
import { Link } from "react-router-dom";

import { UTCToAsiaTimeConverter, roleValidator } from "../../helper/helper";
import {
  AddFavoriteTeacher,
  DeleteLearnerAccount,
  GetFavoriteTeachers,
  RemoveFavoriteTeacher,
  ResetPauseLimit,
} from "../../redux/actions/UserAction";
const RefundModal = ({ id }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [backlogs, setBacklogs] = useState();
  const dispatch = useDispatch();

  // console.log("SUBSCRIPTION MODAL ID", id);
  // console.log(backlogs);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    const onSuccess = (res) => {
      message.success(res.message);
      // setIsModalVisible(false);
      console.log("Backlog", res);
    };

    const onError = (err) => {
      message.error(err);
      console.log(err);
    };
    dispatch(
      AddBacklogSessions(
        {
          subscriptionId: id,
          backlogSessions: parseInt(backlogs),
        },
        onSuccess,
        onError
      )
    );
    // setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  //comment
  return (
    <>
      <Button type="primary" onClick={showModal}>
        Refund
      </Button>
      <Modal
        title="Add backlog"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Input
          onChange={(e) => {
            setBacklogs(e.target.value);
          }}
          placeholder="Enter no. of backlog sessions"
        />
      </Modal>
    </>
  );
};

const WeeklyLimitModal = ({ id }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [weeks, setWeeks] = useState();
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  console.log("WEEKLY LIMIT ID", id);
  console.log(weeks);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setLoader(true);
    const onSuccess = (res) => {
      message.success(res.message);
      console.log("Backlog", res);

      setLoader(false);
      setIsModalVisible(false);
    };

    const onError = (err) => {
      message.error(err);
      console.log(err);
      setLoader(false);
    };
    dispatch(
      ExtendWeeklyLimit(
        {
          subscriptionId: id,
          noOfWeekIncrease: parseInt(weeks),
        },
        onSuccess,
        onError
      )
    );
    // setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Button type="primary" onClick={showModal}>
        {loader ? "Extending..." : "Extend"}
      </Button>
      <Modal
        title="Extend weekly limit"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Input
          onChange={(e) => {
            setWeeks(e.target.value);
          }}
          placeholder="Enter no. weeks"
        />
      </Modal>
    </>
  );
};

const SubscriptionLimitModal = ({ id }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [extendedDays, setExtendedDays] = useState();
  const [sessions, setSessions] = useState();
  const [editor, setEditor] = useState();

  const [loader, setLoader] = useState(false);

  const dispatch = useDispatch();
  console.log("Subscription id", id);
  console.log(extendedDays);
  console.log(sessions);
  console.log(editor);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setLoader(true);
    const onSuccess = (res) => {
      message.success(res.message);
      console.log("Subscription", res);
      setLoader(false);
      setIsModalVisible(false);
      setExtendedDays();
      setSessions();
    };

    const onError = (err) => {
      message.error(err);

      console.log(err);
      setLoader(false);
    };
    dispatch(
      ExtendSubscriptionLimit(
        {
          learnerId: id,
          daysToExtend: parseInt(extendedDays),
          sessionsToAdd: parseInt(sessions),
          subject: editor,
        },
        onSuccess,
        onError
      )
    );
    // setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Button type="primary" onClick={showModal}>
        {loader ? "Extending..." : "Extend"}
      </Button>
      <Modal
        title="Extend Subscription limit"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Input
          onChange={(e) => {
            setExtendedDays(e.target.value);
          }}
          placeholder="Days to extend(in multiples of 7)"
        />
        <div style={{ marginTop: "5px" }}>
          <Input
            onChange={(e) => {
              setSessions(e.target.value);
            }}
            placeholder="Sessions to add"
          />
        </div>

        <div style={{ marginTop: "5px" }}>
          <select
            onChange={(e) => setEditor(e.target.value)}
            style={{ width: "70%", padding: "6px" }}
          >
            <option>Select Subject:</option>
            <option key="1" value="English">
              English
            </option>
            <option key="2" value="Math">
              Math
            </option>
            <option key="3" value="IELTS">
              IELTS
            </option>
          </select>
        </div>
      </Modal>
    </>
  );
};

const SubscriptionPauseModal = ({ learnerId, status }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [days, setDays] = useState();
  const [isSubscriptionPaused, setIsSubscriptionPaused] = useState(false);
  const [pauseCount, setPauseCount] = useState(0);
  const time_zone = JSON.parse(localStorage.getItem("TIMEZONE"));

  // console.log("count", learnerId)

  useEffect(() => {
    if (status === "Paused") {
      setIsSubscriptionPaused(true);
    } else {
      setIsSubscriptionPaused(false);
    }
  }, [learnerId]);

  // console.log("count2", isSubscriptionPaused)

  const [loader, setLoader] = useState(false);

  const dispatch = useDispatch();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setLoader(true);

    if (isSubscriptionPaused) {
      const onSuccess = (res) => {
        message.success(res);
        setIsModalVisible(false);
      };

      const onError = (err) => {
        message.error(err);
        console.log(err);
        setLoader(false);
        setIsModalVisible(false);
      };

      dispatch(
        ResumePlan(
          {
            learnerId: learnerId,
            timezone: time_zone.timezone,
          },
          onSuccess,
          onError
        )
      );
    } else if (days) {
      const onSuccess = (res) => {
        message.success(res);
        setIsModalVisible(false);
        setDays(null);
      };

      const onError = (err) => {
        message.error(err);
        console.log(err);
        setLoader(false);
      };

      dispatch(
        PausePlan(
          {
            learnerId: learnerId,
            pausePeriod: days === "7" ? "7" : "14",
            timezone: time_zone.timezone,
          },
          onSuccess,
          onError
        )
      );
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      {isSubscriptionPaused ? (
        <Button type="primary" onClick={showModal}>
          Resume
        </Button>
      ) : (
        <Button type="primary" onClick={showModal} disabled={pauseCount >= 2}>
          Pause
        </Button>
      )}
      <Modal
        title={
          isSubscriptionPaused ? "Resume Subscription" : "Pause Subscription"
        }
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        {isSubscriptionPaused ? (
          <p>Are you sure you want to resume your subscription?</p>
        ) : (
          <div style={{ marginTop: "5px" }}>
            <select
              onChange={(e) => setDays(e.target.value)}
              style={{ width: "70%", padding: "6px" }}
            >
              <option>Select Days:</option>
              <option key="1" value="7">
                7 days
              </option>
              <option key="2" value="14">
                14 days
              </option>
            </select>
          </div>
        )}
      </Modal>
    </>
  );
};

const LearnerDetails = (learner) => {
  const time_zone = JSON.parse(localStorage.getItem("TIMEZONE"));
  const dispatch = useDispatch();
  const [pastSessions, setPastSessions] = useState(null);
  const [IELTSTeacherList, setIELTSTeacherList] = useState([]);
  const [teacherList, setTeacherList] = useState([]);
  const [upcomingSessions, setUpcomingSessions] = useState(null);
  const [allSubscriptions, setAllSubscriptions] = useState(null);
  const [IELTSSubscriptions, setIELTSSubscriptions] = useState(null);
  const [recordingLoader, setRecordingLoader] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisibleConf, setIsModalVisibleConf] = useState("");
  const [openModal, setOpenModal] = useState();
  const [form] = Form.useForm();
  const [learneratt, setlearneratt] = useState(false);
  const [att, setatt] = useState(false);
  const [reason, setReason] = useState("");
  const [openreasonModal, setOpenReasonModal] = useState();
  const [activePage, setActivePage] = useState(1);
  const [cancelSessionLoader, setCancelSessionLoader] = useState(false);
  const { TabPane } = Tabs;
  const history = useHistory();
  const [pauseSubscriptionId, setPauseSubscriptionId] = useState(null);
  const [currentSubscriptions, setCurrentSubscriptions] = useState([{}])
  const [IELTScurrentSubscriptions, setIELTSCurrentSubscriptions] = useState([{}])
  const [activeTab, setActiveTab] = useState("1");
  const [favTeachers, setFavTeachers] = useState(null);
  const [loader, setLoader] = useState(false);
  const [page, setPage] = useState(1);
  const [isP2PModalVisible, setIsP2PModalVisible] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [plansData, setPlansData] = useState([])
  const [planLoader, setPlanLoader] = useState(false)
  const Admin = JSON.parse(localStorage.getItem("ADMIN"));
  console.log(Admin, "admin");
  console.log("test")

  const getP2PPlan = () => {
    var data = {
      learnerId: learner?.learner?._id
    }
    const onSuccess = (res) => {
      setPlansData(res.plansData)
    }

    const onError = (err) => {
      console.log(err)
    }

    getP2PPlans(onSuccess, onError, data)
  }

  const showP2PModal = () => {
    setIsP2PModalVisible(true);
    getP2PPlan()
  };

  const handleP2POk = () => {
    subscribeP2P()
    setIsP2PModalVisible(false)
  };

  const handleP2PCancel = () => {
    setSelectedSubscription("")
    setIsP2PModalVisible(false);
  };

  const handleSubscriptionChange = (value) => {
    const selectedPlan = plansData.find((plan) => plan?.price === value);
    setSelectedSubscription(selectedPlan);
  };

  const subscribeP2P = () => {
    setPlanLoader(true)
    var data = {
      learnerId : learner?.learner?._id,
      planId: selectedSubscription?._id,
      isWallet:false,
      month: selectedSubscription?.month,
      subscriptionName: selectedSubscription?.courseName,
      subject: selectedSubscription?.subjects,
      source: "Admin Web",
      totalMinutes: selectedSubscription?.totalMinutes,
      amountWithoutGST: selectedSubscription?.price,
      amount: Math.round(selectedSubscription?.price + (selectedSubscription?.price * 18)/100),
      netPayableAmount: Math.round(selectedSubscription?.price + (selectedSubscription?.price * 18)/100),
      isPeerSubscription: true,
      currency: "INR"
    }

    const onSuccess = () => {
      message.success("Plan Activated Successfully :)")
      setPlanLoader(false)
    }

    const onError = (err) => {
      message.error(err.message)
      setPlanLoader(false)
    }
    subscribeAPlan(data,onSuccess, onError)
  }


  // console.log("bug", currentSubscriptions)
  // console.log("bug1", IELTScurrentSubscriptions)

  const httpValidator = (url) => {
    if (!/^https?:\/\//i.test(url)) {
      return "https://" + url;
    } else {
      return url;
    }
  };

  useEffect(() => {
    if (learner?.learner?._id) {
      const onSuccessUpcoming = (res) => {
        console.log("UPCOMING", res);
        setUpcomingSessions(res);
      };

      const onErrorUpcoming = (err) => {
        console.log(err);
      };
      dispatch(
        StudentUpcomingSession(
          {
            studentID: learner?.learner?._id,
            page: 1,
            limit: 10,
            timezone: time_zone.timezone,
          },
          onSuccessUpcoming,
          onErrorUpcoming
        )
      );
      if (activeTab === "3") {
        const onSuccess = (res) => {
          console.log("CURRENTPLAN", res.data.subscriptions);
          setAllSubscriptions(res.data.subscriptions);
          setCurrentSubscriptions(res.data.currentsubscriptions);
        };
        dispatch(currentPlan({ learnerId: learner?.learner?._id, peerSubscription: true }, onSuccess));
      }

      if (activeTab === "1") {
        const onSuccess = (res) => {
          console.log("CURRENTPLAN", res.data.subscriptions);
          setAllSubscriptions(res.data.subscriptions);
          setCurrentSubscriptions(res.data.currentsubscriptions);
        };
        dispatch(currentPlan({ learnerId: learner?.learner?._id }, onSuccess));
      }

      if (activeTab === "2") {
        const onIELTSSuccess = (res) => {
          console.log("CURRENTPLAN", res.data.subscriptions);
          setIELTSSubscriptions(res.data.subscriptions);
          setIELTSCurrentSubscriptions(res.data.currentsubscriptions);
        };

        dispatch(currentPlan({ learnerId: learner?.learner?._id, subject: "IELTS" }, onIELTSSuccess));
      }

      getFavoriteTeachers();
    }

  }, [learner, activeTab]);

  const fetchPastSessions = () => {
    const onSuccessPast = (res) => {
      console.log("PAST", res);
      setPastSessions(res);
    };

    const onErrorPast = (err) => {
      console.log(err);
    };
    dispatch(
      StudentPastSession(
        {
          studentID: learner?.learner?._id,
          page: activePage,
          limit: 10,
          timezone: time_zone.timezone,
        },
        onSuccessPast,
        onErrorPast
      )
    );
  };

  useEffect(() => {
    if (learner?.learner?._id) {
      fetchPastSessions();
    }
  }, [learner, activePage]);

  const onChangePagination = (page, pageSize) => {
    setActivePage(page.current);
  };

  useEffect(() => {
    const onSuccess = (res) => {
      setIELTSTeacherList(res);
    };
    const onError = (err) => {
      console.log(err);
      message.error(err);
    };
    getTeacherList_({ page: 1, limit: 100, subject: "IELTS" }, onSuccess, onError); //500 is the listSize param
  }, []);

  const getTeacherList = (favSpeakers) => {
    let favSpeakersIds = favSpeakers?.map((e) => e._id);
    const onSuccess = (res) => {
      const newRes = res.map((e) => {
        if (favSpeakersIds.includes(e._id)) e.liked = true;
        return e;
      });
      setTeacherList([...teacherList, ...newRes]);
      setPage(page + 1);
      setLoader(false);
    };
    const onError = (err) => {
      console.log(err);
      message.error(err);
      setLoader(false);
    };
    getTeacherList_({ page: page, limit: 20, subject: "English" }, onSuccess, onError); //500 is the listSize param
  };

  const handleLoadMore = () => {
    setLoader(true);
    getTeacherList(favTeachers);
  }
  //current plan
  // useEffect(()=>{
  //   const onSuccess = (res) => {
  //     console.log("CURRENTPLAN", res)

  //   }
  //   dispatch(currentPlan({ learnerId: learner?.learner?._id },onSuccess));
  // },[learner])

  const recording = (id) => {
    setRecordingLoader(id);
    const data = {
      meetingId: id,
    };
    const onSuccess = (data) => {
      setRecordingLoader(false);
      if (data?.recording_files) {
        window.open(data.recording_files[0].play_url, "_blank");
      } else {
        message.error("recording not found", 8);
      }
    };
    const onError = (err) => {
      setRecordingLoader(false);
      message.error(err, 8);
    };
    dispatch(GetMeetingRecording(data, onSuccess, onError));
  };

  // const refund = (id) => {
  //   console.log("SUBSCRITION ID", id)
  // }

  //Table data and colums
  const mark = (id, sessionTimeUTC) => {
    var data = {
      sessionId: id,
      joiningTime: sessionTimeUTC,
      forLearner: learneratt,
      absent: att,
    };

    var onSuccess = (response) => {
      setOpenModal(); //close the popup

      setlearneratt(false);
      setatt(false);
      message.success(response);
    };

    var onError = (error) => {
      message.error(error);
      setOpenModal(); //close the popup
      setlearneratt(false);
      setatt(false);
    };

    dispatch(markSessionAttendence__(data, onSuccess, onError));
  };
  const addQualityIssue = (id) => {
    if (reason !== "") {
      var data = {
        sessionId: id,

        reason: reason,
      };

      var onSuccess = (response) => {
        setOpenReasonModal();
        setReason("");

        message.success(response);
      };

      var onError = (error) => {
        message.error(error);

        setOpenReasonModal();
        setReason("");
      };

      dispatch(markSessionQualityIssue_(data, onSuccess, onError));
    } else {
      message.error("Please specify the issue!");
    }
  };

  const openAttendancePopup = (id) => {
    if (openModal !== id) {
      setOpenModal(id);
    } else {
      setOpenModal();
    }
  };

  const openReasonPopup = (id) => {
    if (openreasonModal !== id) {
      setOpenReasonModal(id);
    } else {
      setOpenReasonModal();
    }
  };

  const [role, setRole] = useState("Learner")

  const cancelSession = (id, role) => {
    setCancelSessionLoader(id);
    const payload = {
      sessionId: id,
    };

    upcomingSessions.sessions.map((session) => session.type === "Demo" ? payload.cancelRequestedBy = role : "")

    const onSuccess = (res) => {
      message.success(res);

      if (upcomingSessions && upcomingSessions.sessions) {
        const sessionList = upcomingSessions.sessions.map((session) => {
          if (session._id === id) {
            session.isCancel = true;
          }
          return session;
        });
        setUpcomingSessions({ ...upcomingSessions, sessions: sessionList });
      }
      setCancelSessionLoader(false);
    };
    const onError = (error) => {
      setCancelSessionLoader(false);
      message.error(error);
    };
    CancelSession(payload, onSuccess, onError);
  };

  const columnsUpcoming = [
    {
      title: "Session no",
      dataIndex: "no",
      key: "no",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Session Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
    },
    {
      title: "Meeting no.",
      dataIndex: "meet",
      key: "meet",
    },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
    },
    {
      title: "Cancel Session",
      key: "cancel",
      fixed: "cancel",
      width: 50,

      render: ({ sessionId, isCancel, type }) => (
        <Popconfirm
          title={
            <div>
              {type === "Demo" ?
                <>
                  <p>Cancellation Requested By?</p>
                  <Radio.Group onChange={(e) => setRole(e.target.value)} value={role}>
                    <Radio value="Learner">Learner</Radio>
                    <Radio value="Tutor">Tutor</Radio>
                  </Radio.Group>
                </>
                :
                "Are you sure want to cancel ?"
              }
            </div>
          }
          onConfirm={() => type === "Demo" ? cancelSession(sessionId, role) : cancelSession(sessionId)}
          onCancel={() => { }}
          okText="Yes"
          cancelText="No"
        >
          <Button
            danger
            block
            loading={sessionId === cancelSessionLoader}
            disabled={isCancel}
          >
            {isCancel ? "Canceled" : "Cancel"}
          </Button>
        </Popconfirm>

      ),
    },
  ];
  const dataUpcoming = upcomingSessions
    ? upcomingSessions.sessions.map((data, i) => ({
      key: i,
      sessionId: data?._id,
      isCancel: data?.isCancel,
      no: data?.sessionCount,
      type: data?.type,
      date: moment(new Date(data.sessionDate)).format("YYYY-MM-DD"),
      time:
        UTCToAsiaTimeConverter(data.startTime) +
        "-" +
        UTCToAsiaTimeConverter(data.endTime),
      meet: data.meetingNo,
      subject: data.subjects,
    }))
    : "";

  const columnsPast = [
    {
      title: "Session no",
      dataIndex: "no",
      key: "no",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Session Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Teacher",
      dataIndex: "teacher",
      key: "teacher",
    },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
    },
    {
      title: "Meeting no.",
      dataIndex: "meet",
      key: "meet",
    },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
    },
    {
      title: "Recording",
      key: "meetingRecording",
      fixed: "right",
      width: 20,
      render: ({ meetingNo, meetingRecording }) => (
        <>
          {meetingRecording === null ? (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a onClick={() => recording(meetingNo)}>
              {recordingLoader === meetingNo ? "Fetching..." : "Recording"}
            </a>
          ) : (
            // eslint-disable-next-line react/jsx-no-target-blank
            <a href={meetingRecording} target="_blank">
              Recording
            </a>
          )}
        </>
      ),
    },

    {
      title: "Tutor's Attendence",
      width: 20,
      dataIndex: "tutorAttendence",
      key: "tutorAttendence",
    },

    {
      title: "Session attendance",
      key: "sessionAttendance",
      fixed: "right",
      width: 20,
      render: ({ sessionId, teacherJoining, sessionTimeUTC }) => (
        <>
          <Modal
            title="Mark session attendance"
            visible={openModal === sessionId}
            closable={true}
            footer={null}
            onCancel={() => {
              setOpenModal();
              setlearneratt(false);
              setatt(false);
            }}
          >
            <Form form={form} layout="vertical">
              <Form.Item label="Mark for" name="learner">
                <Radio.Group
                  onChange={(e) => setlearneratt(e.target.value)}
                  value={learneratt}
                >
                  <Space direction="vertical">
                    <Radio value={true}>Learner</Radio>
                    <Radio value={false}>Tutor</Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
              <Form.Item label="Mark as " name="session">
                <Radio.Group
                  onChange={(e) => setatt(e.target.value)}
                  value={att}
                >
                  <Space direction="vertical">
                    <Radio value={true}>Absent</Radio>
                    <Radio value={false}>Present</Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
              <Button
                type="primary"
                block
                onClick={() => mark(sessionId, sessionTimeUTC)}
              >
                Mark and close
              </Button>
            </Form>
          </Modal>
          <Button
            disabled={
              !roleValidator(["admin", "superadmin", "subadmin", "marketing"])
            }
            onClick={() => {
              if (
                roleValidator(["admin", "superadmin", "subadmin", "marketing"])
              )
                openAttendancePopup(sessionId);
            }}
          >
            Mark
          </Button>
        </>
      ),
    },
    {
      title: "Quality Issue",
      key: "qlty",
      fixed: "right",
      width: 20,

      render: ({ sessionId, qualityIssueReason }) => (
        <>
          <Modal
            title="Specify Quality Issue"
            visible={openreasonModal === sessionId}
            closable={true}
            footer={null}
            onCancel={() => {
              setOpenReasonModal();
              setReason("");
            }}
          >
            <form>
              <Input
                placeholder="Specify reason"
                disabled={qualityIssueReason}
                onChange={(e) => {
                  setReason(e.target.value);
                }}
                defaultValue={qualityIssueReason}
              />

              <br />
              <Button
                type="primary"
                block
                disabled={qualityIssueReason}
                onClick={() => {
                  if (!qualityIssueReason) addQualityIssue(sessionId);
                }}
              >
                Submit
              </Button>
            </form>
          </Modal>

          <Button onClick={() => openReasonPopup(sessionId)}>
            {qualityIssueReason ? "Specified" : "Specify"}
          </Button>
        </>
      ),
    },
  ];
  const dataPast = pastSessions
    ? pastSessions.sessions.map((data, i) => ({
      key: i,
      sessionId: data?._id,
      no: data?.sessionCount,
      type: data?.type,
      sessionTimeUTC: data.startTime,
      teacherJoining: UTCToAsiaTimeConverter(
        data.speakerJoiningTime?.slice(0, 2)
      ),
      date: moment(new Date(data.sessionDate)).format("YYYY-MM-DD"),
      teacher: data?.teacher?.name,
      time:
        UTCToAsiaTimeConverter(data.startTime) +
        "-" +
        UTCToAsiaTimeConverter(data.endTime),
      meet: data.meetingNo,
      subject: data.subjects,

      tutorAttendence:
        data.speakerJoiningTime.length > 0 ? "Present" : "Absent",
      meetingRecording: data.meetingRecording
        ? `https://clapingo-testing.netlify.app/recordings?link=${httpValidator(
          data.meetingRecording
        )}`
        : null,
      qualityIssueReason: data.qualityIssue ? data.qualityIssueReason : null,
    }))
    : "";


  const resetPause = (subscriptionId) => {
    const onSuccess = () => {
      message.success("Pause limit reset successfully")
    }
    const onError = (err) => {
      message.error(err)
    }
    ResetPauseLimit({ subscriptionId }, onSuccess, onError)
  }

  const columnsSubscription = [
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
    },
    {
      title: "Duration",
      dataIndex: "duration",
      key: "duration",
    },
    {
      title: "Total Sessions",
      dataIndex: "total",
      key: "total",
    },
    {
      title: "Available Sessions",
      dataIndex: "available",
      key: "available",
    },
    {
      title: "Total Sessions/Week",
      dataIndex: "totalweek",
      key: "totalweek",
    },
    {
      title: "Available Sessions/Week",
      dataIndex: "availableweek",
      key: "availableweek",
    },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
    },
    {
      title: "Course Name",
      dataIndex: "course",
      key: "course",
    },
    {
      title: "Backlog",
      dataIndex: "backlog",
      key: "backlog",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Refund",
      dataIndex: "subscriptionId",
      key: "subscriptionId",
      render: (subscriptionId) => <RefundModal id={subscriptionId} />,
    },
    {
      title: "Extend Weekly limit",
      dataIndex: "subscriptionId",
      key: "subscriptionId",
      render: (subscriptionId) => <WeeklyLimitModal id={subscriptionId} />,
    },
    {
      title: "Extend subscription limit",
      dataIndex: "subscriptionId",
      key: "subscriptionId",
      render: (subscriptionId) => (
        <SubscriptionLimitModal id={learner?.learner?._id} />
      ),
    },
    {
      title: "Pause Subscription",
      dataIndex: "subscriptionId",
      key: "subscriptionId",
      render: (subscriptionId, record) => (
        <SubscriptionPauseModal
          id={record.subscriptionId}
          learnerId={learner?.learner?._id}
          status={record.status}
          isSubscriptionPaused={record.subscriptionId === pauseSubscriptionId}
        />
      ),
    },
    {
      title: "Reset Pause Limit",
      dataIndex: "resetPauseLimit",
      key: "resetPauseLimit",
      render: (subscriptionId, record) => (
        <Button type="primary" onClick={() => { resetPause(record.subscriptionId) }}>Reset Pause limit</Button>
      ),
    },
  ];


  const peerToPeerColumnsSubscription = columnsSubscription
    .filter(col =>
      ["startDate", "endDate", "course", "duration", "total", "available", "subject", "status"].includes(col.key)
    )
    .map(col => {
      if (col.key === "total") {
        return { ...col, title: "Total Minutes" };
      }
      if (col.key === "available") {
        return { ...col, title: "Available Minutes" };
      }
      return col;
    });

  peerToPeerColumnsSubscription.push({ key: "month", dataIndex: 'month', title: "Month" });

  const dataSubscription = allSubscriptions
    ? allSubscriptions
      .sort((a, b) =>
        a.status !== "Running" ? (b.status !== "Completed" ? 0 : -1) : -1
      )
      .map((data, i) => ({
        key: i,
        startDate: moment(new Date(data.startDate)).format("YYYY-MM-DD"),
        endDate: moment(new Date(data.endDate)).format("YYYY-MM-DD"),
        duration: data.plan?.classDuration,
        total: data.totalSession,
        available: data.availableSession,
        totalweek: data.totalWeeklySession,
        availableweek: data.availableWeeklySession,
        subject: data.subjects,
        course: data.plan?.courseName,
        backlog: data.backlogSession,
        status: data.status,
        subscriptionId: data._id,
      }))
    : "";

  const dataPeerSubscription = allSubscriptions
    ? allSubscriptions
      .sort((a, b) =>
        a.status !== "Running" ? (b.status !== "Completed" ? 0 : -1) : -1
      )
      .map((data, i) => ({
        key: i,
        startDate: moment(new Date(data.startDate)).format("YYYY-MM-DD"),
        endDate: moment(new Date(data.endDate)).format("YYYY-MM-DD"),
        duration: data.plan?.classDuration,
        total: data.totalMinutes,
        available: data.availableMinutes,
        subject: data.subjects,
        course: data.plan?.courseName,
        backlog: data.backlogSession,
        status: data.status,
        subscriptionId: data._id,
        month: data.month ? data.month : "N/A"
      }))
    : "";

  const dataieltsSubscription = IELTSSubscriptions
    ? IELTSSubscriptions.sort((a, b) =>
      a.status !== "Running" ? (b.status !== "Completed" ? 0 : -1) : -1
    ).map((data, i) => ({
      key: i,
      startDate: moment(new Date(data.startDate)).format("YYYY-MM-DD"),
      endDate: moment(new Date(data.endDate)).format("YYYY-MM-DD"),
      duration: data.plan?.classDuration,
      total: data.totalSession,
      totalweek: data.totalWeeklySession,
      subject: data.subjects,
      course: data.plan?.courseName,
      backlog: data.backlogSession,
      status: data.status,
      subscriptionId: data._id,
    }))
    : "";
  const assignTeacher_ = (lid, Tid) => {
    const data = {
      learnerId: lid,

      teacherId: Tid,
    };
    const onSuccess = (res) => {
      message.success(res.message);
      setIsModalVisibleConf("");
      // console.log
    };

    const onError = (err) => {
      message.error(err);
    };
    dispatch(assignTeacher(data, onSuccess, onError));
  };
  const columnsIELTSTeacherList = [
    {
      title: "Sl. No",
      dataIndex: "key",
      key: "key",
    },
    {
      title: "Teacher Name",
      dataIndex: "teacherName",
      key: "teacherName",
    },
    {
      title: "Assign teacher",
      dataIndex: "teacherId",
      key: "teacherId",
      render: (teacherId) => (
        <>
          <Modal
            title="Are you sure you want to assign this tutor ?"
            visible={isModalVisibleConf === teacherId}
            onCancel={() => {
              setIsModalVisibleConf("");
            }}
            footer={[]}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                onClick={() => {
                  assignTeacher_(learner?.learner?._id, teacherId);
                }}
              >
                Yes
              </Button>
              <Button
                onClick={() => {
                  setIsModalVisibleConf("");
                }}
              >
                No
              </Button>
            </div>
          </Modal>

          <button
            onClick={() => {
              setIsModalVisibleConf(teacherId);
            }}
          >
            Assign
          </button>
        </>
      ),
    },
  ];

  const columnsTeacherList = [
    {
      title: "Sl. No",
      dataIndex: "key",
      key: "key",
    },
    {
      title: "Teacher Name",
      dataIndex: "teacherName",
      key: "teacherName",
    },
    {
      title: "Add To Favorite",
      dataIndex: "teacherId",
      key: "teacherId",
      render: (teacherId, { liked }) => (
        <button
          onClick={() => {
            liked
              ? removeFavoriteTeacher(teacherId)
              : addFavoriteTeacher(teacherId);
          }}
        >
          {liked ? "Remove" : "Add"}
        </button>
      ),
    },
  ];

  const teacherLISTforTable = IELTSTeacherList
    ? IELTSTeacherList.map((data, i) => ({
      key: i + 1,
      teacherName: data?.name,
      teacherId: data?._id,
    }))
    : "";

  const allTeacherListforTable = teacherList
    ? teacherList.map((data, i) => ({
      key: i + 1,
      teacherName: data?.name,
      teacherId: data?._id,
      liked: data?.liked,
    }))
    : "";

  const showModal = () => {
    setIsModalVisible(true);
  };
  const [numberOfSessions, setNumberOfSessions] = useState(1);
  const handleOk = () => {
    const payload = {
      subject: "English",
      learnerId: learner.learner._id,
      sessionsBought: numberOfSessions,
      forKids: true,
    };

    const onSuccess = (res) => {
      console.log("Success:", res);
      message.success(res.data.message);
      setIsModalVisible(false);
    };

    const onError = (err) => {
      console.error("Error:", err);
      message.error(err);
      setIsModalVisible(false);
    };

    console.log("Making network call...");
    dispatch(AddKidsSession(payload, onSuccess, onError));
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleNumberOfSessionsChange = (value) => {
    setNumberOfSessions(value);
  };
  const copyToClipboard = (txt) => {
    navigator.clipboard.writeText(txt);
    message.success(`${txt} copied successfully`);
  };

  const getFavoriteTeachers = () => {
    const onSuccess = (data) => {
      setFavTeachers(data.favSpeakers);
      getTeacherList(data.favSpeakers);
    };
    const onError = (err) => {
      message.error(err);
    };
    GetFavoriteTeachers({ learnerId: learner?.learner?._id }, onSuccess, onError);
  };

  const addFavoriteTeacher = (teacherId) => {
    const onSuccess = (data) => {
      message.success(data.message);
      let updatedTeacherList = teacherList?.map(e => {
        if (e._id === teacherId) e.liked = true;
        return e;
      })
      setTeacherList(updatedTeacherList);
    };
    const onError = (err) => {
      message.error(err);
    };
    AddFavoriteTeacher({ speakerId: teacherId, learnerId: learner?.learner?._id }, onSuccess, onError);
  };

  const removeFavoriteTeacher = (teacherId) => {
    const onSuccess = (data) => {
      message.success(data.message);
      let updatedTeacherList = teacherList?.map(e => {
        if (e._id === teacherId) e.liked = false;
        return e;
      })
      setTeacherList(updatedTeacherList);
    };
    const onError = (err) => {
      message.error(err);
    };
    RemoveFavoriteTeacher({ speakerId: teacherId, learnerId: learner?.learner?._id }, onSuccess, onError);
  };

  const deleteLearner = () => {

    const payload = {
      learnerId: learner.learner._id
    }

    const onSuccess = () => {
      message.success("learner deleted successfully")
      history.push("/learner/all-learner")
    }
    const onError = (err) => {
      message.error(err)
    }
    DeleteLearnerAccount(payload, onSuccess, onError)
  }

  return (
    <div>
      <Card className="teacher-details">
        {
          learner?.learner?.status === "Deleted" ? (
            <p>
              Learner not available. learner deleted
            </p>
          ) :
            learner ? (
              <>
                <Row>
                  <Col span={6}>
                    {/* <img
                src={
                  teacher.teacher.image
                    ? teacher.teacher.image
                    : "https://joeschmoe.io/api/v1/random"
                }
                alt="teacheravatar"
              /> */}

                    {/* <ImgCrop
     rotate
      style={{
        width:"8rem",
        height:"8rem"
      }}
    >
      <Upload
        action={()=>false}
        listType="picture-card"
        fileList={fileList}
        onChange={onChange}
        onPreview={onPreview}
        style={{
          width:"8rem",
          height:"8rem"
        }}
      >
        {fileList.length < 1 && '+ Upload'}
      </Upload>
    </ImgCrop>
     */}
                  </Col>
                  <Col span={18}>
                    <Row>
                      <Col span={12}>
                        <p className="teacher-name">
                          {learner?.learner?.name}{" "}
                          <span>
                            {learner?.learner?.countryCode}{" "}
                            {learner?.learner?.phoneNumber}
                          </span>
                        </p>
                        {/* <p className="description">
                      {teacher.teacher.description?.replace(/\r?\n|\r/g, " ")}
                    </p> */}
                      </Col>
                    </Row>

                    {/* <div className="access">
              <Tag color="#f50">Regular Demo</Tag>
              <Tag color="#f50">Regular Session</Tag>
              <Tag color="#f50">Kid's Demo</Tag>
              <Tag color="#f50">Kid's SEssion</Tag>
              </div> */}

                    {/* <Row>
                <Col span={6} className="active-stac">
                  <h5>Total class</h5>
                  <p>{teacher.totalSession}</p>
                </Col>
                <Col span={6} className="active-stac">
                  <h5>Rating</h5>
                  <p>{teacher.rating}</p>
                </Col>
                <Col span={6} className="active-stac">
                  <h5>Earnings</h5>
                  <p>0</p>
                </Col>
              </Row> */}
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>
                    <span className="key">Email</span>{" "}
                    <p className="value">{learner?.learner?.email}</p>
                  </Col>
                  <Col span={8}>
                    <span className="key">Device Details</span>{" "}
                    <p className="value">{learner?.learner?.deviceDetails}</p>
                  </Col>
                  <Col span={8}>
                    <span className="key">Source</span>{" "}
                    <p className="value">{learner?.learner?.source}</p>
                  </Col>
                  <Col span={8}>
                    <span className="key">Subscribed</span>{" "}
                    <p className="value">
                      {learner?.learner?.isLearnerSubscribed
                        ? "Subscribed"
                        : "Not Subscribed"}
                    </p>
                  </Col>
                  <Col span={8}>
                    <span className="key">Demo Paid</span>{" "}
                    <p className="value">
                      {learner?.learner?.isDemoPayed ? "Paid" : "Not Paid"}
                    </p>
                  </Col>
                  <Col span={8}>
                    <span className="key">Referral code</span>{" "}
                    <p className="value">{learner?.learner?.referal}</p>
                  </Col>

                  <Col span={12}>
                    <span className="key">English demo:</span>{" "}
                    <p
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        copyToClipboard(
                          `https://clapingo.com/english/${learner?.learner?._id}`
                        )
                      }
                    >
                      Copy English Demo link
                    </p>
                  </Col>

                  <Col span={12}>
                    <span className="key">IELTS demo:</span>{" "}
                    <p
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        copyToClipboard(
                          `https://clapingo.com/ielts/${learner?.learner?._id}`
                        )
                      }
                    >
                      Copy IELTS Demo link
                    </p>
                  </Col>
                  <Col span={8}>
                    <span className="key">Learner Id:</span>
                    <p
                      style={{ cursor: "pointer" }}
                      onClick={() => copyToClipboard(`${learner?.learner?._id}`)}
                    >
                      {learner?.learner?._id}
                    </p>
                  </Col>
                  {learner?.learner?.isLearnerSubscribed &&
                    IELTScurrentSubscriptions.length !== 0 ? (
                    <Col span={8}>
                      <span className="key">
                        Subscription Pause Details (IELTS):
                      </span>
                      <p style={{ cursor: "pointer", marginTop: "1rem" }}>
                        Start Date:{" "}
                        {IELTScurrentSubscriptions
                          ? IELTScurrentSubscriptions[0].pauseDetails
                            ? IELTScurrentSubscriptions[0].pauseDetails?.slice(
                              -1
                            )[0] &&
                              IELTScurrentSubscriptions[0].status === "Paused"
                              ? moment(
                                new Date(
                                  IELTScurrentSubscriptions[0].pauseDetails.slice(
                                    -1
                                  )[0].pauseStartDate
                                )
                              ).format("YYYY-MM-DD")
                              : "N/A"
                            : ""
                          : "N/A"}
                      </p>
                      <p style={{ cursor: "pointer" }}>
                        Resume Date:{" "}
                        {IELTScurrentSubscriptions
                          ? IELTScurrentSubscriptions[0].pauseDetails
                            ? IELTScurrentSubscriptions[0].pauseDetails.slice(
                              -1
                            )[0] &&
                              IELTScurrentSubscriptions[0].status === "Paused"
                              ? moment(
                                new Date(
                                  IELTScurrentSubscriptions[0].pauseDetails.slice(
                                    -1
                                  )[0].pauseResumeDate
                                )
                              ).format("YYYY-MM-DD")
                              : "N/A"
                            : ""
                          : "N/A"}
                      </p>
                      <p style={{ cursor: "pointer" }}>
                        {" "}
                        Pause Count:{" "}
                        {IELTScurrentSubscriptions
                          ? IELTScurrentSubscriptions[0].pauseDetails
                            ? IELTScurrentSubscriptions[0].pauseDetails.length
                            : "N/A"
                          : "N/A"}
                      </p>
                    </Col>
                  ) : (
                    ""
                  )}

                  {learner?.learner?.isLearnerSubscribed &&
                    currentSubscriptions.length !== 0 ? (
                    <Col span={8}>
                      <span className="key">
                        Subscription Pause Details (English):
                      </span>
                      <p style={{ cursor: "pointer", marginTop: "1rem" }}>
                        Start Date:{" "}
                        {currentSubscriptions
                          ? currentSubscriptions[0].pauseDetails
                            ? currentSubscriptions[0].pauseDetails?.slice(-1)[0] &&
                              currentSubscriptions[0].status === "Paused"
                              ? moment(
                                new Date(
                                  currentSubscriptions[0].pauseDetails.slice(
                                    -1
                                  )[0].pauseStartDate
                                )
                              ).format("YYYY-MM-DD")
                              : "N/A"
                            : ""
                          : "N/A"}
                      </p>
                      <p style={{ cursor: "pointer" }}>
                        Resume Date:{" "}
                        {currentSubscriptions
                          ? currentSubscriptions[0].pauseDetails
                            ? currentSubscriptions[0].pauseDetails.slice(-1)[0] &&
                              currentSubscriptions[0].status === "Paused"
                              ? moment(
                                new Date(
                                  currentSubscriptions[0].pauseDetails.slice(
                                    -1
                                  )[0].pauseResumeDate
                                )
                              ).format("YYYY-MM-DD")
                              : "N/A"
                            : ""
                          : "N/A"}
                      </p>
                      <p style={{ cursor: "pointer" }}>
                        {" "}
                        Pause Count:{" "}
                        {currentSubscriptions
                          ? currentSubscriptions[0].pauseDetails
                            ? currentSubscriptions[0].pauseDetails.length
                            : "N/A"
                          : "N/A"}
                      </p>
                    </Col>
                  ) : (
                    ""
                  )}
                  {learner?.learner?.activeSubscription.length !== 0 ||
                    learner?.learner?.isKid === true ? (
                    <Row gutter={80}>
                      <Col span={6}>
                        <Link
                          to={`/learner/schedule-session/${learner?.learner?._id}`}
                        >
                          <Button style={{ marginRight: "16px" }} type="primary">
                            Schedule Session
                          </Button>
                        </Link>
                      </Col>
                      <Col span={6}>
                      {!Admin.roles.includes("subadmin") && (
                        <Button type="primary" onClick={showModal}>
                          Add Kids Session
                        </Button>
                      )}
                        <Modal
                          title="Set Number of Sessions"
                          visible={isModalVisible}
                          onOk={handleOk}
                          onCancel={handleCancel}
                        >
                          <Form>
                            <Form.Item
                              label="Number of Sessions"
                              name="numberOfSessions"
                              rules={[
                                {
                                  required: true,
                                  message: "Please input the number of sessions!",
                                },
                              ]}
                            >
                              <Input
                                type="number"
                                value={numberOfSessions}
                                onChange={(e) =>
                                  handleNumberOfSessionsChange(e.target.value)
                                }
                              />
                            </Form.Item>
                          </Form>
                        </Modal>
                      </Col>
                      {!Admin.roles.includes("subadmin") && (
                        <Col span={6}>
                          <Button type="danger" onClick={() => deleteLearner()}>
                            Delete Learner
                          </Button>
                        </Col>
                      )}
                    </Row>
                  ) : (
                    ""
                  )}
                </Row>
                <Row style={{ marginTop: "5px" }}>
                  <Col span={5}>
                    <span className="key">Migrated to Curriculum</span>{" "}
                    <p className="value">
                      {learner?.learner?.isMigratedToCurriculum ? "Yes" : "No"}
                    </p>
                  </Col>
                  <Col span={5}>
                    <span className="key">Level</span>{" "}
                    <p className="value">{learner?.learner?.level}</p>
                  </Col>
                  {roleValidator(['superadmin']) &&
                    <Col span={5}>
                      <Button type="primary" onClick={showP2PModal}
                        disabled={planLoader}
                      >
                        Activate P2P Subscription
                      </Button>
                    </Col>
                  }
                </Row>
            {/* P2P Subscription Modal */}
            <Modal
              title="Activate P2P Subscription"
              visible={isP2PModalVisible}
              onOk={handleP2POk}
              onCancel={handleP2PCancel}
            >
              <Form layout="vertical">
                <Form.Item label="Select Subscription Type">
                  <Select
                    placeholder="Select a subscription"
                    onChange={handleSubscriptionChange}
                    value={selectedSubscription?.price}
                  >
                   {plansData?.map((data, i) => (
                   <Select.Option key={i} value={data?.price}>
                   {data?.courseName}
                 </Select.Option>
                  ))}
                  </Select>
                </Form.Item>
              </Form>
            </Modal>


            {/* <Row>
              <button onClick={() => history.push(`/learner/profile/demo/${learner?.learner?._id}`)} >Assign Demo</button>
            </Row> */}
                <Divider plain />
                <Tabs defaultActiveKey="1">
                  <TabPane tab="Upcomming Sessions" key="1">
                    <Table
                      dataSource={dataUpcoming}
                      pagination={false}
                      columns={columnsUpcoming}
                    />
                  </TabPane>
                  <TabPane tab="Past Sessions" key="2">
                    <Table
                      dataSource={dataPast}
                      onChange={onChangePagination}
                      pagination={{
                        pageSize: 10,
                        total: pastSessions?.pastSessionsCount,
                        defaultCurrent: activePage,
                      }}
                      columns={columnsPast}
                    />
                  </TabPane>
                  <TabPane tab="Subscriptions" key="3">
                    <Tabs defaultActiveKey="1" onChange={(key) => setActiveTab(key)}>
                      <TabPane tab="English" key="1">
                        <Table
                          dataSource={dataSubscription}
                          pagination={false}
                          columns={columnsSubscription}
                          scroll={{ x: 1300 }}
                        />
                      </TabPane>
                      <TabPane tab="IELTS" key="2">
                        <Table
                          dataSource={dataieltsSubscription}
                          pagination={false}
                          columns={columnsSubscription}
                          scroll={{ x: 1300 }}
                        />
                      </TabPane>
                      <TabPane tab="Peer-to-Peer" key="3">
                        <Table
                          dataSource={dataPeerSubscription}
                          pagination={false}
                          columns={peerToPeerColumnsSubscription}
                          scroll={{ x: 1300 }}
                        />
                      </TabPane>
                    </Tabs>
                  </TabPane>
                  <TabPane tab="Teachers List" key="4">
                    <Tabs defaultActiveKey="104">
                      <TabPane tab="Assign Teacher" key="105">
                        <Table
                          dataSource={teacherLISTforTable}
                          pagination={false}
                          columns={columnsIELTSTeacherList}
                          scroll={{ x: 400 }}
                        />
                      </TabPane>
                      <TabPane tab="Add To Favorite" key="106">
                        <Table
                          dataSource={allTeacherListforTable}
                          pagination={false}
                          columns={columnsTeacherList}
                          scroll={{ x: 1300 }}
                        />
                        <div style={{ textAlign: "center", margin: "1rem 0" }}>
                          <Button type="primary" onClick={handleLoadMore} loading={loader}>
                            Load More
                          </Button>
                        </div>
                      </TabPane>
                    </Tabs>
                  </TabPane>
                </Tabs>

                {/* {!loading ? (
              <Table scroll={{ x: 1500 }} dataSource={data} pagination={false} columns={columns} />
            ) : (
              <div className="spin-center">
                <Spin size="large" />
              </div>
            )} */}
                {/* <Row gutter={2}>
              <Col span={6} >
                <Card className="utm-card" style={{ background: "#b71540" }}>
                  <p>Upcoming Sessions</p>
                  <h5>{upcomingSessions?.sessions.length}</h5>
                </Card>
              </Col>

              <Col span={6} >
                <Card className="utm-card" style={{ background: "#5A0013" }}>
                  <p>Past Sessions</p>
                  <h5> {pastSessions?.sessions.length}</h5>
                </Card>
              </Col>



            </Row> */}
                {/* <EditProfile teacher={teacher?.teacher} /> */}
              </>
            ) : (
              <div className="spin-center">
                <Spin size="large" />
              </div>
            )}
      </Card>
    </div>
  );
};

export default LearnerDetails;
