import { Card} from "antd";
import React from "react";
import PageBreadcrumb from "../component/PageBreadcrumb";
import VideoApprovalRequest from "../component/VideoApproval/VideoApprovalRequest";

const VideoApproval = () => {

  return (
    <>
      <PageBreadcrumb route={["dashboard", "Video approval"]} />
      <Card style={{ marginBottom: "1rem" }}>
        {/* <Tabs defaultActiveKey="1" centered>
          <TabPane tab="Approval requests" key="1">
            <ApprovedVideosHistory />
          </TabPane>
          <TabPane tab="Approval history" key="2"> */}
            <VideoApprovalRequest />
          {/* </TabPane>
        </Tabs> */}
      </Card>
    </>
  );
};

export default VideoApproval;
