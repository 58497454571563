import axios from "axios";
// axios.defaults.timeout = 30000;
axios.defaults.timeoutErrorMessage='timeout'
axios.defaults.headers.common['Authorization'] = "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOm51bGwsImlzcyI6ImV1QlJFbnFPU0lLc0lua1VsbjJ3REEiLCJleHAiOjE3MzAxODQ2NjAsImlhdCI6MTYwMzI2MzA5MH0.5tgdRavcOxk5-lwLDMX5b7y-bFfMHLdY-mOGbUnJ3G0";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    Zoom(url = `${process.env.REACT_APP_BASE_URL}/api/`) {
        return {
            createMeeting: (data) => axios.post(url+"session/createmeeting",data),
            getMeetingrecording: (data) => axios.post(url+"session/getRecording",data),
        }
    }
}