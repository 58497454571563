import React, { useEffect, useState } from "react";
import PageBreadcrumb from "../../component/PageBreadcrumb";
import { Card, Col, Empty, Form, Input, Row, message, Spin, Typography } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useParams } from "react-router-dom";
const { Title } = Typography;

const ModuleDetails = () => {
  const [form] = Form.useForm();
  const { id } = useParams();

  return (
    <div>
      <PageBreadcrumb
        route={["dashboard", "Curriculums", "Module"]}
      />
      <Card>
        {false  ? (
                <Spin tip="Loading" size="small">
                <div className="content" />
              </Spin>
        ) : (
          <Form
            form={form}
            layout="vertical"
          >
            <Form.Item
              label={`Module Title`}
              name={`name`}
              rules={[{ required: true, message: "Please add video title !" }]}
            >
              <Input placeholder="Curriculum title" />
            </Form.Item>

            {/* Curriculum Description */}
            <Form.Item label="Module Description" name={`description`}>
              <TextArea rows={2} />
            </Form.Item>
          </Form>
        )}
      </Card>

      {/* Topics Section */}
      <Card>
        <Row gutter={16}>
          <Col flex="auto">
            <Title level={4}>Topics</Title>
          </Col>
          <Col>
            <div style={{ textAlign: "right" }}>
              {/* <AddUpdateModule curriculumId={id} /> */}
            </div>
          </Col>
        </Row>

        {/* Topic List Component */}
        {/* {false> 0 ? (
           <ModuleList  />
          
        ) : (
          <Empty
            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
            imageStyle={{
              height: 60,
            }}
            description={<span>No module created for <strong>module 1</strong></span>}
          />
        )} */}
      </Card>
    </div>
  )
}

export default ModuleDetails