import axios from "axios";

const admin = JSON.parse(localStorage.getItem("ADMIN"));

if (admin) {
  axios.defaults.headers.common["x-access-token"] = admin.token;
}
axios.defaults.headers.common["platform"] = "AdminWeb";
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  TeacherApis(url = `${process.env.REACT_APP_BASE_URL}/api`) {
    return {
      addTeacher: (data) => axios.post(url + "/teacher/signupTeacher", data),
      getTeacher: (data) => axios.post(url + "/teacher/teacherProfile", data),
      getTeacherList: ({ page, limit, subject }) =>
        axios.get(
          url +
            `/teacher/getTeacherList?page=${page}&limit=${limit}&subject=${subject}`
        ),
      assignTeacher: (data) =>
        axios.post(url + "/teacher/assignIELTSTeacher", data),
      teacherYoutubeConsent: (data) =>
        axios.post(url + "/teacher/signYTConsentByAdmin", data),
      updateTeacher: (data) => axios.put(url + "/teacher/updateteacher", data),
      getDemoConversion: ({ staDate, endDate, kids }) =>
        axios.get(
          url +
            `/teacher/getDemoConversion?staDate=${staDate}&endDate=${endDate}&kids=${kids}`
        ),
      uploadProfile: (data) => axios.put(url + "/teacher/profilesetup", data),
      generateMagicLink: (teacherId) =>
        axios.get(url + `/teacher/${teacherId}/magicLink`),
      getPayouts: (teacherId) =>
        axios.get(url + `/payouts/getPayouts?teacherId=${teacherId}`),
      updatePayouts: (data) => axios.post(url + `/payouts/editPayouts`, data),
      withdrawAmount: (data) =>
        axios.post(url + `/payouts/withdrawAmount`, data),
      workingHrs: ({ type, subject }) =>
        axios.get(
          url +
            `/schedule/getAvailableWorkingHr?type=${type}&subject=${subject}`
        ),
      tags: () => axios.get(url + `/tags`),
      teacherConcern: ({ status, page, limit }) =>
        axios.get(
          url +
            `/teacher/getAllRaiseConcern?status=${status}&page=${page}&size=${limit}`
        ),
      updateTeacherConcern: (data) =>
        axios.put(url + "/teacher/resolveRaiseConcern", data),
      deleteRating: (meetingNo) =>
        axios.delete(url + `/rating/delete-and-update-rating/${meetingNo}`),
      getCreatorList: () => axios.get(url + "/teacher/getCreatorsListByAdmin"),
      getTeacherApplications: (status) =>
        axios.get(url + `/teacher/getTeacherApplications?status=${status}`),
      changeTeacherApplicationStatus: (data) =>
        axios.post(url + "/teacher/reviewApplication", data),
      getAllTechIssues: (data) =>
        axios.post(url + `/adminauth/getAllTechIssueRequestedByTeacher`, data),
      changeTechIssueStatus: (data) =>
        axios.post(url + `/adminauth/changeReportTechIssueStatus`, data),
      adminLog: (data) => axios.post(url + `/adminauth/createAdminLog`, data),
    };
  },
};
