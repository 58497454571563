import axios from "axios";
const token = JSON.parse(localStorage.getItem("TOKEN"))
const timezone = JSON.parse(localStorage.getItem("TIMEZONE"))

// axios.defaults.timeout = 30000;
axios.defaults.timeoutErrorMessage='timeout'
if(token){
    axios.defaults.headers.common["x-access-token"] = token;
}
if(timezone){
    axios.defaults.headers.common["timezone"] = timezone?.timezone;
}
axios.defaults.headers.common["platform"] = "AdminWeb";
// eslint-disable-next-line import/no-anonymous-default-export
export default {
    Plan(url = `${process.env.REACT_APP_BASE_URL}/api/`) {
        return {
            FetchAll: () => axios.get(url+"learner/getPlans"),
            FetchAllIELTS: (subject) => axios.get(url+`learner/getPlans?subject=IELTS`),
            FetchAllKids: (subject) => axios.get(url+`learner/getPlans?forKids=true&subject=English`),
            getplanKids: (subject) => axios.get(url+`learner/getFrequenciesAndDurationpublic?forKids=true&subject=${subject}`),
            kidsPlan: () => axios.get(url+ "learner/getPlans?forKids=true&subject=English"),
            Subscribe: (data) => axios.post(url+"learner/subscribe",data),
            currentPlan: (data) => axios.post(url+"learner/getSubscriptions",data),
            getplan: () => axios.get(url+"learner/getFrequenciesAndDurationpublic"),
            getplanIELTS: (planParam) => axios.get(url+`learner/getFrequenciesAndDurationpublic?subject=IELTS&frequency=5`),
            getAllCourse: () => axios.get(url+"courses/getAllCourses?page=1&limit=10"),
            upgrade: (data) => axios.post(url+"learner/upgradesubscribe",data),
            FetchTransaction: () => axios.get(url+"razorpay/fetchtransaction"),
            CuponCode: (data) => axios.post(url+"referral/getReferralOrOffer",data),
            PausePlan: (data) => axios.post(url+"learner/pauseSubscription",data),
            ResumePlan: (data) => axios.post(url+"learner/resumeSubscription",data),
            ActivateNext: (data) => axios.post(url+"learner/activeNextSubscription",data),
            StripeCapture: (data) => axios.post(url+"stripe/create-checkout-session",data),
            FetchAllHomepage: () => axios.get(url+"learner/getPlans/"),
            getplanHomepage: () => axios.get(url+"learner/getFrequenciesAndDurationpublic/"),
            generatePaymentLink: (data) => axios.post(url+"razorpay/generate-payment-link",data),
            getP2PPlan: ({learnerId}) => axios.get(url + `learner/getPlans?&learnerId=${learnerId}&planType=Peer to Peer Learning`),
        }
    }
}