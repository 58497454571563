import React, {useState } from "react";
import {
  Modal,
  Button,
  Form,
  Input,
  Row,
  Col,
  message,
  Select,
  DatePicker
} from "antd";
import { UsersStories } from '../../redux/actions/AuthAction';
import moment from "moment-timezone";

const { Option } = Select;
const dateFormat = "YYYY-MM-DD HH:mm:ss";

const AddStories = () => {

  const [fileType, setFileType] = useState('url');
  const [documentsName, setDocumentsName] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [visible, setVisible] = useState(false);
  const [loader, setLoader] = useState(false);
  const [date, setDate] = useState(moment(new Date()).format(dateFormat));
 

  const handleTypeChange = (value) => {
    setFileType(value);
    setSelectedFile(null);
    setDocumentsName(null);
  };

  function onPickDate(date, dateString) {
    setDate(dateString);
  }


  const inputStyle = {
    width: '300px',
    height: '40px', 
  };

  const inputStyleUpload = {
    width: '500px',
    height: '40px', 
  };


  // selected the file to upload
  const handleFileSelect = (e) => {
    if (e.target.files[0]) {
        setSelectedFile(e.target.files[0]);
    }
    };

  //  get the presigned and upload the file in S3
  const handleUpload = () => {
    setLoader(true);
    if (fileType === "url") {
      // Check if the URL ends with the allowed extensions.
      if (
        documentsName.endsWith(".png") ||
        documentsName.endsWith(".jpeg") ||
        documentsName.endsWith(".jpg") ||
        documentsName.endsWith(".mp4")
      ) {
        var data = {
          content_url: documentsName,
          fileType: fileType,
          expireDate: date,
          contentType: getContentTypeFromURL(documentsName),
        };

        const onSuccess = () => {
          message.success("Uploaded Successfully");
          setVisible(false);
          setLoader(false);
        };

        const onError = (errmsg) => {
          message.error(errmsg);
          setLoader(false);
        };

        UsersStories(data, onSuccess, onError);
      } else {
        message.error(
          "Invalid URL. Please use URLs with .png, .jpeg, .jpg, or .mp4 extensions."
        );
        setLoader(false);
      }
    } else {
      var data = {
          fileName: selectedFile.name,
          fileType: selectedFile.type,
          expireDate: date, 
          contentType: selectedFile.type==="image/png" || selectedFile.type==="image/jpg" || selectedFile.type==="image/jpeg" ? "image": "video"
       }
      const onSuccess = async (res) => {
        if(fileType !== "url"){
        const presignedURL = res?.preSignedUrl
        console.log("url", presignedURL)

        const response = await fetch(presignedURL, {
          method: 'PUT',
          body: selectedFile,
          headers: {
            'Content-Type': selectedFile.type,
          },
        });

        if (response.ok) {
          message.success('File uploaded successfully!');
          setLoader(false);
          setSelectedFile(null)
          setVisible(false)
        } else {
          message.error('Failed to upload file:', response.statusText);
        }
      }else {
        message.success("Uploaded Successfully")
        setVisible(false)
      }
      };
      const onError = (errmsg) => {
        message.error(errmsg);
        setLoader(false);
      };
      UsersStories(data, onSuccess, onError);
    }
  };


  const getContentTypeFromURL = (url) => {
    if (url.endsWith(".jpg") || url.endsWith(".jpeg")) {
      return "image";
    } else if (url.endsWith(".png")) {
      return "image";
    } else if (url.endsWith(".mp4")) {
      return "video";
    } else {
      // Default content type if not recognized
      return "application/octet-stream";
    }
  };

    if (fileType !== "url"){
      var isButtonDisabled = !selectedFile || !documentsName
    }else {
      var isButtonDisabled = !documentsName
    }

  return (
    <>
      <Button type="primary" onClick={() => setVisible(true)}>
         Upload Stories
      </Button>
      <Modal
        centered
        visible={visible}
        onCancel={() => {
          setVisible(false);
        }}
        footer={null}
        width={1000}
      >

        <Form
          layout="vertical"
          autoComplete="off"
          initialValues={{
            fileType: 'url',
          }}
        >
          <Row gutter={16}>
            <Col span={6}>
            <Form.Item label="Select File Type" name="fileType">
             <Select onChange={handleTypeChange}>
                <Option value="url">URL</Option>
                <Option value="image">Image</Option>
                <Option value="video">Video</Option>
            </Select>
           </Form.Item>
            </Col>
            </Row>


        <Row>
        <Col>
        <Form.Item label="Select expiry Date" name="date">
        <DatePicker showTime
                onChange={onPickDate}
                defaultValue={moment(new Date(), dateFormat)}
                format={dateFormat}
              />
         </Form.Item>
        </Col>
      </Row>

            <Row gutter={16}>
            <Col span={12}>
            {fileType === 'url' && (
        <Form.Item  
        label="URL"
        rules={[{ required: true }]}
        tooltip="This is a required field"
        name="documentsName">
            <Input style={inputStyle} placeholder="Enter URL" onChange={e=>setDocumentsName(e.target.value)}/>
        </Form.Item>
      )}

      {fileType === 'image' && (
        <Form.Item
        label="Document Name"
        rules={[{ required: true }]}
        tooltip="This is a required field"
        name="documentsName"
      >
  <Input style={inputStyle} placeholder="Enter document Name" onChange={e=>setDocumentsName(e.target.value)}/>
  </Form.Item>
      )}

      {fileType === 'video' && (
        <Form.Item
        label="Document Name"
        rules={[{ required: true }]}
        tooltip="This is a required field"
        name="documentsName"
      >
   <Input style={inputStyle} placeholder="Enter document Name" onChange={e=>setDocumentsName(e.target.value)}/>
  </Form.Item>
      )}
                </Col>
            </Row>
            
            {fileType!== "url" &&
            <Row gutter={16}>
            <Col span={12}>
            <Form.Item
                rules={[{ required: true }]}
                tooltip="This is a required field"
              >
        <div className="resource-upload">
        <Input type="file" onChange={handleFileSelect} style={inputStyleUpload}/>
        </div>
        </Form.Item>
        </Col>
        </Row>
         }

          <Form.Item style={{ marginTop: "1rem" }}>
          <Button onClick={handleUpload} disabled={isButtonDisabled} loading={loader} type="primary" block>{loader? "Uploading" : "Upload"}</Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default AddStories;

