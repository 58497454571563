import { Button, Card, Col, DatePicker, message, Radio, Row, Space, Spin, Table } from "antd";
import moment from "moment-timezone";
import React, { useEffect,useState } from "react";
import { useDispatch } from "react-redux";
import PageBreadcrumb from "../../component/PageBreadcrumb";
import { GetDemoConversionDetails } from "../../redux/actions/TeacherAction";

const { RangePicker } = DatePicker;

var percentage = (num1,num2)=>{
  return ((num1 * 100) / num2).toFixed(2)
}

const DemoToConversion = () => {
  const dateFormat = "YYYY-MM-DD";

  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(moment().subtract(14, "days").format(dateFormat));
  const [endDate, setEndDate] = useState(moment().format(dateFormat));
  const [isKids, setIsKids] = useState("Regular");
  const [conversionData, setConversionData] = useState(null);
  const [loading, setLoading] = useState(false);

  const datePicker = (date, dateString) => {
    setStartDate(dateString[0]);
    setEndDate(dateString[1]);
  };


  useEffect(() => {
    getDemoToConversion();
  }, [])
  

  const getDemoToConversion = () =>{
    setLoading(true)
    var data = {
        staDate:startDate,
        endDate:endDate,
        kids:isKids === "Kids"?true:false
    }

    const onSuccess = (data) =>{
        setLoading(false)
        setConversionData(data)
    }

    const onError = (msg) =>{
        setLoading(false)
        message.error(msg);
    }
      dispatch(GetDemoConversionDetails(data,onSuccess,onError))
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: text => <a>{text}</a>,
    },
    {
      title: 'Phone number',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
        title: 'Demo taken',
        dataIndex: 'totalDemo',
        key: 'totalDemo',
      },
      {
        title: 'Conversion',
        dataIndex: 'totalConversion',
        key: 'totalConversion',
      },
      {
        title: 'Percentage',
        dataIndex: 'percentage',
        key: 'percentage',
        sorter: (a, b) => (a.percentage - b.percentage),
        render: (text)=> <p>{text}%</p>
      }
  ];
  
  const data = conversionData?.map((data,i)=>{
      return{
         key:i,
         name:data.teacher[0]?.name?data.teacher[0].name:"N/A",
         phoneNumber:data.teacher[0]?.phoneNumber?data.teacher[0].phoneNumber:"N/A",
         email:data.teacher[0]?.email?data.teacher[0].email:"N/A",
         totalDemo:data.totalDemo,
         totalConversion:data.totalSubscription,
         percentage:percentage(Number(data.totalSubscription),Number(data.totalDemo))
      }
  }).sort((a, b) => (b.percentage - a.percentage))

  return (
    <div>
      <PageBreadcrumb route={["teacher", "Teachers demo to conversion"]} />
      <Card
        title={
          <Row>

          <Col span={6}>
            <Space>
            <Radio.Group onChange={(e)=>setIsKids(e.target.value)} value={isKids}>
              <Radio value={"Kids"}>Kids</Radio>
              <Radio value={"Regular"}>Regular</Radio>
            </Radio.Group>
            </Space>
          </Col>


            <Col span={8}>
              <Space>
                <RangePicker
                  onChange={datePicker}
                  defaultValue={[moment(startDate, dateFormat), moment(endDate, dateFormat)]}
                  format={dateFormat}
                />
                <Button type="primary" onClick={() => getDemoToConversion()}>
                  Search
                </Button>
              </Space>
            </Col>

          </Row>
        }
        style={{ marginBottom: "1rem" }}
      >
{loading ? (
        <div className="spin-center">
          <Spin size="large" />
        </div>
      ) : (
<Table columns={columns} dataSource={data} pagination={false} />
      )}
      </Card>
    </div>
  );
};

export default DemoToConversion;
