import React from "react";
import { Breadcrumb,Card } from "antd";
import { Link } from "react-router-dom";

const PageBreadcrumb = ({ route }) => {
  return (
    <div style={{background:"#fff",padding:"0.1rem",marginBottom:"1rem"}}>
      <Breadcrumb style={{ margin: "16px" }} className="breadcrumb">
        {route.map((data, i) => (
          <Breadcrumb.Item key={i}> {data} </Breadcrumb.Item>
        ))}
      </Breadcrumb>
    </div>
  );
};

export default PageBreadcrumb;
