import React, { useEffect, useState } from "react";
import { message, Select, Space, Spin } from "antd";
import { getTeacherList_ } from "../../redux/actions/TeacherAction";

const AddRemoveMentee = ({selectedTeachers,setSelectedTeachers}) => {
  const [options, setOptions] = useState([]);
  const [loader, setLoader] = useState(false);

  const handleChange = (val,data) => {
    const formattedData = data.map(res => {
      delete res.label
      delete res.value
      return res
    })
    setSelectedTeachers(formattedData)
  };

  useEffect(() => {
    setLoader(true);
    const payload = {
      page: 1,
      limit: 1000,
      subject: "English",
    };

    const onSuccess = (data) => {
      const teachers = data.map((teacher) => {
        return { value: teacher.name, label: teacher.name ,id:teacher._id,name:teacher.name };
      });
      setOptions(teachers);
      setLoader(false);
    };
    const onError = (msg) => {
      message.error(msg);
      setLoader(false);
    };
    getTeacherList_(payload, onSuccess, onError);
  }, []);

  return (
    <>
      {loader ? (
        <div className="spin-center">
          <Spin size="large" />
        </div>
      ) : (
        <Space
          style={{
            width: "100%",
            margin: "20px 0",
          }}
          direction="vertical"
        >
          <Select
            mode="multiple"
            style={{
              width: "100%",
            }}
            placeholder="Please select"
            defaultValue={selectedTeachers}
            onChange={handleChange}
            options={options}
          />
        </Space>
      )}
    </>
  );
};
export default AddRemoveMentee;
