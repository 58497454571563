import React, { useState, useEffect } from "react";
import { Table, Tag, Spin, message, Button, Space, Row, Col, Card, Select } from "antd";
import { useDispatch } from "react-redux";
import PageBreadcrumb from "../../component/PageBreadcrumb";
import Search from "antd/lib/input/Search";
import { Link } from "react-router-dom";
import { AddAdminRole, CreateAdmin, GetAdminList } from "../../redux/actions/AuthAction";
import AddAdmin from "../../component/adminComponent/AddAdmin";
import Modal from "antd/lib/modal/Modal";

const AddRoleModal = ({roles,name,adminId}) =>{

  const [openModal, setOpenModal] = useState(false)
  const [adminRoles, setAdminRoles] = useState()
  const [loader,setLoader] = useState(false)

  const { Option } = Select;

  useEffect(() => {
    if(roles){
      setAdminRoles(roles)
    }
  }, [])
  

  const handleAddRoles = (value) =>{
    setAdminRoles(value)
  }

  const changeRoles = (id, currRoles) => {
    setLoader(true)
    const data = {
        adminId:id.toString(),
        role:currRoles
    }
    const onSuccess = (res) => {
      setLoader(false)
      setOpenModal(false)
      message.success("Role updated successfully")
    };
    const onError = (errmsg) => {
      setLoader(false)
      message.error(errmsg);
    };
    AddAdminRole(data, onSuccess, onError);
  };

  return(
    <>
    <Button onClick={()=>setOpenModal(true)}>Add role</Button>
    <Modal
    centered
    visible={openModal}
    onCancel={() =>setOpenModal(false)}
    footer={null}
    width={500}
  >
    <h3>Choose the roles to assign to {name}</h3>
    <Select mode="multiple" style={{ width: 350 }} value={adminRoles} onChange={handleAddRoles}>
        <Option disabled value={"Select"}>select roles</Option>
        <Option value={"superadmin"}>superadmin</Option>
        <Option value={"admin"}>admin</Option>
        <Option value={"telecaller"}>telecaller</Option>
        <Option value={"reviewer"}>reviewer</Option>
        <Option value={"editor"}>editor</Option>
        <Option value={"subadmin"}>subadmin</Option>

    </Select> 
    <Button style = {{float:"right"}} onClick={()=>changeRoles(adminId, adminRoles)} disabled={loader}>{loader?"Adding..":"Add"}</Button>
  </Modal>
  </>
  )
}

const AllAdmin = () => {
  const dispatch = useDispatch();
  const [activePage, setActivePage] = useState(1);
  
  const [admins, setAdmins] = useState();
  const [loader, setLoader] = useState()
  
  useEffect(() => {
    getReviewerAndEditorList();
  }, []);

  const getReviewerAndEditorList = () => {
    setLoader(true)
    const onSuccess = (res) => {
      setLoader(false)
      setAdmins(res.res.data?.adminList);
    };
    const onError = (errmsg) => {
      setLoader(false)
      console.log(errmsg);
    };
    GetAdminList(onSuccess, onError);
  };

  const columns = [
    { title: "No", width: 250, dataIndex: "no", key: "1" },
    { title: "Name", width: 250, dataIndex: "name", key: "2" },
    { 
      title: "Role", 
      width: 250, 
      dataIndex: "role", 
      key: "3" ,
      render:(roles)=>
        roles.map((role, i)=>(
          <div key = {i}>
              {role}
          </div>
      ))
    },
    { 
      title: "Add new Roles",
      width: 350, 
      dataIndex: "addRole", 
      key: "4" ,
      render:(_,{role,name,key})=>{
        return <AddRoleModal roles={role} name={name} adminId={key}/>
      }
  },
  ];

  const data = admins?.map((data, i) => ({
    key: data._id,
    no: i + 1,
    name: data.name,
    role: data.roles,
    addRole:data.roles,
  }));

  return (
    <div>
      <PageBreadcrumb route={["dashboard", "Admins"]} />
      <Card style={{ marginBottom: "1rem" }}>
        <Row justify="space-between">
          <Col span={12}>
            <Search
              placeholder="Search admin by name/number"
              enterButton="Search"
            />
          </Col>
          <Col span={4}>
            <AddAdmin />
          </Col>
        </Row>
      </Card>
      {
        loader?
        <div className="spin-center">
          <Spin size="large" />
        </div>
        :
        <Table
        columns={columns}
        dataSource={data}
        scroll={{ x: 1300 }}
        pagination={{
          pageSize: 80,
          total: admins,
          defaultCurrent: activePage,
        }}
      />
      }

    </div>
  );
};

export default AllAdmin;
