import api from "../apis/demoPoolApi";
import * as constant from "../constants/Constant";

export const AddDemoPool= (data, onSuccess, onError) => (dispatch) => {
  api
    .DemoPoolApi()
    .AddDemoPools(data)
    .then((res) => {
      onSuccess();
      console.log(res.data);
    })
    .catch((err) => {
      console.log(err.response.data.message);
      onError(err.response.data.message);
    });
};

export const GetDemoPool= (onSuccess, onError) => (dispatch) => {
  api
    .DemoPoolApi()
    .GetDemoPools()
    .then((res) => {
      onSuccess(res);
      console.log(res);
    })
    .catch((err) => {
      console.log(err);
      onError(err);
    });
};

export const GetDemoPoolDetails= (onSuccess, onError, poolid) => (dispatch) => {
  api
    .DemoPoolApi()
    .getPoolDetails(poolid)
    .then((res) => {
      onSuccess(res);
      console.log(res);
    })
    .catch((err) => {
      console.log(err);
      onError(err);
    });
};
export const GetMaxSlot = (localDate,demoConversionFilter, onSuccess, onError) => (dispatch) => {
  api
    .DemoPoolApi()
    .getMaxAvailableSlots(localDate, "Asia/Calcutta",demoConversionFilter) // Pass localDate and timezone here
    .then((res) => {
      onSuccess(res);
      console.log(res);
    })
    .catch((err) => {
      console.log(err);
      onError(err);
    });
};

export const UpdateDemoPool= (data, onSuccess, onError) => (dispatch) => {
  api
    .DemoPoolApi()
    .UpdateDemoPools(data)
    .then((res) => {
      onSuccess();
      console.log(res.data);
    })
    .catch((err) => {
      console.log(err.response.data.message);
      onError(err.response.data.message);
    });
};

export const GetAvailableSlots= (data, onSuccess, onError,id) => {
  api
    .DemoPoolApi()
    .getAvailableSlots(data)
    .then((res) => {
      onSuccess(id,res.data.bookSlots,res.data.totalSlotsForDay);
      // console.log("AVAILABLE SLOT API",res.data.bookSlots);
    })
    .catch((err) => {
      console.log(err);
      onError(err.response.data.message);
    });
};



  