import React from "react";
import {
  Button,
  Card,
  Divider,
  Space,
  Tag,
  Typography,
  Avatar,
  List,
  Collapse
} from "antd";
import { VideoCameraOutlined, BookOutlined, UsergroupAddOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import AddUpdateModule from "./AddUpdateModule";
import EditTopic from "./EditTopic";

const { Title } = Typography;
const { Panel } = Collapse;

const ModuleList = () => {
  const curriculum = useSelector(
    (state) => state.CurriculumReducer.curriculumDetails
  );

  const customHeaderStyle = {
    background: "#fafafa",
    color: "#0E433A",
    paddingX: "2px",
  };

  const Header = (module) => (
      <Card
      headStyle={customHeaderStyle}
      bordered={false}
      title={
        <div style={{ display: "flex" }}>
          <Title level={5}>{module?.name}</Title>
          <Tag
            color="purple"
            style={{
              marginTop: "auto",
              marginBottom: "auto",
              marginLeft: "10px",
            }}
          >
            {module.level}
          </Tag>
        </div>
      }
      extra={
        <div onClick={(e)=>{e.stopPropagation()}}><AddUpdateModule curriculumId={curriculum._id} moduleId={module._id} /></div>}
      bodyStyle={{ padding: 0 }}
    >
    </Card>
  );

  return (
    <Space direction="vertical" size={16} style={{ width: "100%" }}>
      <Collapse>
        {curriculum.modules.map((module, i) => (
            <Panel header={Header(module)} key={i}>
              <Card
                style={{ padding: 0, border: "none" }} // This might also be necessary
                bodyStyle={{ padding: 0 }}
              >
                {module?.chapters?.map((chapter, i) => (
                  <div key={i}>
                    <Divider>{chapter.name}</Divider>
                    <div className="topics">
                      <List
                        itemLayout="horizontal"
                        dataSource={chapter?.topics}
                        renderItem={(topic, index) => (
                          <List.Item
                            actions={
                              [
                                <EditTopic curriculumId={curriculum._id} curriculum={curriculum}
                                topicData={topic} moduleId={module._id} chapterId={chapter._id}/>,
                              ]
                            } 
                          >
                            <List.Item.Meta
                              avatar={
                                <Avatar
                                  shape="square"
                                  icon={
                                    index === 3 ? (
                                      <BookOutlined />
                                    ) : (
                                      <VideoCameraOutlined />
                                    )
                                  }
                                />
                              }
                              // title={topic?.name}
                              description={topic?.name}
                            />
                          </List.Item>
                        )}
                      />
                    </div>
                  </div>
                ))}

                <List
                  itemLayout="horizontal"
                  dataSource={module?.topics}
                  renderItem={(topic, index) => (
                    <List.Item
                      style={{paddingLeft: '8px'}}
                      actions={
                        [
                          <EditTopic curriculumId={curriculum._id} curriculum={curriculum}
                          topicData={topic} moduleId={module._id} />,
                        ]
                      } 
                    >
                      <List.Item.Meta
                        avatar={
                          <Avatar
                            shape="square"
                            icon={module.level ? 
                              index === 3 ? (
                                <BookOutlined />
                              ) : (
                                <VideoCameraOutlined />
                              )
                              : topic.type==="Session" ? 
                              (
                                <VideoCameraOutlined />
                              )
                              : <UsergroupAddOutlined />
                            }
                          />
                        }
                        style={{alignItems: 'center'}}
                        title={topic?.name}
                      />
                    </List.Item>
                  )}
                />
              </Card>
            </Panel> 
        ))}
      </Collapse>
    </Space>
  );
};
export default ModuleList;
