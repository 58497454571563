import React, { useEffect, useState } from 'react'
import PageBreadcrumb from '../../component/PageBreadcrumb'

import {
  Tabs,
  Form,
  Input,
  Button,
  Row,
  Col,
  Divider,
  Card,
  Select,
  message,
  PageHeader,
  Spin,
  Switch
} from "antd";
import { useDispatch } from "react-redux";
import { formatPaneData, formatPopulateData, formatPopulateVideoSessionData, formatTags, groupByNumber, groupBySessionNumber, joinTags } from "../../helper/helper";
import { AddEditCourse, GetACourse } from "../../redux/actions/CoursesAction";
import { useHistory,useLocation } from "react-router-dom";
import { getCreatorsList } from "../../redux/actions/TeacherAction";
import DraggableTabs from '../Courses/DraggableTabs';
import { GetAllVideoSessions } from '../../redux/actions/UserAction';
import { AddEditVideoSession } from '../../redux/actions/SessionAction';

const AddVideos = ({ id, isSingleThumb, form, formChanges}) => {
  const { Option } = Select;

  // deploy comment

    return (
      <div>
        <Row justify="space-between">
          <Col span={11}>
            <Form.Item 
            label={`Video Session${id} Title`}  
            name={`title${id}`} 
            required 
            tooltip="This is a required field"
            rules={[{ required: true, message: 'Please add video session title !' }]}
            >
              <Input placeholder="VideoSession title" />
            </Form.Item>
          </Col>
          <Col span={11}>
            <Form.Item
              label={`Video Session${id} Description`}
              name={`description${id}`}
              required
              tooltip="This is a required field"
              rules={[{ required: true, message: 'Please add video session short description !' }]}
            >
              <Input placeholder="Video Session short description" />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="space-between">
          <Col span={11}>
            <Form.Item 
            label={`Video Session${id} Link`} 
            name={`videoLink${id}`} 
            required 
            tooltip="This is a required field"
            rules={[{ required: true, message: 'Please add video session link !' }]}
            >
              <Input placeholder="https://abcd.com" />
            </Form.Item>
          </Col>

          <Col span={11}>
            <Form.Item 
            label={`Video Session${id} Thumbnail`} 
            name={`image${id}`} 
            required 
            tooltip="This is a required field"
            rules={[{ required: true, message: 'Please add video session thumbnail !' }]}
            >
              <Input placeholder="https://abcd.com" />
            </Form.Item>
          </Col>

        </Row> 

        <Row justify="space-between">
          <Col span={11}>
            <Form.Item 
            label={`Video Session${id} Doc Link`} 
            name={`learnerDoc${id}`} 
            // required 
            tooltip="This is a required field"
            // rules={[{ required: true, message: 'Please add video session doc link !' }]}
            >
              <Input placeholder="https://abcd.com" />
            </Form.Item>
          </Col>

          {/* <Col span={11}>
            <Form.Item 
            label={`Video Session${id} accessibility time`} 
            name={`accessiblityTime${id}`} 
            required 
            tooltip="This is a required field"
            rules={[{ required: true, message: 'Please add video session accessibility time !' }]}
            >
              <Input placeholder="https://abcd.com" />
            </Form.Item>
          </Col> */}

        </Row> 
      </div>
    );
  };

  const initialPanes = [
    { title: "Session 1", content: <AddVideos id={1} />, key: "1" }
  ];


const VideoSessions = () => {

  const dispatch = useDispatch();
  const history = useHistory();
  const {search} = useLocation()
  const action = new URLSearchParams(search).get("action");
  const id = new URLSearchParams(search).get("id");
  let keyArr = [`title`, `description`, `videoLink`, `image`, `learnerDoc`];
  const admin = JSON.parse(localStorage.getItem("ADMIN"))
 


  const { TabPane } = Tabs;
  const { TextArea } = Input;
  const { Option } = Select;

  const children = [];

  for (let i = 10; i < 36; i++) {
    children.push(
      <Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>
    );
  }

  const [form] = Form.useForm();
  const [activeState, setActiveState] = useState(initialPanes[0].key);
  const [panes, setPanes] = useState(initialPanes);
  const [loader, setLoader] = useState(false);
  const [val, setVal] = useState("");
  const [instructor, setInstructor] = useState([]);
  const [disabled, setDisabled] = useState(true)
  const [instructorId, setInstructorId] = useState([])
  const [data, setData] = useState();
  const handleChange = (e) => {
    const regex = /^(1[0-2]|[1-9])$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setVal(e.target.value);
    }
  };


  useEffect(()=> {
    const onSuccess = (data) =>{
       console.log("data",data)
       setInstructor(data.creators)
      //  setInstructorId(data?.creators.map((creator) => (creator._id)))
    }
       const onError = (msg) =>{
        console.log(msg)
     }
     getCreatorsList(onSuccess,onError)
  }, [])

  console.log("testing", instructor)
  console.log("testingid", instructorId)


  const [fetchingCourseData, setFetchingCourseData] = useState(false);
  const [preFillValues, setPreFillValues] = useState({
      subject: "Financial Education"  
  });
  const [isSingleThumb, setIsSingleThumb] = useState(true);
  const [formChanges, setFormChanges] = useState(true);
  const handleVideoThumb = () => {
    setIsSingleThumb(!isSingleThumb);
  }


  useEffect(() => {
    fetchVideoSessions();
  }, [])

  const fetchVideoSessions = () => {
    setFetchingCourseData(true)
    const onSuccess = (data) =>{

      var videoData =  formatPopulateVideoSessionData(data.videoSessionsDetail)

      var videoComponent = [];
      data.videoSessionsDetail.map((data,i)=>{
         videoComponent.push({ title: `Video ${i+1}`, content: <AddVideos id={i+1} data={data} />, key: i+1 })
      })

      setPanes(videoComponent)
      setActiveState(String(videoComponent.length))

       var preFill = {
        ...videoData
      }

      setPreFillValues(preFill)
      setIsSingleThumb(data.courseData?.isSingleThumb);
      setFetchingCourseData(false)
    }

    const onError = (error) =>{
      message.error(error)
      setFetchingCourseData(false)
    }

    const params = {
      subject: "Financial Education",
    }

  dispatch(GetAllVideoSessions(params,onSuccess,onError))
  }

  const onChange = (activeKey) => {
    setActiveState(activeKey);
  };

  const onEdit = (targetKey, action) => {
    if (action === "remove") {
      remove(targetKey);
    } else if (action === "add") {
      add();
    }
  };

  const remove = (key) => {
    let newPre = [...panes];
    const id = newPre.findIndex((item) => +item.key === +key);

    newPre.splice(id, 1);
    if(newPre[id])
    for(let i=id; i<newPre.length; i++){
      console.log("before", newPre[i], i);
      newPre[i] = {
        key: i+1,
        title: `Video ${i+1}`,
        content: <AddVideos id={i+1} />
      };

      console.log("after", newPre[i], i);
      keyArr.forEach(elm=>{
        let key2 = `${elm}${i+2}`;
        let value = form.getFieldValue(key2);
        console.log("get", key2, value);
        let key1= `${elm}${i+1}`;
        console.log("set", key1, value);
        form.setFieldsValue({[key1]: value});
        form.setFieldsValue({[key2]: ""});
      })

      console.log("prevlog", newPre);
      console.log("formvalues", form.getFieldsValue(true));

        setActiveState(String(id+1));
    }
    else {
      console.log("running else part", id);
      keyArr.forEach(elm=>{
        let key1 = `${elm}${id+1}`;
        form.setFieldsValue({[key1]: ""});
      })
      setActiveState(String(id));
     }
    setPanes(newPre);
    setFormChanges((prev)=>!prev);
  };

  const add = () => {
    const activeKey = (panes && panes.length ? +panes[panes.length - 1].key : 0) + 1;
    console.log("adding", activeKey);
    setActiveState(String(activeKey));
    setPanes((prev) => [
      ...prev,
      {
        title: "Video " + activeKey,
        content: <AddVideos id={activeKey} />,
        key: activeKey,
      },
    ]);
  };



  const onFinish = (values) => {
    console.log("mylogfinish", panes, values);
    const formFieldsValue = form.getFieldsValue(true);
    console.log("form value", formFieldsValue);
    let groupByNumberData = groupBySessionNumber(formFieldsValue);
    groupByNumberData = groupByNumberData.filter(video=>video.title);
    groupByNumberData?.forEach((e)=>{
      e.subject="Financial Education";
      e.duration=60;
    });
    
    console.log("formdata groupbynumber", groupByNumberData);
    // return;
    // setLoader(true)
    var data = {
      // courseName: values.courseName,
      // coursePrice: values.coursePrice,
      // teacherName: values.teacherName,
      // teacherDesc: values.teacherDesc,
      // courseDesc: values.courseDesc,
      // subject: values.subject,
      // thumbnail: values.thumbnail,
      // courseStatus:values.courseStatus,
      // courseLevel: values.courseLevel,
      subject: "Financial Education",
      adminId: admin?._id,
      videos: groupByNumberData,
      // teacherImage: values.teacherImage,
      // creatorId: instructorId,
  }

  console.log("payload", data);


  const onSuccess = (msg) =>{
    setLoader(false)
    message.success(msg);
    // setPanes(initialPanes);
    // form.resetFields()
    fetchVideoSessions();
  }

  const onError = (err) =>{
    setLoader(false)
    message.error(err);
  }

  dispatch(AddEditVideoSession(data,onSuccess,onError))

  }

  // useEffect(()=> {
  //   setPanes(prev=>{

  //     for(let i=0; i<prev.length; i++){
  //       prev[i] = {
  //         key: i+1,
  //         title: `Video ${i+1}`,
  //         content: <AddVideos id={i+1} isSingleThumb={isSingleThumb} />
  //       };
        
  //     }
  //     return [...prev];
  //   })
  // },[isSingleThumb])

  const onFormValueChange = (data) => {
    console.log("changed", data);
  }

const handleInputChange = () =>{
   if(instructor.value === "Other"){
    setDisabled(false)
   }
}

console.log("disabled",disabled)

  return (
    <div>
      <PageBreadcrumb route={["Session", "Video sessions"]} />
  {
    !fetchingCourseData?
    <Card>
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      initialValues={preFillValues}
        onValuesChange={onFormValueChange}
        // requiredMark={requiredMark}
    >
      <Divider orientation="left">Add video sessions</Divider>
      
      <DraggableTabs type="editable-card" onChange={onChange} activeKey={activeState} setActiveState={setActiveState} onEdit={onEdit} panes={panes} setPanes={setPanes} form={form} setFormChanges={setFormChanges} keyArr={keyArr} >
        {panes.map((pane) => (
          <TabPane tab={pane.title} key={pane.key}>
            {/* {pane.content} */}
            <AddVideos id={pane.key} isSingleThumb={isSingleThumb} form={form} formChanges={formChanges} />
          </TabPane>
        ))}
      </DraggableTabs>


      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loader}  block>
          Update
        </Button>
      </Form.Item>
    </Form>
  </Card>
  :
  <div className="spin-center">
    <Spin size="large" />
  </div>
  }
 
    </div>
  );
};

export default VideoSessions;