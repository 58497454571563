import api from "../apis/UserApis";
import * as constant from "../constants/Constant";

export const GetAllTeacher = (data, onSuccess, onError) => (dispatch) => {
  api
    .UserApis()
    .getAllTeacher(data)
    .then((res) => {
      dispatch({
        type: constant.GET_ALL_TEACHER,
        payload: res.data.teacherData,
      });
      if (onSuccess) {
        onSuccess(res.data.teacherData, res.data);
      }
    })
    .catch((err) => {
      console.log(err);
      if (onError) {
        onError(err);
      }
    });
};

export const getInfluencer =
  (data, params, onSuccess, onError) => async (dispatch) => {
    try {
      console.log(data, params);
      var result = await api.UserApis().getInfluencer(data, params);
      console.log(result);
      if (result.status === 200) {
        onSuccess(result);
      }
    } catch (error) {
      console.log(error);
      onError();
    }
  };

export const GetAllInfluencer = (data, onSuccess, onError) => (dispatch) => {
  api
    .UserApis()
    .getAllInfluencer(data)
    .then((res) => {
      console.log(res);
      onSuccess(res.data);
    })
    .catch((err) => {
      console.log(err);
      onError(err);
    });
};

export const GetInfluencerEarningsData =
  (data, onSuccess, onError) => (dispatch) => {
    api
      .UserApis()
      .getInfluencerEarningsData(data)
      .then((res) => {
        console.log(res);
        onSuccess(res.data);
      })
      .catch((err) => {
        console.log(err);
        onError(err);
      });
  };

export const AddAffiliateBank = (data, onSuccess, onError) => (dispatch) => {
  api
    .UserApis()
    .addAffiliateBank(data)
    .then((res) => {
      console.log(res);
      onSuccess(res.data);
    })
    .catch((err) => {
      console.log(err);
      onError(err);
    });
};

export const ChangeInfluencerStatus = (data, onSuccess, onError) => {
  api
    .UserApis()
    .changeInfluencerStatus(data)
    .then((res) => {
      console.log(res);
      onSuccess(res.data);
    })
    .catch((err) => {
      console.log(err.response);
      onError(err.response.data.message);
    });
};

export const SendAffiliatePay = (data, onSuccess, onError) => {
  api
    .UserApis()
    .sendAffiliatePay(data)
    .then((res) => {
      console.log(res);
      onSuccess(res.data);
    })
    .catch((err) => {
      console.log(err.response);
      onError(err.response.data.message);
    });
};

export const SearchTeacher = (data, onSuccess, onError) => (dispatch) => {
  api
    .UserApis()
    .SearchTeacherByNameOrPhoneno(data)
    .then((res) => {
      onSuccess(res.data.Teacher);
    })
    .catch((err) => {
      console.log(err);
      onError(err.response.data.message);
    });
};

export const searchLearner = (data, onSuccess, onError) => (dispatch) => {
  api
    .UserApis()
    .searchLearner(data)
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      console.log(err.response);
      onError(err.response.data.message);
    });
};

export const learnerProfile = (data, onSuccess, onError) => (dispatch) => {
  api
    .UserApis()
    .getLearnerbyId(data)
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      console.log(err.response);
      onError(err);
    });
};

export const GetAlllearner = (data, onSuccess, onError) => (dispatch) => {
  api
    .UserApis()
    .findAllLearner(data)
    .then((res) => {
      console.log(res.data);
      onSuccess(res.data);
    })
    .catch((err) => {
      console.log(err);
      onError(err.response.data.message);
    });
};

export const GetLearnerPayoutRequests =
  (data, onSuccess, onError) => (dispatch) => {
    api
      .UserApis()
      .getLearnerPayoutRequests(data)
      .then((res) => {
        console.log(res.data);
        onSuccess(res.data);
      })
      .catch((err) => {
        console.log(err);
        onError(err.response.data.message);
      });
  };
export const GetAffiliatePayoutRequests =
  (data, onSuccess, onError) => (dispatch) => {
    api
      .UserApis()
      .getAffiliatePayoutRequests(data)
      .then((res) => {
        console.log(res.data);
        onSuccess(res.data);
      })
      .catch((err) => {
        console.log(err);
        onError(err.response.data.message);
      });
  };

export const SendLearnerPayout = (data, onSuccess, onError) => (dispatch) => {
  api
    .UserApis()
    .sendLearnerPayout(data)
    .then((res) => {
      console.log(res.data);
      onSuccess(res.data);
    })
    .catch((err) => {
      console.log(err);
      onError(err.response.data.message);
    });
};

export const SendAffiliatePayout = (data, onSuccess, onError) => (dispatch) => {
  api
    .UserApis()
    .sendAffiliatePayout(data)
    .then((res) => {
      console.log(res.data);
      onSuccess(res.data);
    })
    .catch((err) => {
      console.log(err);
      onError(err.response.data.message);
    });
};

export const GetLearnerNotes = () => (dispatch) => {
  api
    .UserApis()
    .getLearnerNotes()
    .then((res) => {
      console.log(res.data);
    })
    .catch((err) => {
      console.log(err.response);
    });
};

export const GetSubscribedUser = () => (dispatch) => {
  api
    .UserApis()
    .getSubscribedUser()
    .then((res) => {
      console.log(res.data.subscribedLearners);
      dispatch({
        type: constant.GET_ALL_SUBSCRIBED_USER,
        payload: res.data.subscribedLearners,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const GetAllLearner = () => (dispatch) => {
  api
    .UserApis()
    .findAllLearner()
    .then((res) => {
      console.log(
        res.data.learner.filter((data) => data.isLearnerSubscribed == true)
      );
      dispatch({
        type: constant.GET_ALL_SUBSCRIBED_USER,
        payload: res.data.learner.filter(
          (data) => data.isLearnerSubscribed == true
        ),
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getAllCoupons = (data, onSuccess) => (dispatch) => {
  api
    .UserApis()
    .getAllCoupons()
    .then((res) => {
      console.log(res);
      onSuccess(res);
      // dispatch({
      //     type:constant.GET_ALL_SUBSCRIBED_USER,
      //     payload:res.data.learner.filter(data => data.isLearnerSubscribed == true)
      // })
    })
    .catch((err) => {
      console.log(err);
    });
};



export const DeleteCoupon = (data, onSuccess, onError) => (dispatch) => {
  console.log("couponapi,", data);
  api
    .UserApis()
    .deleteCoupon(data)
    .then((res) => {
      console.log(res);
      onSuccess(res);
    })
    .catch((err) => {
      onError(err);
      console.log(err);
    });
};

export const FindLearnerByPhoneno =
  (data, onSuccess, onError) => (dispatch) => {
    api
      .UserApis()
      .findLearner(data)
      .then((res) => {
        onSuccess(res.data.learner._id);
        // console.log(res.data.learner)
      })
      .catch((err) => {
        console.log(err);
        onError();
      });
  };

export const GetUserSchedule = (data, onSuccess, onError) => (dispatch) => {
  api
    .UserApis()
    .getUserSchedule(data)
    .then((res) => {
      onSuccess(res.data.userSchedule[0]);
      //  console.log(res.data.userSchedule[0])
    })
    .catch((err) => {
      console.log(err);
      onError();
    });
};

export const FindLearnerSubscription =
  (data, onSuccess, onError) => (dispatch) => {
    api
      .UserApis()
      .getSubscriptions(data)
      .then((res) => {
        // console.log(res.data.currentsubscriptions[0])
        onSuccess(res.data.currentsubscriptions[0]);
      })
      .catch((err) => {
        console.log(err);
        onError();
      });
  };

export const Feedback = (data, onSuccess, onError) => (dispatch) => {
  api
    .UserApis()
    .feedback(data)
    .then((res) => {
      dispatch({
        type: constant.USER_FEEDBACK,
        payload: res.data,
      });
      if (onSuccess) {
        onSuccess();
      }
    })
    .catch((err) => {
      if (onError) {
        onError();
      }
      console.log(err);
    });
};

export const Query = (onSuccess) => (dispatch) => {
  api
    .UserApis()
    .query()
    .then((res) => {
      //  console.log(res.data.eedbacks)
      onSuccess(res.data.feedbacks);
    })
    .catch((err) => {
      console.log(err);
    });
};

export const GetPlans = (onSuccess, phoneNumber) => (dispatch) => {
  api
    .UserApis()
    .getPlans(phoneNumber)
    .then((res) => {
      console.log(res.data.plansData);
      onSuccess(res.data.plansData, res.data.dynamicPricing);
    })
    .catch((err) => {
      console.log(err);
    });
};

export const AddUser = (data, onSuccess, onError) => (dispatch) => {
  api
    .UserApis()
    .addUser(data)
    .then((res) => {
      console.log(res.data);
      onSuccess();
    })
    .catch((err) => {
      console.log(err.response);
      onError(err.response.data.message);
    });
};

export const CreateCoupon = (data, onSuccess, onError) => (dispatch) => {
  api
    .UserApis()
    .addCoupon(data)
    .then((res) => {
      console.log(res.data);
      onSuccess(res);
    })
    .catch((err) => {
      console.log(err.response);
      onError(err.response.data.message);
    });
};

export const UpdateCoupon = (data, onSuccess, onError) => (dispatch) => {
  api
    .UserApis()
    .updateCoupon(data)
    .then((res) => {
      console.log(res.data);
      onSuccess(res);
    })
    .catch((err) => {
      console.log(err.response);
      onError(err.response.data.message);
    });
};

export const GetUTMData = (data, onSuccess, onError) => (dispatch) => {
  api
    .UserApis()
    .getUTMData(data)
    .then((res) => {
      console.log(res);
      onSuccess(res.data);
      dispatch({
        type: constant.FETCH_CAMPAIGN_DATA,
        payload: res.data.utmData,
      });
    })
    .catch((err) => {
      console.log(err);
      onError(err.response.data.message);
    });
};

export const GetUTMConversion = (data, onSuccess, onError) => (dispatch) => {
  api
    .UserApis()
    .getUTMConversion(data)
    .then((res) => {
      console.log(res);
      onSuccess(res.data);
    })
    .catch((err) => {
      console.log(err);
      onError(err.response.data.message);
    });
};

export const GetAllApprovalReq =
  (payload, onSuccess, onError) => (dispatch) => {
    api
      .UserApis()
      .getAllApprovalRequests(payload)
      .then((res) => {
        console.log("Approval :", res);
        onSuccess(res.data);
      })
      .catch((err) => {
        console.log(err.response);
        onError(err.response.data.message);
      });
  };

export const ChangeStatus = (data, onSuccess, onError) => (dispatch) => {
  api
    .UserApis()
    .changeStatus(data)
    .then((res) => {
      console.log(res);
      onSuccess(res.data.message);
    })
    .catch((err) => {
      console.log(err.response);
      onError(err.response.data.message);
    });
};

export const SearchBySerialNum = (serialNumber, onSuccess, onError) => {
  api
    .UserApis()
    .getRequestBySerialNumber(serialNumber)
    .then((res) => {
      console.log(res);
      onSuccess(res.data);
    })
    .catch((err) => {
      console.log(err.response);
      onError(err.response.data.message);
    });
};

export const SearchByName = (data, onSuccess, onError) => {
  api
    .UserApis()
    .searchYTRequestsByName(data)
    .then((res) => {
      console.log(res);
      onSuccess(res.data);
    })
    .catch((err) => {
      console.log(err.response);
      onError(err.response.data.message);
    });
};

export const UtmConversionDetails = (data, onSuccess, onError) => {
  api
    .UserApis()
    .utmConversionDetails(data)
    .then((res) => {
      console.log(res);
      onSuccess(res.data.utmData);
    })
    .catch((err) => {
      console.log(err.response);
      onError(err.response.data.message);
    });
};

export const GetUtmParams = (data, onSuccess, onError) => {
  api
    .UserApis()
    .getUtmParam(data)
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      console.log(err.response);
      onError(err.response.data.message);
    });
};

export const SendBookSessionNotification = (data, onSuccess, onError) => {
  api
    .UserApis()
    .sendBookSessionNotification(data)
    .then((res) => {
      console.log(res);
      onSuccess(res.data.utmData);
    })
    .catch((err) => {
      console.log(err.response);
      onError(err.response.data.message);
    });
};
export const GetAllYouTubers = (onSuccess, onError) => (dispatch) => {
  api
    .UserApis()
    .getAllYouTubers()
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      onError(err.response.data);
    });
};

export const GetAllVideoSessions = (data, onSuccess, onError) => (dispatch) => {
  api
    .UserApis()
    .getAllVideoSessions(data)
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      onError(err.response.data.message);
    });
};

export const GetUserVideoSessions =
  (data, onSuccess, onError) => (dispatch) => {
    api
      .UserApis()
      .getUserVideoSession(data)
      .then((res) => {
        onSuccess(res.data);
      })
      .catch((err) => {
        onError(err.response.data.message);
      });
  };

export const SearchUTMCampaigns = (data, onSuccess, onError) => (dispatch) => {
  console.log(data);
  api
    .UserApis()
    .searchUTMCampaigns(data)
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      onError(err.response.data.message);
    });
};

export const AddFavoriteTeacher = (data, onSuccess, onError) => {
  api
    .UserApis()
    .addFavoriteTeacher(data)
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      onError(err.response.data.message);
    });
};

export const RemoveFavoriteTeacher = (data, onSuccess, onError) => {
  api
    .UserApis()
    .removeFavoriteTeacher(data)
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      onError(err.response.data.message);
    });
};

export const GetFavoriteTeachers = (data, onSuccess, onError) => {
  api
    .UserApis()
    .getFavoriteTeachers(data)
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      onError(err.response.data.message);
    });
};

export const DeleteLearnerAccount = (data, onSuccess, onError) => {
  api
    .UserApis()
    .deleteLearnerAccount(data)
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      onError(err.response.data.message);
    });
};

export const ResetPauseLimit = (data, onSuccess, onError) => {
  api
    .UserApis()
    .resetPauseLimit(data)
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      onError(err.response.data.message);
    });
};
