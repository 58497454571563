import React, { useState, useEffect } from "react";
import { Table, Spin } from "antd";
import PageBreadcrumb from "../component/PageBreadcrumb";
import { useDispatch } from "react-redux";
import { Query } from "../redux/actions/UserAction";
import { getDate } from "../helper/helper";

const TeacherQuery = () => {
  const dispatch = useDispatch();
  const [query, setQuery] = useState(null);

  useEffect(() => {
    const onSuccess = (data) => {
      console.log(data);
      setQuery(data);
    };
    dispatch(Query(onSuccess));
  }, []);

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width: 150,
    },
    {
      title: "Query",
      dataIndex: "query",
      key: "query",
    },
    {
      title: "Teacher",
      dataIndex: "teacher",
      key: "teacher",
    },
    {
      title: "Number",
      dataIndex: "number",
      key: "number",
    },
  ];

  const data = query
    ? query.slice(0).reverse().map((data) => ({
        key: data._id,
        date: getDate(data.createdAt),
        query: data.query,
        teacher: data.teacher ? data.teacher.name : "Not available",
        number: data.teacher ? data.teacher.phoneNumber : "Not available",
      }))
    : "";

  return (
    <div>
      <PageBreadcrumb route={["dashboard", "Teacher Query"]} />

      {query ? (
        <Table
          columns={columns}
          dataSource={data}
          scroll={{ x: 1000 }}
          pagination={{
            defaultPageSize: 40,
            showSizeChanger: true,
            pageSizeOptions: ["80", "120", "160"],
          }}
        />
      ) : (
        <div className="spin-center">
          <Spin size="large" />
        </div>
      )}
    </div>
  );
};

export default TeacherQuery;
