import api from "../apis/ZoomApi";

export const CreateZoomMeeting = (data, onSuccess, onError) => (dispatch) => {
  //SIGNUP LEARNER

  api
    .Zoom()
    .createMeeting(data)
    .then((res) => {
      console.log(res);
      onSuccess(res.data.meeting);
    })
    .catch((err) => {
      if (err.code === 'ECONNABORTED'){
        onError("Timeout");
      }else{
      console.log(err);
      onError(err.response.data.message);
      }
    });
};
export const ZoomMeetingRecording = (data, onSuccess, onError) => (
  dispatch
) => {
  //SIGNUP LEARNER
  console.log(data);
  api
    .Zoom()
    .getMeetingrecording(data)
    .then((res) => {
      console.log(res);
      onSuccess(res.data.meeting);
    })
    .catch((err) => {
      if (err.code === 'ECONNABORTED'){
        onError("Timeout");
      }else{
      console.log(err.response);
      onError(err.response.data.message);
      }
    });
};
