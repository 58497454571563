import { Button, Modal, Tabs,Card } from "antd";
import React, { useState } from "react";
import { Sticky, StickyContainer } from "react-sticky";
import BreakdownTable from "./BreakdownTable";
const { TabPane } = Tabs;

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar
        {...props}
        className="site-custom-tab-bar"
        style={{ ...style }}
      />
    )}
  </Sticky>
);

const EarningBreakdownModal = ({name,earningData}) => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <Button onClick={() => setVisible(true)}>Vew breakdown</Button>
      <Modal
        title={`${name} monthly earning breakdown`}
        centered
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        width={1000}
        footer={[]}
      >
        <StickyContainer>
          <Tabs defaultActiveKey="1" renderTabBar={renderTabBar}>
            {
              earningData?.sessionTaken?.map((data,i)=>(
                <TabPane tab={`${data.forKids?"Kids":""} ${data.type} ${data.sessionDuration} session`} key={i}>
                  <Card>
                    <p>Total session :{data?.totalSessions}</p>
                    <p>Earning ({data.totalSessions} * {data.payForEachSession}): {data.totalSessions * data.payForEachSession}</p>
                  </Card>
                  <BreakdownTable sessionsData={data?.sessionsData} pay={data.payForEachSession}/>
                </TabPane>
              ))
            }
          </Tabs>
        </StickyContainer>
      </Modal>
    </>
  );
};

export default EarningBreakdownModal;
