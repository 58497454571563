import React, { useState } from "react";
import "antd/dist/antd.css";
import { Tabs } from "antd";
import { DndProvider, DragSource, DropTarget } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

const { TabPane } = Tabs;

// Drag & Drop node
const TabNode = (props) => {
  const { connectDragSource, connectDropTarget, children } = props;

  return connectDragSource(connectDropTarget(children));
};

const cardTarget = {
  drop(props, monitor) {
    const dragKey = monitor.getItem().index;
    const hoverKey = props.index;

    if (dragKey === hoverKey) {
      return;
    }

    props.moveTabNode(dragKey, hoverKey);
    monitor.getItem().index = hoverKey;
  }
};

const cardSource = {
  beginDrag(props) {
    return {
      id: props.id,
      index: props.index
    };
  }
};

const WrapTabNode = DropTarget("DND_NODE", cardTarget, (connect) => ({
  connectDropTarget: connect.dropTarget()
}))(
  DragSource("DND_NODE", cardSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  }))(TabNode)
);

const DraggableTabs = ({ type, onChange, onEdit, activeKey, setActiveState, panes, setPanes,form, setFormChanges, keyArr, ...props}) => {
  const [order, setOrder] = useState([]);

  const moveTabNode = (dragKey, hoverKey) => {
    const newPanes = [...panes];
    const newOrder = order.slice();
    const { children } = props;

    React.Children.forEach(children, (c) => {
      if (newOrder.indexOf(c.key) === -1) {
        newOrder.push(c.key);
      }
    });

    const dragIndex = newOrder.indexOf(dragKey);
    const hoverIndex = newOrder.indexOf(hoverKey);

    // newOrder.splice(dragIndex, 1);
    // newOrder.splice(hoverIndex, 0, dragKey);
    console.log("mylog2", dragIndex, dragKey, hoverIndex, hoverKey);
    console.log("mylog2", newOrder);
    console.log(panes[hoverIndex], panes[dragIndex]);
    // newPanes.splice(dragIndex, 1);
    // newPanes.splice(hoverIndex, 0, panes[dragIndex]);
    // let arr = [`videoTitle`, `videoDesc`, `videoLink`, `thumbnail`, `isDiffThumb`, `isVideoFree`];
    keyArr.forEach(elm=>{
      let key2 = `${elm}${dragKey}`;
      let value2 = form.getFieldValue(key2);
      console.log("get", key2, value2);
      let key1= `${elm}${hoverKey}`;
      let value1 = form.getFieldValue(key1);
      console.log("get", key1, value1);
      form.setFieldsValue({[key1]: value2});
      console.log("set", key1, value2);
      form.setFieldsValue({[key2]: value1});
      console.log("set", key2, value1);
    })
    setActiveState(String(hoverKey));
    setFormChanges((prev)=>!prev);
    // setPanes(newPanes);
    // setOrder(newOrder);
  };

  const renderTabBar = (props, DefaultTabBar) => (
    <DefaultTabBar {...props}>
      {(node) => (
        <WrapTabNode key={node.key} index={node.key} moveTabNode={moveTabNode}>
          {node}
        </WrapTabNode>
      )}
    </DefaultTabBar>
  );

  const tabs = [];
  const { children } = props;
  React.Children.forEach(children, (c) => {
    tabs.push(c);
  });

  const orderTabs = tabs.slice().sort((a, b) => {
    const orderA = order.indexOf(a.key);
    const orderB = order.indexOf(b.key);

    if (orderA !== -1 && orderB !== -1) {
      return orderA - orderB;
    }
    if (orderA !== -1) {
      return -1;
    }
    if (orderB !== -1) {
      return 1;
    }

    const ia = tabs.indexOf(a);
    const ib = tabs.indexOf(b);

    return ia - ib;
  });

  return (
    <DndProvider backend={HTML5Backend}>
      <Tabs renderTabBar={renderTabBar} {...props} type={type} onChange={onChange} onEdit={onEdit} activeKey={activeKey}>
        {orderTabs}
      </Tabs>
    </DndProvider>
  );
};

export default DraggableTabs;