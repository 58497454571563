import React from "react";
import { Table, Typography } from 'antd';
import { useSelector } from "react-redux";
const { Text } = Typography;

const PopularCity = () => {

    const citys = useSelector((state) => state.AdminReducer.popuarCitys);

      const fixedColumns = [
        {
          title: 'City',
          dataIndex: 'city',
          fixed: true,
          width: 300,
        },
        {
          title: 'Total User',
          dataIndex: 'total_user',
          defaultSortOrder: 'descend',
          sorter: (a, b) => a.total_user - b.total_user,
        },
      ];
      
      const fixedData = citys?.map((data,i)=>({
          key: i,
          city: data._id.city,
          total_user: data.number,
      }))

       
      
      return(
        <>      
          <Table
            columns={fixedColumns}
            dataSource={fixedData}
            pagination={false}
            scroll={{y: 300 }}
            bordered
          />
        </>
      );
}

export default PopularCity
