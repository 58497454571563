import React,{useState, useEffect} from "react";
import PageBreadcrumb from "../../component/PageBreadcrumb";
import { Card, Col , Spin, Table} from "antd";
import UploadStories from "../../component/adminComponent/AddStories";
import { GetAllstories } from "../../redux/actions/AuthAction";


const UsersStories = () => {

  const [stories, setStories] = useState();
  const [loader, setLoader] = useState(false);

// console.log("set", stories)
  
  useEffect(() => {
    getAllStories();
  }, []);

  // get all stories uploaded
const getAllStories = () => {
    setLoader(true)
    const onSuccess = (res) => {
      setLoader(false)
      setStories(res);
    };
    const onError = (errmsg) => {
      setLoader(false)
      console.log(errmsg);
    };
    GetAllstories(onSuccess, onError);
  };

  const columns = [
    { title: "No", width: 50, dataIndex: "no", key: "1" },,
    { title: "Media URl", width: 300, dataIndex: "url", key: "2"  ,render: (text) => (
        <a href={text} target="_blank" rel="noopener noreferrer">
          {text}
        </a>
      ),},
  ];

  const data = stories?.map((data, i) => ({
    key: data._id,
    no: i + 1,
    url: data.media_url,
  }));


    return (
        <div>
            <PageBreadcrumb route={["dashboard", "User Stories"]} />
            <Card title= "User Stories">
            <Col span={4}>
                <UploadStories /> 
           </Col>
            </Card>
      {
           loader ?
           <div className="spin-center">
           <Spin size="large" />
         </div>
         :
         <Table
         columns={columns}
         dataSource={data}
         scroll={{ x: 1000 }}
         pagination={false}
       />
      }
          
        </div>
    )

}

export default UsersStories;