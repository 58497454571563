import React, { useEffect, useState } from "react";
import {
  Modal,
  Button,
  Form,
  Input,
  Row,
  Col,
  message,
  Select,
  Menu,
  Switch,
  Tag,
  Divider,
  Dropdown,
  Typography,
  Alert,
} from "antd";
import { DownOutlined } from "@ant-design/icons";
import countries from "../../data/countries.json";
import callingcode from "../../data/callingCode.json";
import { useDispatch, useSelector } from "react-redux";
import {
  UpdateTeacherYoutubeConsent,
  addAdminLog,
  addTeacher,
  getTags,
} from "../../redux/actions/TeacherAction";

const { Title } = Typography;
const { Option } = Select;

const skillTags = [
  { value: "spoken english" },
  { value: "interview preparation" },
  { value: "IELTS" },
  { value: "Grammar expert" },
];

const type = [{ value: "Teacher" }, { value: "Creator" }];

function tagRender(props) {
  const { label, closable, onClose } = props;
  const onPreventMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Tag
      color={"#1890ff"}
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{ marginRight: 3 }}
    >
      {label}
    </Tag>
  );
}

const AddTeacher = ({ loadNewTeacher }) => {
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [subject, setSubject] = useState("English");
  const [loader, setLoader] = useState(false);
  const [credentials, setCredentials] = useState(null);
  const [skillTags, setSkillTags] = useState(null);
  const [type, setType] = useState("");
  const [isSalesPerson, setIsSalesPerson] = useState(false);
  const [isFluencyCounsellor, setIsFluencyCounsellor] = useState(false);
  const admin = useSelector((state) => state.AdminReducer.admin);
  const [youtube, setYoutube] = useState(false);
  // console.log("admin", admin)

  useEffect(() => {
    // setLoader(true)
    // console.log("use effect called");
    const onSuccess = (data) => {
      //  setPayout(data.payoutsData)
      //  setLoader(false)
      // console.log(data);
      let modifiedArray = data.tags.map((item) => ({ value: item }));
      setSkillTags(modifiedArray);
    };

    const onError = (msg) => {
      //  message.error(msg)
      //  setLoader(false)
      console.log(msg);
    };
    dispatch(getTags(onSuccess, onError));
  }, []);

  console.log(skillTags);
  console.log(type);

  const handleYoutubeChange = (checked) => {
    setYoutube(checked);
  };
  console.log("youtube", youtube)

  const updateYoutubeConcent = (val) => {

    var data = {
      teacherId: val,
      enable: youtube,
    };
    const onSuccess = (msg) => {
      message.success(msg, 8);
    };
    const onError = (msg) => {
      message.error(msg, 8);
    };

    dispatch(UpdateTeacherYoutubeConsent(data, onSuccess, onError));
  };

  const onFinish = (values) => {
    setLoader(true);

    var onboardingData = {
      countryCode: values.country,
      phoneNumber: values.phoneNumber,
      email: values.email,
      name: values.name,
      isDemo: values.demoSessionAccess,
      isSession: values.regularSessionAccess ,
      isKidDemo: values.kidsDemoAccess,
      isKidSession: values.kidsRegularAccess,
      country: values.country,
      timezone: values.timezone,
      tags: values.skills,
      subject: [
        {
          subject: subject,
          disabled: false,
          adults: values.regularSessionAccess || values.demoSessionAccess,
          kids: values.kidsRegularAccess || values.kidsDemoAccess,
        },
      ],
      type: type,
      isSalesPerson: isSalesPerson,
      isFluencyCounsellor: isFluencyCounsellor,
    };

    var onSuccess = (data) => {
      updateYoutubeConcent(data.newTeacher._id)
      setLoader(false);
      console.log(data.newTeacher);
      loadNewTeacher(data.newTeacher);
      message.success(data.message);
      form.resetFields();
      setCredentials({
        name: data.newTeacher.name,
        email: data.newTeacher.email,
        phoneNumber: data.phoneNumber,
        password: data.teacherPassword,
      });
      const payload = {
        role: admin?.details?.role,
        admin: admin?.details?.name,
        activity: "AddTeacher",
        payload: onboardingData,
      };
      var onLogSuccess = (res) => {
        console.log("res", res);
      };
      var onLogError = (msg) => {
        message.error(msg);
      };
      addAdminLog(payload, onLogSuccess, onLogError);
    };

    var onError = (msg) => {
      setLoader(false);
      message.error(msg);
    };

    dispatch(addTeacher(onboardingData, onSuccess, onError));
  };

  const prefixSelector = (
    <Form.Item name="countryCode" noStyle>
      <Select showSearch style={{ width: 70 }} defaultValue={"+91"}>
        {callingcode.map((data) => {
          return (
            <Option value={data.dial_code} key={data.dial_code}>
              {data.dial_code}
            </Option>
          );
        })}
      </Select>
    </Form.Item>
  );

  const menu = (
    <Menu onClick={({ key }) => setSubject(key)}>
      <Menu.Item key="English">English</Menu.Item>
      <Menu.Item key="IELTS">IELTS</Menu.Item>
    </Menu>
  );

  return (
    <>
      <Button type="primary" onClick={() => setVisible(true)}>
        Add Teacher
      </Button>
      <Modal
        centered
        visible={visible}
        onCancel={() => {
          setVisible(false);
          setCredentials(null);
        }}
        footer={null}
        width={1000}
      >
        {credentials ? (
          <Alert
            message={`Credentials successfully sent to ${credentials.email}`}
            description={`Credentials for ${credentials.name} tutor account, Phone number - ${credentials.phoneNumber} & Password - ${credentials.password}`}
            type="info"
            showIcon
          />
        ) : (
          ""
        )}

        <Form
          form={form}
          layout="vertical"
          autoComplete="off"
          onFinish={onFinish}
          initialValues={{
            timezone: "Asia/Kolkata",
            country: "IN",
            countryCode: "+91",
            kidsDemoAccess: false,
            kidsRegularAccess: false,
            regularSessionAccess: false,
            demoSessionAccess: false,
            isSalesPerson: false,
          }}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Phone number"
                rules={[{ required: true }]}
                tooltip="This is a required field"
                name="phoneNumber"
              >
                <Input
                  addonBefore={prefixSelector}
                  placeholder="Add phone number"
                />
              </Form.Item>
              <Form.Item
                label="Email"
                rules={[{ required: true }]}
                tooltip="This is a required field"
                name="email"
              >
                <Input placeholder="Add email" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Name"
                rules={[{ required: true }]}
                tooltip="This is a required field"
                name="name"
              >
                <Input placeholder="Add username" />
              </Form.Item>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="country"
                    label="Country"
                    tooltip="This is a required field"
                    rules={[{ required: true }]}
                  >
                    <Select showSearch defaultValue={"IN"}>
                      {countries.map((data) => {
                        return (
                          <Option
                            value={data.country_code}
                            key={data.country_code}
                          >
                            {data.country_code}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Timezone"
                    tooltip="This is a required field"
                    name="timezone"
                    rules={[{ required: true }]}
                  >
                    <Select showSearch defaultValue={"Asia/Kolkata"}>
                      {countries.map((data) => {
                        return (
                          <Option
                            value={data.timezones[0]}
                            key={data.timezones[0]}
                          >
                            {data.timezones[0]}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col span={18}>
              <Form.Item label="Add skills " name="skills">
                <Select
                  mode="multiple"
                  showArrow
                  tagRender={tagRender}
                  style={{ width: "100%" }}
                  options={skillTags}
                />
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item
                label="Type"
                tooltip="This is a required field"
                name="type"
                rules={[{ required: true }]}
              >
                <Select
                  showSearch
                  style={{ width: 150 }}
                  placeholder="Select session for"
                  onChange={setType}
                >
                  <Option value="Teacher">Teacher</Option>
                  <Option value="Creator">Creator</Option>
                </Select>
              </Form.Item>

            </Col>

            <Divider plain />
            <Col span={24}>
              <Row>
                <Col span={8}>
                  <Title level={5}>Add Subject Access</Title>
                </Col>
                <Col span={8} offset={8}>
                  <Dropdown overlay={menu}>
                    <a
                      href="#"
                      className="ant-dropdown-link"
                      onClick={(e) => e.preventDefault()}
                    >
                      {subject} <DownOutlined />
                    </a>
                  </Dropdown>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={16}>
                <Col span={6}>
                  <Form.Item
                    label={`Kid Regular session(${subject})`}
                    name="kidsRegularAccess"
                  >
                    <Switch defaultChecked={false} />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    label={`Kid Demo session(${subject})`}
                    name="kidsDemoAccess"
                  >
                    <Switch defaultChecked={false} />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    label={`Regular session(${subject})`}
                    name="regularSessionAccess"
                  >
                    <Switch defaultChecked={false} />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    label={`Demo session(${subject}) `}
                    name="demoSessionAccess"
                    // tooltip="This is a required field"
                    rules={[{ required: false }]}
                  >
                    <Switch defaultChecked={false} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={16}>
                <Col span={6}>
                  <Form.Item label={`Sales Person`} name="isSalesPerson">
                    <Switch
                      defaultChecked={false}
                      checked={isSalesPerson}
                      onChange={(checked) => {
                        setIsSalesPerson(checked);
                        if (checked) setIsFluencyCounsellor(false);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    label={`Fluency Counsellor`}
                    name="isFluencyCounsellor"
                  >
                    <Switch
                      defaultChecked={false}
                      checked={isFluencyCounsellor}
                      onChange={(checked) => {
                        setIsFluencyCounsellor(checked);
                        if (checked) setIsSalesPerson(false);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    label="YouTube Consent"
                    name="youtubeConsent"
                  >
                    <Switch defaultChecked={false} onChange={handleYoutubeChange} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>

          <Form.Item style={{ marginTop: "1rem" }}>
            <Button type="primary" htmlType="submit" loading={loader} block>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default AddTeacher;
