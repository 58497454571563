import api from "../apis/TeacherApis";
import * as constant from "../constants/Constant";

export const teacherProfile = (data, onSuccess, onError) => (dispatch) => {
  api
    .TeacherApis()
    .getTeacher(data)
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      console.log(err.response);
      onError(err);
    });
};

export const getTags = (onSuccess, onError) => (dispatch) => {
  api
    .TeacherApis()
    .tags()
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      console.log(err.response);
      onError(err);
    });
};

export const addTeacher = (data, onSuccess, onError) => (dispatch) => {
  api
    .TeacherApis()
    .addTeacher(data)
    .then((res) => {
      console.log(res.data);
      onSuccess(res.data);
    })
    .catch((err) => {
      console.log(err);
      onError(err.response.data.message);
    });
};

export const assignTeacher = (data, onSuccess, onError) => (dispatch) => {
  api
    .TeacherApis()
    .assignTeacher(data)
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      console.log(err);
      onError(err.response.data.message);
    });
};

export const UpdateTeacherYoutubeConsent =
  (data, onSuccess, onError) => (dispatch) => {
    api
      .TeacherApis()
      .teacherYoutubeConsent(data)
      .then((res) => {
        onSuccess(res.data.message);
      })
      .catch((err) => {
        console.log(err);
        onError(err.response.data.message);
      });
  };

export const UpdateTeacher = (data, onSuccess, onError) => (dispatch) => {
  api
    .TeacherApis()
    .updateTeacher(data)
    .then((res) => {
      onSuccess(res.data.message);
    })
    .catch((err) => {
      console.log(err.response.data);
      onError(err.response.data.message);
    });
};

export const GetDemoConversionDetails =
  (data, onSuccess, onError) => (dispatch) => {
    api
      .TeacherApis()
      .getDemoConversion(data)
      .then((res) => {
        onSuccess(res.data.conversionData);
      })
      .catch((err) => {
        console.log(err);
        onError(err.response.data.message);
      });
  };

export const UploadProfile = (data, onSuccess, onError) => (dispatch) => {
  api
    .TeacherApis()
    .uploadProfile(data)
    .then((res) => {
      console.log(res.data.message);
      onSuccess(res.data.message);
    })
    .catch((err) => {
      onError(err.response.data.message);
      console.log(err.response.data);
    });
};

export const GenerateMagicLink = (teacherId, onSuccess, onError) => {
  api
    .TeacherApis()
    .generateMagicLink(teacherId)
    .then((res) => {
      console.log(res.data);
      onSuccess(res.data);
    })
    .catch((err) => {
      onError(err.response.data.message);
      console.log(err.response.data);
    });
};

export const GetPayouts = (teacherId, onSuccess, onError) => {
  api
    .TeacherApis()
    .getPayouts(teacherId)
    .then((res) => {
      console.log(res.data);
      onSuccess(res.data);
    })
    .catch((err) => {
      onError(err);
    });
};

export const UpdatePayouts = (data, onSuccess, onError) => {
  api
    .TeacherApis()
    .updatePayouts(data)
    .then((res) => {
      console.log(res.data);
      onSuccess(res.data.message);
    })
    .catch((err) => {
      console.log(err.response.data);
      onError(err.response.data.message);
    });
};

export const WithdrawAmount = (data, onSuccess, onError) => {
  api
    .TeacherApis()
    .withdrawAmount(data)
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      console.log(err.response);
      onError(err.response.data.message);
    });
};

export const getWorkingHrs = async (data, onSuccess, onError) => {
  try {
    const workingHr = await api.TeacherApis().workingHrs(data);
    onSuccess(workingHr.data);
  } catch (err) {
    console.log(err);
    onError(err.response.data.message);
  }
};

export const getTeacherList_ = async (data, onSuccess, onError) => {
  try {
    const list = await api.TeacherApis().getTeacherList(data);
    onSuccess(list.data.teachersData);
  } catch (err) {
    console.log(err);
    onError(err.response.data.message);
  }
};

export const teachersConcern = (data, onSuccess, onError) => {
  api
    .TeacherApis()
    .teacherConcern(data)
    .then((res) => {
      console.log(res);
      onSuccess(res.data);
    })
    .catch((err) => {
      console.log(err);
      onError(err);
    });
};

export const updateTeacherConcernStatus = (data, onSuccess, onError) => {
  api
    .TeacherApis()
    .updateTeacherConcern(data)
    .then((res) => {
      console.log(res);
      onSuccess(res.data);
    })
    .catch((err) => {
      console.log(err.response);
      onError(err.response.data.message);
    });
};

export const DeleteRating = (meetingNo, onSuccess, onError) => {
  api
    .TeacherApis()
    .deleteRating(meetingNo)
    .then((res) => {
      console.log(res.data);
      onSuccess(res.data);
    })
    .catch((err) => {
      onError(err.response.data.message);
      console.log(err.response.data);
    });
};

// getCreatorsList
export const getCreatorsList = (onSuccess, onError) => {
  api
    .TeacherApis()
    .getCreatorList()
    .then((res) => {
      console.log(res.data);
      onSuccess(res.data);
    })
    .catch((err) => {
      console.log(err.response);
      onError(err.response.data.message);
    });
};

export const getTeacherApplications = (status, onSuccess, onError) => {
  api
    .TeacherApis()
    .getTeacherApplications(status)
    .then((res) => {
      onSuccess(res.data.data);
    })
    .catch((err) => {
      console.log(err);
      onError(err.response);
    });
};

export const getAllTechIssues = (data, onSuccess, onError) => {
  api
    .TeacherApis()
    .getAllTechIssues(data)
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      console.log(err);
      onError(err.response);
    });
};

export const changeTechIssueStatus = (data, onSuccess, onError) => {
  api
    .TeacherApis()
    .changeTechIssueStatus(data)
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      console.log(err);
      onError(err.response);
    });
};

export const updateTeacherApplicationStatus = (payload, onSuccess, onError) => {
  api
    .TeacherApis()
    .changeTeacherApplicationStatus(payload)
    .then((res) => {
      console.log(res);
      onSuccess(res.data.message);
    })
    .catch((err) => {
      console.log(err.response);
      onError(err.response.data.message);
    });
};

// adminLog
export const addAdminLog = (data, onSuccess, onError) => {
  api
    .TeacherApis()
    .adminLog(data)
    .then((res) => {
      console.log(res.data);
      onSuccess(res.data);
    })
    .catch((err) => {
      console.log(err);
      onError(err.response.data.message);
    });
};
