import React, { useState, useEffect } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import PageBreadcrumb from "../../component/PageBreadcrumb";
import {
  Typography,
  Card,
  Form,
  Select,
  Radio,
  Button,
  message,
  DatePicker,
} from "antd";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { TimeSpliter, TimingSlot, meetingTime } from "../../helper/helper";
import {
  GetAvailableHost,
  CreateZoomMeeting,
  CreateSession,
} from "../../redux/actions/SessionAction";

const { Title } = Typography;
const { Option } = Select;
const dateFormat = "YYYY-MM-DD";

const AssignSession = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const Teacher = useSelector((state) => state.AdminReducer.teacher);
  const [time, setTime] = useState("10:00 to 11:00");
  const [duretion, setDuration] = useState(15);
  const [exactTime, setExactTime] = useState(null);
  const [childrens, setChildren] = useState(null);
  const [date, setDate] = useState(null);
  const [meetingtime, setMeetingTime] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [host, setHost] = useState(null);
  const [loading, setLoding] = useState(false);
  const [form] = Form.useForm();

  const userDetails = JSON.parse(localStorage.getItem('USER_DETAILS'))

  const datePicker = (date, dateString) => {
    setDate(dateString);
  };

  useEffect(() => {
    if (meetingtime && duretion) {
      var timingData = meetingTime(meetingtime, duretion);
      setStartTime(timingData.start);
      setEndTime(timingData.end);
    }
  });

  //get avaulable host
  useEffect(() => {
    if (startTime && endTime && date) {
      const Loding = (status) => {
        setLoding(status);
      };
      const onSuccess = (data) => {
        setHost(data);
        setLoding(false);
        console.log(data);
      };
      const data = {
        startTime: startTime,
        endTime: endTime,
        sessionDate: date,
      };
      dispatch(GetAvailableHost(data, Loding, onSuccess));
    }
  }, [startTime, endTime, date]);

  //set session duration
  useEffect(() => {
    if (props.location.subscription) {
      if (props.location.subscription.plan.classDuration == "15min") {
        setDuration(15);
      } else {
        setDuration(25);
      }
    }
  }, []);

  //set exact time
  useEffect(() => {
    console.log(time);
    setExactTime(TimeSpliter(time, duretion));
  }, [duretion, time]);

  //form submit
  const onFinish = (values) => {
    if (host) {
      createZoomMeeting(values.selectateacher);
    } else {
      message.error("Host not available ,");
    }
  };

  const createZoomMeeting = (teacher) => {
    setLoding(true);
    const ZoomMeeting = {
      userId: host,
      data: {
        topic: "Clapingo Demo Session",
        type: 2,
        start_time: `${date}T${startTime}:00Z`, //YY-MM-DD T HH:MM:SS
        duration: 15,
        schedule_for: host,
        timezone: "Asia/Calcutta",
        password: "clapingo",
        agenda: "Learn English",
        settings: {
          host_video: true,
          participant_video: true,
          cn_meeting: false,
          in_meeting: true,
          join_before_host: true,
          mute_upon_entry: true,
          watermark: false,
          use_pmi: false,
          approval_type: 2,
          auto_recording: "cloud",
          registrants_email_notification: true,
        },
      },
    };

    const onSuccess = (data) => {
      console.log(data);

      const SessionData = {
        meetingNo: String(data.id),
        learner: props.location.details.id,
        teacher_: teacher,
        startTime: startTime,
        endTime: endTime,
        type: "Regular",
        sessionDuration: String(duretion),
        sessionDate: date,
        meetingPassword: data.h323_password,
        startMeetingUrl: data.start_url,
        joinMeetingUrl: data.join_url,
        host: host,
      };
      const created = () => {
        form.resetFields();
        setLoding(false);
        // history.push("/subscribedusers");
        history.push({
          pathname: "/subscribedusersession",
          details: {
            id: props.location.details.id,
            phone: props.location.details.phone,
            name: props.location.details.name,
          },
        });
        message.success("meeting created successfully");
      };
      const error = (msg) => {
        setLoding(false);
        message.error(msg);
      };
      dispatch(CreateSession(SessionData, created, error));
      console.log(SessionData);
    };

    const onError = (msg) => {
      setLoding(true);
      message.error(msg);
    };

    dispatch(CreateZoomMeeting(ZoomMeeting, onSuccess, onError));
  };

  const onReset = () => {
    form.resetFields();
  };

  useEffect(() => {
    if (Teacher) {
      const children = [];
      Teacher.map((data, i) => {
        // console.log(data);
        children.push(
          <Option key={i} value={data._id}>
            {data.name}
          </Option>
        );
      });
      setChildren(children);
    }
  }, [Teacher]);

  return (
    <div>
      <PageBreadcrumb
        route={["dashboard", "Subscribed users", "Assign session"]}
      />
      {/* <button
        onClick={history.push({
          pathname: "/subscribedusersession",
          details: {
            id: props.location.details.id,
            phone: props.location.details.phone,
            name: props.location.details.name,
          }
        }
        )}
      >
        mm
      </button> */}
      <Title level={3}>
        {" "}
        <Link
          to={{
            pathname: "/subscriptiondetails",
            details: {
              id: props.location.details.id,
              phone: props.location.details.phone,
              name: props.location.details.name,
            },
          }}
        >
          <ArrowLeftOutlined />
        </Link>{" "}
        Assign Session to learner
      </Title>

      <Card>
        <Form
          form={form}
          name="control-hooks"
          layout={"vertical"}
          initialValues={{ remember: true }}
          onFinish={onFinish}
        >
          <Form.Item
            label="Date"
            name="date"
            rules={[{ required: true, message: "Please Select a date" }]}
          >
            <DatePicker onChange={datePicker} format={dateFormat} />
          </Form.Item>

          <Form.Item
            label="Time"
            name="time"
            value={time}
            onChange={(e) => setTime(e.target.value)}
            rules={[
              { required: true, message: "Please Select atleast one time" },
            ]}
          >
            <Radio.Group buttonStyle="solid">
              <Radio.Button value="10:00 to 11:00" style={{ margin: 8 }}>
                10:00 to 11:00 am
              </Radio.Button>
              <Radio.Button value="11:00 to 12:00" style={{ margin: 8 }}>
                11:00 to 12:00 am
              </Radio.Button>
              <Radio.Button value="12:00 to 13:00" style={{ margin: 8 }}>
                12:00 to 13:00 pm
              </Radio.Button>
              <Radio.Button value="13:00 to 14:00" style={{ margin: 8 }}>
                13:00 to 14:00 pm
              </Radio.Button>
              <Radio.Button value="14:00 to 15:00" style={{ margin: 8 }}>
                14:00 to 15:00 pm
              </Radio.Button>
              <Radio.Button value="15:00 to 16:00" style={{ margin: 8 }}>
                15:00 to 16:00 pm
              </Radio.Button>
              <Radio.Button value="16:00 to 17:00" style={{ margin: 8 }}>
                16:00 to 17:00 pm
              </Radio.Button>
              <Radio.Button value="17:00 to 18:00" style={{ margin: 8 }}>
                17:00 to 18:00 pm
              </Radio.Button>
              <Radio.Button value="18:00 to 19:00" style={{ margin: 8 }}>
                18:00 to 19:00 pm
              </Radio.Button>
              <Radio.Button value="19:00 to 20:00" style={{ margin: 8 }}>
                19:00 to 20:00 pm
              </Radio.Button>
              <Radio.Button value="20:00 to 21:00" style={{ margin: 8 }}>
                20:00 to 21:00 pm
              </Radio.Button>
              <Radio.Button value="21:00 to 22:00" style={{ margin: 8 }}>
                21:00 to 22:00 pm
              </Radio.Button>
              <Radio.Button value="22:00 to 23:00" style={{ margin: 8 }}>
                22:00 to 23:00 pm
              </Radio.Button>
              <Radio.Button value="23:00 to 24:00" style={{ margin: 8 }}>
                23:00 to 24:00 pm
              </Radio.Button>
            </Radio.Group>
          </Form.Item>

          {!props.location.subscription ? (
            <Form.Item
              label="Duration"
              name="duration"
              value={time}
              onChange={(e) => setDuration(e.target.value)}
              rules={[{ required: true, message: "Please Select a duration" }]}
            >
              <Radio.Group buttonStyle="solid">
                <Radio.Button value={15} style={{ margin: 8 }}>
                  15 min
                </Radio.Button>
                <Radio.Button value={25} style={{ margin: 8 }}>
                  25 min
                </Radio.Button>
              </Radio.Group>
            </Form.Item>
          ) : (
            ""
          )}

          {exactTime ? (
            <Form.Item
              label="Exact Time"
              name="exacttime"
              onChange={(e) => setMeetingTime(e.target.value)}
            >
              {duretion == 15 ? (
                <Radio.Group buttonStyle="solid">
                  <Radio.Button
                    value={`${exactTime[0] + " to " + exactTime[1]}`}
                    style={{ margin: 8 }}
                  >
                    {exactTime[0] + " to " + exactTime[1]}
                  </Radio.Button>
                  <Radio.Button
                    value={`${
                      TimingSlot(exactTime[1], 5) +
                      " to " +
                      TimingSlot(exactTime[2], 5)
                    }`}
                    style={{ margin: 8 }}
                  >
                    {TimingSlot(exactTime[1], 5) +
                      " to " +
                      TimingSlot(exactTime[2], 5)}
                  </Radio.Button>
                  <Radio.Button
                    value={`${
                      TimingSlot(exactTime[2], 10) +
                      " to " +
                      TimingSlot(exactTime[3], 10)
                    }`}
                    style={{ margin: 8 }}
                  >
                    {TimingSlot(exactTime[2], 10) +
                      " to " +
                      TimingSlot(exactTime[3], 10)}
                  </Radio.Button>
                </Radio.Group>
              ) : (
                <Radio.Group buttonStyle="solid">
                  <Radio.Button value={exactTime[0]} style={{ margin: 8 }}>
                    {exactTime[0] + " to " + exactTime[1]}
                  </Radio.Button>
                  <Radio.Button
                    value={TimingSlot(exactTime[1], 5)}
                    style={{ margin: 8 }}
                  >
                    {TimingSlot(exactTime[1], 5) +
                      " to " +
                      TimingSlot(exactTime[2], 5)}
                  </Radio.Button>
                </Radio.Group>
              )}
            </Form.Item>
          ) : (
            ""
          )}

          <Form.Item
            name="selectateacher"
            label="Select a Teacher"
            rules={[{ required: true }]}
          >
            <Select
              style={{ width: "100%" }}
              placeholder="Please select a Teacher"
              allowClear
            >
              {childrens}
            </Select>
          </Form.Item>

          <Form.Item>
            <Button type="primary" loading={loading} htmlType="submit">
              {loading ? "Loding..." : "Submit"}
            </Button>
            <Button
              style={{ marginLeft: 10 }}
              htmlType="button"
              onClick={onReset}
            >
              Reset
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default AssignSession;
