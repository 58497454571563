import React, { useEffect, useState } from "react";
import {
  Modal,
  Button,
  Form,
  Input,
  DatePicker,
  Row,
  Col,
  message,
  Select,
  Switch,
  TimePicker,
  Divider,
  Typography,
  Radio,
  AutoComplete,
} from "antd";
import { useDispatch } from "react-redux";
import {
  CreateCoupon,
  SearchUTMCampaigns,
  UpdateCoupon,
} from "../redux/actions/UserAction";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

const { Title } = Typography;
const { Option } = Select;
const dateFormat = "YYYY-MM-DD";
dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

const AddCoupon = (props) => {
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [kids, setKids] = useState(true);
  const [adults, setAdults] = useState(true);
  const [subject, setSubject] = useState("English");
  const [loader, setLoader] = useState(false);
  const [disableCoupon, setDisableCoupon] = useState(
    props.value ? props.value?.edit?.isDisable : false
  );
  const [couponType, setCouponType] = useState(
    props.value ? props.value?.edit?.couponType : "Discount"
  );
  const [bogoOffer, setBogoOffer] = useState([]);
  const [bonusSession, setBonusSession] = useState(
    props.value ? Boolean(props.value.edit.bonusSessions) : false
  );
  const [bonusMonth, setBonusMonth] = useState(
    props.value ? Boolean(props.value.edit?.bonusMonths) : false
  );
  const [subscriptionMembership, setSubscriptionMembership] = useState(false);
  const [removeFromCouponList, setRemoveFromCouponList] = useState(
    props.value ? props.value?.edit?.removeFromCouponList : false
  );
  const [couponValidFor, setCouponValidFor] = useState(
    props.value ? props.value?.edit.couponValidFor : "Subscription"
  );

  const defaultStartDate = props?.value?.edit.startDate
    ? dayjs(convertToTimeZoneDate(props?.value?.edit.startDate), dateFormat)
    : "";

  const defaultEndDate = props?.value?.edit.endDate
    ? dayjs(convertToTimeZoneDate(props?.value?.edit.endDate), dateFormat)
    : "";

  const defaultStartTime = props?.value?.edit.startDate
    ? dayjs(convertToISTTimeZone(props?.value?.edit.startDate), "HH:mm")
    : "";

  const defaultEndTime = props?.value?.edit.endDate
    ? dayjs(convertToISTTimeZone(props?.value?.edit.endDate), "HH:mm")
    : "";

  // Segmentation filter

  let isSegmentFilterApplied =
    props.value?.edit.segmentation?.attendedNotSubscribed ||
    props.value?.edit.segmentation?.demoPaidNotBooked ||
    props.value?.edit.segmentation?.previousSubscriptionMonth ||
    props.value?.edit.segmentation?.signupNotPaid ||
    props.value?.edit.segmentation?.subscriptionCount
      ? true
      : false;
  const [addSegmentFiler, setAddSegmentFilter] = useState(
    isSegmentFilterApplied
  );
  const [demoCoupon, setDemoCoupon] = useState(false);
  const [isFreeCourse, setIsFreeCourse] = useState(
    props.value && props.value?.edit?.freeCourses?.length ? true : false
  );

  // Exclusion filter
  const [addExclusionFiler, setAddExclusionFilter] = useState(
    props.value?.edit.exclusionCriteria?.noOfDays ||
      props.value?.edit.exclusionCriteria?.noOfDaysSinceSubs
      ? true
      : false
  );
  const [searchText, setSearchText] = useState({ searchText: "", param: "" });
  const [options, setOptions] = useState({
    source: [],
    medium: [],
    campaign: [],
    content: [],
  });
  const [utmParameters, setUtmParameters] = useState([
    {
      name: "source",
      value: "",
      isContained: false,
      isEqual: false,
    },
    {
      name: "medium",
      value: "",
      isContained: false,
      isEqual: false,
    },
    {
      name: "campaign",
      value: "",
      isContained: false,
      isEqual: false,
    },
    {
      name: "content",
      value: "",
      isContained: false,
      isEqual: false,
    },
  ]);
  const [selectedCourses, setSelectedCourses] = useState([]);

  function convertToISTTimeZone(date) {
    const timeInUTC = dayjs.utc(date);
    const timeInTimeZone = timeInUTC.tz("Asia/Kolkata");
    return timeInTimeZone.format("HH:mm");
  }

  function convertToTimeZoneDate(date) {
    const dateInUTC = dayjs.utc(date);
    const dateInTimeZone = dateInUTC.tz("Asia/Kolkata");
    return dateInTimeZone.format(dateFormat);
  }

  const disable = () => {
    setDisableCoupon(!disableCoupon);
  };
  const removeFromCouponList_ = () => {
    setRemoveFromCouponList(!removeFromCouponList);
  };
  const disablesubscriptionMembership = () => {
    setSubscriptionMembership(!subscriptionMembership);
  };
  const handleAdults = (value) => {
    setAdults(value);
  };

  const handleKids = (value) => {
    setKids(value);
  };

  const handleSubject = (value) => {
    setSubject(value);
  };

  const handleCouponType = (value) => {
    setCouponType(value);
  };
  const handlePlanMonths = (value) => {
    setBogoOffer([
      ...bogoOffer,
      {
        subscriptionMonth: parseInt(value[value.length - 1]),
        bonusMonth: 0,
        subscriptionMembership: subscriptionMembership,
      },
    ]);
  };

  const handleSelectedCourses = (value) => {
    setSelectedCourses(value);
  };

  const handlePlanMonthsBonus = (value) => {
    for (let i = 0; i < bogoOffer.length; i++) {
      if (i === value.length - 1) {
        bogoOffer[i].bonusMonth = parseInt(value[value.length - 1]);
      }
    }
  };

  const updateCoupon = (values) => {
    let error = false;
    if (addExclusionFiler) error = validateUTMParameters();
    if (!error) {
      setLoader(true);
      var userData = {
        coupon: values.coupon,
        endDate:
          typeof values.end === "object"
            ? values.end.format(dateFormat)
            : values.end,
        endTime: convertToISTTimeZone(values.endTime),
        disableCoupon: disableCoupon,
        desc: values.desc,
        occasion: values.occ,
        discount: values.discount,
        maximumUse: values.max,
        couponType: couponType,
        removeFromCouponList: removeFromCouponList,
        segmentation: {},
        exclusionCriteria: {},
      };

      if (couponValidFor === "P2P") {
        delete userData.subscriptionMembership;
        delete userData.bogoOffer;
        delete userData.segmentation;
        delete userData.exclusionCriteria;
        delete userData.bonusMonths;
        delete userData.bonusSessions;
      }

      if (demoCoupon) {
        userData["demoCoupon"] = demoCoupon;
      }

      if (isFreeCourse) {
        userData["freeCourses"] = selectedCourses;
      }

      if (addExclusionFiler) {
        const UTMParameters = utmParameters.filter((element) => {
          if (element.value) return element;
        });
        userData["exclusionCriteria"] = {
          utmParameters: UTMParameters,
          noOfDays: values.noOfDays,
          noOfDaysSinceSubs: values.noOfDaysSinceSubs,
        };
      }

      if (addSegmentFiler) {
        if (values.previousSubscriptionMonth) {
          userData["segmentation"]["hasSubscribedPreviously"] = true;
          userData["segmentation"]["previousSubscriptionMonth"] =
            values.previousSubscriptionMonth;
        } else {
          userData["segmentation"]["hasSubscribedPreviously"] = false;
          userData["segmentation"]["previousSubscriptionMonth"] = 0;
        }

        if (values.attendedNotSubscribed) {
          userData["segmentation"]["attendedNotSubscribed"] = true;
        } else {
          userData["segmentation"]["attendedNotSubscribed"] = false;
        }

        if (values.demoPaidNotBooked) {
          userData["segmentation"]["demoPaidNotBooked"] = true;
        } else {
          userData["segmentation"]["demoPaidNotBooked"] = false;
        }

        if (values.signupNotPaid) {
          userData["segmentation"]["signupNotPaid"] = true;
        } else {
          userData["segmentation"]["signupNotPaid"] = false;
        }

        if (values.subscriptionCount) {
          userData["segmentation"]["subscribed"] = true;
          userData["segmentation"]["subscriptionCount"] =
            values.subscriptionCount;
        } else {
          userData["segmentation"]["subscribed"] = false;
          userData["segmentation"]["subscriptionCount"] = 0;
        }
      }

      if (couponType === "Bonus") {
        userData["bonusMonths"] = bonusMonth;
        userData["bonusSessions"] = bonusSession;
      } else {
        userData["bonusMonths"] = false;
        userData["bonusSessions"] = false;
      }

      if (
        (values.planMonths &&
          !values.planMonths.includes("N/A") &&
          couponType === "Special") ||
        couponType === "Bogo"
      ) {
        userData["planMonths"] = values.planMonths;
      }

      if (couponType === "AOV") {
        userData["cartAmount"] = values.cartAmount;
        userData["cashBackAmount"] = values.cashBackAmount;
      }

      userData["couponValidFor"] = couponValidFor;

      if (couponValidFor === "Course") {
        delete userData.couponType;
      }

      const onSuccess = (res) => {
        setLoader(false);
        console.log(res);
        message.success(res.data.message);
        props.getLearner(1, true);
        setVisible(false);
      };

      const onError = (msg) => {
        setLoader(false);
        console.log(msg);
        message.error(msg);
      };

      dispatch(UpdateCoupon(userData, onSuccess, onError));
    }
  };

  useEffect(() => {
    const getData = setTimeout(() => {
      getPanelValue(searchText.searchText, searchText.param);
    }, 500);

    return () => clearTimeout(getData);
  }, [searchText]);

  const getPanelValue = (searchText, param) => {
    let payload = {};
    if (!searchText) return;
    if (param === "source") {
      payload = {
        utm_source: searchText,
      };
    }
    if (param === "medium") {
      payload = {
        utm_medium: searchText,
      };
    }
    if (param === "campaign") {
      payload = {
        utm_campaign: searchText,
      };
    }
    if (param === "content") {
      payload = {
        utm_content: searchText,
      };
    }

    const onSuccess = (response) => {
      console.log(response);
      let options = [];
      const key = `utm_${param}`;
      response.suggestions[key].forEach((element) => {
        options.push({ value: element });
      });
      setOptions({ ...options, [param]: options });
    };
    const onError = (error) => {
      console.log(error);
    };
    dispatch(SearchUTMCampaigns(payload, onSuccess, onError));
  };

  useEffect(() => {
    if (
      props.value &&
      props.value.edit.exclusionCriteria?.utmParameters.length > 0
    ) {
      const updatedUtmParameters = [
        {
          name: "source",
          value: "",
          isContained: false,
          isEqual: false,
        },
        {
          name: "medium",
          value: "",
          isContained: false,
          isEqual: false,
        },
        {
          name: "campaign",
          value: "",
          isContained: false,
          isEqual: false,
        },
        {
          name: "content",
          value: "",
          isContained: false,
          isEqual: false,
        },
      ];
      props.value?.edit?.exclusionCriteria?.utmParameters.forEach((element) => {
        if (element.name === "source") {
          updatedUtmParameters[0] = element;
        }
        if (element.name === "medium") {
          updatedUtmParameters[1] = element;
        }
        if (element.name === "campaign") {
          updatedUtmParameters[2] = element;
        }
        if (element.name === "content") {
          updatedUtmParameters[3] = element;
        }
      });
      setUtmParameters(updatedUtmParameters);
    }
  }, []);

  const validateUTMParameters = () => {
    let error = false;
    utmParameters.forEach((element) => {
      if (element.value && !element.isContained && !element.isEqual) {
        error = true;
        message.error(
          `Please select either Contains or Exactly in UTM ${element.name}`
        );
      }
    });
    return error;
  };
  const addCoupon = (values) => {
    let error = false;
    if (addExclusionFiler) error = validateUTMParameters();
    if (!error) {
      setLoader(true);
      var userData = {
        coupon: values.coupon,
        endDate: values.end.format(dateFormat),
        startDate: values.start.format(dateFormat),
        startTime: values.startTime.format("HH:mm"),
        endTime: values.endTime.format("HH:mm"),
        desc: values.desc,
        occasion: values.occ,
        discount: values.discount,
        maximumUse: values.max,
        forKids: kids,
        forAdults: adults,
        subject: subject,
        couponType: couponType,
        subscriptionMembership: subscriptionMembership,
        bogoOffer: bogoOffer,
        removeFromCouponList: removeFromCouponList,
        segmentation: {},
        exclusionCriteria: {},
      };

      if (couponValidFor === "P2P") {
        delete userData.subscriptionMembership;
        delete userData.bogoOffer;
        delete userData.segmentation;
        delete userData.exclusionCriteria;
        delete userData.bonusMonths;
        delete userData.bonusSessions;
      }

      if (demoCoupon) {
        userData["demoCoupon"] = demoCoupon;
      }

      if (isFreeCourse) {
        userData["freeCourses"] = selectedCourses;
      }

      if (addSegmentFiler) {
        if (values.previousSubscriptionMonth) {
          userData["segmentation"]["hasSubscribedPreviously"] = true;
          userData["segmentation"]["previousSubscriptionMonth"] =
            values.previousSubscriptionMonth;
        } else {
          userData["segmentation"]["hasSubscribedPreviously"] = false;
          userData["segmentation"]["previousSubscriptionMonth"] = 0;
        }

        if (values.attendedNotSubscribed) {
          userData["segmentation"]["attendedNotSubscribed"] = true;
        } else {
          userData["segmentation"]["attendedNotSubscribed"] = false;
        }

        if (values.demoPaidNotBooked) {
          userData["segmentation"]["demoPaidNotBooked"] = true;
        } else {
          userData["segmentation"]["demoPaidNotBooked"] = false;
        }

        if (values.signupNotPaid) {
          userData["segmentation"]["signupNotPaid"] = true;
        } else {
          userData["segmentation"]["signupNotPaid"] = false;
        }

        if (values.subscriptionCount) {
          userData["segmentation"]["subscribed"] = true;
          userData["segmentation"]["subscriptionCount"] =
            values.subscriptionCount;
        } else {
          userData["segmentation"]["subscribed"] = false;
          userData["segmentation"]["subscriptionCount"] = 0;
        }
      }

      if (couponType === "Bonus") {
        userData["bonusMonths"] = bonusMonth;
        userData["bonusSessions"] = bonusSession;
      } else {
        userData["bonusMonths"] = false;
        userData["bonusSessions"] = false;
      }

      if (
        (values.planMonths &&
          !values.planMonths.includes("N/A") &&
          couponType === "Special") ||
        couponType === "Bogo"
      ) {
        userData["planMonths"] = values.planMonths;
      }

      if (couponType === "AOV") {
        userData["cartAmount"] = values.cartAmount;
        userData["cashBackAmount"] = values.cashBackAmount;
      }

      userData["couponValidFor"] = couponValidFor;

      if (couponValidFor === "Course") {
        delete userData.couponType;
        delete userData.forKids;
        delete userData.forAdults;
      }

      if (addExclusionFiler) {
        const UTMParameters = utmParameters.filter((element) => {
          if (element.value) return element;
        });
        userData["exclusionCriteria"] = {
          utmParameters: UTMParameters,
          noOfDays: values.noOfDays,
          noOfDaysSinceSubs: values.noOfDaysSinceSubs,
        };
      }
      const onSuccess = (res) => {
        setLoader(false);
        console.log(res);
        message.success(res.data.message);
        props.getLearner(1, true);
        setVisible(false);
      };

      const onError = (msg) => {
        setLoader(false);
        console.log(msg);
        message.error(msg);
      };

      console.log(userData);
      dispatch(CreateCoupon(userData, onSuccess, onError));
    }
  };

  const getDisabledSeconds = () => {
    var seconds = [];
    for (var i = 0; i < 60; i++) {
      seconds.push(i);
    }
    return seconds;
  };

  return (
    <React.Fragment key={props.value ? props.value.key : "add-coupon"}>
      <Button type="primary" onClick={() => setVisible(true)}>
        {props.name ? "Edit" : "Add coupon"}
      </Button>
      <Modal
        centered
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
        width={1000}
        bodyStyle={{ maxHeight: "90vh", overflowY: "auto" }}
      >
        <Form
          form={form}
          layout="vertical"
          autoComplete="off"
          onFinish={
            props.name
              ? (value) =>
                  updateCoupon({
                    ...value,
                    segmentation: props.value?.edit?.segmentation,
                  })
              : addCoupon
          }
          initialValues={{
            coupon: props.value ? props.value.name : "",
            couponValidFor: props.value
              ? props.value.couponValidFor
              : "Subscription",
            desc: props.value ? props.value.desc : "",
            max: props.value ? props.value.max : "",
            occ: props.value ? props.value.occ : "",
            forkids: props.value ? props.value.kids : "false",
            start: defaultStartDate,
            end: defaultEndDate,
            startTime: defaultStartTime,
            endTime: defaultEndTime,
            discount: props.value ? props.value.discount : "",
            foradults: props.value ? props.value.adults : "true",
            bonusMonths: props.value ? props.value.edit.bonusMonths : false,
            bonusSessions: props.value ? props.value.edit.bonusSessions : false,
            subject: props.value ? props.value.sub : "English",
            couponType: props.value ? props.value.edit.couponType : "Discount",
            cartAmount: props.value ? props.value.cartAmount : "",
            cashBackAmount: props.value ? props.value.edit.cashBackAmount : "",
            attendedNotSubscribed: props.value
              ? props.value?.edit?.segmentation?.attendedNotSubscribed
              : null,
            demoPaidNotBooked: props.value
              ? props.value?.edit?.segmentation?.demoPaidNotBooked
              : null,
            signupNotPaid: props.value
              ? props.value?.edit?.segmentation?.signupNotPaid
              : null,
            previousSubscriptionMonth: props.value
              ? props.value?.edit?.segmentation?.previousSubscriptionMonth
              : null,
            subscriptionCount: props.value
              ? props.value?.edit?.segmentation?.subscriptionCount
              : null,
            noOfDays: props.value
              ? props.value?.edit?.exclusionCriteria?.noOfDays
              : null,
            noOfDaysSinceSubs: props.value
              ? props.value?.edit?.exclusionCriteria?.noOfDaysSinceSubs
              : null,
          }}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="coupon"
                rules={[{ required: true }]}
                tooltip="This is a required field"
                name="coupon"
              >
                <Input
                  disabled={props.name ? true : false}
                  placeholder="Add Coupon"
                />
              </Form.Item>
              <Form.Item
                label="Description"
                rules={[{ required: true }]}
                tooltip="This is a required field"
                name="desc"
              >
                <Input placeholder="Desc" />
              </Form.Item>
              <Form.Item
                label="Max Use"
                rules={[{ required: true }]}
                tooltip="This is a required field"
                name="max"
              >
                <Input placeholder="eg:3" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Occasion"
                rules={[{ required: true }]}
                tooltip="This is a required field"
                name="occ"
              >
                <Input placeholder="Ocassion" />
              </Form.Item>
              <Row gutter={12}>
                <Col span={6}>
                  <Form.Item
                    label="Coupon Type"
                    tooltip="This is a required field"
                    name="couponValidFor"
                  >
                    <Select
                      onChange={(value) => {
                        setCouponValidFor(value);
                      }}
                    >
                      <Option value="Subscription">Subscription</Option>
                      <Option value="Course">Courses</Option>
                      <Option value="P2P">P2P</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    hidden={couponValidFor === "Course"}
                    label="For Kids"
                    tooltip="This is a required field"
                    name="forkids"
                  >
                    <Select
                      disabled={props.name ? true : false}
                      style={{ width: 120 }}
                      onChange={handleKids}
                    >
                      <Option value="true">true</Option>
                      <Option value="false">false</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="start"
                    rules={[{ required: true }]}
                    label="Start Date"
                    tooltip="This is a required field"
                    valuePropName="date"
                  >
                    <DatePicker
                      onChange={() => {}}
                      defaultValue={defaultStartDate}
                      format={dateFormat}
                      disabled={!!props?.value?.edit.startDate}
                    />
                  </Form.Item>
                  <Form.Item
                    name="startTime"
                    rules={[{ required: true }]}
                    label="Start Time"
                    tooltip="This is a required field"
                    valuePropName="value"
                  >
                    <TimePicker
                      onChange={() => {}}
                      defaultValue={defaultStartTime}
                      format="HH:mm"
                      disabledTime={() => getDisabledSeconds()}
                      hideDisabledOptions={true}
                      disabled={!!props?.value?.edit.startDate}
                      popupClassName="hideSeconds"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="End Date"
                    rules={[{ required: true }]}
                    tooltip="This is a required field"
                    name="end"
                    className="cursor-pointer"
                    valuePropName="date"
                  >
                    <DatePicker
                      onChange={() => {}}
                      defaultValue={defaultEndDate}
                      format={dateFormat}
                    />
                  </Form.Item>
                  <Form.Item
                    name="endTime"
                    rules={[{ required: true }]}
                    label="End Time"
                    tooltip="This is required field"
                    valuePropName="value"
                  >
                    <TimePicker
                      onChange={() => {}}
                      defaultValue={defaultEndTime}
                      format="HH:mm"
                      disabledTime={() => getDisabledSeconds()}
                      hideDisabledOptions={true}
                      popupClassName="hideSeconds"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={8}>
                <Col span={12}>
                  <Form.Item
                    label="Discount"
                    rules={[{ required: true }]}
                    tooltip="This is a required field"
                    name="discount"
                  >
                    <Input placeholder="10" />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    name="foradults"
                    label="For adults"
                    hidden={couponValidFor === "Course"}
                    tooltip="This is a required field"
                  >
                    <Select
                      disabled={props.name ? true : false}
                      style={{ width: 120 }}
                      onChange={handleAdults}
                    >
                      <Option value="true">true</Option>
                      <Option value="false">false</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    label="Subject"
                    tooltip="This is a required field"
                    name="subject"
                  >
                    <Select
                      defaultValue="English"
                      disabled={props.name ? true : false}
                      style={{ width: 120 }}
                      onChange={handleSubject}
                    >
                      <Option value="English">English</Option>
                      <Option value="IELTS">IELTS</Option>
                      <Option value="Financial Education">
                        Financial Education
                      </Option>
                      <Option value="Math">Math</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    label="Disable Coupon"
                    tooltip="This is a required field"
                    name="couponDisabled"
                  >
                    <Switch
                      size="small"
                      checked={disableCoupon}
                      onChange={disable}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    label="Remove From Coupon List"
                    tooltip="This is a required field"
                    name="removeFromCouponList"
                  >
                    <Switch
                      size="small"
                      checked={removeFromCouponList}
                      onChange={removeFromCouponList_}
                    />
                  </Form.Item>
                </Col>

                <Col span={6}>
                  <Form.Item
                    hidden={couponValidFor === "P2P"}
                    label="Demo coupon"
                    tooltip="Coupon valid 3 days after demo & valid only 1 day."
                    name="demoCoupon"
                  >
                    <Switch
                      size="small"
                      checked={
                        props.value ? props.value?.edit?.demoCoupon : demoCoupon
                      }
                      onChange={() => setDemoCoupon(!demoCoupon)}
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    hidden={couponValidFor === "P2P"}
                    label="Add free courses"
                    tooltip="Add free courses to coupon"
                    name="addFreeCourses"
                  >
                    <Switch
                      size="small"
                      checked={isFreeCourse}
                      onChange={() => setIsFreeCourse(!isFreeCourse)}
                    />
                  </Form.Item>
                </Col>
                {isFreeCourse && (
                  <Col span={8}>
                    <Form.Item label="Courses" name="freeCourses">
                      <Select
                        mode="multiple"
                        defaultValue={
                          props.value && props.value.edit.freeCourses.length
                            ? props.value.edit.freeCourses
                            : []
                        }
                        onChange={handleSelectedCourses}
                      >
                        {props?.courses.map((data, i) => {
                          return (
                            <Option key={i} value={data._id}>
                              {data.courseName}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                )}
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={8}>
                <Col span={4}>
                  <Form.Item
                    hidden={couponValidFor === "Course"}
                    label="Coupon type"
                    name="couponType"
                  >
                    <Select
                      defaultValue={
                        props.value ? props.value.edit.couponType : "Discount"
                      }
                      style={{ width: 120 }}
                      onChange={handleCouponType}
                    >
                      <Option value={"Discount"}>Discount</Option>
                      <Option value={"Bonus"}>Bonus</Option>
                      <Option value={"Special"}>Special</Option>
                      <Option value={"Bogo"}>Bogo</Option>
                      <Option value={"AOV"}>AOV</Option>
                    </Select>
                  </Form.Item>
                </Col>
                {couponType === "Special" || couponType === "Bogo" ? (
                  <>
                    <Col span={4}>
                      <Form.Item label="Plan month" name="planMonths">
                        <Select
                          mode="multiple"
                          defaultValue={
                            props.value && props.value.edit.planMonths
                          }
                          style={{ width: 120 }}
                          onChange={handlePlanMonths}
                        >
                          {/* <Option value={"N/A"}>N/A</Option> */}
                          <Option value={"1"}>1</Option>
                          <Option value={"3"}>3</Option>
                          <Option value={"6"}>6</Option>
                          <Option value={"12"}>12</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </>
                ) : couponType === "Bonus" ? (
                  <>
                    <Col span={4}>
                      <Form.Item label="Bonus session" name="bonusSessions">
                        <Switch
                          size="small"
                          checked={bonusSession}
                          onChange={() => setBonusSession(!bonusSession)}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item label="Bonus month" name="bonusMonths">
                        <Switch
                          size="small"
                          checked={bonusMonth}
                          onChange={() => setBonusMonth(!bonusMonth)}
                        />
                      </Form.Item>
                    </Col>
                  </>
                ) : (
                  ""
                )}

                {couponType === "Bogo" && (
                  <>
                    <Col span={4}>
                      <Form.Item label="Bonus Months" name="BonusPlanMonths">
                        <Select
                          mode="multiple"
                          style={{ width: 100 }}
                          onChange={handlePlanMonthsBonus}
                        >
                          {/* <Option value={"N/A"}>N/A</Option> */}
                          <Option value={"1"}>1</Option>
                          <Option value={"3"}>3</Option>
                          <Option value={"6"}>6</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label="Subscription Membership"
                        tooltip="This is a required field"
                        name="subscriptionmembership"
                      >
                        <Switch
                          size="small"
                          checked={subscriptionMembership}
                          onChange={disablesubscriptionMembership}
                        />
                      </Form.Item>
                    </Col>
                  </>
                )}

                {couponType === "AOV" ? (
                  <>
                    <Col span={4}>
                      <Form.Item
                        label="Cart Value"
                        tooltip="The minimum cart value required to avail the discount"
                        name="cartAmount"
                      >
                        <Input placeholder="Enter cart value" />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item
                        label="Discount Amount"
                        tooltip="The maximum amount upto which the user can avail the discount"
                        name="cashBackAmount"
                      >
                        <Input placeholder="Enter discount amount" />
                      </Form.Item>
                    </Col>
                  </>
                ) : null}
              </Row>
            </Col>
          </Row>
          {couponValidFor != "P2P" && (
            <>
              <Title level={5}>
                Segmentation Filer
                <Switch
                  size="small"
                  checked={addSegmentFiler}
                  onChange={() => setAddSegmentFilter(!addSegmentFiler)}
                />
              </Title>
              <Divider />
            </>
          )}
          {addSegmentFiler ? (
            <Row gutter={10}>
              <Col span={5}>
                <Form.Item
                  hidden={couponValidFor === "Course"}
                  label="Subscribe earlier but currently not"
                  name="previousSubscriptionMonth"
                >
                  <Select
                    defaultValue={
                      props.value?.edit?.segmentation?.previousSubscriptionMonth
                    }
                    style={{ width: 120 }}
                  >
                    <Option value={null}>Select subscription month</Option>
                    <Option value={1}>1 month</Option>
                    <Option value={3}>3 month</Option>
                    <Option value={6}>6 month</Option>
                    <Option value={12}>12 month</Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col span={5}>
                <Form.Item
                  label="Attended not subscribed"
                  name="attendedNotSubscribed"
                >
                  <Switch
                    size="small"
                    defaultChecked={
                      props.value?.edit?.segmentation?.attendedNotSubscribed
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item
                  label="Paid for the demo but not booked"
                  name="demoPaidNotBooked"
                >
                  <Switch
                    size="small"
                    defaultChecked={
                      props.value?.edit?.segmentation?.demoPaidNotBooked
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item
                  label="Signed up but did not pay"
                  name="signupNotPaid"
                >
                  <Switch
                    size="small"
                    defaultChecked={
                      props.value?.edit?.segmentation?.signupNotPaid
                    }
                  />
                </Form.Item>
              </Col>

              <Col span={4}>
                <Form.Item
                  hidden={couponValidFor === "Course"}
                  label="Subscribers"
                  name="subscriptionCount"
                >
                  <Select
                    defaultValue={
                      props.value?.edit?.segmentation?.subscriptionCount
                    }
                    style={{ width: 120 }}
                  >
                    <Option value={null}>Select subscription count</Option>
                    <Option value={1}>1 time</Option>
                    <Option value={2}>2 times</Option>
                    <Option value={3}>3+ times</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          ) : (
            ""
          )}
          {couponValidFor != "P2P" && (
            <Title level={5}>
              Exclusion Filter
              <Switch
                size="small"
                checked={addExclusionFiler}
                onChange={() => setAddExclusionFilter(!addExclusionFiler)}
              />
            </Title>
          )}
          {addExclusionFiler ? (
            <>
              <Row gutter={10}>
                <Col span={7}>
                  <Form.Item
                    hidden={couponValidFor === "Course"}
                    label="No. of days since demo attendance"
                    name="noOfDays"
                    tooltip="For not displaying certain offers to users who attended demos within the past given no. of days"
                  >
                    <Input placeholder="Enter days" />
                  </Form.Item>
                </Col>
                <Col span={7}>
                  <Form.Item
                    hidden={couponValidFor === "Course"}
                    label="No. of days since subscription"
                    name="noOfDaysSinceSubs"
                    tooltip="For not displaying certain offers to users who purchased subscription within the past given no. of days"
                  >
                    <Input placeholder="Enter days" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={10}>
                {utmParameters?.map((param, index) => (
                  <Col span={6} key={index}>
                    <Form.Item
                      label={`UTM ${
                        param?.name.charAt(0).toUpperCase() +
                        param?.name.slice(1)
                      }`}
                    >
                      <Form.Item>
                        <Radio.Group
                          value={
                            param.isContained
                              ? "isContained"
                              : param.isEqual
                              ? "isEqual"
                              : ""
                          }
                        >
                          <Radio
                            value="isContained"
                            onClick={() => {
                              setUtmParameters((prevState) => {
                                const updatedState = prevState.map(
                                  (param, i) => {
                                    if (i === index) {
                                      return {
                                        ...param,
                                        isContained: !param.isContained,
                                        isEqual: false,
                                      };
                                    }
                                    return param;
                                  }
                                );

                                return updatedState;
                              });
                            }}
                          >
                            Contains
                          </Radio>
                          <Radio
                            value="isEqual"
                            onClick={() => {
                              setUtmParameters((prevState) => {
                                const updatedState = prevState.map(
                                  (param, i) => {
                                    if (i === index) {
                                      return {
                                        ...param,
                                        isContained: false,
                                        isEqual: !param.isEqual,
                                      };
                                    }
                                    return param;
                                  }
                                );

                                return updatedState;
                              });
                            }}
                          >
                            Exactly
                          </Radio>
                        </Radio.Group>
                      </Form.Item>
                      <AutoComplete
                        value={param?.value}
                        options={options[param?.name]}
                        style={{
                          width: 200,
                        }}
                        onSearch={(text) =>
                          setSearchText({
                            searchText: text,
                            param: param?.name,
                          })
                        }
                        onChange={(data) => {
                          let newUtmParameters = [...utmParameters];
                          newUtmParameters[index].value = data;
                          setUtmParameters(newUtmParameters);
                        }}
                        placeholder={param?.name}
                      />
                    </Form.Item>
                  </Col>
                ))}
              </Row>
            </>
          ) : (
            ""
          )}
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loader} block>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default AddCoupon;
