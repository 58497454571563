import React, { useState, useEffect } from "react";
import { Table, Spin, Row, Col, Radio, Card, Button, Select, Avatar } from "antd";
import PageBreadcrumb from "../component/PageBreadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { Feedback } from "../redux/actions/UserAction";
import { getDate } from "../helper/helper";

const { Option } = Select;

const UserFeedback = () => {
  const dispatch = useDispatch();
  const [feedbackType, setFeedbackType] = useState("All");
  const [activePage, setActivePage] = useState(1);
  const [searchLoader, setSearchLoader] = useState(false);
  const [subject, setSubject] = useState(null);
  const [sessionFor, setSessionFor] = useState(null);
  const [sessionType, setSessionType] = useState(null);

  var userFeedback = useSelector((state) => state.SessionReducer.userFeedback);


  const onChangeFeedbackType = (e) => {
    setFeedbackType(e.target.value);
  };

  const onChangePagination = (page, pageSize) => {
    if (page.current > activePage) {
      var data = {
        ratingPerPage:100*page.current,
        page:page.current
      }
      if(feedbackType === 'Negative'){
      data["negativeReview"]=true
      }
      if(feedbackType === 'Positive'){
        data["negativeReview"]=false
        }
      dispatch(Feedback(data))
      setActivePage(page.current);
    }
  };

  const search = () =>{
    setSearchLoader(true)
    setActivePage(1)
    var data = {
      ratingPerPage:300,
      page:1
    }

    if(feedbackType === 'Negative'){
      data["negativeReview"]=true
      }
      if(feedbackType === 'Positive'){
        data["negativeReview"]=false
      }

      if(subject){
        data["subject"]=subject
      }

      if(sessionFor === "Kids"){
        data["kid"]=true
      }
      if(sessionType){
        data["sessionType"]=sessionType
      }

      const onSuccess =()=>{
        setSearchLoader(false)
      }
      const onError =()=>{
        setSearchLoader(false)
      }
      dispatch(Feedback(data,onSuccess,onError))
  }

  const columns = [
    {
      title: "Sl No",
      dataIndex: "sl",
      key: "sl",
      width: 70,
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width: 150,
    },
    {
      title: "Feedback",
      dataIndex: "feedback",
      key: "feedback",
      width: 350,
      render: (feedbackList) => (
        <ul>
          {feedbackList.map((item) => (
            <li>{item.answer}</li>
          ))}
        </ul>
      ),
    },
    {
      title: "Rating",
      dataIndex: "rating",
      key: "rating",
    },
    {
      title: "Learner",
      dataIndex: "learner",
      key: "learner",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
    },
    {
      title: "Mobile number",
      dataIndex: "number",
      key: "number",
    },
    {
      title: "Meeting number",
      dataIndex: "meetingnumber",
      key: "meetingnumber",
    },
  ];

  const data = userFeedback
    ? userFeedback.ratingData.map((data, i) => ({
        key: data._id,
        sl: i + 1,
        date: data.sessionData
          ? getDate(data.sessionData.sessionDate)
          : "Not available",
        feedback: data.QA,
        rating: data.rating,
        learner: data.learnerData ? data.learnerData.name : "Not available",
        type:data.sessionType,
        subject:data.subject,
        number: data.learnerData
          ? data.learnerData.phoneNumber
          : "Not available",
        meetingnumber: data.sessionData
          ? data.sessionData.meetingNo
          : "Not available",
      }))
    : "";

  return (
    <div>
      <PageBreadcrumb route={["dashboard", "User Feedback"]} />

      <Card title="Filter feedback" style={{ marginBottom: "1rem" }}>
        <Row>
          <Col span={24}>
            <Radio.Group onChange={onChangeFeedbackType} value={feedbackType}>
              <Radio value={"All"}>All Reviews</Radio>
              <Radio value={"Negative"}>Negative Review</Radio>

              <Select
            showSearch
            style={{ width: 150 }}
            placeholder="Select session for"
            onChange={setSessionFor}
          >
          <Option value={null}>None</Option>
          <Option value="Adult">Adult</Option>
          <Option value="Kids">Kids</Option>
        </Select>

          <Select
            showSearch
            style={{ width: 150 }}
            placeholder="Select subject"
            onChange={setSubject}
          >
          <Option value={null}>None</Option>
          <Option value="English">English</Option>
          <Option value="IELTS">IELTS</Option>
        </Select>

        <Select
            showSearch
            style={{ width: 150 }}
            placeholder="Select session type"
            onChange={setSessionType}
          >
          <Option value={null}>None</Option>
          <Option value="Regular">Regular</Option>
          <Option value="Demo">Demo</Option>
        </Select>


              <Button type="primary" disabled={searchLoader} loading={searchLoader} onClick={() => search()}>
                Search
              </Button>
            </Radio.Group>
          </Col>
        </Row>
      </Card>

      {userFeedback ? (
        <Table
        columns={columns}
        dataSource={data}
        scroll={{ x:1200 }}
        onChange={onChangePagination}
        pagination={{
          pageSize: 100,
          total: userFeedback.totalRating,
          defaultCurrent: activePage,
        }}
        />
      ) : (
        <div className="spin-center">
          <Spin size="large" />
        </div>
      )}
    </div>
  );
};

export default UserFeedback;
