import * as constant from "../constants/CurriculumConstants";

const initialState = {
  curriculums: [],
  curriculumDetails: {},
};

export const CurriculumReducer = (state = initialState, action) => {
  switch (action.type) {
    case constant.CURRICULUM_CREATED:
      const newCurriculums = state.curriculums;
      newCurriculums.push(action.payload);
      return {
        ...state,
        curriculums: newCurriculums,
        curriculumDetails: { ...state.curriculumDetails, ...action.payload,modules:[] },
      };
    case constant.LOAD_CURRICULUM:
      return {
        ...state,
        curriculumDetails: {
          ...action.payload.formattedCurriculum,
          chapters: action.payload.chapters,
        },
      };
    case constant.MODULE_CREATED:
      const newModule = state.curriculumDetails.modules;
      newModule.push(action.payload);
      return {
        ...state,
        curriculumDetails: { ...state.curriculumDetails, modules: newModule },
      };
    case constant.CHAPTER_CREATED:
      var { module, _id, name, description } = action.payload;

      return {
        ...state,
        curriculumDetails: {
          ...state.curriculumDetails,
          modules: state.curriculumDetails.modules.map((mod) => {
            if (mod._id === module._id) {
              return {
                ...mod,
                chapters: [
                  ...(mod.chapters || []),
                  {
                    _id,
                    name,
                    description,
                    topics: [],
                  },
                ],
              };
            }
            return mod;
          }),
        },
      };

    case constant.TOPIC_CREATED:
      var {
        module,
        chapter,
        _id,
        name,
        description,
        lessonMaterial,
        type,
      } = action.payload;

      // Update the state with the new topic
      return {
        ...state,
        curriculumDetails: {
          ...state.curriculumDetails,

          modules: state.curriculumDetails.modules.map((mod) => {
            if (mod._id === module._id) {
              return {
                ...mod,
                chapters: mod.chapters.map((chap) => {
                  if (chap._id === chapter._id) {
                    return {
                      ...chap,
                      topics: [
                        ...chap.topics,
                        {
                          _id,
                          name,
                          description,
                          lessonMaterial,
                          type,
                        },
                      ],
                    };
                  }
                  return chap;
                }),
              };
            }
            return mod;
          }),
        },
      };

      case constant.ASSESSMENT_CREATED:
      const { topicId, assessment } = action.payload;
      return {
        ...state,
        curriculumDetails: {
          ...state.curriculumDetails,
          modules: state.curriculumDetails.modules.map((mod) => {
            return {
              ...mod,
              chapters: mod.chapters.map((chap) => {
                return {
                  ...chap,
                  topics: chap.topics.map((topic) => {
                    if (topic._id === topicId) {
                      return {
                        ...topic,
                        assessment: assessment,
                      };
                    }
                    return topic;
                  }),
                };
              }),
            };
          }),
        },
      };


    default:
      return state;
  }
};
