import React, { useState } from 'react';
import { teachersConcern, updateTeacherConcernStatus } from "../../redux/actions/TeacherAction"
import { useDispatch } from "react-redux";
import { Table, Spin, Modal, Button, message, Radio, Space, Tag } from "antd";
import { utcToLocalTimeConverter } from "../../helper/TimeDate";
import PageBreadcrumb from "../../component/PageBreadcrumb";
import { useEffect } from 'react';
import moment from 'moment-timezone';


const ChangeStatus = ({ raiseConcernId, Concern, setConcern }) => {

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [status, setStatus] = useState("RESOLVED");
  const [infoMessage, setMessage] = useState("");
  const [changeStatusLoader, setChangeStatusLoader] = useState("");

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const updateConcernStatus = () => {
    console.log(raiseConcernId);
    setChangeStatusLoader(raiseConcernId)
    var payload = {
      raiseConcernId: raiseConcernId,
      status: status
    }
    if (status === "RESOLVED") {
      payload["resolveComment"] = infoMessage
    }

    const onSuccess = () => {
      var filteredRequests = Concern.filter((val) => val._id !== raiseConcernId);
      console.log(filteredRequests)
      setConcern(filteredRequests);
      message.success("Status updated successfully", 8);
      setChangeStatusLoader(false)
      setIsModalVisible(false)
    }

    const onError = (err) => {
      message.success(err, 8);
      setChangeStatusLoader(false)
    }
    updateTeacherConcernStatus(payload, onSuccess, onError)
  }

  return (
    <>
      <Button type="primary" onClick={showModal}>
        Edit
      </Button>
      <Modal title="Change status" visible={isModalVisible} onCancel={handleCancel} footer={[]}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <label>Status :</label>
          <select onChange={(e) => setStatus(e.target.value)} style={{ width: "200px" }}>
            <option>RESOLVED</option>
            <option>IN REVIEW</option>
            <option>REQUESTED</option>
            <option>REJECTED</option>
          </select>
        </div>
        {
          status === "RESOLVED" ?
            <div style={{ display: "flex", flexDirection: "column" }}>
              <label>Status :</label>
              <textarea
                style={{ width: "100%" }}
                value={infoMessage}
                onChange={(e) => setMessage(e.target.value)}
              >
              </textarea>
            </div>
            : ""
        }
        <Button type= "primary" style={{ marginTop: "6px" }} onClick={() => updateConcernStatus()} disabled={changeStatusLoader === raiseConcernId} >{changeStatusLoader === raiseConcernId ? "Loading..." : "Update"}</Button>
      </Modal>
    </>
  )
}

const TeacherConcern = () => {
  const [Concern, setConcern] = useState(null);
  const dateFormat = "YYYY-MM-DD";
  const [page, setPage] = useState(0);
  const [initialLoader,setInitialLoader] = useState(true)
  const [tab, setTab] = useState("REQUESTED");

  useEffect(() => {
    setInitialLoader(true);

    var payload = {
        status:tab,
        page:page,
        limit:10
    }

    console.log(payload)

    const onSuccess = (data) => {
      console.log(data);
      setConcern(data.getAllTeacherConcernDetails);
      setInitialLoader(false);
    };

    const onError = (error) => {
      console.log(error);
      setInitialLoader(false);
  };
    teachersConcern(payload,onSuccess, onError);
  }, [tab]);


const tagColour = (status) =>{
  var colour = 'green'
  if(status === "REQUESTED"){
      colour = "red"
  }
  return colour;
}

  const columns = [
    {
      title: "SL.No",
      dataIndex: "sl",
      key: "sl",
    },
    {
      title: "Teacher Name",
      dataIndex: "teacherName",
      key: "teacherName",
    },
    {
      title: "Concern Raised",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Slots Last Updated Date",
      dataIndex: "lastupdatedByTeacher",
      key: "lastupdatedByTeacher",
    },
    {
      title: "Concern Raised Date",
      dataIndex: "createdAtDate",
      key: "createdAtDate",
    },
    {
      title: "Concern Raised Time",
      dataIndex: "createdAtTime",
      key: "createdAtTime",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <>
           <Tag color={tagColour(status)} >
             {status.toUpperCase()}
           </Tag>
        </>
      ),
    },
    {
      title: "Action",
      width: 5,
      dataIndex: "action",
      key: "7",
      render: (action) => (
        <ChangeStatus raiseConcernId={action} Concern={Concern} setConcern={setConcern} />
      ),
    },
  ];

  const data = Concern
    ? Concern.map((data, i) => ({
      key: data._id,
      sl: i + 1,
      teacherName: data.teacherId ? data.teacherId?.name : "N/A",
      description: data.description ? data.description : "N/A",
      lastupdatedByTeacher: `${moment(new Date(data.lastupdatedByTeacher)).format(dateFormat)}`,
      status: data.status,
      createdAtDate: `${moment(new Date(data.createdAt)).format(dateFormat)}`,
      createdAtTime: utcToLocalTimeConverter(data.createdAt),
      action: data?._id,
    }))
    : "";



    return (
      <div>
        <PageBreadcrumb route={["dashboard", "Teacher Occupancy Concern"]} />
        <Space style={{ marginBottom: 24 }}>
          <Radio.Group value={tab} onChange={(e)=>setTab(e.target.value)}>
            <Radio.Button value="REQUESTED">Requested</Radio.Button>
            <Radio.Button value="IN REVIEW">InReview</Radio.Button>
            <Radio.Button value="RESOLVED">Resolved</Radio.Button>
            <Radio.Button value="REJECTED">Rejected</Radio.Button>
          </Radio.Group>
        </Space>
  
        
          {initialLoader?
                <div className="spin-center">
                    <Spin size="large" />
                </div>
                :
          <Table
            columns={columns}
            dataSource={data}
            scroll={{ x: 1000 }}
            pagination={{
              defaultPageSize: 40,
              showSizeChanger: true,
              pageSizeOptions: ["80", "120", "160"],
            }}
          />
  }
            </div>
    );
}

export default TeacherConcern