import React, { useEffect, useState } from "react";
import { Select, Modal, Button, Form, Input, message } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { PlusOutlined } from "@ant-design/icons";
import { CreateChapter } from "../../redux/actions/CurriculumAction";
import { useDispatch, useSelector } from "react-redux";

const { Option } = Select;

const CreateChapterModal = ({ curriculumId, module }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  const createChapter = () => {
    form
      .validateFields()
      .then((values) => {
        values["curriculumId"] = curriculumId;
        values["moduleId"] = module[0]._id;
        console.log(values);
        const onSuccess = (res) => {
          setVisible(false);
          form.resetFields();
          message.success("Chapter created successfully :)");
        };
        const onError = (msg) => {
          message.error(msg);
        };
        CreateChapter(values, onSuccess, onError, dispatch);
      })
      .catch((errorInfo) => {
        console.log("Validation Failed:", errorInfo);
      });
  };

  const handleCancel = () => {
    form.resetFields();
    setVisible(false);
  };

  return (
    <>
      <Button type="text" icon={<PlusOutlined />} onClick={showModal}>
        Add New
      </Button>
      <Modal
        title="Add New Chapter"
        visible={visible}
        onOk={createChapter}
        onCancel={handleCancel}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            label={`Serial Number`}
            name={`serialNo`}
            rules={[
              { required: true, message: "Please add a serial number!" },
              
            ]}
          >
            <Input placeholder="Serial Number" />
          </Form.Item>
          <Form.Item
            label={`Chapter Title`}
            name={`name`}
            rules={[{ required: true, message: "Please add chapter title !" }]}
          >
            <Input placeholder="Chapter title" />
          </Form.Item>
          <Form.Item
            label="Chapter Description"
            name={`description`}
            rules={[
              { required: true, message: "Please add chapter description !" },
            ]}
          >
            <TextArea rows={2} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

const ChapterList = ({ curriculumId, module, setChapter }) => {
  const [chapters, setChapters] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);

  const curriculum = useSelector((state) => state.CurriculumReducer.curriculumDetails);

  useEffect(() => {
    if (curriculum && curriculum.modules && module && module.length > 0) {
      const filteredModule = curriculum.modules.filter((data) => data._id === module[0]._id);
      if (filteredModule.length > 0) {
        setChapters(filteredModule[0].chapters);
      } else {
        setChapters([]);
      }
    }
    console.log("Trigger");
  }, [curriculum, module]);

  const handleChange = (value) => {
    setSelectedValue(value);
    setChapter(value);
  };

  return (
    <Select
      value={selectedValue}
      style={{ width: "100%" }}
      onChange={handleChange}
      dropdownRender={(menu) => (
        <div>
          {menu}
          <CreateChapterModal curriculumId={curriculumId} module={module} />
        </div>
      )}
    >
      {chapters?.map((chapter) => (
        <Option key={chapter._id} value={chapter._id}>
          {chapter.name}
        </Option>
      ))}
    </Select>
  );
};

export default ChapterList;
