import axios from "axios";

axios.defaults.headers.common["platform"] = "AdminWeb";
// eslint-disable-next-line import/no-anonymous-default-export
export default {
    Authontication(url = `${process.env.REACT_APP_BASE_URL}`) {
        return {
            Login: (data) => axios.post(url+`/api/adminauth/adminlogin`,data),
            createAdmin: (data) => axios.post(url+`/api/adminauth/createadmin`,data),
            homeStats: (data) => axios.post(url+`/api/admingraph/homestastistics`,data),
            getAdminList: (data) => axios.get(url+`/api/adminauth/getAdminList`,data),
            addAdminRole:(data) => axios.post(url+`/api/adminauth/addRole`, data),
            resourceUpload:(data) => axios.post(url+`/api/adminauth/uploadTeacherResourseGetPreSignedURL`, data),
            getPreSignedUrl:(data) => axios.post(url+`/api/images/getPreSignedURL`, data),
            createStories: (data) => axios.post(url+`/api/images/createStory`, data),
            getStories:() => axios.get(url+`/api/images/getStoryData`),
            updateSlots: (data) => axios.post(url + `/api/adminauth/storeDynamicDemoDates`, data),
            askOTP:(data) => axios.post(url + `/api/adminauth/v1/askAdminForOtp`, data),
            resendOTP:(data) => axios.post(url + `/api/adminauth/v1/resendotp`, data),
            verifyOTP: (data) => axios.put(url + `/api/adminauth/v1/verifyOtp`, data),
            subscriptionList : (data) => axios.post(url + `/api/adminauth/fetchSubscriptionDataByAdmin`, data),
        }
    }
}

