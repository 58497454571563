import React, { useEffect, useState } from "react";
import _ from "lodash";
import {
  Table,
  DatePicker,
  Select,
  Collapse,
  Button,
  Space,
  message,
  Spin,
} from "antd";
import { GetSessionAllotmentData } from "../../redux/actions/SessionAction";
import { UTCtoLocalConverter, utcToLocalDateConverter, utcToLocalDateTimeConverter } from "../../helper/TimeDate";
import { UTCToAsiaTimeConverter, formatAMPM } from "../../helper/helper";
import moment from "moment-timezone";
import { v4 as uuidv4 } from 'uuid';

const { Panel } = Collapse;
const { RangePicker } = DatePicker;

const dataFormatter = (timeSlot, data) => {
  const teachers = [];

  data.map((teacher) => {
    const aggregatedData = [];

    teacher.aggregatedConversions.map((rec, i) => {
      const sessionDate = rec.sessionDate.split("T");

      aggregatedData.push({
        key: i,
        teacherName: teacher.teacherData.name,
        learnerName: rec?.learnerData?.name ? rec?.learnerData?.name : "N/A",
        bookingTime: utcToLocalDateTimeConverter(rec.createdAt),
        learnerAttended: rec.learnerJoiningTime?.length > 0 ? "Yes" : "No",
        teacherAttended: rec.speakerJoiningTime?.length > 0 ? "Yes" : "No",
        teacherConversion: rec.currentTeacherConversion,
        sessionDate: utcToLocalDateConverter(sessionDate[0], rec.endTime),
        sessionTime: `${UTCToAsiaTimeConverter(rec.startTime)} - ${UTCToAsiaTimeConverter(rec.endTime)}`,
        conversionPercentage: rec.currentTeacherConversion,
      });
    });

    teachers.push({
      key: uuidv4(),
      hourlyRange: timeSlot,
      teacherCount: data?.length,
      teacherName: teacher.teacherData.name,
      conversionPercentage: aggregatedData.length > 0 ? aggregatedData[0].conversionPercentage : 0,
      currentConversionPercentage: teacher.teacherData.demoConversionPercentLast50Sessions,
      demosBooked: teacher.demoSessionCount,
      demosAttended: teacher.totalAttendedDemoSessions,
      convertedSubscriptions: teacher.totalConversionSubscriptions,
      teachers: aggregatedData,
    });
  });

  return {
    timeSlot: timeSlot,
    allotmentData: teachers,
  };
};

const Conversion = () => {
  const [dates, setDates] = useState([moment(), moment()]);
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    fetchAllotmentData();
  }, []);

  const fetchAllotmentData = () => {
    setLoader(true);
    const startDate = moment(dates[0]).utc().format('YYYY-MM-DD');
    const endDate = moment(dates[1]).utc().format('YYYY-MM-DD');
    const onSuccess = (res) => {
      const obj = res.hourWiseTeacherConversion;
      const keys = Object.keys(obj);
      const allotmentData = [];

      for (const key of keys) {
        const value = obj[key];
        if (value[0]?.length > 0) {
          const formattedData = dataFormatter(key, value[0]);
          allotmentData.push(formattedData);
        }
      }

      setData(allotmentData);
      setLoader(false);
    };
    const onError = (msg) => {
      setLoader(false);
      message.error(msg);
    };
    GetSessionAllotmentData(
      { startDate, endDate },
      onSuccess,
      onError
    );
  };

  const handleDateChange = (dates) => {
    setDates(dates);
  };

  const columns = [
    {
      title: "Teacher Name",
      dataIndex: "teacherName",
      key: "teacherName",
    },
    {
      title: "Conversion %",
      dataIndex: "conversionPercentage",
      key: "conversionPercentage",
    },
    {
      title: "Current Conversion %",
      dataIndex: "currentConversionPercentage",
      key: "currentConversionPercentage",
    },
    {
      title: "Number of Demos Booked",
      dataIndex: "demosBooked",
      key: "demosBooked",
    },
    {
      title: "Demos Attended",
      dataIndex: "demosAttended",
      key: "demosAttended",
    },
    {
      title: "Converted Subscriptions",
      dataIndex: "convertedSubscriptions",
      key: "convertedSubscriptions",
    },
  ];

  const summaryColumns = [
    {
      title: "Learner Name",
      dataIndex: "learnerName",
      key: "learnerName",
    },
    {
      title: "Booking Time",
      dataIndex: "bookingTime",
      key: "bookingTime",
    },
    {
      title: "Teacher Name",
      dataIndex: "teacherName",
      key: "teacherName",
    },
    {
      title: "Learner Attended",
      dataIndex: "learnerAttended",
      key: "learnerAttended",
    },
    {
      title: "Teacher Attended",
      dataIndex: "teacherAttended",
      key: "teacherAttended",
    },
    {
      title: "Teacher Conversion",
      dataIndex: "teacherConversion",
      key: "teacherConversion",
    },
    {
      title: "Session Date",
      dataIndex: "sessionDate",
      key: "sessionDate",
    },
    {
      title: "Session Time",
      dataIndex: "sessionTime",
      key: "sessionTime",
    },
  ];

  return (
    <div>
      <div style={{ marginBottom: 16 }}>
        <Space wrap>
          <RangePicker value={dates} onChange={handleDateChange} />
          <Button type="primary" onClick={fetchAllotmentData}>
            Search
          </Button>
        </Space>
      </div>
      {loader ? (
        <div className="spin-center">
          <Spin size="large" />
        </div>
      ) : (
        <div>
          {data.length === 0 ? (
            <div style={{background:"#FFFFFF" ,display:"flex", justifyContent:"center", height:"100vh", marginLeft:"auto", alignItems:"center", fontWeight:"bold"}}>No data available</div>
          ) : (
            <Collapse
              defaultActiveKey={Array.from(
                { length: data.length },
                (_, index) => index
              )}
            >
              {data.map((records, i) => (
                <Panel style={{fontWeight: "600",fontSize:"12px"}} 
                  header=
                  {`${records.timeSlot} - 
                   Demos Booked:
                  ${records.allotmentData.reduce(
                    (total, teacher) => total + teacher.demosBooked,
                    0
                  )},
                   Demos Attended:
                  ${records.allotmentData.reduce(
                    (total, teacher) => total + teacher.demosAttended,
                    0
                  )},
                   Converted Subscriptions:
                  ${records.allotmentData.reduce(
                    (total, teacher) => total + teacher.convertedSubscriptions,
                    0
                  )},
                  Attended Percentage: ${
                    records.allotmentData.reduce(
                      (total, teacher) => total + teacher.demosBooked,
                      0 
                    )  && records.allotmentData.reduce(
                      (total, teacher) => total + teacher.demosAttended,
                      0
                    ) > 0 
                      ? ((records.allotmentData.reduce(
                          (total, teacher) => total + teacher.demosAttended,
                          0
                        )) /
                        (records.allotmentData.reduce(
                          (total, teacher) => total + teacher.demosBooked,
                          0
                        )) * 100).toFixed(1)
                      : 0
                  }%,
                  Converted Percentage: ${
                    records.allotmentData.reduce(
                      (total, teacher) => total + teacher.demosAttended,
                      0
                    ) && records.allotmentData.reduce(
                      (total, teacher) => total + teacher.convertedSubscriptions,
                      0
                    ) > 0
                      ? ((records.allotmentData.reduce(
                          (total, teacher) => total + teacher.convertedSubscriptions,
                          0
                        )) /
                        (records.allotmentData.reduce(
                          (total, teacher) => total + teacher.demosAttended,
                          0
                        )) * 100).toFixed(1)
                      : 0
                  }%
                  `} key={i}>
                    <Table
                      dataSource={records.allotmentData}
                      columns={columns}
                      pagination={false}
                      expandable={{
                        expandedRowRender: (record) => (
                          <Collapse accordion>
                            <Panel header={`${record.teacherName} sessions`} key={record.key}>
                              <Table
                                dataSource={record.teachers}
                                columns={summaryColumns}
                                pagination={false}
                              />
                            </Panel>
                          </Collapse>
                        ),
                      }}
                    />
                  </Panel>
                ))}
              </Collapse>
            )}
        </div>
      )}
    </div>
  );
};

export default Conversion;
