import React, { useEffect, useState } from "react";
import { Modal, Button, Table, InputNumber, Space, DatePicker, Input, Switch, message } from "antd";
import moment from "moment-timezone";
import { useDispatch } from "react-redux";
import { AddDemoPool, GetMaxSlot } from "../../redux/actions/DemoPoolAction";
import { localToUTCDate } from "../../helper/TimeDate";

const { RangePicker } = DatePicker;

const SetDemoPool = ({ callback, poolDate, demoPoolData }) => {
  const [visible, setVisible] = useState(false);
  const [loader, setLoader] = useState(false);
  const [selectedDate, setSelectedDate] = useState(poolDate ? moment(poolDate) : moment());
  const [conversionFilter, setConversionFilter] = useState(0);
  const [allowConversionFilter, setAllowConversionFilter] = useState(false);
  const [maxSlots, setMaxSlot] = useState([]);
  const [timeSlots, setTimeSlots] = useState(Array.from({ length: 24 }, (_, index) => ({
    key: index,
    timeSlot: `${index}:00-${index + 1}:00`,
    reservedSlot: 0,
    maxAvailableSlot: ""
  })));

  const dispatch = useDispatch();

  useEffect(() => {
    // Function to prefill data when the modal is opened for updating demo pool
    const prefillData = () => {
      if (demoPoolData && demoPoolData.length > 0) {
        const firstData = demoPoolData[0];
        setConversionFilter(firstData.demoConversionFilter);
        setAllowConversionFilter(firstData.allowFilter);

        const updatedTimeSlots = timeSlots.map(slot => {
          const matchingData = demoPoolData.find(data => {
            const startTime = moment(data.startTime).format("H:00");
            const endTime = moment(data.endTime).format("H:00");
            const timeSlot = slot.timeSlot.split("-")[0];
            return startTime === timeSlot;
          });

          if (matchingData) {
            return {
              ...slot,
              reservedSlot: matchingData.reservedSlot
            };
          }
          return slot;
        });
        setTimeSlots(updatedTimeSlots);
      }
    };

    if (visible && poolDate && demoPoolData) {
      prefillData(); // Prefill data when the modal is opened
    }
  }, [visible]);

  useEffect(() => {
    const onSuccess = (res) => {
      setMaxSlot(res.data.maxAvailableSlots);
    };

    const onError = (errmsg) => {
      console.log(errmsg);
      message.error(errmsg);
    };

    const formattedDate = selectedDate.format("YYYY-MM-DD");
    dispatch(GetMaxSlot(formattedDate, conversionFilter, onSuccess, onError));

    // Prefill reserved slots and conversion filter from demoPoolData
    if (demoPoolData && demoPoolData.length > 0) {
      const firstData = demoPoolData[0];
      setConversionFilter(firstData.demoConversionFilter);
      setAllowConversionFilter(firstData.allowFilter);

      const updatedTimeSlots = timeSlots.map(slot => {
        const matchingData = demoPoolData.find(data => {
          const startTime = moment(data.startTime).format("H:00");
          const endTime = moment(data.endTime).format("H:00");
          const timeSlot = slot.timeSlot.split("-")[0];
          return startTime === timeSlot;
        });

        if (matchingData) {
          return {
            ...slot,
            reservedSlot: matchingData.reservedSlot
          };
        }
        return slot;
      });
      setTimeSlots(updatedTimeSlots);
    }
  }, [selectedDate, demoPoolData]);

  // New useEffect to trigger dispatch when conversion filter value changes and allowConversionFilter is true
  useEffect(() => {
    const onSuccess = (res) => {
      setMaxSlot(res.data.maxAvailableSlots);
    };

    const onError = (errmsg) => {
      console.log(errmsg);
      message.error(errmsg);
    };

    const formattedDate = selectedDate.format("YYYY-MM-DD");

    // Call the API only if allowConversionFilter is true and conversion filter value changes
    if (allowConversionFilter) {
      dispatch(GetMaxSlot(formattedDate, conversionFilter, onSuccess, onError));
    }
  }, [conversionFilter, allowConversionFilter]);
  
  // Filter time slots based on current time if the selected date is the current date
  useEffect(() => {
    if (selectedDate.isSame(moment(), 'day')) {
      const currentHour = moment().hour();
      const updatedTimeSlots = timeSlots.filter(slot => slot.key >= currentHour);
      setTimeSlots(updatedTimeSlots);
    } else {
      // Reset time slots to include all hours if the selected date is not the current date
      const updatedTimeSlots = Array.from({ length: 24 }, (_, index) => ({
        key: index,
        timeSlot: `${index}:00-${index + 1}:00`,
        reservedSlot: 0,
        maxAvailableSlot: ""
      }));
      setTimeSlots(updatedTimeSlots);
    }
  }, [selectedDate]);

  const datePicker = (date, dateString) => {
    setSelectedDate(date);
  };

  const allowConversionFilterEventListener = (checked) => {
    setAllowConversionFilter(checked);
  };

  const conversionFilterEventListener = (value) => {
    setConversionFilter(value);
  };

  const reserve = () => {
    setLoader(true);

    const filteredTimeSlots = timeSlots.filter(slot => slot.reservedSlot !== 0);

    const demoPools = filteredTimeSlots.map(slot => ({
      startTime: localToUTCDate(moment(selectedDate.format('YYYY-MM-DD') + ' ' + slot.timeSlot.split('-')[0], 'YYYY-MM-DD HH').toDate()),
      endTime: localToUTCDate(moment(selectedDate.format('YYYY-MM-DD') + ' ' + slot.timeSlot.split('-')[1], 'YYYY-MM-DD HH').toDate()),
      reservedSlot: slot.reservedSlot
    }));

    const data = {
      demoPools,
      demoConversionFilter: conversionFilter,
      allowFilter: allowConversionFilter,
      poolLocalDate: selectedDate.format('YYYY-MM-DD')
    };

    const onSuccess = (res) => {
      setLoader(false);
      message.success("New Demo pool added successfully");
      setVisible(false);
      callback(true);
    };

    const onError = (errmsg) => {
      setLoader(false);
      message.error(errmsg);
    };

    dispatch(AddDemoPool(data, onSuccess, onError));
  };

  const handleReservedSlotChange = (key, value) => {

    const hourIndex = timeSlots.findIndex(slot => slot.key === key);


    if (hourIndex !== -1) {
      const maxAvailableSlot = maxSlots[hourIndex]?.totalAvailableSlots;


      if (value > maxAvailableSlot || isNaN(maxAvailableSlot)) {
        message.error("Reserved slot cannot exceed max available slot.");
        const updatedTimeSlots = timeSlots.map(slot => {
          if (slot.key === key) {
            return { ...slot, reservedSlot: isNaN(maxAvailableSlot) ? 0 : maxAvailableSlot };
          }
          return slot;
        });
        setTimeSlots(updatedTimeSlots);
      } else {
        const updatedTimeSlots = timeSlots.map(slot => {
          if (slot.key === key) {
            return { ...slot, reservedSlot: value };
          }
          return slot;
        });
        setTimeSlots(updatedTimeSlots);
      }
    } else {
      message.error("Max slot information not found for the selected time slot.");
    }
  };


  const columns = [
    {
      title: "Time Slot",
      dataIndex: "timeSlot",
      key: "timeSlot"
    },
    {
      title: "Reserved Slot",
      dataIndex: "reservedSlot",
      key: "reservedSlot",
      render: (text, record) => (
        <InputNumber
          min={0}
          value={record.reservedSlot}
          onChange={(value) => handleReservedSlotChange(record.key, value)}
        />
      )
    },
    {
      title: "Max Available Slot",
      dataIndex: "maxAvailableSlot",
      key: "maxAvailableSlot",
      render: (text, record, index) => (
        <span>{maxSlots[index]?.totalAvailableSlots}</span>
      )
    }

  ];

  return (
    <>
      <div style={{ display: "flex", flexDirection: "row", gap: "2rem" }}>
        <Button type="primary" onClick={() => setVisible(true)}>
          {poolDate ? "Update Demo Pool" : "Set Demo Pool"}
        </Button>
      </div>

      <Modal
        centered
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
        width={1000}
      >
        <div>
          <Space style={{ marginBottom: "1rem" }}>
            <DatePicker
              onChange={datePicker}
              value={moment(selectedDate)}
              format="YYYY-MM-DD"
              disabledDate={(current) => current && current < moment().startOf('day')}
            />

            <Input
              onChange={(e) => conversionFilterEventListener(e.target.value)}
              placeholder="Conversion Filter"
              value={conversionFilter} // Prefill conversion filter input box
            />
            <Switch
              onChange={allowConversionFilterEventListener}
              checked={allowConversionFilter} // Prefill switch based on props
              placeholder="Allow Conversion Filter"
            />
            <Button type="primary" onClick={reserve} disabled={loader}>
              {loader ? "Adding..." : "Add"}
            </Button>
            <Button onClick={() => setVisible(false)} type="primary">
              Cancel
            </Button>
          </Space>
          <div style={{ maxHeight: "calc(100vh - 200px)", overflowY: "auto" }}>
            <Table
              dataSource={timeSlots}
              columns={columns}
              pagination={false}
              rowKey={record => record.key}
              style={{ marginTop: "20px" }}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SetDemoPool;
