import { Button, Modal, Typography, Select } from "antd";
import React, { useState } from "react";

const { Title } = Typography;

const SelectTeacher = ({ teachersList, bookRequestedSession, sessionData, bookingLoader, visible, setVisible }) => {
  const [teacherId, setTeacherId] = useState(false);

  const onChange = (_, value) => {
    setTeacherId(value.id);
  };

  return (
    <React.Fragment>
      <Modal
        centered
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
        width={400}
        bodyStyle={{ maxHeight: "90vh", overflowY: "auto" }}
      >
        <Title level={5}>Select teacher:</Title>
        <Select
          showSearch
          placeholder="Select a person"
          optionFilterProp="label"
          onChange={onChange}
          options={teachersList}
          style={{width:"100%"}}
        />
        <br/>
        <br/>
        <Button 
            style={{width:"100%"}} 
            type="primary" 
            onClick={() => bookRequestedSession(sessionData, teacherId)} 
            disabled={bookingLoader === sessionData._id}
            loading={bookingLoader === sessionData._id}
        >
          Book Session
        </Button>
      </Modal>
    </React.Fragment>
  );
};

export default SelectTeacher;
