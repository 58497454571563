import { Button, Card, Col, DatePicker, message, Row, Space, Spin} from 'antd';
import { Table } from "ant-table-extensions";
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react'
import PageBreadcrumb from '../../component/PageBreadcrumb';
import { UTCToAsiaTimeConverter } from '../../helper/helper';
import { GetUtmParams, UtmConversionDetails } from '../../redux/actions/UserAction'
import { localToUTCDate } from '../../helper/TimeDate';
import {DropDown} from './UTMCharts'

const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD HH:mm";

const ConversionData = () => {

    const [conversionData, setConversionData] = useState(null)
    const [utmParams, setUtmParams] = useState(null)
    const [startDate, setStartDate] = useState(moment().subtract(7, 'days'))
    const [endDate, setEndDate] = useState(moment())
    const [loader, setULoder] = useState(false)
    const [utmParamLoader, setUtmParamLoader] = useState(false)

    const [utmMedium, setUtmMedium] = useState("All")
    const [utmCampaign, setUtmCampaign] = useState("All")
    const [utmSource, setUtmSource] = useState("All")
    const [utmRoute, setUtmRoute] = useState("All")

    function onPickDate(date, dateString) {
        setStartDate(dateString[0])
        setEndDate(dateString[1])
      }

    useEffect(() => {

        setULoder(true)

        const data ={
            startDate:localToUTCDate(startDate),
            endDate:localToUTCDate(endDate)
        }

        const onSuccess = (data) =>{
            setULoder(false)
            setConversionData(data)
        }

        const onError = (err) =>{
           setULoder(false)
           message.error(err)
        }

        UtmConversionDetails(data,onSuccess,onError)
    }, [])

    useEffect(() => {
      setUtmParamLoader(true)

      const data ={
        startDate:localToUTCDate(startDate),
        endDate:localToUTCDate(endDate)
      }  

      const setParams = (utmData) =>{
        setUtmParamLoader(false)
        setUtmParams(utmData)
      }
      const setError = (err) => {
        setUtmParamLoader(false)
        message.error(err)
      }
      GetUtmParams(data,setParams,setError)

    }, [])
    

    const searchByDate = () =>{
        setULoder(true)
        var data ={
            startDate:localToUTCDate(startDate),
            endDate:localToUTCDate(endDate)
        }

        if(utmSource !== "All"){
          data["utm_source"]=utmSource
        }
        if(utmMedium !== "All"){
          data["utm_medium"]=utmMedium
        }
        if(utmCampaign !== "All"){
          data["utm_campaign"]=utmCampaign
        }
        if(utmRoute !== "All"){
          data["route"]=utmRoute
        }

        const onSuccess = (data) =>{
            setULoder(false)
            setConversionData(data)
        }

        const onError = (err) =>{
           setULoder(false)
           message.error(err)
        }

        UtmConversionDetails(data,onSuccess,onError)

    }
    
    const columns = [
        {
          title: 'User Name',
          dataIndex: 'userName',
          key: 'userName',
        },
        {
          title: 'Mobile Number',
          dataIndex: 'mobNumber',
          key: 'mobNumber',
        },
        {
          title: 'UTM Campaign',
          dataIndex: 'utmCampaign',
          key: 'utmCampaign',
        },
        {
            title: 'UTM Medium',
            dataIndex: 'utmMedium',
            key: 'utmMedium',
          },
          {
            title: 'UTM Source',
            dataIndex: 'utmSource',
            key: 'utmSource',
          },
          {
            title: 'Route',
            dataIndex: 'route',
            key: 'route',
          },
          {
            title: 'Teacher Name',
            dataIndex: 'teacherName',
            key: 'teacherName',
          },
          {
            title: 'Teacher Number',
            dataIndex: 'teacherNumber',
            key: 'teacherNumber',
          },
          {
            title: 'Demo date',
            dataIndex: 'demoDate',
            key: 'demoDate',
          },
          {
            title: 'Session time',
            dataIndex: 'sessionTime',
            key: 'sessionTime',
          },
          {
            title: 'Learner joining',
            dataIndex: 'learnerJoining',
            key: 'learnerJoining',
          },
          {
            title: 'Teacher Joining',
            dataIndex: 'teacherJoining',
            key: 'teacherJoining',
          },
          {
            title: 'Learner subscribed',
            dataIndex: 'learnerSubscribed',
            key: 'learnerSubscribed',
          },
          {
            title: 'Amount paid',
            dataIndex: 'amountPayed',
            key: 'amountPayed',
          },
          {
            title: 'Subscription date',
            dataIndex: 'subscriptionDate',
            key: 'subscriptionDate',
          },
      ];

      const dataSource = conversionData?.map(data=>(
        {
        'userName':data?.userId?.name?data?.userId?.name:"Not available",
        'mobNumber':data?.userId?.phoneNumber?data?.userId?.phoneNumber:"Not available",
        'utmCampaign':data?.utm_campaign,
        'utmMedium':data?.utm_medium,
        'utmSource':data?.utm_source,
        'route':data?.route,
        'teacherName':data?.demoTeacherId?data?.demoTeacherId?.name:"Not booked",
        'teacherNumber':data?.demoTeacherId?data?.demoTeacherId?.phoneNumber:"Not booked",
        'demoDate':data?.demoSessionId? moment(new Date(data.demoSessionId.sessionDate)).format('YYYY-MM-DD') : "Not booked",
        'sessionTime': data?.demoSessionId?.startTime ?UTCToAsiaTimeConverter(data?.demoSessionId?.startTime) +" - " +UTCToAsiaTimeConverter(data?.demoSessionId?.endTime):"Not booked",
        'learnerJoining':data?.demoSessionId?.learnerJoiningTime?.length > 0 ?"Attended":"Not attended",
        'teacherJoining':data?.demoSessionId?.speakerJoiningTime?.length > 0 ?"Attended":"Not attended",
        'learnerSubscribed':data?.firstSubscriptionId?"Subscribed":"Not subscribed",
        'amountPayed':data?.firstSubscriptionId?data?.firstSubscriptionId?.amount:"Not subscribed",
        'subscriptionDate':data?.firstSubscriptionId?moment(new Date(data?.firstSubscriptionId?.startDate)).format('YYYY-MM-DD'):"Not subscribed",
    }
      ))

  return (
    <div>
        <PageBreadcrumb route={["campaign", "UTM Conversion"]} />
        <Card style={{ marginBottom: "1rem" }}>
           <Row gutter={16}>
           <Col span={12}>
            <Space>
              <RangePicker
                onChange={onPickDate}
                defaultValue={[moment(startDate, dateFormat), moment(endDate, dateFormat)]}
                format={dateFormat}
              />
            </Space>
          </Col>
           </Row>

{
  utmParamLoader ?
  <div className="spin-center">
  <Spin size="large" />
</div>
:
    <div style={{display:"flex",marginTop:"10px"}}>
      <div style={{display:"flex",flexDirection:"column",width:"20%"}}>
          <p>UTM Campaign:</p>
          <DropDown options={utmParams?.utmCampaign?[...utmParams?.utmCampaign,"All"]:["All"]} defaultValue={"All"} handleChange={(val)=>{setUtmCampaign(val)}}/>
      </div>
      <div style={{display:"flex",flexDirection:"column",marginLeft:"30px",width:"20%"}}>
          <p>UTM Medium :</p>
          <DropDown options={utmParams?.utmMedium?[...utmParams?.utmMedium,"All"]:["All"]} defaultValue={"All"} handleChange={(val)=>{setUtmMedium(val)}}/>
      </div>
      <div style={{display:"flex",flexDirection:"column",marginLeft:"30px",width:"20%"}}>
          <p>UTM Source :</p>
          <DropDown options={utmParams?.utmSource?[...utmParams?.utmSource,"All"]:["All"]} defaultValue={"All"} handleChange={(val)=>{setUtmSource(val)}}/>
      </div>
      <div style={{display:"flex",flexDirection:"column",marginLeft:"30px",width:"20%"}}>
          <p>Route :</p>
          <DropDown options={utmParams?.route?[...utmParams?.route,"All"]:["All"]} defaultValue={"All"} handleChange={(val)=>{setUtmRoute(val)}}/>
      </div>
      <div style={{display:"flex",flexDirection:"column",marginLeft:"30px",width:"20%"}}>
        <p>.</p>
        <Button type="primary" block onClick={() => searchByDate()}>Search</Button>
      </div>
    </div>
}

        </Card>
        {
            loader?
            <div className="spin-center">
               <Spin size="large" />
            </div>
          :
          <Table dataSource={dataSource} exportable columns={columns} scroll={{ x: 2500 }} pagination={false} />
        }
        
    </div>
  )
}

export default ConversionData