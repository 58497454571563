import {
  Tabs,
  Form,
  Input,
  Button,
  Row,
  Col,
  Divider,
  Card,
  Select,
  message,
  PageHeader,
  Spin,
  Switch
} from "antd";
import { useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import { formatPaneData, formatPopulateData, formatTags, groupByNumber, joinTags } from "../../helper/helper";
import { AddEditCourse, GetACourse } from "../../redux/actions/CoursesAction";
import { useHistory,useLocation } from "react-router-dom";
import DraggableTabs from "./DraggableTabs";
import NewDraggableTabs from "./NewDraggableTabs";
import { getCreatorsList } from "../../redux/actions/TeacherAction";

const AddVideos = ({ id, isSingleThumb, form, formChanges}) => {
  const isThumbnail = form.getFieldValue(`isDiffThumb${id}`);

  console.log("rendering thumb out", isThumbnail, id, form.getFieldsValue(true));
  const { Option } = Select;
  const [isVideoThumb, setIsVideoThumb] = useState(isThumbnail==undefined ? true : isThumbnail);
 

  useEffect(()=>{
    // const thumbnail = form.getFieldValue(`thumbnail${id}`);
    // console.log("render thumbnail value", thumbnail);
    // if(thumbnail) setIsVideoThumb(true);
    console.log("useEffect running", id)
    let valueToSet = isThumbnail==undefined ? true : isThumbnail;
    // if(valueToSet==isVideoThumb) return;
    setIsVideoThumb(valueToSet);
  },[formChanges]);

  // deploy comment

    return (
      <div>
        <Row justify="space-between">
          <Col span={11}>
            <Form.Item 
            label={`Video${id} Title`}  
            name={`videoTitle${id}`} 
            required 
            tooltip="This is a required field"
            rules={[{ required: true, message: 'Please add video title !' }]}
            >
              <Input placeholder="Video title" />
            </Form.Item>
          </Col>
          <Col span={11}>
            <Form.Item
              label={`Video${id} Description`}
              name={`videoDesc${id}`}
              required
              tooltip="This is a required field"
              rules={[{ required: true, message: 'Please add video short description !' }]}
            >
              <Input placeholder="Video short description" />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="space-between">
          <Col span={11}>
            <Form.Item 
            label={`Video${id} Link`} 
            name={`videoLink${id}`} 
            required 
            tooltip="This is a required field"
            rules={[{ required: true, message: 'Please add video link !' }]}
            >
              <Input placeholder="https://abcd.com" />
            </Form.Item>
          </Col>
          <Col span={11}>
            <Form.Item 
            label={`Video${id} free`} 
            name={`isVideoFree${id}`} 
            required
            tooltip="This is a required field"
            rules={[{ required: true, message: 'Please add video free or locked !' }]}
            >
            <Select placeholder="select video locked or free " >
              <Option value={true}>Free</Option>
              <Option value={false}>Locked</Option>
            </Select>
          
            </Form.Item>
          </Col>
        </Row>
        <Row>
            {isSingleThumb? <Col span={6}><Form.Item
              label="Apply different thumbnail to this video"
              tooltip="This is a required field"
              name={`isDiffThumb${id}`} 
            >
                <Switch size="small" checked={isVideoThumb} onChange={()=>setIsVideoThumb(!isVideoThumb)} />
            </Form.Item></Col> : null}
            {(!isSingleThumb || isVideoThumb) ? <Col span={11}>
            <Form.Item 
            label={`Video${id} Thumbnail`} 
            name={`thumbnail${id}`} 
            required 
            tooltip="This is a required field"
            rules={[{ required: true, message: 'Please add thumbnail !' }]}
            >
              <Input placeholder="https://abcd.com" />
            </Form.Item>
          </Col> : null}
        </Row>
 
      </div>
    );
  };

  const initialPanes = [
    { title: "Video 1", content: <AddVideos id={1} />, key: "1" }
  ];


const AddCourse = () => {

  const dispatch = useDispatch();
  const history = useHistory();
  const {search} = useLocation()
  const action = new URLSearchParams(search).get("action");
  const id = new URLSearchParams(search).get("id");
  let keyArr = [`videoTitle`, `videoDesc`, `videoLink`, `thumbnail`, `isDiffThumb`, `isVideoFree`];
 


  const { TabPane } = Tabs;
  const { TextArea } = Input;
  const { Option } = Select;

  const children = [];

  for (let i = 10; i < 36; i++) {
    children.push(
      <Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>
    );
  }

  const [form] = Form.useForm();
  const [activeState, setActiveState] = useState(initialPanes[0].key);
  const [panes, setPanes] = useState(initialPanes);
  const [loader, setLoader] = useState(false);
  const [val, setVal] = useState("");
  const [instructor, setInstructor] = useState([]);
  const [disabled, setDisabled] = useState(true)
  const [instructorId, setInstructorId] = useState([])
  const [data, setData] = useState();
  const handleChange = (e) => {
    const regex = /^(1[0-2]|[1-9])$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setVal(e.target.value);
    }
  };


  useEffect(()=> {
    const onSuccess = (data) =>{
       console.log("data",data)
       setInstructor(data.creators)
      //  setInstructorId(data?.creators.map((creator) => (creator._id)))
    }
       const onError = (msg) =>{
        console.log(msg)
     }
     getCreatorsList(onSuccess,onError)
  }, [])

  console.log("testing", instructor)
  console.log("testingid", instructorId)


  const [fetchingCourseData, setFetchingCourseData] = useState(false);
  const [preFillValues, setPreFillValues] = useState({
      subject: "English" ,
      courseLevel: "Beginner"
  });
  const [isSingleThumb, setIsSingleThumb] = useState(true);
  const [formChanges, setFormChanges] = useState(true);
  const handleVideoThumb = () => {
    setIsSingleThumb(!isSingleThumb);
  }


  useEffect(() => {
    if(id){
      setFetchingCourseData(true)
      const onSuccess = (data) =>{

        var videoData =  formatPopulateData(data.courseVideos)

        var videoComponent = [];
        data.courseVideos.map((data,i)=>{
           videoComponent.push({ title: `Video ${i+1}`, content: <AddVideos id={i+1} data={data} />, key: i+1 })
        })

        setPanes(videoComponent)
        setActiveState(String(videoComponent.length))

         var preFill = {
          courseName:data.courseData?.courseName,
          courseDesc:data.courseData?.courseDesc,
          thumbnail:data.courseData?.thumbnail,
          subject: data.courseData?.subject,
          courseLevel: data.courseData?.courseLevel,
          courseTags:joinTags(data.courseData?.courseTags),
          coursePrice:data.courseData?.coursePrice,
          months:data.courseData?.months,
          teacherName:data.courseData?.teacherName,
          teacherImage:data.courseData?.teacherImage,
          teacherDesc:data.courseData?.teacherDesc,
          courseStatus:data.courseData?.status,
          isSingleThumb: data.courseData?.isSingleThumb,
          singleThumb: data.courseData?.singleThumb,
          creatorId: instructorId,
          ...videoData
        }

        setPreFillValues(preFill)
        setIsSingleThumb(data.courseData?.isSingleThumb);
        setFetchingCourseData(false)
      }

      const onError = (error) =>{
        message.error(error)
        setFetchingCourseData(false)
      }

    dispatch(GetACourse({courseId:id},onSuccess,onError))

    }
  }, [])

  const onChange = (activeKey) => {
    setActiveState(activeKey);
  };

  const onEdit = (targetKey, action) => {
    if (action === "remove") {
      remove(targetKey);
    } else if (action === "add") {
      add();
    }
  };

  const remove = (key) => {
    let newPre = [...panes];
    const id = newPre.findIndex((item) => +item.key === +key);
    
    newPre.splice(id, 1);
    if(newPre[id])
    for(let i=id; i<newPre.length; i++){
      console.log("before", newPre[i], i);
      newPre[i] = {
        key: i+1,
        title: `Video ${i+1}`,
        content: <AddVideos id={i+1} />
      };

      console.log("after", newPre[i], i);
      keyArr.forEach(elm=>{
        let key2 = `${elm}${i+2}`;
        let value = form.getFieldValue(key2);
        console.log("get", key2, value);
        let key1= `${elm}${i+1}`;
        console.log("set", key1, value);
        form.setFieldsValue({[key1]: value});
        form.setFieldsValue({[key2]: ""});
      })

      console.log("prevlog", newPre);
      console.log("formvalues", form.getFieldsValue(true));

        setActiveState(String(id+1));
    }
    else {
      console.log("running else part", id);
      keyArr.forEach(elm=>{
        let key1 = `${elm}${id+1}`;
        form.setFieldsValue({[key1]: ""});
      })
      setActiveState(String(id));
     }
    // setPanes((prev) => {
    //   let newPrev = [...prev];
    //   const idx = newPrev.findIndex((item) => +item.key === +key);
    //   newPrev.splice(idx, 1);
    //   console.log("after splicing", newPrev, idx);
    //   let arr = [`videoTitle`, `videoDesc`, `videoLink`, `thumbnail`, `isDiffThumb`, `isVideoFree`];
    //   if(newPrev[idx]){
    //     // prev[idx] = {
    //     //   key: idx+1,
    //     //   title: `Video ${idx+1}`,
    //     //   content: <AddVideos id={idx+1} />
    //     // }
    //     for(let i=idx; i<newPrev.length; i++){
    //       console.log("before", newPrev[i], i);
    //       newPrev[i] = {
    //         key: i+1,
    //         title: `Video ${i+1}`,
    //         content: <AddVideos id={i+1} />
    //       };
    //       console.log("after", newPrev[i], i);
    //     // arr.forEach(elm=>{
    //     //   let key2 = `${elm}${i+2}`;
    //     //   let value = form.getFieldValue(key2);
    //     //   console.log("get", key2, value);
    //     //   let key1= `${elm}${i+1}`;
    //     //   console.log("set", key1, value);
    //     //   form.setFieldsValue({[key1]: value});
    //     //   form.setFieldsValue({[key2]: ""});
    //     // })
    //   }
    //   console.log("prevlog", newPrev);
    //   console.log("formvalues", form.getFieldsValue(true));

    //     setActiveState(String(idx+1));
    //  }
    //  else {
    //   console.log("running else part", idx);
    //   // arr.forEach(elm=>{
    //   //   let key1 = `${elm}${idx+1}`;
    //   //   form.setFieldsValue({[key1]: ""});
    //   // })
    //   setActiveState(String(idx));
    //  }
    //  console.log("prev", newPrev);
    //   return [...newPrev];
    // });
    setPanes(newPre);
    setFormChanges((prev)=>!prev);
  };

  const add = () => {
    const activeKey = (panes && panes.length ? +panes[panes.length - 1].key : 0) + 1;
    console.log("adding", activeKey);
    setActiveState(String(activeKey));
    setPanes((prev) => [
      ...prev,
      {
        title: "Video " + activeKey,
        content: <AddVideos id={activeKey} />,
        key: activeKey,
      },
    ]);
  };



  const onFinish = (values) => {
    console.log("mylogfinish", panes, values);
    const formFieldsValue = form.getFieldsValue(true);
    console.log("form value", formFieldsValue);
    let groupByNumberData = groupByNumber(formFieldsValue);
    groupByNumberData = groupByNumberData.filter(video=>video.videoTitle);
    
    if(isSingleThumb)
    groupByNumberData.forEach(video=>{
      if(video.isDiffThumb==undefined && video.thumbnail){
         video.isDiffThumb = true; 
         return;
        }
        console.log(video);
      if(video.isDiffThumb!==undefined && (video.thumbnail?.trim()=="" || !video.thumbnail)){
        video.isDiffThumb = false; 
        video.thumbnail = values.singleThumb;
        return;
      }
      if(video.isDiffThumb==false) video.thumbnail = values.singleThumb;
    })
    else groupByNumberData.forEach(video=>{
      video.isDiffThumb = true;
    });
    console.log("formdata groupbynumber", groupByNumberData);
    // return;
    // setLoader(true)
    var data = {
      courseName: values.courseName,
      coursePrice: values.coursePrice,
      teacherName: values.teacherName,
      teacherDesc: values.teacherDesc,
      courseDesc: values.courseDesc,
      subject: values.subject,
      thumbnail: values.thumbnail,
      courseStatus:values.courseStatus,
      courseLevel: values.courseLevel,
      videoData: groupByNumberData,
      teacherImage: values.teacherImage,
      courseDuration:2,
      isSingleThumb : values.isSingleThumb,
      // creatorId: values.instructor,
      creatorId: instructorId,
  }

  if(action === "Edit"){
    data["courseId"]=id
  }

  if(values.courseTags){
    data["courseTags"] = formatTags(values.courseTags)
  }

  if(values.months){
    data["months"]=values.months
    data["isLifeTimeAccess"]=false
  }else{
    data["isLifeTimeAccess"]=true
  }

  if(isSingleThumb) {
    data["singleThumb"] = values.singleThumb;
  }

  console.log("payload", data);


  const onSuccess = (msg) =>{
    setLoader(false)
    message.success(msg);
    setPanes(initialPanes)
    form.resetFields()
    history.push('/courses')
  }

  const onError = (err) =>{
    setLoader(false)
    message.error(err);
  }

  dispatch(AddEditCourse(data,onSuccess,onError))

  }

  // useEffect(()=> {
  //   setPanes(prev=>{

  //     for(let i=0; i<prev.length; i++){
  //       prev[i] = {
  //         key: i+1,
  //         title: `Video ${i+1}`,
  //         content: <AddVideos id={i+1} isSingleThumb={isSingleThumb} />
  //       };
        
  //     }
  //     return [...prev];
  //   })
  // },[isSingleThumb])

  const onFormValueChange = (data) => {
    console.log("changed", data.teacherName);
    if(data.teacherName){
      console.log("YESS", instructor)
      const result = instructor.filter(instructor => instructor.name == data.teacherName ) 
      setInstructorId(result[0]._id)
      console.log(result[0]._id)
    }
    // setData(data)

  }

const handleInputChange = () =>{
   if(instructor.value === "Other"){
    setDisabled(false)
   }
}

console.log("disabled",disabled)

  return (
    <div>
        <PageHeader
    className="site-page-header"
    onBack={() =>  history.push('/courses')}
    title="Back"
    subTitle="to courses page"
  />

  {
    !fetchingCourseData?
    <Card>
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      initialValues={preFillValues}
        onValuesChange={onFormValueChange}
        // requiredMark={requiredMark}
    >
      <Form.Item
        label="Course Title"
        name="courseName"
        required
        tooltip="This is a required field"
        rules={[{ required: true, message: 'Please input your Course Title !' }]}
      >
        <Input placeholder="input placeholder" />
      </Form.Item>
      <Form.Item
        label="Course Description"
        name="courseDesc"
        rules={[{ required: true, message: 'Please input your Course Description !' }]}
        required
        tooltip="This is a required field"
      >
        <TextArea rows={4} placeholder="Course short description"  />
      </Form.Item>

      <Row justify="space-between">
        <Col span={11}>
          <Form.Item
            label="Thumbnail"
            required
            tooltip="This is a required field"
            name="thumbnail"
            rules={[{ required: true, message: 'Please add course thumbnail !' }]}
          >
            <Input placeholder="Course thumbnail" />
          </Form.Item>
        </Col>
        <Col span={11}>
          <Form.Item 
          label="Tags" 
          name="courseTags"
          >
            <Input placeholder="English,Grammar" />
          </Form.Item>
        </Col>
      </Row>

      <Row justify="space-between">
        <Col span={11}>
          <Form.Item
            label="Price"
            required
            tooltip="This is a required field"
            name="coursePrice"
            rules={[{ required: true, message: 'Please add course price !' }]}
          >
            <Input placeholder="999" />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label="Months (Course expiry)"
            name="months"
          >
        <div>
        <Input placeholder="Enter between 1-12" id="my-input" type="text" value={val} onChange={handleChange}/>
        </div>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label="Subject"
            name="subject"
            required
            tooltip="This is a required field"
            rules={[{ required: true, message: 'Please select one subject !' }]}
          >
            <Select defaultValue="English" >
              <Option value="English">English</Option>
              <Option value="IELTS">IELTS</Option>
            </Select>
          </Form.Item>
        </Col>

        <Col span={4}>
          <Form.Item
            label="Level"
            name="courseLevel"
            required
            tooltip="This is a required field"
            rules={[{ required: true, message: 'Please select course level !' }]}
          >
            <Select defaultValue="Beginner" >
              <Option value="Beginner">Beginner</Option>
              <Option value="Intermediate">Intermediate</Option>
              <Option value="Advanced">Advanced</Option>
            </Select>
          </Form.Item>
        </Col>

      </Row>

      <Row justify="space-between">
        <Col span={11}>
          <Form.Item 
          label="Disabled"
          name="courseStatus" 
          required 
          >
            <Select defaultValue="Active" >
              <Option value="Active">Active</Option>
              <Option value="Disabled">Disabled</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Divider orientation="left">About the Instructor</Divider>

      <Row justify="space-between">
        <Col span={11}>
          <Form.Item
            label="Teacher Name"
            // required
            tooltip="This is a required field"
            name="teacherName"
            // rules={[{ required: true, message: 'Please add instructor name !' }]}
          >
            <Input placeholder="Instructor name" type="text"/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Creator Name"
            name="teacherName"
            rules={[{ required: true, message: 'Please select creators name !' }]}
          >
            <Select onChange={handleInputChange}>
               {instructor.map(instructors => (
                 <Option value={instructors.name} key={instructors._id}>{instructors.name}</Option>
               ))}
               <Option value="Other">Other</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={11}>
          <Form.Item 
          label="Instructor Image"
          name="teacherImage" 
          required 
          tooltip="This is a required field"
          rules={[{ required: true, message: 'Please add instructor image !' }]}
          >
            <Input placeholder="input placeholder" />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        label="Instructor Description"
        name="teacherDesc"
        rules={[{ required: true, message: 'Please add instructor details !' }]}
      >
        <TextArea rows={4} placeholder="Instructor short description !" />
      </Form.Item>

      <Divider orientation="left">Add videos</Divider>
      <Row>
        <Col span={5}>
          <Form.Item
            label="Apply one thumbnail to all videos"
            tooltip="This is a required field"
            name="isSingleThumb"
          >
              <Switch size="small" checked={isSingleThumb} onChange={()=>handleVideoThumb()} />
          </Form.Item>
        </Col>
        {isSingleThumb ? <Col span={5}>
            <Form.Item 
            label="Video Thumbnail"
            name="singleThumb" 
            required 
            tooltip="This is a required field"
            rules={[{ required: true, message: 'Please add video thumbnail !' }]}
            >
              <Input placeholder="https://abcd.com" />
            </Form.Item>
          </Col> : null}
      </Row>
      {/* <Tabs
        type="editable-card"
        onChange={onChange}
        activeKey={activeState}
        onEdit={onEdit}
      >
        {panes.map((pane) => (
        <TabPane tab={pane.title} key={pane.key}>
            {pane.content}
          </TabPane>
        ))}
      </Tabs> */}

      {/* <NewDraggableTabs setPanes={setPanes} panes={panes} onEdit={onEdit} /> */}

      <DraggableTabs type="editable-card" onChange={onChange} activeKey={activeState} setActiveState={setActiveState} onEdit={onEdit} panes={panes} setPanes={setPanes} form={form} setFormChanges={setFormChanges} keyArr={keyArr} >
      {panes.map((pane) => (
        <TabPane tab={pane.title} key={pane.key}>
            {/* {pane.content} */}
            <AddVideos id={pane.key} isSingleThumb={isSingleThumb} form={form} formChanges={formChanges} />
          </TabPane>
        ))}
  </DraggableTabs>


      <Form.Item>
          <Button type="primary" htmlType="submit" loading={loader}  block>
            {action === "Edit"?'Edit course':"Add course"} 
          </Button>
      </Form.Item>
    </Form>
  </Card>
  :
  <div className="spin-center">
    <Spin size="large" />
  </div>
  }
 
    </div>
  );
};

export default AddCourse;


