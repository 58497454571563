import { Button, Collapse, Spin, Table, Tag } from 'antd'
import React,{useEffect,useState} from 'react'
import { availableHrDataFormater,bookedHrByTimeslot,bookedHrDataFormater, calculateBookedHr, calculateTotalHr } from '../../helper/TimeDate'
import { getWorkingHrs } from '../../redux/actions/TeacherAction'
const { Panel } = Collapse;

const WorkingHr = () => {

    const [bookedHr,setBookedHr] = useState()
    const [totalHr,setTotalHr]  = useState()
    const [details,setDetails]  = useState(false)
    const [loading,setLoading] = useState()

    const onChange = (key) => {
        console.log(key);
      };

    useEffect(() => {
        setLoading(true)
        const onSuccess = (res) =>{
            var formatedTotalHr = availableHrDataFormater(res.totalHr)
            var formatedBookedHr = bookedHrDataFormater(res.bookedHr)
            setBookedHr(formatedBookedHr)
            setTotalHr(formatedTotalHr)
            setLoading(false)
        }
        const onError = (err) =>{
            console.log(err)
            setLoading(false)
            setBookedHr([])
            setTotalHr([])
        }
        getWorkingHrs({type:"All",subject:"English"},onSuccess,onError)
    }, [])
    
    const dataSource = totalHr && bookedHr?[
        {
            day:"Sunday",
            totalHr:calculateTotalHr(totalHr["Sunday"]),
            bookedHr:calculateBookedHr(bookedHr["Sunday"]),
            availableHr:calculateTotalHr(totalHr["Sunday"])-calculateBookedHr(bookedHr["Sunday"]),
            breakdown:"Sunday"
        },
        {
            day:"Monday",
            totalHr:calculateTotalHr(totalHr["Monday"]),
            bookedHr:calculateBookedHr(bookedHr["Monday"]),
            availableHr:calculateTotalHr(totalHr["Monday"])-calculateBookedHr(bookedHr["Monday"]),
            breakdown:"Monday"
        },
        {
            day:"Tuesday",
            totalHr:calculateTotalHr(totalHr["Tuesday"]),
            bookedHr:calculateBookedHr(bookedHr["Tuesday"]),
            availableHr:calculateTotalHr(totalHr["Tuesday"]) - calculateBookedHr(bookedHr["Tuesday"]),
            breakdown:"Tuesday"
        },
        {
            day:"Wednesday",
            totalHr:calculateTotalHr(totalHr["Wednesday"]),
            bookedHr:calculateBookedHr(bookedHr["Wednesday"]),
            availableHr:calculateTotalHr(totalHr["Wednesday"])-calculateBookedHr(bookedHr["Wednesday"]),
            breakdown:"Wednesday"
        },
        {
            day:"Thursday",
            totalHr:calculateTotalHr(totalHr["Thursday"]),
            bookedHr:calculateBookedHr(bookedHr["Thursday"]),
            availableHr:calculateTotalHr(totalHr["Thursday"])-calculateBookedHr(bookedHr["Thursday"]),
            breakdown:"Thursday"
        },
        {
            day:"Friday",
            totalHr:calculateTotalHr(totalHr["Friday"]),
            bookedHr:calculateBookedHr(bookedHr["Friday"]),
            availableHr:calculateTotalHr(totalHr["Friday"])-calculateBookedHr(bookedHr["Friday"]),
            breakdown:"Friday"
        },
        {
            day:"Saturday",
            totalHr:calculateTotalHr(totalHr["Saturday"]),
            bookedHr:calculateBookedHr(bookedHr["Saturday"]),
            availableHr:calculateTotalHr(totalHr["Saturday"])-calculateBookedHr(bookedHr["Saturday"]),
            breakdown:"Saturday"
        }
    ]:[]

      const columns = [
        {
          title: 'Day',
          dataIndex: 'day',
          key: 'day',
        },
        {
          title: 'Total Hr',
          dataIndex: 'totalHr',
          key: 'totalHr',
        },
        {
          title: 'Booked Hr',
          dataIndex: 'bookedHr',
          key: 'bookedHr',
        },
        {
            title: 'Available Hr',
            dataIndex: 'availableHr',
            key: 'availableHr',
        },
        {
            title: 'Action',
            dataIndex: 'breakdown',
            key: 'breakdown',
            render:(breakdown)=>(<Button type="link" onClick={()=>setDetails(breakdown)}>View breakdown</Button>)
        },
      ];

  return (
    <div>
        {
            loading?
            <div className="spin-center">
                <Spin size="large" />
            </div>
            :
            <>
            {
                details?
                <>
                <Button onClick={()=>setDetails(false)}>Back</Button>
                <p>{details} details</p>
                <Collapse defaultActiveKey={['0']} onChange={onChange}>
                    {
                        totalHr[details]?.sort((a, b)=>{return a.totalHr - b.totalHr}).map(data=>(
                            <Panel header={`${data._id.timeSlot} : Total ${data.totalHr}hr ${data.totalHr} teacher available . ${bookedHrByTimeslot(data._id.timeSlot,bookedHr[details])} booked`} key={data._id.timeSlot}>
                              {
                                  data.teachers.map(teacher => (
                                    <Tag color="blue" key={teacher._id}>{teacher.name}</Tag>
                                  ))
                              }
                            </Panel>
                        ))
                    }
                </Collapse>
                </>
                :
                <Table dataSource={dataSource} columns={columns} pagination={false} />
            }
            
            </>
        }
       
    </div>
  )
}

export default WorkingHr