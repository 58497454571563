import { Space, Table, Tag } from 'antd';
import moment from 'moment-timezone';
import React from 'react';
import { UTCToAsiaTimeConverter } from '../../helper/helper';

const dateFormat = "YYYY-MM-DD";

const columns = [
  {
    title: 'Meeting No',
    dataIndex: 'meetingNo',
    key: 'meetingNo',
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: 'Duration',
    dataIndex: 'duration',
    key: 'duration',
  },
  {
    title: 'Cost',
    dataIndex: 'cost',
    key: 'cost',
  },
];


const BreakdownTable = ({sessionsData,pay}) => {



    const data = sessionsData?.map((data,i)=>({
            key: i,
            meetingNo: data.meetingNo,
            type:data.forKids?"Kids " + data.type:data.type,
            date: `${moment(new Date(data.sessionDate)).format(dateFormat)} ${UTCToAsiaTimeConverter(data.startTime)}`,
            duration: data.sessionDuration,
            cost: `${pay} ₹`
    }))


    return <Table columns={columns} dataSource={data} />
};

export default BreakdownTable;