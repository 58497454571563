import React, { useState, useEffect } from "react";
import {
  Button,
  Space,
  Spin,
  Tag,
  Select,
  DatePicker,
  Radio,
  Col,
  Row,
  Card,
} from "antd";
import { Table } from "ant-table-extensions";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import PageBreadcrumb from "../../component/PageBreadcrumb";
import { UTCToAsiaTimeConverter } from "../../helper/helper";
import { FetchCancelledSessions, Upcoming } from "../../redux/actions/SessionAction";

const dateFormat = "YYYY-MM-DD";


const LearnerCanceledSession = () => {
  var [canceledSession,setCanceledSession] = useState([]);
  const [date, setDate] = useState(moment(new Date()).format(dateFormat));
  const [sessionType, setSessionType] = useState("Regular");
  const [loader, setLoader] = useState(false);

  function onPickDate(date, dateString) {
    setDate(dateString);
  }
  const onChangeSessionType = (e) => {
    setSessionType(e.target.value);
  };

  const fetchCanceledSession = () => {
    setLoader(true);
    var data = {
      date: date,
      type: sessionType
    };
    const onSuccess = (data) => {
        setLoader(false);
        setCanceledSession(data.sessions)
    };
  
    const onError = (err) => {
        setLoader(false);
        console.log(err)
    };
  
    FetchCancelledSessions(data, onSuccess, onError);
  };


  useEffect(() => {
    fetchCanceledSession()
  }, [])
  


    const columns = [
        {
          title: 'Sl No',
          dataIndex: 'slNo',
          key: 'slNo',
          width: 70,
        },
        {
          title: 'Learner name',
          dataIndex: 'learnerName',
          key: 'learnerName'
        },
        { title: 'Teacher name', dataIndex: 'teacherName', key: 'teacherName' },
        { title: 'Canceled by teacher', dataIndex: 'canceledBy', key: 'canceledBy' },
        { title: 'Type', dataIndex: 'type', key: 'type' ,
        filters: [
          {
            text: 'Demo',
            value: 'Demo',
          },
          {
            text: 'Regular',
            value: 'Regular',
          },
        ],
        onFilter: (value, record) => record.type.startsWith(value),
      },
        { title: 'User type', dataIndex: 'userType', key: 'userType' ,
        filters: [
          {
            text: 'Kid',
            value: 'Kid',
          },
          {
            text: 'Adult',
            value: 'Adult',
          },
        ],
        onFilter: (value, record) => record.userType.startsWith(value),
      },
        { title: 'Date', dataIndex: 'date', key: 'date' },
        { title: 'Time', dataIndex: 'time', key: 'time' },
        { title: 'Meeting no', dataIndex: 'meetingNo', key: 'meetingNo' },
      ].filter(Boolean);
      
      const sessionDetails = canceledSession?.map((data,i)=>({
        key: i,
        slNo: i+1,
        learnerName: data?.learner?.name,
        teacherName:data?.teacher?.name,
        type: data.type,
        userType: data.forKids?"Kid":"Adult",
        date: moment(new Date(data.sessionDate)).format('YYYY-MM-DD'),
        time: UTCToAsiaTimeConverter(data.startTime)+" - "+UTCToAsiaTimeConverter(data.endTime),
        meetingNo:data.meetingNo,
        canceledBy:data?.assignTeacher?"Yes":"No"
      }))
            

    return (
        <div>
            <PageBreadcrumb route={["Session", "Upcoming sessions"]} />
            <Card title="Filter Session" style={{ marginBottom: "1rem" }}>
              <Row>
                <Col span={4}>
                  <Radio.Group onChange={onChangeSessionType} value={sessionType}>
                    <Radio value={"Regular"}>Regular</Radio>
                    <Radio value={"Demo"}>Demo</Radio>
                  </Radio.Group>
                </Col>

                <Col span={20}>
                  <Space>
                    <DatePicker
                      onChange={onPickDate}
                      defaultValue={moment(new Date(), dateFormat)}
                      format={dateFormat}
                    />
                    <Button
                      type="primary"
                      onClick={() => {
                        fetchCanceledSession();
                      }}
                    >
                      Search
                    </Button>
                  </Space>
                </Col>
              </Row>
           </Card>
            {
              !loader ?
              <Table 
              columns={columns} 
              dataSource={sessionDetails} 
              scroll={{ x: 1400 }} 
              pagination={false}
              exportable
              searchable
              />
              :
              <div className="spin-center">
                <Spin size="large" />
               </div>
            }
            
        </div>
    )
}

export default LearnerCanceledSession
