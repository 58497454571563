import { Card } from "antd";
import React from "react";
import PageBreadcrumb from "../component/PageBreadcrumb";
import YouTubersList from "../component/YouTubers/YouTubersList";

const YouTubers = () => {
  return (
    <div>
      <PageBreadcrumb route={["dashboard", "YouTubers list"]} />
      <Card style={{ marginBottom: "1rem" }}>
        <YouTubersList />
      </Card>
    </div>
  );
};

export default YouTubers;
