import { Card, Row, Col, Spin,Upload,message,Button, Divider } from "antd";
import { useDispatch, useSelector } from "react-redux";
import React,{useState,useEffect} from "react";
import S3FileUpload from "react-s3";
import ImgCrop from 'antd-img-crop';
import EditProfile from "./EditProfile";
import { GenerateMagicLink, UploadProfile, addAdminLog } from "../../redux/actions/TeacherAction";

const TeacherDetails = ({ teacher }) => {

  const dispatch = useDispatch();
  const [fileList, setFileList] = useState([]);
  const [magicLinkLoader, setMagicLinkLoader] = useState(false);
  const admin = useSelector(state => state.AdminReducer.admin)
  console.log("admin", admin)

  useEffect(() => {
    if(teacher){
    if(teacher.teacher.image){
      setFileList([
        {
          uid: '1',
          name: 'image.png',
          status: 'done',
          url: teacher.teacher.image,
        }]
      )
    }
  }
  }, [teacher])
    
  const onChange = ({ fileList: newFileList }) => {
      setFileList(newFileList);
       if(!newFileList.length > 0){
        return
      }
      if (newFileList[0].status === 'uploading') {
        return
     }
  
        var file = newFileList[0].originFileObj;
    
       const config = {
         bucketName: "clapngobucket",
         dirName: "Images",
         region: "us-east-1",
         accessKeyId: "AKIAQYKMKJ3DNVIM4HSM",
         secretAccessKey: "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",
       };
   
       S3FileUpload.uploadFile(file, config)
         .then((data) => {
           const onSuccess = (msg) => {
            message.success(msg);
           };
           const onError = (msg) => {
            setFileList([]);
            message.error('Something went wrong !');
           };
           dispatch(UploadProfile({ image: data.location,teacherId:teacher.teacher._id}, onSuccess, onError));
         })
         .catch((err) => {
          setFileList([]);
           message.error('Something went wrong !');
         });
  };

  const onPreview = async file => {
    let src = file.url;
    if (!src) {
      src = await new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
  
    const image = new Image();
    image.src = src;

    var a = document.createElement('a');
    a.href = src;
    a.download = src;
    a.target="_blank"
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);


  };

  const magicLink = ()=>{
    setMagicLinkLoader(true)
    const onSuccess = (data) =>{
      console.log(data.token)
      setMagicLinkLoader(false)
      if(window.location.hostname == "admin.clapingo.com"){
        window.open(`https://teacher.clapingo.com?token=${data.token}`)
      }else{
        window.open(`https://teacher-web-git-dev-clapingo.vercel.app/?token=${data.token}`)
      }
      message.success('Magic link generated !')
      const payload = {
        role: admin?.details?.role,
        admin: admin?.details?.name,
        activity: "teacherMagicLogin",
        teacherId: teacher?.teacher?._id
      }
      var onLogSuccess = (res) => {
        console.log("res",res)
      }
      var onLogError = (msg) => {
        message.error(msg);
      }
      addAdminLog(payload, onLogSuccess, onLogError)
    }
    const onError = (errormsg) =>{
      setMagicLinkLoader(false)
      message.err(errormsg)
    }
    GenerateMagicLink(teacher.teacher._id,onSuccess,onError)
  }

  return (
    <Card className="teacher-details">
      {teacher ? (
        <>
          <Row>
            <Col span={6}>
           
              {/* <img
                src={
                  teacher.teacher.image
                    ? teacher.teacher.image
                    : "https://joeschmoe.io/api/v1/random"
                }
                alt="teacheravatar"
              /> */}

    <ImgCrop
     rotate
      style={{
        width:"8rem",
        height:"8rem"
      }}
    >
      <Upload
        action={()=>false}
        listType="picture-card"
        fileList={fileList}
        onChange={onChange}
        onPreview={onPreview}
        style={{
          width:"8rem",
          height:"8rem"
        }}
      >
        {fileList.length < 1 && '+ Upload'}
      </Upload>
    </ImgCrop>
    
            </Col>
            <Col span={18}>
              <Row>
                <Col span={12}>
                  <p className="teacher-name">
                      {teacher.teacher.name}{" "}
                      <span>+91 {teacher.teacher.phoneNumber}</span>
                    </p>
                    <p className="description">
                      {teacher.teacher.description?.replace(/\r?\n|\r/g, " ")}
                    </p>
                </Col>
                <Col span={6}>
                  <Button onClick={()=>magicLink()} loading={magicLinkLoader}>Magic link</Button>
                </Col>
              </Row>
 
              {/* <div className="access">
              <Tag color="#f50">Regular Demo</Tag>
              <Tag color="#f50">Regular Session</Tag>
              <Tag color="#f50">Kid's Demo</Tag>
              <Tag color="#f50">Kid's SEssion</Tag>
              </div> */}
             
              <Row>
                <Col span={4} className="active-stac">
                  <h5>Total class</h5>
                  <p>{teacher.totalSession}</p>
                </Col>
                <Col span={4} className="active-stac">
                  <h5>Rating</h5>
                  <p>{teacher?.teacher.rating}</p>
                </Col>
                <Col span={4} className="active-stac">
                  <h5>Referral Code</h5>
                  <p>{teacher?.teacher.referral}</p>
                </Col>
                <Col span={4} className="active-stac">
                  <h5>Earnings</h5>
                  <p>{teacher?.teacher.earnings}</p>
                </Col>
              </Row>
            </Col>
          </Row>
          <Divider  plain/>
          
          <EditProfile teacher={teacher?.teacher} />
    
        </>
      ) : (
        <div className="spin-center">
          <Spin size="large" />
        </div>
      )}
    </Card>
  );
};

export default TeacherDetails;
