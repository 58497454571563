/* eslint-disable react/jsx-no-duplicate-props */
import { Card, Select } from 'antd'
import React,{useState} from 'react'
import Chart from '../../component/charts/mongodb/Chart'
const { Option } = Select;

export const DropDown = ({options,defaultValue,handleChange}) =>{
    return (
        <Select defaultValue={defaultValue} style={{ width: '100%' }} onChange={handleChange}>
            {
                options.map((data,i)=>(
                    <Option value={data} key={i}>{data}</Option>
                ))
            }
      </Select>
    )
}

const UTMCharts = () => {

    const [paid, setPaid] = useState('N/A')
    const [booked, setBooked] = useState('N/A')
    const [route, setRoute] = useState('N/A')

    const CAMPAIGN_FILTER = {};

    if(paid === 'Subscribed'){
        CAMPAIGN_FILTER['firstSubscriptionId']={$ne:null}
    }else if(booked === 'Not Subscribed'){
        CAMPAIGN_FILTER['firstSubscriptionId']=null
    } 

    if(booked === 'Booked'){
        CAMPAIGN_FILTER['demoSessionId']={$ne:null}
    }else if(booked === 'Not booked'){
        CAMPAIGN_FILTER['demoSessionId']=null
    } 

    const FILTER_UTM_BY_PATH = {}

    if(route !== "N/A"){
        FILTER_UTM_BY_PATH['route']=route
    }

    console.log(FILTER_UTM_BY_PATH)
    
  return (
      <>
        <Card>
        <div style={{display:"flex"}}>
          <div style={{display:"flex",flexDirection:"column",width:"20%"}}>
              <p>Subscribed:</p>
              <DropDown options={['N/A','Subscribed','Not Subscribed']} defaultValue={paid} handleChange={(val)=>setPaid(val)}/>
          </div>
          <div style={{display:"flex",flexDirection:"column",marginLeft:"30px",width:"20%"}}>
              <p>Demo booked :</p>
              <DropDown options={["N/A","Booked",'Not booked']} defaultValue={booked} handleChange={(val)=>setBooked(val)}/>
          </div>
          </div>
          <Chart height={'80vh'} width={'100%'} filter={CAMPAIGN_FILTER} chartId={'62ce9114-2fb0-4cd6-852f-f0e9a2c95609'}/>
        </Card>

        <Card>
        <div style={{display:"flex"}}>
          <div style={{display:"flex",flexDirection:"column"}} styles={{"width":"30%"}}>
              <p>Campaign Path :</p>
              <DropDown options={[
                  'N/A',
                  '/thankyou/google/book-demo/:component',
                  '/thankyou/book-demo/:component',
                  '/adult-spoken199-fb',
                  '/thankyou/digi/book-demo/:component',
                  '/thankyou/ielts/book-demo/:component'
                  ]} defaultValue={"N/A"} handleChange={(val)=>setRoute(val)}/>
          </div>
          </div>
          <Chart height={'80vh'} width={'100%'} filter={FILTER_UTM_BY_PATH} chartId={'62cec32d-6bc4-42cc-8681-15b909395633'}/>
        </Card>

      </>
  )
}

export default UTMCharts