import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Space,
  Spin,
  Select,
  DatePicker,
  Col,
  Row,
  Card,
  Modal,
  message,
} from "antd";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import PageBreadcrumb from "../../component/PageBreadcrumb";
import { UTCToAsiaTimeConverter,upcomingTimeValidator } from "../../helper/helper";
import { GetSwapAvailableTeacher, SwapTeacher, Upcoming } from "../../redux/actions/SessionAction";

const dateFormat = "YYYY-MM-DD";

const SwapModal = ({teacherId,startTime,sessionDate, isSessionSwapped, demoMeetingNo}) => {

  const dispatch = useDispatch();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [teachersLoading, setTeachersLoading] = useState(false);
  const [availableSwapTeacher, setAvailableSwapTeacher] = useState([]);
  const [availableSwapTeacherFormatted, setAvailableSwapTeacherFormatted] = useState([]);
  const [selectedSwapTeacher, setSelectedSwapTeacher] = useState(null);
  const [swappingLoader, setSwappingLoader] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
    setSelectedSwapTeacher(null)
    fetchSwapTeachers()
  };
  const handleOk = () => {
    setIsModalOpen(false);
    setSelectedSwapTeacher(null)
    setAvailableSwapTeacher([])
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setSelectedSwapTeacher(null)
    setAvailableSwapTeacher([])
  };

  const handleChange = (value) => {
    const filteredData = availableSwapTeacher.filter(session => session._id === value)
    if(filteredData?.length > 0){
        setSelectedSwapTeacher(filteredData[0])
    }
  };


  const fetchSwapTeachers = () => {
    setTeachersLoading(true)
    const payload = {
        teacherId:teacherId,
        startTime:startTime,
        sessionDate:sessionDate,
        type:"Demo"
    }

    const onSuccess = (data) =>{
        setAvailableSwapTeacher(data.filterTeachers)
        const fil = data.filterTeachers.map(data=>{
            if(data.teacherData?.length > 0){
            return {
                label:`${data.teacherData[0]?.name} (${data.teacherData[0]?.demoConversionPercentLast50Sessions})`,
                value:data._id
            }
            }
        }).filter(value => value !== undefined)
        setAvailableSwapTeacherFormatted(fil)
        setTeachersLoading(false)
    }
    const onError = (err) =>{
        message.error(err)
        setTeachersLoading(false)
    }

      GetSwapAvailableTeacher(payload,onSuccess,onError)
  }
  
  const swapTeacher = () =>{
    // setSwappingLoader(true)
    const payload = {
            "demoTeacherId":teacherId,
            "demoMeetingNo":demoMeetingNo,
            "regularTeacherId":selectedSwapTeacher.teacher,
             "regularMeetingNo":selectedSwapTeacher.meetingNo
    }

    const onSuccess = (data) =>{
        message.success("Teacher swapped successfully")
        setSwappingLoader(false)
        setIsModalOpen(false)
        setSelectedSwapTeacher(null)
        setAvailableSwapTeacher([])
    }

    const onError = (err) =>{
        message.error(err)
        setSwappingLoader(false)
    }

    dispatch({
            type: "TEACHER_SWAPPED",
            payload: demoMeetingNo
    })
    SwapTeacher(payload,onSuccess,onError)
  }

  return (
    <>
    {/* TODO: disabled={isSessionSwapped} */}
      <Button type="primary" onClick={showModal}>
        {isSessionSwapped?"Already swapped":"Swap Teacher"}
      </Button>
      <Modal
        title="Basic Modal"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        {
            teachersLoading?
            <div className="spin-center">
            <Spin size="large" />
          </div>
          :
          <>
{
    availableSwapTeacher?.length <= 0 ?
    <p>No teacher found to swap</p>
    :""

}
            <Select
            // defaultValue={availableSwapTeacherFormatted?.length > 0 ? availableSwapTeacherFormatted[0] :""}
            style={{
              width: "80%",
            }}
            onChange={handleChange}
            options={availableSwapTeacherFormatted}
          />

        <Button type="primary" onClick={()=>swapTeacher()} disabled={swappingLoader || availableSwapTeacher?.length <= 0}>Swap</Button>
          </>
        }
      </Modal>
    </>
  );
};

const lowConversionIdentifier = (data) => {
  if (parseFloat(data.conversion) < 10) {
    return "red";
  }
};

const DemoSession = () => {
  const dispatch = useDispatch();
  var upcomingSession = useSelector((state) => state.SessionReducer.upcoming);
  const [isCurriculumSession, setIsCurriculumSession] = useState("");
  const [date, setDate] = useState(moment(new Date()).format(dateFormat));

  function onPickDate(date, dateString) {
    setDate(dateString);
  }

  const onChangeIsCurriculumSession = (value) => {
    setIsCurriculumSession(value);
  };

  useEffect(()=>{
    fetchUpcomingSession()
  },[])

  const fetchUpcomingSession = () => {
    // setLoader(true);
    var data = {
      date: date,
      type: "Demo",
      page: 1,
      limit: 500,
      timezone: "Asia/Kolkata"
    };

    const onSuccess = () => {};

    const onError = () => {};

    dispatch(Upcoming(data, onSuccess, onError));
  };

  const columns = [
    {
      title: "Sl No",
      dataIndex: "slNo",
      key: "slNo",
      width: 70,
    },
    {
      title: "Learner name",
      dataIndex: "learnerName",
      key: "learnerName",
    },
    { title: "Teacher name", dataIndex: "teacherName", key: "teacherName" },
    { title: "Teacher conversion", dataIndex: "conversion", key: "conversion" },
    { title: "Type", dataIndex: "type", key: "type" },
    {
      title: "User type",
      dataIndex: "userType",
      key: "userType",
      filters: [
        {
          text: "Kid",
          value: "Kid",
        },
        {
          text: "Adult",
          value: "Adult",
        },
      ],
      onFilter: (value, record) => record.userType.startsWith(value),
    },
    { title: "Date", dataIndex: "date", key: "date" },
    { title: "Time", dataIndex: "time", key: "time" },
    { title: "Meeting no", dataIndex: "meetingNo", key: "meetingNo" },
    {
      title: "Swap teacher",
      key: "swapTeacher",
      fixed: "right",
      width: 150,
      render: ({ teacherId, orgStartTime ,orgSessionDate, isSessionSwapped,meetingNo }) => <SwapModal teacherId={teacherId} startTime={orgStartTime} sessionDate={orgSessionDate} isSessionSwapped={isSessionSwapped} demoMeetingNo={meetingNo} />,
    },
  ];

  const sessionDetails = upcomingSession?.map((data, i) => {
    if(upcomingTimeValidator(data.sessionDate,data.endTime)){
      return({
        key: i,
        slNo: i + 1,
        learnerName: data?.learner?.name,
        teacherName: data?.teacher?.name,
        conversion: `${data?.teacher?.demoConversionPercentLast50Sessions}%`,
        teacherId: data?.teacher?._id,
        orgStartTime:data.startTime,
        orgSessionDate:data.sessionDate,
        isSessionSwapped:data.isSessionSwapped,
        type: data.type,
        userType: data.forKids ? "Kid" : "Adult",
        date: moment(new Date(data.sessionDate)).format("YYYY-MM-DD"),
        time:
          UTCToAsiaTimeConverter(data.startTime) +
          " - " +
          UTCToAsiaTimeConverter(data.endTime),
        meetingNo: data.meetingNo,
        host: data.host,
        password: data.meetingPassword,
        joiningLink: data.joinMeetingUrl,
        magicLink: data.forKids
          ? `https://kids.clapingo.com/learner/session?id=${data?.learner?._id}`
          : `https://clapingo.com/upcoming_session?id=${data?.learner?._id}`,
      })
  }}
  ).filter(value => value !== undefined);

  return (
    <div>
      <PageBreadcrumb route={["Session", "Upcoming sessions"]} />
      <Card title="Filter Session" style={{ marginBottom: "1rem" }}>
        <Row>
          <Col span={12}>
            <Space>
              <DatePicker
                onChange={onPickDate}
                defaultValue={moment(new Date(), dateFormat)}
                format={dateFormat}
              />
              <Button
                type="primary"
                onClick={() => {
                  fetchUpcomingSession();
                }}
              >
                Search
              </Button>
            </Space>
          </Col>
        </Row>
      </Card>
      {upcomingSession ? (
        <Table
          columns={columns}
          dataSource={sessionDetails}
          scroll={{ x: 1400 }}
          rowClassName={(record, index) => lowConversionIdentifier(record)}
          pagination={false}
        />
      ) : (
        <div className="spin-center">
          <Spin size="large" />
        </div>
      )}
    </div>
  );
};

export default DemoSession;
