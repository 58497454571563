import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Divider,
  Space,
  Tag,
  Typography,
  Avatar,
  List,
  Form,
  message,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import EditCertificate from "./EditCertificate";
import { DeleteCertificate, GetCertificates } from "../../redux/actions/CurriculumAction";

const { Title } = Typography;

const CertificateList = () => {
    const [certificate, setCertificate] = useState([])
    const [editCertificateData, setEditCertificateData] = useState(null);

    const handleEditCertificate = (certificates) => {
      setEditCertificateData(certificates);
      console.log("1",certificates)
    };
  
  
    const [loader, setLoader] = useState({
      curriculumLoading: false,
    });
  
  const curriculum = useSelector(
    (state) => state.CurriculumReducer.curriculumDetails
  );

  console.log("curr", curriculum)


  useEffect(() => {
    setLoader({ ...loader, curriculumLoading: true });
    var data = {
        page: 1,
        limit: 10,
        curriculumId: curriculum?._id
    }
    const onSuccess = (res) => {
     console.log("success", res.data)
     setCertificate(res.data)
    };
    const onError = (msg) => {
      message.error(msg)
      setLoader({ ...loader, curriculumLoading: false });
    };
    GetCertificates(onSuccess, onError, data);
}, []);
    
  const deleteCertificate = (id) => {
    const onSuccess = (msg) => {
      message.success(msg);

      const data = {
        page: 1,
        limit: 10
      };

      const onSuccessGetCertificates = (res) => {
        setCertificate(res.data);
      };

      const onErrorGetCertificates = (msg) => {
        message.error(msg);
      };

      GetCertificates(onSuccessGetCertificates, onErrorGetCertificates, data);
    };

    const onError = (msg) => {
      message.error(msg);
    };

    DeleteCertificate({ certificateId: id }, onSuccess, onError);
  };

  const customHeaderStyle = {
    backgroundColor: "#F2F5F4",
    color: "#0E433A",
    paddingX: "2px",
  };

  return (
    <Space direction="vertical" size={16} style={{ width: "100%" }}>
        <Card
          headStyle={customHeaderStyle}
          title={
            <div style={{ display: "flex" }}>
              <Title level={5}>Certificates List</Title>
            </div>
          }
        >
          <Card
            style={{ padding: 0, border: "none" }}
            bodyStyle={{ padding: 0 }}
          >
            {certificate.map((certificates, i) => (
              <div key={i}>
                <List>
                  <List.Item 
                  actions={
                    [
                      <EditCertificate curriculumId={certificates.curriculumId}
                      certificateData={certificates}/>,
                      <Button type="link" danger onClick={()=> deleteCertificate(certificates._id)}>
                        Delete
                      </Button>,
                    ]
                  }>
                    {certificates.certificateName}
                </List.Item>
                </List>
                <Divider></Divider>
              </div>
            ))}
          </Card>
        </Card>
    </Space>
  );
};
export default CertificateList;
