import api from "../apis/adminAuth";
import * as constant from "../constants/Constant";

export const refresh = (data) => (dispatch) => {
    dispatch({
        type: constant.ADMIN_LOGIN,
        payload: data
    })
};

  //LOGIN ADMIN
export const AdminLogin = (logindata, onSuccess, onError) => (dispatch) => {
  api
    .Authontication()
    .Login(logindata)
    .then((res) => {
      localStorage.setItem("ADMIN", JSON.stringify(res.data.admin));
      onSuccess(res.data.message);
    })
    .catch((err) => {
      console.log(err.response);
      onError("somthing went wrong");
    });
};

export const AdminStats = (logindata, onSuccess, onError) => (dispatch) => {
  api
    .Authontication()
    .homeStats(logindata)
    .then((res) => {
      console.log(res.data.data)
      onSuccess()
      dispatch({
        type: constant.STASTISTICS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      onError()
      // onError(err.response.data.message);
    });
};


export const GetAdminList = (onSuccess, onError) => {
  api.Authontication().getAdminList()
  .then((res) => {
    console.log(res)
      const reviewer = []
      const editor = []
      res.data?.adminList?.map((data) => {
        if(data.roles.includes("editor")){
          editor.push(data)
        }else if(data.roles.includes("reviewer")){
          reviewer.push(data)
        }
      })
      onSuccess({reviewer,editor, res})
    })
    .catch((err) => {
      console.log(err.response);
      onError(err.response.data?.message);
    });
};

export const CreateAdmin = (data, onSuccess, onError) => {
  api.Authontication().createAdmin(data)
  .then((res) => {
      onSuccess(res.data?.message)
    })
    .catch((err) => {
      console.log(err.response);
      onError(err.response.data?.message);
    });
};

export const AddAdminRole = (data, onSuccess, onError) => {
  api.Authontication().addAdminRole(data)
  .then((res) => {
      onSuccess(res.data?.message)
    })
    .catch((err) => {
      console.log(err.response);
      onError(err.response.data?.message);
    });
};

// resource upload
export const ResourcesUpload = (data, onSuccess, onError) => {
  api.Authontication().resourceUpload(data)
  .then((res) => {
      onSuccess(res)
      console.log(res)
    })
    .catch((err) => {
      console.log(err.response);
      onError(err.response.data?.message);
    });
};

export const GetPreSignedUrl = (data, onSuccess, onError) => {
  api.Authontication().getPreSignedUrl(data)
  .then((res) => {
      onSuccess(res)
      console.log(res)
    })
    .catch((err) => {
      console.log(err.response);
      onError(err.response.data?.message);
    });
};


// user stories
export const UsersStories = (data, onSuccess, onError) => {
  api.Authontication().createStories(data)
  .then((res) => {
      onSuccess(res.data)
      console.log(res)
    })
    .catch((err) => {
      console.log(err.response);
      onError(err.response.data?.message);
    });
};

// get stories

export const GetAllstories = (onSuccess,onError) => {
  api.Authontication().getStories()
    .then((res) => {
      //  console.log(res.data)
        onSuccess(res.data.storyData)
    })
    .catch((err) => {
      console.log(err.response);
      onError(err.response.data.message)
    });
};

// update Slots
export const updatedSlot = (data, onSuccess, onError) => {
  api.Authontication().updateSlots(data)
  .then((res) => {
      onSuccess(res.data)
      console.log(res)
    })
    .catch((err) => {
      console.log(err.response);
      onError(err.response.data?.message);
    });
};

// Ask OTP 
export const AskOTP = (data, onSuccess, onError) => (dispatch) => {
  api
    .Authontication()
    .askOTP(data)
    .then((res) => {
      onSuccess(res.data.message);
    })
    .catch((err) => {
      console.log(err.response);
      onError("somthing went wrong");
    });
};

// resend OTP 
export const resendOTP = (data, onSuccess, onError) => (dispatch) => {
  api
    .Authontication()
    .resendOTP(data)
    .then((res) => {
      onSuccess(res.data.message);
    })
    .catch((err) => {
      console.log(err.response);
      onError("somthing went wrong");
    });
};

// verifyOTP 
export const verifyOTP = (data, onSuccess, onError) => (dispatch) => {
  api
    .Authontication()
    .verifyOTP(data)
    .then((res) => {
      onSuccess(res.data.message);
      localStorage.setItem("ADMIN", JSON.stringify(res.data.adminData));
    })
    .catch((err) => {
      console.log(err.response);
      onError("somthing went wrong");
    });
};

export const SubscriptionsList = (data, onSuccess, onError) => (dispatch) => {
  api
    .Authontication()
    .subscriptionList(data)
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      console.log(err.response);
      onError("somthing went wrong");
    });
};