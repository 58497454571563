import React, { useEffect, useState } from "react";
import SideDrawer from "./SideDrawer";
import { Button, Divider, Form, Input, InputNumber, Select, message } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { CreateModule } from "../../redux/actions/CurriculumAction";
import { useDispatch, useSelector } from "react-redux";
import TopicsList from "./TopicsList";

const AddUpdateModule = ({ curriculumId, moduleId }) => {
  const [form] = Form.useForm();
  const [moduleCreated, setModuleCreated] = useState();
  const curriculum = useSelector(
    (state) => state.CurriculumReducer.curriculumDetails
  );
  const dispatch = useDispatch();

  const module = curriculum?.modules?.filter(
    (module_) => module_._id === moduleId
  );

  console.log("curriculum", curriculum);

  useEffect(() => {
    if (module?.length > 0) {
      form.setFieldsValue({
        serialNo: module[0]?.serialNo,
        name: module[0]?.name,
        description: module[0]?.description,
        level: module[0]?.level,
      });
      setModuleCreated(true);
    }
  }, [module]);

  const createModule = () => {
    form
      .validateFields()
      .then((values) => {
        console.log(values);
        values["curriculumId"] = curriculumId;
        const onSuccess = (res) => {
          setModuleCreated(true);
          message.success("Module created successfully :)");
        };
        const onError = (msg) => {
          message.error(msg);
        };
        CreateModule(values, onSuccess, onError, dispatch);
      })
      .catch((errorInfo) => {
        console.log("Validation Failed:", errorInfo);
      });
  };

  const onDrawerClose = () => {
    form.resetFields();
    setModuleCreated(false);
  };

  return (
    <SideDrawer
      buttonType={moduleId ? "link" : "primary"}
      buttonText={moduleId ? "Update Module" : "Add Module"}
      drawerTitle={moduleId ? "Update Module" : "Add Module"}
      onDrawerClose={onDrawerClose}
    >
      <Form form={form} layout="vertical">
        {!moduleId ? <Form.Item
          label={`Serial Number`}
          name={`serialNo`}
          rules={[
            { required: true, message: "Please add a serial number!" },
            { type: 'number', message: 'Serial number must be a number' },
          ]}
        >
          <InputNumber placeholder="Serial Number" />
        </Form.Item> : null}
        <Form.Item
          label={`Module Title`}
          name={`name`}
          rules={[{ required: true, message: "Please add module title !" }]}
        >
          <Input placeholder="Curriculum title" />
        </Form.Item>
        <Form.Item
          label="Module Description"
          name={`description`}
          rules={[
            { required: true, message: "Please add module description !" },
          ]}
        >
          <TextArea rows={2} />
        </Form.Item>
        <Form.Item
          label="Select Level"
          name={`level`}
        >
          <Select>
            <Select.Option value="Level 1">Level 1</Select.Option>
            <Select.Option value="Level 2">Level 2</Select.Option>
            <Select.Option value="Level 3">Level 3</Select.Option>
            <Select.Option value="Level 4">Level 4</Select.Option>
            <Select.Option value="Level 5">Level 5</Select.Option>
            <Select.Option value="Level 6">Level 6</Select.Option>
            <Select.Option value="Level 7">Level 7</Select.Option>
            <Select.Option value="Level 8">Level 8</Select.Option>
            <Select.Option value="Level 9">Level 9</Select.Option>
          </Select>
        </Form.Item>
        {!moduleCreated ? (
          <div style={{ textAlign: "right" }}>
            <Button type="primary" onClick={createModule}>
              Create module
            </Button>
          </div>
        ) : (
          ""
        )}
      </Form>
      {moduleCreated ? (
        <>
          <Divider orientation="left">Topics</Divider>
          <TopicsList curriculumId={curriculumId} module={module} />
        </>
      ) : (
        ""
      )}
    </SideDrawer>
  );
};

export default AddUpdateModule;
