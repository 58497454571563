import React, { useState, useEffect } from "react";
import { Table, Tag, Spin, message ,
  Button,
  Space,
  Row,
  Col,
  Card
} from "antd";
import { useDispatch } from "react-redux";
import PageBreadcrumb from "../../component/PageBreadcrumb";
import { GetAlllearner, searchLearner } from "../../redux/actions/UserAction";
import AddLearner from "../../component/learnerComponent/AddLearner";
import Search from "antd/lib/input/Search";
import { Link } from "react-router-dom";
//leaners
const AllLearner = () => {
  const dispatch = useDispatch();
  var limitPerPage = 80;
  const [totalLearner, setTotalLearner] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [loader, setLoader] = useState(false);
  const [learners, setLearners] = useState([]);
  const [filteredLearners, setFilteredLearners] = useState();
  const [searchKeyword, setSearchKeyword] = useState();
  const [ULoader, setULoder] = useState([]);

  useEffect(() => {
    getLearner(1, true);
  }, []);

  // console.log(learners)


  const searchByLearner= () =>{
    const trimmedKeyword = searchKeyword.trim();
    if(!trimmedKeyword){
      message.warning("No Input Provided", 8);
      return
    }

   setLoader(true)
   var emailTest = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g; 
   var isEmail = emailTest.test(trimmedKeyword);  
   var hasNumbertest = /\d/;
   var hasNumber = hasNumbertest.test(trimmedKeyword);
   var data;
    if(hasNumber || isEmail){
      data = {
        phoneNumber:trimmedKeyword,
      }
    } else {
      data = {
        phoneNumber:trimmedKeyword,
        via:"name" 
  
      }
    }
    
    var onSuccess = (data) =>{
      console.log("SEARCH SUCCESS")
      console.log(data)
      setLearners(data.learner)
      setLoader(false)
    }
    var onError = (error) =>{
      console.log("SEARCH FAILURE")
      message.success(error, 8);
      
      setLoader(false)
    }
    dispatch(searchLearner(data,onSuccess,onError))
  }

  // console.log(filteredLearners)

  const columns = [
    { title: "No", width: 30, dataIndex: "no", key: "1" },
    { title: "Name", width: 80, dataIndex: "name", key: "2", render: (text,record) => <Link to={`/learner/profile/${record.key}`}>{text}</Link>, },
    { title: "Phone No", width: 50, dataIndex: "phoneNumber", key: "3" },
    { title: "Email", width: 50, dataIndex: "email", key: "4" },
    {
      title: "Subscribed",
      width: 30,
      dataIndex: "subscribed",
      key: "5",
      render: (tags) => (
        <>
          {tags.map((tag) => {
            let color = tag == "yes" ? "geekblue" : "volcano";
            if (tag === "loser") {
              color = "volcano";
            }
            return (
              <Tag color={color} key={tag}>
                {tag.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: "Kids",
      width: 30,
      dataIndex: "kids",
      key: "6",
      render: (tags) => (
        <>
          {tags.map((tag) => {
            let color = tag == "yes" ? "geekblue" : "volcano";
            if (tag === "loser") {
              color = "volcano";
            }
            return (
              <Tag color={color} key={tag}>
                {tag.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
    },
    { title: "City", width: 50, dataIndex: "city", key: "8" },
    { title: "Profession", width: 30, dataIndex: "profession", key: "9" },
  ];

  const data = learners?.map((data, i) => ({
    key: data._id,
    no: i + 1,
    name: data?.name?data.name:"N/A",
    phoneNumber: data.phoneNumber,
    email: data.email,
    subscribed: data.isLearnerSubscribed ? ["yes"] : ["No"],
    kids: data.isKid ? ["yes"] : ["No"],
    city: data.city,
    profession: data.profession,
  }));

  const onChangePagination = (page, pageSize) => {
    if (page.current > activePage) {
      getLearner(page.current);
      setActivePage(page.current);
    }
  };

  const getLearner = (activePage, reset) => {
    setLoader(true);
    var data = {
      page: activePage,
      limit: limitPerPage,
    };

    var onSuccess = (response) => {
      if (reset) {
        setLearners(response.learner);
      } else {
        setLearners([...learners, ...response.learner]);
      }

      setTotalLearner(Number(response.learnerCount));
      setLoader(false);
    };
    var onError = (error) => {
      console.log(error);
      message.success(error, 8);
      setLoader(false);
    };
    dispatch(GetAlllearner(data, onSuccess, onError));
  };

  return (
    <div>
      <PageBreadcrumb route={["dashboard", "Learners"]} />
      <Card  style={{ marginBottom: "1rem" }}>
    <Row justify="space-between">
      {/* <Col span={4}>col-4</Col>
      <Col span={4}>col-4</Col>
      <Col span={4}>col-4</Col> */}
      {/* <button onClick={()=>searchByLearner()}>Search</button> */}
      <Col span={12}>
            <Search placeholder="Search learner by name/number" onSearch={searchByLearner} onChange={(e)=>setSearchKeyword(e.target.value)}  enterButton="Search" />
          </Col>
      <Col span={4}>
        <AddLearner/>
      </Col>
    </Row>
    </Card>

      {loader ? (
        <div className="spin-center">
          <Spin size="large" />
        </div>
      ) : (
        <Table
          columns={columns}
          dataSource={data}
          scroll={{ x: 1300 }}
          onChange={onChangePagination}
          pagination={{
            pageSize: 80,
            total: totalLearner,
            defaultCurrent: activePage,
          }}
        />
      )}
    </div>
  );
};

export default AllLearner;
