import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import {useLocation} from "react-router-dom";
import PageBreadcrumb from "../../component/PageBreadcrumb";
import {
  Layout,
  Menu
} from "antd";
import TeacherAttandance from "../../component/Teacher profile/TeacherAttandance";
import TeacherDetails from "../../component/Teacher profile/TeacherDetails";
import { teacherProfile } from "../../redux/actions/TeacherAction";
import Payouts from "../../component/Teacher profile/Payouts";
const { Content, Sider } = Layout;


const TeacherProfile = () => {
  const dispatch = useDispatch();
  let { id} = useParams();
  const location = useLocation();
  const queryParam = new URLSearchParams(location.search)
  const tab = queryParam.get('tab')

  const [component,setComponent] = useState(tab)
  const [teacher,setTeacher] = useState(null)

  useEffect(() => {
    
    const data = {
      teacherId:id,
      date:new Date(),
      timezone: "UTC"
    }

    const onSuccess = (res) =>{
      setTeacher(res)
    }

    const onError = (err) =>{
      console.log(err)
    }

    dispatch(teacherProfile(data,onSuccess,onError))
  }, []);
  
  return (
    <div>
      <PageBreadcrumb route={["dashboard", "Teachers", "Profile",component]} />

      <Layout>
        <Sider width={150} className="site-layout-background">
          <Menu
            mode="inline"
            defaultSelectedKeys={["1"]}
            defaultOpenKeys={["sub1"]}
            style={{ height: "100%", borderRight: 0 }}
          >
            <Menu.Item key="1" onClick={()=>setComponent("Details")}>Teacher details</Menu.Item>
            <Menu.Item key="3" onClick={()=>setComponent("Attendance")}>Attendance</Menu.Item>
            <Menu.Item key="4" onClick={()=>setComponent("Payouts")}>Payouts</Menu.Item>
          </Menu>
        </Sider>
        <Layout style={{ padding: "0 15px 15px" }}>
          <Content
            className="site-layout-content"
            style={{
              padding: 0,
              margin: 0,
              minHeight: 280,
            }}
          >
            {component === "Details"?
            <TeacherDetails teacher={teacher}/>
             :component === "Attendance"?
             <TeacherAttandance/>
             :component === "Payouts"?
             <Payouts/>
             :""

            }
            
          </Content>
        </Layout>
      </Layout>

      
    </div>
  );
};

export default TeacherProfile;
