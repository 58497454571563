import axios from "axios";

const admin = JSON.parse(localStorage.getItem("ADMIN"))

if(admin){
    axios.defaults.headers.common["x-access-token"] = admin.token;
}
axios.defaults.headers.common["platform"] = "AdminWeb";
// if(timezone){
//     axios.defaults.headers.common["timezone"] = timezone?.timezone;
// }
// eslint-disable-next-line import/no-anonymous-default-export
export default {
    SessinApis(url = `${process.env.REACT_APP_BASE_URL}/api`) {
        return {
            availableTeacher: (data) => axios.post(url + "/schedule/getTeacherNew", data),
            assignTeacher: (data) => axios.post(url +"/session/assignTeacher",data),
            getAvailableHost: (data) => axios.post(url +"/getAvailableHost",data),
            createSession: (data) => axios.post(url +"/session/create",data),
            deleteSession: (data) => axios.post(url +"/session/delete",data),
            createZoomMeeting: (data) => axios.post(url +"/session/createmeeting",data),
            fetchUpcomingStudentSession: (data) => axios.post(url +"/session/fetchupcomingstudentsession",data),
            fetchPastStudentSession: (data) => axios.post(url +"/session/fetchpaststudentsession",data),
            fetchCancelledSessions: (data) => axios.post(url +"/session/fetchCancelledSessions",data),
            fetchupcomingsessionadmin: (data) => axios.post(url +"/session/fetchupcomingsessionadminnew",data),
            fetchpastsessionadmin: (data) => axios.post(url +"/session/fetchpastsessionadminnew",data),
            generateWeeklysessionreport: (data) => axios.post(url +"/session/weeklysessionreport",data),
            getSubscriptionsByDateRange: ({startDate,endDate}) => axios.get(url +`/adminauth/getSubscriptionsByDateRange?startDate=${startDate}&endDate=${endDate}`),
            getListBlockhostByDateRange: (data) => axios.post(url +`/adminauth/listBlockhostByDateRange`,data),
            getSessionByDateRange: (data) => axios.post("https://utils.clapingo.com/api/report/sessionReportBaseOnDateRange",data),
            getDemopaidnotbookedByDateRange: (data) => axios.post("https://utils.clapingo.com/api/report/demoPaidnotBookedBaseOnDateRange",data),
            getSubscriptionByDateRange: (data) => axios.post("https://utils.clapingo.com/api/report/subscriptionreportBaseOnDateRange",data),
            getSubscriptionAuditingReport: (data) => axios.post("https://utils.clapingo.com/api/report/subscriptionAuditingreportBaseOnDateRange",data),
            getTeacherworkinghourByDateRange: (data) => axios.post("https://utils.clapingo.com/api/report/getTeacherWorkingHoursByDateRange",data),
            getTeacherEarningsReport: (data) => axios.post(url + "/session/teacherEarningReport",data),
            getteacherConversionStatsReport: (data) => axios.post("https://utils.clapingo.com/api/report/teacherConversionStatsReport",data),
            getp2pDetailsReport: (data) => axios.post("https://utils.clapingo.com/api/report/p2pDetailsReport",data),
            getp2pCallDetailsReport: (data) => axios.post("https://utils.clapingo.com/api/report/p2pCallDetailsReport",data),
            getSessionAutoSwapReport: (data) => axios.post("https://utils.clapingo.com/api/report/sessionAutoSwapReport",data),
            getDemoCancelReport: (data) => axios.post("https://utils.clapingo.com/api/report/demoCancelSessionReport",data),
            getSignupSuccessReport: (data) => axios.post("https://utils.clapingo.com/api/report/signupSuccessReport",data),

            getcurrentWorkingHourReport: (data) => axios.post("https://utils.clapingo.com/api/report/getCurrentTeacherWorkingHours",data),
            getDemoSessionRecordingReport:(data) => axios.post("https://utils.clapingo.com/api/report/demoSessionRecordingReport",data),
            getsessionWiseConversionReport: (data) => axios.post("https://utils.clapingo.com/api/report/getConversionReport",data),
            getp2pDetailsReport: (data) => axios.post("https://utils.clapingo.com/api/report/p2pDetailsReport",data),
            getP2PSessionWiseReport: (data) => axios.post("https://utils.clapingo.com/api/report/p2pSessionWiseReport",data),
            getp2pUTMReferralReport: (data) => axios.post("https://utils.clapingo.com/api/report/p2pUTMReferralReport", data),
            getp2pCallDetailsReport: (data) => axios.post("https://utils.clapingo.com/api/report/p2pCallDetailsReport",data),
            learnerReportUpload:(data) => axios.post("https://utils.clapingo.com/api/report/findfailedPaymentLearnerDetails",data),
            getDemoLeadReport: (data) => axios.post("https://utils.clapingo.com/api/report/generateDemoLeadsReport",data),
            
            getSlotDetailsReport: (data) => axios.post("https://utils.clapingo.com/api/report/slotsDetailsReport", data),
            getbacklogAndQualityMarkReport: (data) => axios.post("https://utils.clapingo.com/api/report/backlogAndQualityMarkReportBaseOnDateRange",data),
            getBacklogSessionReport: (data) => axios.post("https://utils.clapingo.com/api/report/backlogSessionReportBasedOneDateRange", data),
            getAllReports: (data) => axios.post(url +"/adminauth/getAllGeneratedReport",data),
            teacherAttendance: (data,params) => axios.post(url +`/session/teacher-attendance?page=${params.page}&limit=${params.limit}`,data),
            individualTeacherAttendance: (data,params) => axios.post(url +`/session/individual-teacher-attendance?startDate=${params.startDate}&endDate=${params.endDate}`,data),
            sessionScore: ({start,end}) => axios.get(url +`/rating/get-kids-session-score?startDate=${start}&endDate=${end}`),
            getMeetingRecording: (data) => axios.post(url+"/session/getRecording",data),
            getYtEditors: (data) => axios.get(url+"/adminauth/getEditorsYT", data ),
            fetchTeacherCanceledSession: (data) => axios.post(url +"/session/fetchteachercanceledsessionadmin",data),
            cancelSession: (data) => axios.post(url +"/session/cancelViaAdmin",data),
            approvedTeacherPayout: (data) => axios.post(url +"/payouts/approvedTeacherPayout",data),
            studentUpcomingSession: (data) => axios.post(url + "/session/fetchupcomingstudentsessionnew", data),
            studentPastSession: (data) => axios.post(url + "/session/fetchpaststudentsessionnew", data),
            addBacklogSessions: (data) => axios.post(url+ "/learner/addBacklogSessions",data),
            extendWeeklyLimit: (data) => axios.post(url + '/learner/increaseWeeklyValidity',data),
            extendSubscriptionLimit: (data) => axios.put(url + '/learner/increaseSubscription',data),
            markSessionAttendence : (data) => axios.put(url+'/session/markSessionAttendence', data),
            markSessAttendence : (data) => axios.put(url+'/session/markSessionAttendance', data),
            markSessionQualityIssue: (data) => axios.put(url+'/session/markSessionQualityIssue', data),

            fetchAllDisputes: (resolved, closed) => axios.get(url + `/session/getAllDispute?resolved=${resolved}&closed=${closed}&page=1&limit=100`),

            resolveDispute: (disputeId) => axios.put(url + `/session/resolveDispute/${disputeId}`),
            rejectDispute: (disputeId, rejectReason) => axios.put(url + `/session/closeDispute/${disputeId}`, rejectReason),
            getAvalableHost: (data, demoToken) => {
                if (demoToken) {
                    axios.defaults.headers.common["x-access-token"] = demoToken;
                    return(

                    axios.post(url + "/session/blockHost", data))
                }
                else {
                    return(axios.post(url + "/session/blockHost", data))

                }
            },
            addOrUpdateVideoSession: (data) => axios.post(url+'/session/addOrUpdateVideoSession', data),
            createASession:(data) => axios.post(url + '/session/v1/createnew', data),
            getSessionAllotment: ({startDate,endDate}) => axios.get(url+`/teacher/getConversionData?startDate=${startDate}&endDate=${endDate}&timezone=Asia/Calcutta&reportType=Hourly`),
            addKidsSession :(data) => axios.post(url + '/session/purchaseSessions', data),

            getSwapAvailableTeacher :(data) => axios.post(url + '/adminauth/getfilterRegularTeachers', data),
            swapTeacher :(data) => axios.post(url + '/adminauth/swapTeacher', data),
            getPseudoSlots :() => axios.get(url + '/session/getPsuedoSlots?page=1&limit=1000'),
            cancelPseudoSlot :(data) => axios.put(url + '/session/cancelSlot',data),
        }
    }
}




