import React from "react";
import { PageHeader } from "antd";
import {useHistory} from 'react-router-dom'

const CreateSession = () => {

    const history  = useHistory()

  return (
    <div>
      <PageHeader
        className="site-page-header"
        onBack={() => history.push("/session")}
        title="Create Session"
        subTitle=""
      />
    </div>
  );
};

export default CreateSession;
