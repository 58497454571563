import React, { useEffect, useState } from "react";
import PageBreadcrumb from "../../component/PageBreadcrumb";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Spin,
  message,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useHistory, Link } from "react-router-dom";
import TextArea from "antd/lib/input/TextArea";
import {
  CreateCurriculum,
  GetCurriculums,
} from "../../redux/actions/CurriculumAction";
import { useDispatch } from "react-redux";

const AddCurriculum = () => {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        const onSuccess = (curriculum) => {
          form.resetFields();
          setTimeout(() => {
            history.push(`/curriculum/${curriculum._id}`);
          }, 100);
          message.success("Curriculum created");
          setIsModalOpen(false);
        };
        const onError = (msg) => {
          message.error(msg);
          setIsModalOpen(false);
        };
        CreateCurriculum(values, onSuccess, onError, dispatch);
      })
      .catch((errorInfo) => {
        console.log("Validation Failed:", errorInfo);
      });
  };
  const handleCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
  };

  return (
    <>
      <Button
        type="dashed"
        onClick={showModal}
        style={{ width: "300px", height: "15rem" }}
        icon={<PlusOutlined />}
      >
        Add Curriculum
      </Button>
      <Modal open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <Form form={form} layout="vertical">
          <Form.Item
            label={`Curriculum Title`}
            name={"name"}
            required
            rules={[
              { required: true, message: "Please add curriculum title !" },
            ]}
          >
            <Input placeholder="Curriculum title" />
          </Form.Item>
          <Form.Item
            required
            name={"description"}
            label="Curriculum Description"
            rules={[
              {
                required: true,
                message: "Please add curriculum description !",
              },
            ]}
          >
            <TextArea rows={4} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

const Curriculums = () => {
  const [curriculums, setCurriculums] = useState(null);
  const [loader, setLoader] = useState(null);

  const fetchAllCurriculums = () => {
    setLoader(true);
    const onSuccess = (res) => {
      setCurriculums(res);
      setLoader(false);
    };
    const onError = (msg) => {
      message.error(msg);
      setLoader(false);
    };
    GetCurriculums(onSuccess, onError);
  };

  useEffect(() => {
    fetchAllCurriculums();
  }, []);

  return (
    <div>
      <PageBreadcrumb route={["dashboard", "Curriculums"]} />
      <div className="site-card-wrapper">
        {loader ? (
          <Spin tip="Loading" size="small">
            <div className="content" />
          </Spin>
        ) : (
          <Row gutter={16} align="middle">
            {curriculums?.map((curriculum, i) => (
              <Col span={8} key={i}>
                <Card
                  title={curriculum?.name}
                  extra={
                    <Link to={`/curriculum/${curriculum?._id}`}>Details</Link>
                  }
                  style={{ width: "300px", height: "15rem" }}
                >
                  <p>{curriculum?.description}</p>
                </Card>
              </Col>
            ))}

            <Col span={8}>
              <AddCurriculum />
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
};

export default Curriculums;
