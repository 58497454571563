import React, { useEffect, useState } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import PageBreadcrumb from "../../component/PageBreadcrumb";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useDispatch, useSelector } from "react-redux";
import {
  Typography,
  Card,
  Tabs,
  message,
  Row,
  Col,
  Button,
  Spin,
  Empty,
} from "antd";
import {
  FetchPastStudentSession,
  FetchUpcomingStudentSession,
} from "../../redux/actions/SessionAction";
import { Link } from "react-router-dom";
import { formatAMPM, getDate } from "../../helper/helper";

const { Title, Text } = Typography;
const { TabPane } = Tabs;
const { Meta } = Card;

const SessionDetails = (props) => {
  const dispatch = useDispatch();
  const Teacher = useSelector((state) => state.AdminReducer.teacher);
  const [upcomingSession, setUpcomingSession] = useState("loding");
  const [pastSession, setPastSession] = useState("loding");
  const userDetails = JSON.parse(localStorage.getItem('USER_DETAILS'))

  const TeacherName = (id) => {
    var name;
    Teacher.filter((data) => {
      if (data._id === id) {
        console.log(data.name);
        name = data.name;
        name = name.split(" ");
      }
    });
    return name;
  };

  useEffect(() => {
    const id = props.location.details?props.location.details.id:userDetails.id;
    const upcoming = (data) => {
      console.log(data);
      setUpcomingSession(data);
    };
    const past = (data) => {
      console.log(data);
      setPastSession(data);
    };
    const onError = (msg) => {
      message.error(msg);
    };
    dispatch(FetchUpcomingStudentSession({ studentID: id }, upcoming, onError));
    dispatch(FetchPastStudentSession({ studentID: id }, past, onError));
  }, []);

  return (
    <div className="site-card-wrapper">
      <PageBreadcrumb
        route={["dashboard", `${props.location.details?props.location.details.name:userDetails.name} Sessions`]}
      />
      <Title level={3}>
        {" "}
        <Link
          to={{
            pathname: "/subscriptiondetails",
            details: {
              id: props.location.details?props.location.details.id:userDetails.id,
              phone: props.location.details?props.location.details.phone:userDetails.phone,
              name: props.location.details?props.location.details.name:userDetails.name,
            },
          }}
        >
          <ArrowLeftOutlined />
        </Link>{" "}
        {props.location.details?props.location.details.name:userDetails.name} Sessions
      </Title>

      <Card>
        <Tabs defaultActiveKey="1" centered>
          <TabPane tab="UPCOMING DEMO" key="1">
            <div className="site-card-wrapper">
              <Row gutter={16}>
                {upcomingSession === "loding" ? (
                  <Col span={24}>
                    <div className="spin-center">
                      <Spin size={"large"} />
                    </div>
                  </Col>
                ) : upcomingSession[0] ? (
                  <>
                    {upcomingSession.map((data) => (
                      <Col span={8} style={{ marginTop: 10 }} key={data._id}>
                        <Card
                          hoverable
                          style={{ backgroundColor: "#f1f2f6" }}
                          actions={[
                            <div style={{ flexDirection: "row" }}>
                              <div style={{ display: "flex" }}>
                                <CopyToClipboard
                                  text={data.startMeetingUrl}
                                  onCopy={() => {
                                    message.success("Start link Copyed");
                                  }}
                                >
                                  <Button
                                    type="primary"
                                    style={{ marginTop: 10, margin: 5 }}
                                    block
                                  >
                                    Start Link
                                  </Button>
                                </CopyToClipboard>

                                <CopyToClipboard
                                  text={data.joinMeetingUrl}
                                  onCopy={() => {
                                    message.success("Join link Copyed");
                                  }}
                                >
                                  <Button
                                    type="primary"
                                    style={{ marginTop: 10, margin: 5 }}
                                    block
                                  >
                                    Join Link
                                  </Button>
                                </CopyToClipboard>
                              </div>
                            </div>,
                          ]}
                        >
                          <Meta
                            title={`${props.location.details?props.location.details.name:userDetails.name} Upcoming Sessions`}
                            description={
                              <>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Text> {getDate(data.sessionDate)} </Text>
                                  <Text>
                                    {formatAMPM(data.startTime)} -{" "}
                                    {formatAMPM(data.endTime)}{" "}
                                  </Text>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    marginTop: 10,
                                  }}
                                >
                                  <span
                                    style={{
                                      textAlign: "center",
                                      color: "#333",
                                    }}
                                  >
                                    <p style={{ lineHeight: 1 }}>Learner</p>
                                    <p style={{ lineHeight: 0 }}>
                                      {" "}
                                      {data.learner.name}{" "}
                                    </p>
                                  </span>
                                  <span
                                    style={{
                                      textAlign: "center",
                                      color: "#333",
                                    }}
                                  >
                                    <p style={{ lineHeight: 1 }}>Teacher</p>
                                    <p style={{ lineHeight: 0 }}>
                                      {data.assignTeacher
                                        ? TeacherName(data.assignTeacher._id)
                                        : data.teacher
                                        ? TeacherName(data.teacher._id)
                                        : "Not available"}{" "}
                                    </p>
                                  </span>
                                  <span
                                    style={{
                                      textAlign: "center",
                                      color: "#333",
                                    }}
                                  >
                                    <p style={{ lineHeight: 1 }}>Status</p>
                                    <p style={{ lineHeight: 0 }}>
                                      {" "}
                                      {data.teacherStatus}{" "}
                                    </p>
                                  </span>
                                </div>
                              </>
                            }
                          />
                        </Card>
                      </Col>
                    ))}
                  </>
                ) : (
                  <Col span={24}>
                    <Empty description={false} />
                  </Col>
                )}
              </Row>
            </div>
          </TabPane>
          <TabPane tab="PAST DEMO" key="2">
            <div className="site-card-wrapper">
              <Row gutter={16}>
                {pastSession === "loding" ? (
                  <Col span={24}>
                    <div className="spin-center">
                      <Spin size={"large"} />
                    </div>
                  </Col>
                ) : pastSession[0] ? (
                  <>
                    {pastSession.map((data) => (
                      <Col span={8} style={{ marginTop: 10 }} key={data._id}>
                        <Card
                          hoverable
                          style={{ backgroundColor: "#f1f2f6" }}
                          actions={[
                            <div style={{ flexDirection: "row" }}>
                              <div style={{ display: "flex" }}>
                                <CopyToClipboard
                                  text={data.startMeetingUrl}
                                  onCopy={() => {
                                    message.success("Start link Copyed");
                                  }}
                                >
                                  <Button
                                    type="primary"
                                    style={{ marginTop: 10, margin: 5 }}
                                    block
                                  >
                                    Start Link
                                  </Button>
                                </CopyToClipboard>

                                <CopyToClipboard
                                  text={data.joinMeetingUrl}
                                  onCopy={() => {
                                    message.success("Join link Copyed");
                                  }}
                                >
                                  <Button
                                    type="primary"
                                    style={{ marginTop: 10, margin: 5 }}
                                    block
                                  >
                                    Join Link
                                  </Button>
                                </CopyToClipboard>
                              </div>
                            </div>,
                          ]}
                        >
                          <Meta
                            title={`${props.location.details?props.location.details.name:userDetails.name} Upcoming Sessions`}
                            description={
                              <>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Text> {getDate(data.sessionDate)} </Text>
                                  <Text>
                                    {formatAMPM(data.startTime)} -{" "}
                                    {formatAMPM(data.endTime)}{" "}
                                  </Text>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    marginTop: 10,
                                  }}
                                >
                                  <span
                                    style={{
                                      textAlign: "center",
                                      color: "#333",
                                    }}
                                  >
                                    <p style={{ lineHeight: 1 }}>Learner</p>
                                    <p style={{ lineHeight: 0 }}>
                                      {" "}
                                      {data.learner.name}{" "}
                                    </p>
                                  </span>
                                  <span
                                    style={{
                                      textAlign: "center",
                                      color: "#333",
                                    }}
                                  >
                                    <p style={{ lineHeight: 1 }}>Teacher</p>
                                    <p style={{ lineHeight: 0 }}>
                                      {data.assignTeacher
                                        ? TeacherName(data.assignTeacher._id)
                                        : data.teacher
                                        ? TeacherName(data.teacher._id)
                                        : "Not available"}{" "}
                                    </p>
                                  </span>
                                  <span
                                    style={{
                                      textAlign: "center",
                                      color: "#333",
                                    }}
                                  >
                                    <p style={{ lineHeight: 1 }}>Status</p>
                                    <p style={{ lineHeight: 0 }}>
                                      {" "}
                                      {data.teacherStatus}{" "}
                                    </p>
                                  </span>
                                </div>
                              </>
                            }
                          />
                        </Card>
                      </Col>
                    ))}
                  </>
                ) : (
                  <Col span={24}>
                    <Empty description={false} />
                  </Col>
                )}
              </Row>
            </div>
          </TabPane>
        </Tabs>
      </Card>
    </div>
  );
};

export default SessionDetails;
