import React, { useEffect, useState } from "react";
import { Table, Card, message, Spin, Avatar, Col, Row, Button, Space, Input, Modal, DatePicker } from "antd";
import { useDispatch, useSelector } from "react-redux";
import PageBreadcrumb from "../../component/PageBreadcrumb";
import { GetAllTeacher, SearchTeacher } from "../../redux/actions/UserAction";
import Search from "antd/lib/input/Search";
import { Link } from "react-router-dom";
import EarningBreakdownModal from "../../component/EarningBreakdown/EarningBreakdownModal";
import { ApprovedTeacherPayout } from "../../redux/actions/SessionAction";
import { GetPayouts, WithdrawAmount } from "../../redux/actions/TeacherAction";
import moment from "moment-timezone";
import { convertToUTCMomentObj } from "../../helper/TimeDate";
const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';

// Array custom method
// eslint-disable-next-line no-extend-native
Array.prototype.insert = function ( index, ...items ) {
  this.splice( index, 0, ...items );
};

const totalHrBonus = (hr) =>{
  hr = Number(hr)
  if(hr > 60 && hr < 75){
    return 500
  }else if(hr > 75 && hr < 100){
    return 1000
  }else if(hr > 100){
    return 2000
  }else{
    return 0
  }
}

const TransferPayout = ({teacherId,name,bankVerificationStatus,earning,teacher, setTeacher, payoutStartDate, payoutEndDate}) =>{

  const admin = JSON.parse(localStorage.getItem("ADMIN"))
  const {sessionEarning,referralEarning,lateJoin,missed,panelty,tds,total,totalHours} = earning;
  
  const [isModalVisible,setIsModalVisible] = useState(false)
  const [amount, setAmount] = useState(0)
  const [btnLoad, setBtnLoad] = useState(false)

  console.log("payout")
 console.log(payoutStartDate, payoutEndDate)
  

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const withdrawPayoutAmount = (payoutId) =>{

    const onSuccess = (res) => {
      const index = teacher.findIndex(x => x._id === teacherId);
      const teacherData = teacher.filter(thr => thr._id === teacherId)
      const teachers = teacher.filter(thr => thr._id !== teacherId)
      teacherData[0].earningData.totalEarning=0
      teacherData[0].earningData.totalReferralEarning=0
      teacherData[0].earningData.totalSessionEarning=0
      teacherData[0].earningData.totalPenalty=0
      teacherData[0].earningData.totalHours=0
      teacherData[0].sessionEarning=0
      teacherData[0].referralEarning=0
      teachers.insert(index,teacherData[0])
      setTeacher(teachers)
      setBtnLoad(false)
      setIsModalVisible(false);
      message.success('Amount successfully transferred :)')
    };
    const onError = (msg) => {
        message.error(msg);
        setBtnLoad(false)
    };
    WithdrawAmount({payoutId:payoutId},onSuccess,onError)
  }

  const approvePayout= (amount) =>{
      setBtnLoad(true)
      const data = {
          approverId:admin?._id,
          teacherId:teacherId,
          amount:amount,
          payoutStartDate: payoutStartDate.format(dateFormat),
          payoutEndDate: payoutEndDate.format(dateFormat)
      }

      const onSuccess = (data) =>{
        if(data?.payout){
          withdrawPayoutAmount(data?.payout?._id)
        }else{
          message.error("Something went wrong !")
          setBtnLoad(false)
        }
      }

      const onError = (msg) =>{
        message.error(msg)
        setBtnLoad(false)
      }

      ApprovedTeacherPayout(data,onSuccess,onError)
    
  }


    return(
      <>
        <Button onClick={()=>showModal()} disabled={bankVerificationStatus !== "Verified"}>Transfer earning</Button>
        <Modal title={name} visible={isModalVisible} onCancel={handleCancel} footer={[]}>
          <small><strong>Session</strong> : {sessionEarning} </small><br/>
          <small><strong>Referral</strong> : {(referralEarning).toFixed(2)} </small><br/>
          <small><strong>Total Hr bonus</strong> : {totalHours} </small><br/>
          <small style={{color:"red"}}><strong>Total Penelty </strong> : -{panelty} </small><br/>
          <small style={{color:"red"}}><strong>TDS(after penalty)</strong> : -{tds} </small><br/>
          <small>({lateJoin} lateJoin {missed} missed)</small>
          <hr/>
          <Input placeholder="Add custom amount" onChange={(e)=>setAmount(e.target.value)} type='number' required/>
          <p><strong>Total</strong> : {Number(total)+Number(amount)} </p>
          <Button type='primary' block onClick={()=>approvePayout(Number(total)+Number(amount))} disabled={btnLoad}>Transfer</Button>
        </Modal>
 
    </>
    )
}

const TeachersEarning = () => {
  const dispatch = useDispatch();
  // const teacherList =  useSelector(state => state.AdminReducer.teacher)
  const admin = JSON.parse(localStorage.getItem("ADMIN"))
  // const getAllTeacherLoader = useSelector(state => state.AdminReducer.getAllTeacherLoader)
  const [page, setPage] = useState(0);
  const [loadMore, setLoadMore] = useState(false);
  const [loader, setLoader] = useState(false);
  const [teacher, setTeacher] = useState([]);
  const [startDate, setStartDate] = useState(moment().subtract(1, 'month').endOf('month').startOf('day'));
  const [endDate, setEndDate] = useState(moment());
  const [cardData, setCardData] = useState({});

  const loadNewTeacher = (data) => {
    setTeacher([...teacher, data]);
  }

  // useEffect(() => {
  //   setTeacher(teacherList);
  // }, [getAllTeacherLoader]);
  

  useEffect(()=>{
    getTeacherData();
  },[])

  const getTeacherData = () => {
    setLoader(true);
    const data = {
        page: 1,
        limit: 50,
        startDate: convertToUTCMomentObj(startDate).format(dateFormat),
        endDate: convertToUTCMomentObj(endDate).format(dateFormat)
    };
    const onSuccess = (data, allData) => {
        if (data.length > 0) {
            setTeacher(data);
            setPage(1);
        }
        setLoader(false);
        delete allData.teacherData;
        allData.tds = Math.round(allData.GrandtotalSessionEarning + allData.GrandtotalReferralEarning) ===0 || (allData.GrandtotalSessionEarning + allData.GrandtotalReferralEarning - allData.totalPenalty)<0 ? 0 : Math.round(((allData.GrandtotalSessionEarning + allData.GrandtotalReferralEarning) - (allData.totalPenalty)) * 10 / 100)
        allData.total = Math.round(((allData.GrandtotalSessionEarning - allData.totalPenalty) - allData.tds) + allData.GrandTotalOfextraBonus +  allData.GrandtotalReferralEarning)
        setCardData(allData);
    };
    const onError = (msg) => {
        console.log(msg);
        setLoader(false);
    };
    dispatch(GetAllTeacher(data, onSuccess, onError))
  }

  const loadMoreTeacher = () => {
    setLoadMore(true);
    const data = {
        page: page + 1,
        limit: 50,
        startDate: startDate.format(dateFormat),
        endDate: endDate.format(dateFormat)
    };
    const onSuccess = (data, allData) => {
        if (data.length > 0) {
            setTeacher([...teacher, ...data]);
            setPage(page + 1);
        }
        setLoadMore(false);
        delete allData.teacherData;
        setCardData((prev)=>{
          const prevState = {...prev};
          prevState.GrandtotalSessionEarning+=allData.GrandtotalSessionEarning
          prevState.GrandtotalReferralEarning+=allData.GrandtotalReferralEarning
          prevState.GrandTotalOfextraBonus+=allData.GrandTotalOfextraBonus
          prevState.totalPenalty+=allData.totalPenalty
          prevState.tds=Math.round(prevState.GrandtotalSessionEarning + prevState.GrandtotalReferralEarning) ===0 || (prevState.GrandtotalSessionEarning + prevState.GrandtotalReferralEarning - prevState.totalPenalty)<0 ? 0 : Math.round(((prevState.GrandtotalSessionEarning + prevState.GrandtotalReferralEarning) - (prevState.totalPenalty)) * 10 / 100);
          prevState.total = Math.round(prevState.GrandtotalSessionEarning - (prevState.totalPenalty+prevState.tds) + totalHrBonus(prevState.GrandTotalOfextraBonus))
          return prevState;
        })
    };
    const onError = (msg) => {
        console.log(msg);
        setLoadMore(false);
    };
    dispatch(GetAllTeacher(data, onSuccess, onError))
}

  function isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  const onSearch = (value) => {
    if (!value) return;
    var data = {};
    if (isNumeric(value)) {
      data["phoneNumber"] = value;
      data["name"] = value;
    } else {
      data["name"] = value;
    }

    const onSuccess = (res) => {
      setTeacher(res);
    };

    const onError = (msg) => {
      message.error(msg, 8);
    };

    dispatch(SearchTeacher(data, onSuccess, onError));
  };
  
  const columns = [
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      width: 40,
      render: (text,record) =>  <Avatar size={60} src={`//images.weserv.nl/?url=${text}&h=60&w=60`} />
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 40,
      render: (text,record) => <Link to={`/teacher/profile/${record.key}?tab=Payouts`}>{text}</Link>,
    },
    {
      title: "Phone Number",
      dataIndex: "number",
      key: "number",
      width: 60,
    },
    {
        title: "Earning",
        dataIndex: "earning",
        key: "earning",
        width: 40,
        render: ({sessionEarning,referralEarning,lateJoin,missed,panelty,tds,total,totalHours})=> <>
        <small><strong>Session</strong> : {sessionEarning} </small><br/>
        <small><strong>Referral</strong> : {(referralEarning).toFixed(2)} </small><br/>
        <small><strong>Total Hr bonus</strong> : {totalHours} </small><br/>
        <small style={{color:"red"}}><strong>Total Penelty </strong> : -{panelty} </small><br/>
        <small style={{color:"red"}}><strong>TDS(after penalty)</strong> : -{tds} </small><br/>
        
        <small>({lateJoin} lateJoin {missed} missed)</small>
        <hr/>
        <p><strong>Total</strong> : {total} </p>
        </>
      },
      
      {
        title: 'Action',
        key: 'operation',
        fixed: 'right',
        width: 60,
        render: ({key,name,bankVerificationStatus,earningData,earning}) => {
          return(
            <Space>
              {/* {
                ((process.env.REACT_APP_BASE_URL === "https://api.clapingo.com") &&
                (admin._id === "6097c60c2eaaf35da9a2e425"))?  */}
                 <TransferPayout payoutStartDate={startDate} payoutEndDate={endDate} teacherId={key} name={name} bankVerificationStatus={bankVerificationStatus} earning={earning} teacher={teacher} setTeacher={setTeacher} />
                   {/* :""
               } */}
          {/* <Button disabled={approved === admin._id || earning.total<=0 } type="primary" onClick={()=>ApprovePayout(admin._id,key,earning.total)} loading={approveBtnLoader === key} >Approve</Button> */}
            <EarningBreakdownModal name={name} earningData={earningData}/>
            </Space>
          )
        }
      },

  ];


  const data = teacher?.map((data, i) => {
    var teacherTags = [];
    if (data.isDemo) {
      teacherTags.push("Demo");
    }
    if (data.isDisabled) {
      teacherTags.push("Disabled");
    }
    if (data.isKidDemo) {
      teacherTags.push("KidDemo");
    }
    if (data.isKidSession) {
      teacherTags.push("KidSession");
    }
    if (data.isYoutubeConsentSigned) {
      teacherTags.push("Youtube");
    }

    const totalPenelty = data?.earningData?.totalPenalty
    //const tds = Math.round( ((data?.earningData?.totalSessionEarning) - (data?.earningData?.totalPenalty)) * parseInt(data.earningData?.TDS) / 100)
    const tds = Math.round(data?.earningData?.totalSessionEarning + data?.earningData?.totalReferralEarning) ===0 || (data?.earningData?.totalSessionEarning + data?.earningData?.totalReferralEarning - data?.earningData?.totalPenalty)<0 ? 0 : Math.round(((data?.earningData?.totalSessionEarning + data?.earningData?.totalReferralEarning + data?.earningData?.extraBonus) - (data?.earningData?.totalPenalty)) * parseInt(data?.earningData?.TDS) / 100)
    const totalEarning = data.sessionEarning + data.referralEarning

    return {
      key: data?._id,
      image:data?.image,
      name: data?.name,
      about: data?.description?data.description:"Not available",
      number: data?.phoneNumber,
      rating: data?.rating,
      earning:{
        sessionEarning:data?.sessionEarning,
        referralEarning:data?.referralEarning,
        lateJoin:data?.earningData?.latePenalty,
        missed:data?.earningData?.sessionMissingPenalty,
        totalHours:totalHrBonus(data?.earningData?.totalHours),
        panelty:totalPenelty,
        tds:tds,
        total: Math.round(totalEarning - (totalPenelty+tds) + totalHrBonus(data?.earningData?.totalHours))
      },
      earningData: data?.earningData,
      bankVerificationStatus:data?.bankVerificationStatus
    };
  });

  function onPickDate(date, dateString) {
    setStartDate(date[0]);
    setEndDate(date[1]);
   
  }


  console.log("mylog", data);

  return (
    <div>
      <PageBreadcrumb route={["dashboard", "Teachers"]} />

      <Card>
        <Row>
         {/* <Col span={7}>
              <Search
                placeholder="Search by Phone Number/Name"
                onSearch={onSearch}
                enterButton
                style={{ width: 300 }}
              />
          </Col> */}
          <Col span={15}>
            <Space>
              <RangePicker onChange={onPickDate} format={dateFormat} value={[startDate, endDate]} />
              <Button type="primary" onClick={() => getTeacherData()} loading={loader}>Filter</Button>
            </Space>
          </Col>
        </Row>
      </Card>
      <Card style={{width: 300, margin: "20px 0"}}>
        <div style={{fontWeight: 700, marginBottom: 10}}>Earning Report (Total)</div>
        <small><strong>Total Session</strong> : {cardData.GrandtotalSessionEarning} </small><br/>
        <small><strong>Total Referral</strong> : {(cardData.GrandtotalReferralEarning)?.toFixed(2)} </small><br/>
        <small><strong>Total Hr bonus</strong> : {cardData.GrandTotalOfextraBonus} </small><br/>
        <small style={{color:"red"}}><strong>Total Penalty </strong> : -{cardData.totalPenalty} </small><br/>
        <small style={{color:"red"}}><strong>TDS(after penalty)</strong> : -{cardData.tds} </small><br/>
        <hr/>
        <p><strong>Total</strong> : {(cardData.GrandtotalSessionEarning + cardData.GrandtotalReferralEarning + cardData.GrandTotalOfextraBonus)-(cardData.totalPenalty+cardData.tds)} </p>
      </Card>
      {
        loader?
           <div className="spin-center">
           <Spin size="large" />
         </div>
         :
         <Table
         columns={columns}
         dataSource={data}
         scroll={{ x: 1000 }}
         pagination={false}
       />
      }

      {
        loadMore?
        <div className="spin-center">
        <Spin size="large" />
      </div>
      :""
      }

      <div style={{'text-align':'center','margin-top':"10px"}}>
        <Button onClick={()=>loadMoreTeacher()} disabled={loadMore || loader} >Load More</Button>
      </div>

    </div>
  );
};

export default TeachersEarning;

