import React, { useState, useEffect } from "react";
import { Table, Spin } from "antd";
import PageBreadcrumb from "../component/PageBreadcrumb";
import { useDispatch } from "react-redux";
import { GetPlans } from "../redux/actions/UserAction";
import {getDate} from '../helper/helper'

const PlanList = () => {
    const dispatch = useDispatch();
    const [Plans, setPlans] = useState(null);
  
    useEffect(() => {
      const onSuccess = (data) => {
        console.log(data);
        setPlans(data);
      };
      dispatch(GetPlans(onSuccess));
    }, []);

    const columns = [
        {
          title: "Duration",
          dataIndex: "duration",
          key: "duration",
        },
        {
          title: "Name",
          dataIndex: "name",
          key: "name",
        },
        {
          title: "Frequency",
          dataIndex: "frequency",
          key: "frequency",
        },
        {
          title: "Discountprice",
          dataIndex: "discountprice",
          key: "discountprice",
        },
        {
          title: "Price",
          dataIndex: "price",
          key: "price",
        },
      ];
    
      const data = Plans
        ?Plans.map((data) =>({
            key: data._id,
            duration: data.classDuration,
            name: data.courseName,
            frequency: data.frequency,
            discountprice: data.originalPrice,
            price: data.price,
        }))
        : "";
    
    return (
        <div>
            <PageBreadcrumb route={["dashboard", "Plan List"]} />
           {/* delete  classDuration courseName frequency originalPrice price */}
           {Plans ? (
        <Table
          columns={columns}
          dataSource={data}
          scroll={{ x: 1000 }}
          pagination={{
            defaultPageSize: 40,
            showSizeChanger: true,
            pageSizeOptions: ["80", "120", "160"],
          }}
        />
      ) : (
        <div className="spin-center">
          <Spin size="large" />
        </div>
      )}
        </div>
    )
}

export default PlanList
