import React, { useEffect, useState} from "react";
import {
  Avatar,
  Button,
  Col,
  Divider,
  Drawer,
  Form,
  Input,
  List,
  Row,
  Select,
  Upload,
  message,
  Card,
  Space
} from "antd";
import {
  EditOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  CloseOutlined
} from "@ant-design/icons";
import { CreateExercise, UpdateExercise } from "../../redux/actions/CurriculumAction";
const { Option } = Select;
const { TextArea } = Input;

const AddUpdateExercise = ({exercises, setExercises, topicData, exerciseData}) => {
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  useEffect(()=>{
    if(exerciseData){
      console.log("ex", exerciseData)
      form.setFieldsValue({
       ...exerciseData
      });
    }
    else{
      form.setFieldsValue({
        type: "reading",
        questions: [{serialNo: 1, parameters: [""]}]
      })
    }
  }, [exerciseData])

  const createExercise = (payload) => {
    const onSuccess = (data) => {
      message.success(data.message);
      setExercises([...exercises, data.exercise]);
      onClose();
    }
    const onError = (err) => {
      message.error(err);
    }
    CreateExercise(payload, onSuccess, onError);
  }

  const updateExercise = (payload) => {
    const onSuccess = (data) => {
      message.success(data.message);
      let newExercises = exercises.map(ex=>ex._id === payload._id ? payload : ex);
      setExercises(newExercises);
      onClose();
    }
    const onError = (err) => {
      message.error(err);
    }
    UpdateExercise(payload, onSuccess, onError);
  }

  const onFinish = (values) => {
    console.log("form values", values);
    let payload = JSON.parse(JSON.stringify(values));
    payload.questions.forEach((ques, i)=>{
      if(!exerciseData)ques.serialNo = i+1;
      if(exerciseData?.type!=="conversational"){
        if(ques.question.includes("{placeholder}")) ques.type = "FMCQ";
        else ques.type = "MCQ";
      }
    })
    if(!exerciseData) {
      payload.topicId = topicData._id;
      console.log("form mod values", payload);
      createExercise(payload);
    }
    else {
      payload.exerciseId = exerciseData._id;
      console.log("form mod values", payload);
      updateExercise(payload);
    }
  }


  return (
    <div>
      <Button type="dashed" onClick={showDrawer} block icon={<EditOutlined />}>
        {exerciseData ? "Edit" : "Add Exercise"}
      </Button>

      <Drawer
        title={exerciseData ? "Edit Exercise" : "Add Exercise"}
        width={"40%"}
        closable={false}
        onClose={onClose}
        visible={open}
      >

        <Form form={form} layout="vertical" onFinish={onFinish} >
          {!exerciseData ? <Form.Item
            label="Exercise Type"
            name="type"
            rules={[{ required: true, message: 'Missing first name' }]}
          >
            <Select>
              <Option value="reading">reading</Option>
              <Option value="listening">listening</Option>
              <Option value="conversational">conversational</Option>
            </Select>
          </Form.Item> : null}

          <Form.Item
            label="Title"
            name="name"
            rules={[{ required: true, message: 'Missing title' }]}
          >
            <Input placeholder={`Type title`} />
          </Form.Item>

          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => prevValues.type !== currentValues.type}
          >
            {({ getFieldValue }) =>
              getFieldValue('type') !== 'conversational' ? (
                <>
                <Form.Item
                  label="Paragraph"
                  name="paragraph"
                  rules={[{ required: true, message: 'Missing paragrah' }]}
                >
                  <TextArea rows={6} />
              </Form.Item>
              {exerciseData?.type==="listening" ? 
              <audio controls style={{marginBottom: '10px', height: '35px', width: '100%'}} >
                <source src={exerciseData?.paragraphAudioUrl} type="audio/mpeg" />
              </audio>
              : null}
              </>
              ) : (
                <>
                  <Form.Item
                    label="Objective"
                    name="objective"
                    rules={[{ required: true, message: 'Missing objective' }]}
                  >
                    <Input placeholder={`Type objective`} />
                  </Form.Item>
                  <Form.Item
                    label="Scenario"
                    name="scenario"
                    rules={[{ required: true, message: 'Missing scenario' }]}
                  >
                    <Input placeholder={`Type scenario`} />
                  </Form.Item>
                  <Form.Item
                    label="Conversation partner"
                    name="convPartner"
                    rules={[{ required: true, message: 'Missing conversation partner' }]}
                  >
                    <Input placeholder={`Type conversation partner`} />
                  </Form.Item>
                </>
              )
            }
          </Form.Item>

          <div style={{marginBottom:"0.8rem", marginTop:"0.8", fontSize:"0.9rem", color: "red", fontWeight: 500}}><b>Note:</b> Please use <b>{`{placeholder}`}</b> to indicate where a blank should be added in the question.</div>

            <Form.List name="questions">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }, i) => (
                  <Card
                size="small"
                key={key}
                title={`QUESTION ${name + 1}`}
                extra={
                  fields.length > 1 ? <CloseOutlined
                    onClick={() => {
                      remove(name);
                    }}
                  /> : null
                }
                style={{
                    borderColor:"lightgrey",
                    borderRadius:"10px",
                    padding:"4px"
                }}
              >     <Form.Item noStyle name={[name, 'serialNo']} />
                    <Form.Item noStyle name={[name, 'type']} />

                    <Form.Item
                      {...restField}
                      label={`Question ${i+1}`}
                      name={[name, 'question']}
                      rules={[{ required: true, message: `Missing question ${i+1}` }]}
                      style={{flex: 1}}
                    >
                      <Input placeholder={`Type question ${i+1}`} />
                    </Form.Item>

                    {exerciseData?.type==="conversational" ? 
                      <audio controls style={{marginBottom: '10px', height: '35px', width: '100%'}} >
                        <source src={exerciseData?.questions[i]?.questionAudioUrl} type="audio/mpeg" />
                      </audio>
                      : null
                    }

                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, currentValues) => prevValues.type !== currentValues.type}
                    >
                      {({ getFieldValue }) =>
                        getFieldValue('type') === 'conversational' ? (
                          <Form.Item 
                            label="Hint" 
                            name={[name, 'hint']}
                            rules={[{ required: true, message: `Missing Hint` }]}
                          >
                            <Input placeholder="hint" />
                          </Form.Item>
                        ) : null
                      }
                    </Form.Item>

                            <Form.Item
                              noStyle
                              shouldUpdate={(prevValues, currentValues) => prevValues.type !== currentValues.type}
                            >

                                  {({ getFieldValue }) =>
                                    getFieldValue('type') !== 'conversational' ? (
                                      <Form.Item label="Options" required={true} style={{marginBottom: 0}}>
                                        <Row gutter={12} align="middle">
                                          {Array(4).fill('_').map((_, j)=>(
                                            <Col xs={6}>  
                                              <Form.Item
                                                name={[name, 'options', j]}
                                                rules={[{ required: true, message: `Missing option ${j+1}` }]}
                                              >
                                                <Input placeholder={`option ${j+1}`} />
                                              </Form.Item>
                                            </Col>
                                          ))}
                                        </Row>
                                      </Form.Item>
                                    ) : null
                                  }
                            </Form.Item>

                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, currentValues) => prevValues.type !== currentValues.type}
                    >
                    {({ getFieldValue }) =>
                      getFieldValue('type') !== 'conversational' ? (
                        <Form.Item
                          label={`Correct Answer`}
                          name={[name, 'correctAnswer']}
                          rules={[{ required: true, message: 'Missing correct answer' }]}
                        >
                          <Input placeholder={`Type answer`} />
                        </Form.Item>
                      ) : null
                    }
                    </Form.Item>

                    <Form.List name={[name, 'parameters']}>
                      {(fields, { add, remove }) => (
                        <>
                          <Form.Item label="Parameters" required={true} style={{marginBottom: 0}}>
                            <Row gutter={12}>
                              {fields.map(({ key, name:name2, ...restField }, j) => (
                                <Col xs={6}>
                                  <Space key={key} align="start">
                                    <Form.Item
                                      {...restField}
                                      name={[name2]}
                                      rules={[{ required: true, message: `Missing param ${j+1}` }]}
                                    >
                                      <Input placeholder={`param ${j+1}`} />
                                    </Form.Item>
                                    {fields.length > 1 ? <MinusCircleOutlined onClick={() => remove(name2)} /> : null}
                                  </Space>
                                </Col>
                              ))}
                            </Row>
                          </Form.Item>
                          {fields.length < 4 ? <Form.Item>
                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                              Add Parameter
                            </Button>
                          </Form.Item> : null}
                        </>
                      )}
                    </Form.List>
                  </Card>
                ))}
                <Form.Item style={{marginTop: '10px'}}>
                  <Button type="dashed" onClick={() => add({parameters: [""]})} block icon={<PlusOutlined />}>
                    Add Question
                  </Button>
                </Form.Item>
              </>
            )}
            </Form.List>


            <Form.Item>
            <div style={{ textAlign: "right", marginTop: "10px" }}>
              <Button type="primary" block htmlType="submit">
                {exerciseData ? "Update Exercise" : "Add Exercise"}
              </Button>
            </div>
            </Form.Item>
        </Form>

      </Drawer>
    </div>
  )
}

export default AddUpdateExercise