import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Col, Row, Menu, Dropdown, Button, DatePicker, Spin } from "antd";
import AreaChartGraph from "../component/charts/AreaChart";
import { DailySubscriptionReport, DateRangeSessionGraph, SessionTrafficDayWise, TeacherTimeslotsStats } from "../redux/actions/StatisticsAction";
import { DownOutlined } from '@ant-design/icons';
import BarChartComponent from "../component/charts/BarChart";
import PieChartComponent from '../component/charts/PieChart'
import { Link } from "react-router-dom";
import PopularCity from "../component/PopularCity";
import DemoPieChartComponent from "../component/charts/DemoPieChart";
import RegularPieChartComponent from "../component/charts/RegularPieChart";

const { RangePicker } = DatePicker;
var weekList = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]

const Home = () => {

  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [weekDay, setWeekDay] = useState(null);

  const [loding, setLoding] = useState(false);

  const dateFormat = "YYYY-MM-DD";

  var subscriptionData = useSelector((state) => state.AdminReducer.subscriptionAndRetention);
  var referralData = useSelector((state) => state.AdminReducer.referralData);
  var timeSlotStats = useSelector((state) => state.AdminReducer.timeSlotStats);

  useEffect(() => {
    if (startDate && endDate) {
      setLoding(true);
      const onSuccess = () => {
        setLoding(false);
      };
      const onErr = () => {
        setLoding(false);
      };

      dispatch(
        DailySubscriptionReport({ startDate: startDate, endDate: endDate }, onSuccess, onErr)
      );
      dispatch(SessionTrafficDayWise({ startDate: startDate, endDate: endDate }, onSuccess, onErr))

    }
  }, [startDate, endDate]);

  const searchWeeklyTimeslot = (week) => {
    setWeekDay(week)
    dispatch(TeacherTimeslotsStats({
      day: week,
      time: "00:00"
    }))
  }

  const datePicker = (date, dateString) => {
    setStartDate(dateString[0]);
    setEndDate(dateString[1]);
  };

  const menu = (
    <Menu>
      {
        weekList.map((val) => {
          return <Menu.Item key={val} onClick={() => searchWeeklyTimeslot(val)}>{val}</Menu.Item>
        })
      }
    </Menu>
  );

  return (
    <div>

      <Row gutter={16}>
        <Col span={6}>
          <Card className="stat_card">
            <p>Total Subscription</p>
            <h5>{subscriptionData?.totalsubscription}</h5>
          </Card>
        </Col>
        <Col span={6}>
          <Card className="stat_card">
            <p>Retention Ratio</p>
            <h5>{subscriptionData?.ratio}</h5>
          </Card>
        </Col>
        <Col span={6}>
          <Card className="stat_card">
            <p>Total Retention</p>
            <h5>{subscriptionData?.totalretaintaion}</h5>
          </Card>
        </Col>
        <Col span={6}>
          <Card className="stat_card">
            <p>Total Attrition</p>
            <h5>{subscriptionData?.totalattrition}</h5>
          </Card>
        </Col>
      </Row>

      <Row gutter={16} style={{ marginTop: "1rem" }}>
        <Col span={6}>
          <Card className="stat_card">
            <p>User Referred</p>
            <h5>{referralData?.userReferred}</h5>
          </Card>
        </Col>
        <Col span={6}>
          <Card className="stat_card">
            <p>Ref.Join</p>
            <h5>{referralData?.refferalJoining}</h5>
          </Card>
        </Col>
        <Col span={6}>
          <Card className="stat_card">
            <p>Ref.Subscribe</p>
            <h5>{referralData?.refferalSubscription}</h5>
          </Card>
        </Col>
        <Col span={6}>
          <Card className="stat_card">
            <p>Ref.Demo</p>
            <h5>{referralData?.refferalDemo}</h5>
          </Card>
        </Col>
      </Row>



      {/* chart */}
      <Card
        title="No of Subscription"
        extra={
          <RangePicker onChange={datePicker}
            // defaultValue={[moment(firstday, dateFormat), moment(lastday, dateFormat)]}
            format={dateFormat} />}
        style={{ marginTop: "2rem" }}
      >
        {loding ? (
          <div className="spin-center">
            <Spin size="large" />
          </div>
        ) : (
          <AreaChartGraph />
        )}
      </Card>

      <Row gutter={16}>
        <Col span={12}>
          <Card
            title="Popular week day"
            style={{ marginTop: "0.5rem" }}
          >
            {loding ? (
              <div className="spin-center">
                <Spin size="large" />
              </div>
            ) : (
              <BarChartComponent />
            )}
          </Card>
        </Col>
        <Col span={12}>
          <Card
            title="Teacher time slot"
            extra={
              <>
                <Dropdown overlay={menu}>
                  <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                    {weekDay ? weekDay : new Date().toLocaleString('en-us', { weekday: 'long' })} <DownOutlined />
                  </a>
                </Dropdown>
                <Button type="link">
                  <Link to="/time-slots-details">View details</Link>
                </Button>
              </>
            }
            style={{ marginTop: "0.5rem" }}
          >
            {!timeSlotStats ? (
              <div className="spin-center">
                <Spin size="large" />
              </div>
            ) : (
              <PieChartComponent />
            )
            }

          </Card>
        </Col>
        <Col span={12}>
          <Card
            title="Demo Teacher time slot"
            extra={
              <>
                <Dropdown overlay={menu}>
                  <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                    {weekDay ? weekDay : new Date().toLocaleString('en-us', { weekday: 'long' })} <DownOutlined />
                  </a>
                </Dropdown>
                <Button type="link">
                  <Link to="/time-slots-details">View details</Link>
                </Button>
              </>
            }
            style={{ marginTop: "0.5rem" }}
          >
            {!timeSlotStats ? (
              <div className="spin-center">
                <Spin size="large" />
              </div>
            ) : (
              <DemoPieChartComponent />
            )
            }

          </Card>
        </Col>
        <Col span={12}>
          <Card
            title="Regular Teacher time slot"
            extra={
              <>
                <Dropdown overlay={menu}>
                  <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                    {weekDay ? weekDay : new Date().toLocaleString('en-us', { weekday: 'long' })} <DownOutlined />
                  </a>
                </Dropdown>
                <Button type="link">
                  <Link to="/time-slots-details">View details</Link>
                </Button>
              </>
            }
            style={{ marginTop: "0.5rem" }}
          >
            {!timeSlotStats ? (
              <div className="spin-center">
                <Spin size="large" />
              </div>
            ) : (
              <RegularPieChartComponent />
            )
            }

          </Card>
        </Col>
      </Row>

      <PopularCity />
    </div>
  );
};

export default Home;
