import api from "../apis/CoursesApi";
import * as constant from "../constants/Constant";

export const AddEditCourse = (data,onSuccess,onError) => (dispatch) => {
  api
    .Courses().addEditCourse(data)
    .then((res) => {
      console.log(res.data)
        onSuccess(res.data.message)
    })
    .catch((err) => {
      console.log(err.response);
      onError(err.response.data.message)
    });
};

export const GetAllCourse = (page,limit,onSuccess,onError) => (dispatch) => {
  api
    .Courses().getAllCourse(page,limit)
    .then((res) => {
      //  console.log(res.data)
        onSuccess(res.data.allCourses)
    })
    .catch((err) => {
      console.log(err.response);
      onError(err.response.data.message)
    });
};

export const GetACourse = (data,onSuccess,onError) => (dispatch) => {
  api
    .Courses().getACourse(data)
    .then((res) => {
      //  console.log(res.data)
        onSuccess(res.data)
    })
    .catch((err) => {
      console.log(err.response);
      onError(err.response.data.message)
    });
};

export const DeleteACourse = (data, onSuccess, onError) => {
  console.log(data)
  api
    .Courses()
    .deleteCourse(data)
    .then((res) => {
      onSuccess(res.data.message);
      console.log(res.data);
    })
    .catch((err) => {
      console.log(err.response.data.message);
      onError(err.response.data.message);
    });
};
export const GetSubscriptionBundle = (onSuccess, onError) => (dispatch) => {
  api
    .Courses().GetSubscriptionBundle()
    .then((res) => {
      //  console.log(res.data)
        onSuccess(res.data)
    })
    .catch((err) => {
      console.log(err.response);
      onError(err.response.data.message)
    });
}

export const AddToSubscriptionBundle = (data, onSuccess, onError) => (dispatch) => {
  api
  .Courses().addToSubscriptionBundle(data)
  .then((res) => {
    //  console.log(res.data)
      onSuccess(res.data.message)
  })
  .catch((err) => {
    console.log(err.response);
    onError(err.response.data.message)
  });
}

export const RemoveFromSubscriptionBundle= (data,onSuccess,onError) => (dispatch) => {
  api
    .Courses().removeFromSubscriptionBundle(data)
    .then((res) => {
      //  console.log(res.data)
        onSuccess(res.data.message)
    })
    .catch((err) => {
      console.log(err.response);
      onError(err.response.data.message)
    });
};


