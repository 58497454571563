import React, { useState, useEffect } from "react";
import { convertUTC } from "../../helper/TimeDate";
import { Table, Spin, Card, Button, Row, Col, Modal } from "antd";
import { useDispatch } from "react-redux";
import PageBreadcrumb from "../../component/PageBreadcrumb";
import SetDemoPool from "../../component/adminComponent/SetDemoPool";
import { GetDemoPool, GetDemoPoolDetails } from "../../redux/actions/DemoPoolAction";
import moment from "moment-timezone"; // Import moment-timezone

const DemoPool = () => {
  const dispatch = useDispatch();
  const [updateList, setUpdateList] = useState(false);
  const [loader, setLoader] = useState(false);
  const [poolRes, setPoolRes] = useState([]);
  const [poolDetails, setPoolDetails] = useState();
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [modalLoading, setModalLoading] = useState(false);

  useEffect(() => {
    getDemoPools();
  }, [updateList]);

  const callback = (got) => {
    setUpdateList(got);
  };

  const getDemoPools = () => {
    setLoader(true);

    const onSuccess = async (res) => {
      console.log("demo pool res", res);
      setPoolRes(res.data?.poolRes);
      setLoader(false);
      setUpdateList(false);
    };

    const onError = (errmsg) => {
      setLoader(false);
      setUpdateList(false);
      console.log(errmsg);
    };

    dispatch(GetDemoPool(onSuccess, onError));
  };

  const getPoolDetails = (poolid) => {
    console.log("poolid", poolid);
    setModalLoading(true);

    const onSuccess = (res) => {
      setPoolDetails(res.data.demoPoolStatsData);
      console.log("demo pool res", res);
      setModalLoading(false);
    };

    const onError = (errmsg) => {
      console.log(errmsg);
      setModalLoading(false);
    };

    dispatch(GetDemoPoolDetails(onSuccess, onError, poolid));
  };

  const handleMainTableExpand = (expanded, record) => {
    if (expanded) {
      setExpandedRowKeys([record.key]);
    } else {
      setExpandedRowKeys([]);
    }
  };

  const openModal = (data) => {
    setModalData(data);
    getPoolDetails(data._id);
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
    setModalData(null);
    setPoolDetails(null);
  };

  const mainTableColumns = [
    {
      title: "Pool Date",
      dataIndex: "poolDate",
      key: "poolDate",
    },
    {
      title: "Actions",
      dataIndex: "",
      key: "actions",
      render: (text, record) => {

        // Extracting start time, end time, and reserved slot from all items in demoPools array
        const demoPoolData = record.demoPools.map(pool => ({
          startTime: pool.startTime,
          endTime: pool.endTime,
          reservedSlot: pool.reservedSlot,
          demoConversionFilter: pool.demoConversionFilter,          
          allowFilter: pool.allowFilter          

        }));
        console.log("record and demopool", record, demoPoolData)
        return (
          <Col span={4}>
            <SetDemoPool
              callback={callback}
              poolDate={record.poolDate}
              demoPoolData={demoPoolData} // Pass demo pool data array
            />
          </Col>
        );
      },
    },
  ];





  const expandedRowRender = (record) => {
    const innerTableColumns = [
      {
        title: "Start Time (IST)",
        dataIndex: "startTime",
        key: "startTime",
        render: (time) => moment(time).format("HH:mm") // Display only time without date
      },
      {
        title: "End Time (IST)",
        dataIndex: "endTime",
        key: "endTime",
        render: (time) => moment(time).format("HH:mm") // Display only time without date
      },
      {
        title: "Reserved Slot",
        dataIndex: "reservedSlot",
        key: "reservedSlot",
      },
      {
        title: "Conversion Filter",
        dataIndex: "demoConversionFilter",
        key: "demoConversionFilter",
      },
      {
        title: "Allow Conversion Filter",
        dataIndex: "allowFilter",
        key: "allowFilter",
        render: (text) => (text ? "True" : "False"),
      },
      {
        title: "Action",
        key: "action",
        render: (text, record) => (
          <Button type="primary" onClick={() => openModal(record)}>
            View Details
          </Button>
        ),
      },
    ];

    return (
      <Table
        dataSource={record.demoPools}
        columns={innerTableColumns}
        pagination={false}
      />
    );
  };

  const mainTableData = poolRes?.map((pool) => ({
    key: pool.poolDate,



    poolDate: moment.utc(pool.poolDate).tz("Asia/Kolkata").format("YYYY-MM-DD"), // Convert poolDate from UTC to IST



    demoPools: pool.demoPools.map((demo) => ({
      ...demo,
      // Convert UTC to IST for Start Time and End Time
      startTime: moment.utc(demo.startTime).tz("Asia/Kolkata").format("YYYY-MM-DD HH:mm"),
      endTime: moment.utc(demo.endTime).tz("Asia/Kolkata").format("YYYY-MM-DD HH:mm"),
    })),
  }));
  



  return (
    <div>
      <PageBreadcrumb route={["dashboard", "DemoPool"]} />
      <Card style={{ marginBottom: "1rem" }}>
        <Row justify="space-between">
          <Col span={4}>
            <SetDemoPool callback={callback} />
          </Col>

        </Row>
      </Card>
      {loader ? (
        <div className="spin-center">
          <Spin size="large" />
        </div>
      ) : (
        <div>
          <Table
            dataSource={mainTableData}
            columns={mainTableColumns}
            expandable={{
              expandedRowKeys,
              onExpand: handleMainTableExpand,
              expandedRowRender,
            }}
          />
          <Modal
            title="Details"
            visible={modalVisible}
            onCancel={closeModal}
            footer={[
              <Button key="close" onClick={closeModal}>
                Close
              </Button>,
            ]}
            bodyStyle={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}
          >
            {modalLoading ? (
              <Spin />
            ) : (
              <div>
                {poolDetails && (
                  <div>
                    <p><strong>Total Demo Eligible Slots:</strong> {poolDetails.totalDemoEligibleSlots}</p>
                    <p><strong>Total Demo Eligible Slots Left:</strong> {poolDetails.totalDemoEligibleSlotsLeft}</p>
                    <p><strong>Total Unbooked Slots:</strong> {poolDetails.totalUnbookedSlots}</p>
                    <p><strong>Total Slots Booked:</strong> {poolDetails.totalSlotsBooked}</p>
                    <p><strong>Reserved Slots Count:</strong> {poolDetails.reservedSlotsCount}</p>
                    <p><strong>Worst Converting Tutor:</strong> {poolDetails.worstConvertingTutor}</p>
                    <p><strong>Next 5 Slots Worst Conversion:</strong> {poolDetails.next5SlotsWorstConversion}</p>
                    <p><strong>Next 10 Slots Worst Conversion:</strong> {poolDetails.next10SlotsWorstConversion}</p>
                    <h4><strong>Tutors:</strong></h4>
                    <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                      <thead style={{ backgroundColor: '#f0f0f0' }}>
                        <tr>
                          <th style={{ padding: '8px', border: '1px solid #ddd' }}>Name</th>
                          <th style={{ padding: '8px', border: '1px solid #ddd' }}>Demo Conversion</th>
                          <th style={{ padding: '8px', border: '1px solid #ddd' }}>Available Slots</th>
                          <th style={{ padding: '8px', border: '1px solid #ddd' }}>Current Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {poolDetails.tutors.map((tutor, index) => (
                          <tr key={index}>
                            <td style={{ padding: '8px', border: '1px solid #ddd' }}>{tutor.name}</td>
                            <td style={{ padding: '8px', border: '1px solid #ddd' }}>{tutor.demoConversion}</td>
                            <td style={{ padding: '8px', border: '1px solid #ddd' }}>{tutor.availableSlots}</td>
                            <td style={{ padding: '8px', border: '1px solid #ddd' }}>{tutor.currentStatus}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            )}
          </Modal>

        </div>
      )}
    </div>
  );
};

export default DemoPool;
