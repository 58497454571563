import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import PageBreadcrumb from "../../component/PageBreadcrumb";
import { learnerProfile } from "../../redux/actions/UserAction";
import LearnerDetails from "./LearnerDetails";

const LearnerProfile = () => {
  const dispatch = useDispatch();
  let { id } = useParams();
  const [learner,setLearner] = useState(null)

  useEffect(() => {
    
    const data = {
      learnerId:id,
    }

    const onSuccess = (res) =>{
      console.log(res)
      setLearner(res)
    }

    const onError = (err) =>{
      console.log(err)
    }

    dispatch(learnerProfile(data,onSuccess,onError))
  }, []);
  
  return (
    <div>
      <PageBreadcrumb route={["dashboard", "Learner", "Profile"]} />
      <LearnerDetails learner={learner?.learner}/>
    </div>
  );
};

export default LearnerProfile;
