// Curriculum
export const CURRICULUM_CREATED = "CURRICULUM_CREATED"
export const LOAD_CURRICULUM = "LOAD_CURRICULUM"

// Module
export const MODULE_CREATED = "MODULE_CREATED"

// Chapter
export const CHAPTER_CREATED = "CHAPTER_CREATED"

// Topic
export const TOPIC_CREATED = "TOPIC_CREATED"

// Assessment
export const ASSESSMENT_CREATED = "ASSESSMENT_CREATED";