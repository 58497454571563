import React, { useState, useEffect } from "react";
import { Table, Tag, Spin, message ,
  Button,
  Space,
  Row,
  Col,
  Card, DatePicker, Select
} from "antd";
import { useDispatch } from "react-redux";
import PageBreadcrumb from "../../component/PageBreadcrumb";
import { SubscriptionsList } from "../../redux/actions/AuthAction";
import moment from "moment-timezone";
import {UTCtoLocalConverter} from "../../helper/TimeDate"
const { RangePicker } = DatePicker;

const SubscriptionList = () => {
  const dispatch = useDispatch();
  const [activePage, setActivePage] = useState(1);
  const [loader, setLoader] = useState(false);
  const [subscriptionList, setSubscriptionList] = useState([]);
  const dateFormat = "YYYY-MM-DD";
  const [startDate, setStartDate] = useState(moment().subtract(1, "days").format(dateFormat));
  const [endDate, setEndDate] = useState(moment().format(dateFormat));
  const [subscriptionType, setSubscriptionType] = useState("Non-Curriculum");
  const [subject, setSubject] = useState("English")

  const datePicker = (date, dateString) => {
    setStartDate(dateString[0]);
    setEndDate(dateString[1]);
  };

  useEffect(() => {
    getList(1, true);
  }, []);

  const search= () =>{
    setLoader(true)
    var data = {
      startDate: startDate,
      endDate: endDate,
      page : 1,
      limit: 50,
    }

    if(subscriptionType === "Curriculum") {
      data.isCurriculum = true
    }

    if(subscriptionType === "Non-Curriculum") {
      data.nonCurriculum = true
    }

    if(subscriptionType === "Peer Subscription") {
      data.isPeerSubscription = true
    }

    if(subject === "English"){
      data.subject = "English"
    }

    if(subject === "IELTS"){
      data.subject = "IELTS"
    }


    var onSuccess = (data) =>{
      console.log(data.getsubcriptionDetails)
      setSubscriptionList(data.getsubcriptionDetails)
      setLoader(false)
    }
    var onError = (error) =>{
      console.log("SEARCH FAILURE")
      message.success(error, 8);
      
      setLoader(false)
    }
    dispatch(SubscriptionsList(data,onSuccess,onError))
  }

  const columns = [
    { title: "No", width: 30, dataIndex: "no", key: "1" },
    { title: "Name", width: 80, dataIndex: "name", key: "2"},
    { title: "Phone No", width: 50, dataIndex: "phoneNumber", key: "3" },
    { title: "Email", width: 10, dataIndex: "email", key: "4" },
     subscriptionType === "Non-Curriculum" || subscriptionType === "Curriculum" ? {
     title: "Booked Sessions", width: 50, dataIndex: "bookedSessions", key: "5" }: null,
    subscriptionType === "Peer Subscription" ? {
       title: "Completed Minutes", width: 50, dataIndex: "bookedMinutes", key: "5" ,
    }: null,
    { title: "Plan Name", width: 50, dataIndex: "planName", key: "6" },
    { title: "Start Date", width: 50, dataIndex: "startDate", key: "7" },
    { title: "End Date", width: 50, dataIndex: "endDate", key: "8" },
    {
      title: "Subscribed",
      width: 10,
      dataIndex: "subscribed",
      key: "9",
      render: (tags) => (
        <>
          {tags.map((tag) => {
            let color = tag == "yes" ? "geekblue" : "volcano";
            if (tag === "loser") {
              color = "volcano";
            }
            return (
              <Tag color={color} key={tag}>
                {tag.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: "Kids",
      width: 10,
      dataIndex: "kids",
      key: "10",
      render: (tags) => (
        <>
          {tags.map((tag) => {
            let color = tag == "yes" ? "geekblue" : "volcano";
            if (tag === "loser") {
              color = "volcano";
            }
            return (
              <Tag color={color} key={tag}>
                {tag.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
    },
  ].filter(Boolean);

  const data = subscriptionList?.map((data, i) => ({
    key: data._id,
    no: i + 1,
    name: data?.learnerData[0]?.name ? data?.learnerData[0]?.name:"N/A",
    phoneNumber: data?.learnerData[0]?.phoneNumber ? data?.learnerData[0]?.phoneNumber : "N/A",
    email:data?.learnerData[0]?.email ? data?.learnerData[0]?.email: "N/A",
    bookedSessions: data?.totalSession && data?.availableSession
    ? data?.totalSession - data?.availableSession
    : "N/A",
    bookedMinutes: data?.totalMinutes && data?.availableMinutes
    ? data?.totalMinutes - data?.availableMinutes
    : "N/A",
    planName : data?.planData[0]?.courseName ? data?.planData[0]?.courseName : "N/A",
    startDate :  UTCtoLocalConverter(data?.startDate),
    endDate:  UTCtoLocalConverter(data?.endDate),
    subscribed: data?.learnerData[0]?.isLearnerSubscribed ? ["yes"] : ["No"],
    kids: data.isKid ? ["yes"] : ["No"],
  }));

  const onChangePagination = (page, pageSize) => {
    if (page.current > activePage) {
      getList(page.current);
      setActivePage(page.current);
    }
  };

  const getList = () => {
    setLoader(true);
    console.log("start",startDate)
    console.log("end",endDate)
    var payload = {
      startDate: startDate,
      endDate: endDate,
      page : 1,
      limit: 50,
      subject: "English",
      nonCurriculum : true
    }

    var onSuccess = (data) => {
     setSubscriptionList(data.getsubcriptionDetails)
     setLoader(false)
    };
    var onError = (error) => {
      console.log(error);
      message.success(error, 8);
      setLoader(false);
    };
    dispatch(SubscriptionsList(payload,onSuccess, onError))
  };

  return (
    <div>
      <PageBreadcrumb route={["dashboard", "Learners"]} />
      <Card  style={{ marginBottom: "1rem" }}>
    <Row>
    <Col span={28}>
              <Space>
                <RangePicker
                  onChange={datePicker}
                  defaultValue={[moment(startDate, dateFormat), moment(endDate, dateFormat)]}
                  format={dateFormat}
                />
              <label style={{ marginLeft:"2rem"}}>Subscription Filter</label>
              <Select
              defaultValue="Non-Curriculum"
              style={{ width: 120 }}
              onChange={(value) => setSubscriptionType(value)}
            >
              <option value="Curriculum">Curriculum</option>
              <option value="Non-Curriculum">Non-Curriculum</option>
              <option value="Peer Subscription">Peer Subscription</option>
            </Select>

            <label style={{ marginLeft:"2rem"}}>Subject</label>
            <Select
              defaultValue="English"
              style={{ width: 120 }}
              onChange={(value) => setSubject(value)}
            >
              <option value="English">English</option>
              <option value="IELTS">IELTS</option>
            </Select>
            <Button type="primary" onClick={() => search()}>
                            Search
              </Button>
              </Space>
              </Col>
              </Row>
              </Card>

      {loader ? (
        <div className="spin-center">
          <Spin size="large" />
        </div>
      ) : (
        <Table
          columns={columns}
          dataSource={data}
          scroll={{ x: 1300 }}
          onChange={onChangePagination}
          pagination={{
            pageSize: 80,
            defaultCurrent: activePage,
          }}
        />
      )}
    </div>
  );
};

export default SubscriptionList;
