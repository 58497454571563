import { Table, Card, Spin,Tag,  Row,
  Button,Radio,Select, message } from "antd";
import React,{useState} from "react";
import { useSelector,useDispatch } from "react-redux";
import PageBreadcrumb from "../../component/PageBreadcrumb";
import { UTCToAsiaTimeConverter } from "../../helper/helper";
import {TeacherTimeslotsStats} from "../../redux/actions/StatisticsAction"

const { Option } = Select;
var weekList = ["Sunday", "Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"]

var tagGroup = (teacherList) =>{

  var updatedTeacherList = teacherList.map(val=>{
    var tags = [];
    if(val.isDemo){
      tags.push("Regular demo")
    }
    if(val.isSession){
      tags.push("Regular session")
    }
    if(val.isKidDemo){
      tags.push("Kids demo")
    }
    if(val.isKidSession){
      tags.push("Kids session")
    }
    if(!val.email){
      val["email"] = "N/A"
    }
    val["tags"] = tags
    return val;
  })
  return updatedTeacherList;
}

const TimeSlotsGraphDetails = () => {

  const dispatch = useDispatch();
  const [subject, setSubject] = useState("English");
  const [type, setType] = useState("Regular");
  const [loader, setLoader] = useState(false);
  const [day, setDay] = useState(new Date().toLocaleString('en-us', {  weekday: 'long' }));

  const stats = useSelector((state) => state.AdminReducer.timeSlotStats);

  const data = stats?.teachers.map((data, i) => {
    if(!data.isDisabled){
      // console.log(data);
      return{
        time: UTCToAsiaTimeConverter(data.startTime).split(":")[0] + " - " +  UTCToAsiaTimeConverter(data.endTime + ":30").split(":")[0],
        teachers: tagGroup(data.teachers),
      }
    }
  }).sort((a, b) => (a.time > b.time) - (a.time < b.time))


  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Phone number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: 'Tags',
      key: 'tags',
      dataIndex: 'tags',
      render: tags => (
        <>
          {tags.map(tag => {
            let color = tag.length > 5 ? 'geekblue' : 'green';
            if (tag === 'loser') {
              color = 'volcano';
            }
            return (
              <Tag color={color} key={tag}>
                {tag.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
    }
  ];

  const getTeacherTimings = () =>{
    setLoader(true)
    var onSuccess = (msg) => {
      setLoader(false)
      message.success(msg);
    }

    var onError = (err) => {
      setLoader(false)
      message.error(err)
    }

    dispatch(TeacherTimeslotsStats({
      day: day,
      time: "00:00",
      forKids:(type === "Regular")?false:true,
      subject:subject
    },onSuccess,onError))
  }


  return (
    <div>
      <PageBreadcrumb route={["dashboard", "Teachers time-slots details"]} />

      <Card title="Filter Timing" style={{ marginBottom: "1rem" }}>
        <Row>
       
            <Radio.Group value={type} onChange={(e)=>setType(e.target.value)}>
              <Radio value={"Kids"}>Kids</Radio>
              <Radio value={"Regular"}>Regular</Radio>
            </Radio.Group>
        
          <Select defaultValue={subject} style={{ width: 120 }} onChange={setSubject}>
              <Option value="English">English</Option>
              <Option value="IELTS">IELTS</Option>
          </Select>
          <Select defaultValue={day} style={{ width: 120 }} onChange={setDay}>
              {weekList.map((val)=>{
                 return <Option value={val}>{val}</Option>
              })}
          </Select>

          <Button type="primary" loading={loader} onClick={() => getTeacherTimings()}>
            Search
          </Button>

          </Row>
          </Card>

      {!stats ? (
        <div className="spin-center">
          <Spin size="large" />
        </div>
      ) : (
        data.map((val) => {
          return (
            <Card 
            title={val.time}
            style={{border: 0,margin:0}}
            headStyle={{backgroundColor: '#fff2e8', border: 0 }}
            bodyStyle={{backgroundColor: '#ffff', border: 0 }}
            >
              <Table
                style={{margin:0,padding:'1px'}}
                columns={columns}
                dataSource={val.teachers}
                pagination={false}
              />
            </Card>
          );
        })
      )}
    </div>
  );
};

export default TimeSlotsGraphDetails;
