import React, { useEffect, useState } from "react";
import PageBreadcrumb from "../../component/PageBreadcrumb";
import { Button, Modal, Radio, Space, Spin, Table, Tag, message } from "antd";
import {
  changeTechIssueStatus,
    getAllTechIssues,
  getTeacherApplications,
  updateTeacherApplicationStatus,
} from "../../redux/actions/TeacherAction";
import { GetMeetingRecording } from "../../redux/actions/SessionAction";
import { useDispatch } from "react-redux";

const ChangeStatus = ({
  currentStatus,
  sessionId,
  GetAllTechIssues,
  resolvedCommentByAdmin
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [updatedStatus, setUpdatedStatus] = useState(currentStatus);
  const [changeStatusLoader, setChangeStatusLoader] = useState(false);
  const [comment, setComment] = useState(resolvedCommentByAdmin);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const hideModal = () => {
    setIsModalVisible(false);
  };

  const updateApplicationStatus = () => {
    setChangeStatusLoader(true);
    var payload = {
      sessionId,
      status: updatedStatus,
    };
    if(comment?.trim()) payload["resolvedComment"] = comment;

    const onSuccess = (msg) => {
      message.success(msg, 8);
      setChangeStatusLoader(false);
      setIsModalVisible(false);
      GetAllTechIssues();
    };

    const onError = (err) => {
      message.error(err, 8);
      setChangeStatusLoader(false);
    };
    changeTechIssueStatus(payload, onSuccess, onError);
  };

  return (
    <>
      <Button
        type="primary"
        onClick={showModal}
        disabled={currentStatus === "Approved"}
      >
        Edit
      </Button>
      <Modal
        title="Change status"
        visible={isModalVisible}
        onCancel={hideModal}
        footer={[]}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <label>Status :</label>
          <select
            onChange={(e) => {setUpdatedStatus(e.target.value); setComment("");}}
            style={{ width: "200px" }}
            value={updatedStatus}
          >
            <option value="Requested">Requested</option>
            <option value="InReview">In Review</option>
            <option value="Approved">Approved</option>
            <option value="Declined">Declined</option>
          </select>
        </div>
        <br/>
        {updatedStatus==="Declined" ? <div>
            <label>Comment :</label>
            <textarea
              style={{ width: "100%" }}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            ></textarea>
        </div> : null}
        <Button
          type="primary"
          style={{ marginTop: "6px" }}
          onClick={() => updateApplicationStatus()}
        >
          {changeStatusLoader ? "Loading..." : "Update"}
        </Button>
      </Modal>
    </>
  );
};

const TechIssues = () => {
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("Requested");
  const [techIssues, setTechIssues] = useState([]);
  const [recordingLoader, setRecordingLoader] = useState(false);
  const [count, setCount] = useState(0);
  const dispatch = useDispatch();

  const GetAllTechIssues = () => {
    setLoading(true);
    const onSuccess = (data) => {
    //   const applications = addKeyToData(data);
      setLoading(false);
      const techIssuesData = data.getAllTeacherTeachIssueDetails?.map((issue, i ) => ({
        name: issue?.teacherDetails[0]?.name,
        email: issue?.teacherDetails[0]?.email,
        phone: issue?.teacherDetails[0]?.phoneNumber,
        subject: issue?.subject,
        techIssuefeedBack: issue?.teachIssuefeedBack?.issueType === "other" ? issue?.teachIssuefeedBack?.otherTypeOfIssue : issue?.teachIssuefeedBack?.issueType,
        status: issue?.teachIssuefeedBack?.status,
        resolvedCommentByAdmin: issue?.teachIssuefeedBack?.resolvedComment || "",
        reportTime: issue?.createdAt,
        meetingNo: issue?.sessionsDetails[0]?.meetingNo,
        _id: issue?.sessionsDetails[0]?._id,
        sno: i+1
      }))
      console.log("onsuccess", techIssuesData)
      setTechIssues(techIssuesData);
      setCount(data.totalCount);
    };

    const onError = (msg) => {
      setLoading(false);
      message.error(msg);
    };
    const data = {
        status: status,
        page: 1,
        limit: 10
    }
    getAllTechIssues(data, onSuccess, onError);
  };

  useEffect(() => {
    if(status)
    GetAllTechIssues();
  }, [status]);

  const recording = (id) => {
    setRecordingLoader(id);
    const data = {
      meetingId: id,
    };
    const onSuccess = (data) => {
      setRecordingLoader(false);
      if(!data?.uuid){
        const newWindow = window.open('', '_blank');
      
        const videoHtml = `
          <html>
            <head>
              <title>Recording</title>
            </head>
            <body style="margin:0">
              <video controls autoplay style="width:100vw; height:100vh;">
                <source src="${data.recording_files[0].play_url}" type="video/mp4">
                Your browser does not support the video tag.
              </video>
            </body>
          </html>
        `;
    
        newWindow.document.write(videoHtml);
        newWindow.document.close();
        }else{
          window.open(data.recording_files[0].play_url, '_blank');
          }
    };
    const onError = (err) => {
      setRecordingLoader(false);
      message.error(err, 8);
    };
    dispatch(GetMeetingRecording(data, onSuccess, onError));
    
  };

  const addKeyToData = (data) => {
    return data.map((item, index) => ({
      ...item,
      key: String(index + 1),
    }));
  };

  function formatTimestampToDDMMYY(timestamp) {
    var dateObject = new Date(timestamp);
    var options = {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    };
    var formattedDate = dateObject.toLocaleDateString("en-GB", options);
    return formattedDate;
  }
  const tagColour = {
    Declined: "red",
    InReview: "blue",
    Requested: "grey",
    Approved: "green",
    // completed: "green",
  };

  const columns = [
    {
      title: "SNo.",
      dataIndex: "sno",
      width: 100,
    },
    {
      title: "Name",
      dataIndex: "name",
      width: 150,
      sorter: (a, b) => a.name - b.name,
    },
    {
      title: "Email",
      dataIndex: "email",
      width: 250
    },
    {
      title: "Phone Number",
      dataIndex: "phone",
      width: 130
    },
    {
      title: "Subject",
      dataIndex: "subject",
      width: 100
    },
    {
      title: "Issue",
      dataIndex: "techIssuefeedBack",
      width: 300,
    },
    {
      title: "Reported on",
      dataIndex: "reportTime",
      sorter: (a, b) => new Date(a.reportTime) - new Date(b.reportTime),
      render: (text) => <>{formatTimestampToDDMMYY(text)}</>,
      width: 150
    },
    {
      title: "Recording",
      key: "meetingNo",
      fixed: "right",
      width: 60,
      render: ({ meetingNo }) => (
        <a href="#" onClick={()=>recording(meetingNo)}>
          {recordingLoader === meetingNo?"Fetching...":"Recording"}
        </a>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      fixed: "right",
      width: 60,
      render: (status) => <Tag color={tagColour[status]}>{status}</Tag>,
    },
    {
      title: "Action",
      dataIndex: "_id",
      fixed: "right",
      width: 50,
      render: (_id, {resolvedCommentByAdmin}) => (
        <ChangeStatus
          currentStatus={status}
          sessionId={_id}
          GetAllTechIssues={GetAllTechIssues}
          resolvedCommentByAdmin={resolvedCommentByAdmin}
        />
      ),
    },
  ];
  return (
    <>
      <PageBreadcrumb route={["dashboard", "Teacher", "Tech Issues"]} />
      <Space style={{ marginBottom: 24 }}>
        <Radio.Group value={status} defaultValue="Requested" onChange={(e) => setStatus(e.target.value)}>
          <Radio.Button value="Requested">Requested</Radio.Button>
          <Radio.Button value="InReview">In Review</Radio.Button>
          <Radio.Button value="Approved">Approved</Radio.Button>
          <Radio.Button value="Declined">Declined</Radio.Button>
        </Radio.Group>
      </Space>
      {loading ? (
        <div className="spin-center">
          <Spin size="large" />
        </div>
      ) : (
        <Table
          columns={columns}
          dataSource={techIssues}
          scroll={{ x: 2600 }}
          pagination={{
            defaultPageSize: 10,
            pageSize: 10,
            total: count
          }}
          
        />
      )}
    </>
  );
};

export default TechIssues;
