
export const getDiscount = (planprice, plandiscount) => {
  var price = Number(planprice);
  var discount = Number(plandiscount);
  var sale = Math.ceil(price-price * discount/100);
  //Math.ceil(price - price * discount);
  return sale;
};

const TimingSlot = (val, num) => {
  const time = val.split(":");
  const h = parseInt(time[0]);
  const m = parseInt(time[1]) + num;
  if(m === 60){
    return parseInt(h)+1+":"+"00"
  }else{
  return `${h}:${m}`;
  }
};

var makeTimeIntervals = function (startTime, endTime, increment) {
  startTime = startTime.toString().split(':');
  endTime = endTime.toString().split(':');
  increment = parseInt(increment, 10);

  var pad = function (n) { return (n < 10) ? '0' + n.toString() : n; },
      startHr = parseInt(startTime[0], 10),
      startMin = parseInt(startTime[1], 10),
      endHr = parseInt(endTime[0], 10),
      endMin = parseInt(endTime[1], 10),
      currentHr = startHr,
      currentMin = startMin,
      previous = currentHr + ':' + pad(currentMin),
      current = '',
      r = [];

  if(currentHr > endHr ){
    return r;
  }

  do {
      currentMin += increment;
      if ((currentMin % 60) === 0 || currentMin > 60) {
          currentMin = (currentMin === 60) ? 0 : currentMin - 60;
          currentHr += 1;
      }
      current = currentHr + ':' + pad(currentMin);
      r.push(previous);
      previous = current;
} while (currentHr !== endHr);

  return r;
};

export const TimeSpliter = (time1,time2, duration) => {

  var splited1 = time2.split(":");

 const endtime = parseInt(splited1[0])+1+":"+splited1[1]

  var arr2 = makeTimeIntervals(time1,endtime,duration)

  var userTime = []

  if(arr2.length <= 0){
    return userTime;
  }

  if (duration === 15) {
    userTime.push({
      timeView: `${arr2[0]} - ${arr2[1]}`,
      start: `${arr2[0]}`,
      end: `${arr2[1]}`,
    });
    userTime.push({
      timeView: `${TimingSlot(arr2[1], 5)} - ${TimingSlot(arr2[2], 5)}`,
      start: `${TimingSlot(arr2[1], 5)}`,
      end: `${TimingSlot(arr2[2], 5)}`,
    });
    userTime.push({
      timeView: `${TimingSlot(arr2[2], 10)} - ${TimingSlot(arr2[3], 10)}`,
      start: `${TimingSlot(arr2[2], 10)}`,
      end: `${TimingSlot(arr2[3], 10)}`,
    });
  } else if (duration === 25) {
    userTime.push({
      timeView: `${arr2[0]} - ${arr2[1]}`,
      start: `${arr2[0]}`,
      end: `${arr2[1]}`,
    });
    userTime.push({
      timeView: `${TimingSlot(arr2[1], 5)} - ${TimingSlot(arr2[2], 5)}`,
      start: `${TimingSlot(arr2[1], 5)}`,
      end: `${TimingSlot(arr2[2], 5)}`,
    });
  }
  return userTime;
};



// const TimingSlot = (val, num) => {
//   const time = val.split(":");
//   const h = parseInt(time[0]);
//   const m = parseInt(time[1]) + num;
//   return `${h}:${m}`;
// };

const ValidateSplitter = (slot) =>{
const timeArr = slot.split(":")
const hr = parseInt(timeArr[0])+1
const timeArr2 = timeArr[1].split("")
const min = timeArr2[1]
  return hr+":"+"0"+min
}

// export const TimeSpliter = (start,end, duration) => {

//  var startArr = start.split(":")
//  var startTime ;
//  var userTime = [];
// if(duration === 15){
//   userTime.push({
//     timeView: `${start} - ${startArr[0]+":"+(parseInt(startArr[1])+duration)}`,
//     start: `${start}`,
//     end: `${startArr[0]+":"+(parseInt(startArr[1])+duration)}`,
//   });

//   startTime = startArr[0]+":"+(parseInt(startArr[1])+duration)
//   const startArr2 = startTime.split(":")
//   userTime.push({
//     timeView: `${TimingSlot(startTime,5)} - ${ValidateSplitter(TimingSlot(startArr2[0]+":"+(parseInt(startArr2[1])+duration),5))}`,
//     start: `${TimingSlot(startTime,5)}`,
//     end: `${ValidateSplitter(TimingSlot(startArr2[0]+":"+(parseInt(startArr2[1])+duration),5))}`,
//   });
//   startTime = ValidateSplitter(TimingSlot(startArr2[0]+":"+(parseInt(startArr2[1])+duration),5))
//   const startArr3 = startTime.split(":")
//   userTime.push({
//     timeView: `${TimingSlot(startTime,5)} - ${TimingSlot(startArr3[0]+":"+(parseInt(startArr3[1])+duration),5)}`,
//     start: `${TimingSlot(startTime,5)}`,
//     end: `${TimingSlot(startArr3[0]+":"+(parseInt(startArr3[1])+duration),5)}`,
//   });
// }else{
//   userTime.push({
//     timeView: `${start} - ${startArr[0]+":"+(parseInt(startArr[1])+duration)}`,
//     start: `${start}`,
//     end: `${startArr[0]+":"+(parseInt(startArr[1])+duration)}`,
//   });
//   startTime = ValidateSplitter(TimingSlot(startArr[0]+":"+(parseInt(startArr[1])+duration),5))
//   const startArr2 = startTime.split(":")
//   userTime.push({
//     timeView: `${start} - ${startArr2[0]+":"+(parseInt(startArr2[1])+duration)}`,
//     start: `${start}`,
//     end: `${startArr2[0]+":"+(parseInt(startArr2[1])+duration)}`,
//   })
// }
// return userTime
// }

export const timeCalculator = (start, end) => {
  const startTime = start.split(":")[1];
  const endTime = end.split(":")[1];
  const gap = parseInt(endTime) - parseInt(startTime);
  return gap
};

export const ReserverdTime = (time) =>{
  const startTime = time.split(":")[0];
  const endTime = parseInt(time.split(":")[0]+1);

  var val15 = TimeSpliter(startTime+" - "+endTime,15)
  var val25 = TimeSpliter(startTime+" - "+endTime,25)

  return {
    val15,val25
  }
}

export const currencyConverter = (number) =>{
 return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(number)
}