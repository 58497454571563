import axios from "axios";

const admin = JSON.parse(localStorage.getItem("ADMIN"))

if(admin){
    axios.defaults.headers.common["x-access-token"] = admin.token;
}
axios.defaults.headers.common["platform"] = "AdminWeb";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    StatisticsApis(url = `${process.env.REACT_APP_BASE_URL}/api`) {
        return {
            dateRangeSessionGraph: (data) => axios.post(url +"/admingraph/TimeIntervalWiseNumberOfSession",data),
            dailySubscriptionReport: (data) => axios.post(url +"/admingraph/dailySubscriptionReport",data),
            sessionTraficDaywise: (data) => axios.post(url +"/admingraph/sessionTraficDaywise",data),
            subscriptionRetentionRatio: () => axios.post(url +"/admingraph/SubscriptionRetentionRatio"),
            referralAnalytics: () => axios.post(url +"/admingraph/ReferralAnalytics"),
            teacherTimeslotsStats: (data) => axios.post(url +"/teacher/getTeachersWithTimeSlots",data),
            popularCity: () => axios.post(url +"/admingraph/popularCity"),
            allDemoTransaction: ({startDate,endDate,page,count}) => axios.get(url +`/razorpay/get-all-transaction?startDate=${startDate}&endDate=${endDate}`),
        }
    }
}


