import React, { useState, useEffect } from "react";
import {Spin, message, Table, Button, Tag, Space, Radio } from "antd";
import { Link} from "react-router-dom";
import { useDispatch } from "react-redux";
import PageBreadcrumb from "../../component/PageBreadcrumb";
import { ChangeInfluencerStatus, GetAllInfluencer } from "../../redux/actions/UserAction";
import Modal from "antd/lib/modal/Modal";
import { utcToLocalDateTimeConverter } from "../../helper/TimeDate";

const ChangeStatus = ({influencerId,influencer,setInfluencer}) =>{

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [status, setStatus] = useState("Approved");
    const [infoMessage, setMessage] = useState("");
    const [changeStatusLoader, setChangeStatusLoader] = useState("");

    const showModal = () => {
      setIsModalVisible(true);
    };
    
    const handleCancel = () => {
      setIsModalVisible(false);
    };

    const updateInfluencerStatus = () =>{

        setChangeStatusLoader(influencerId)

        var payload = {
            influencerId:influencerId,
            status:status
        }
        if(status === "Rejected"){
           payload["rejectReason"]=infoMessage
        }

        const onSuccess = () =>{
            var filteredRequests = influencer.filter((val) => val._id !== influencerId);
            console.log(filteredRequests)
            setInfluencer(filteredRequests);
            message.success("Status updated successfully", 8);
            setChangeStatusLoader(false)
            setIsModalVisible(false)
        }

        const onError = (err) =>{
            message.success(err, 8);
            setChangeStatusLoader(false)
        }
        ChangeInfluencerStatus(payload,onSuccess,onError)
    }

return(
    <>
      <Button  onClick={showModal}>
        Edit
      </Button>
      <Modal title="Change status" visible={isModalVisible} onCancel={handleCancel}  footer={[]}>
          <div style={{display:"flex",flexDirection:"column"}}>
          <label>Status :</label>
          <select onChange={(e)=>setStatus(e.target.value)} style={{width:"200px"}}>
              <option>Approved</option>
              <option>Requested</option>
              <option>Rejected</option>
              <option>InProgress</option>
          </select>
          </div>
          {
              status === "Rejected"?
              <div style={{display:"flex",flexDirection:"column"}}>
                <label>Status :</label>
                <textarea
                    style={{ width: "100%" }}
                    value={infoMessage}
                    onChange={(e) => setMessage(e.target.value)}
                >
                </textarea>
              </div>
              :""
          }
          <Button style={{marginTop:"6px"}} onClick={()=>updateInfluencerStatus()} disabled={changeStatusLoader === influencerId} >{changeStatusLoader === influencerId?"Loading...":"Update"}</Button>
      </Modal>
    </>
)
}

const Affiliate = () => {
    const dispatch = useDispatch();

    const [page, setPage] = useState(0);
    const [initialLoader,setInitialLoader] = useState(true)
    const [loadMore, setLoadMore] = useState(false);
    const [influencer, setInfluencer] = useState(null);
    const [tab, setTab] = useState("Approved");
 
    useEffect(() => {
        setInitialLoader(true);

        var payload = {
            status:tab,
            page:page,
            limit:10
        }

        console.log(payload)

        const onSuccess = (data) => {
            console.log(data)
            setInfluencer(data.influencers);
            setInitialLoader(false);
        };
        const onError = (error) => {
            console.log(error);
            setInitialLoader(false);
        };
        dispatch(GetAllInfluencer(payload,onSuccess, onError));
    }, [tab]);

    function loadMoreInfluencer() {
        setLoadMore(true);
        const payload = {
            status:tab,
            page: page + 1,
            limit: 10,
        };
        const onSuccess = (data) => {
            if (data?.influencers?.length > 0) {
                setInfluencer([...influencer, ...data.influencers]);
                setPage(page + 1);
            }
            setLoadMore(false);
        };
        const onError = (msg) => {
            console.log(msg);
            setLoadMore(false);
        };
        dispatch(GetAllInfluencer(payload,onSuccess, onError));
    }


    const tagColour = (status) =>{
        var colour = 'green'
        if(status === "Requested"){
            colour = "red"
        }
        return colour;
    }

    const columns = [
        { title: "No", width: 5, dataIndex: "no", key: "1" },
        { 
            title: "Name", 
            width: 5, 
            dataIndex: "name", 
            key: "2",
            render: ({name,id}) => (
                <Link to={`/affiliate-profile/${id}`}>{name}</Link>
            )
        },
        { title: "Number", width: 5, dataIndex: "phone", key: "3" },
        { title: "Referral code", width: 5, dataIndex: "code", key: "4" },
        { 
            title: "Status", 
            width: 5, 
            dataIndex: "status", 
            key: "5" ,
            render: (status) => (
                <>
                   <Tag color={tagColour(status)} >
                     {status.toUpperCase()}
                   </Tag>
                </>
              ),
        },
        { title: "Affiliate fee", width: 5, dataIndex: "fee", key: "6" },
        { title: "Requested date", width: 15, dataIndex: "requestedDate", key: "8" },
        { 
            title: "Action", 
            width: 5, 
            dataIndex: "action", 
            key: "7",
            render: (action) => (
                <ChangeStatus influencerId={action} influencer={influencer} setInfluencer={setInfluencer} />
            ),
        },
    ];

    const tableContent = influencer?.map((data,i)=>({
        no:i+1,
        name:{name:data?.name,id:data?._id},
        phone:data?.phoneNumber,
        code:data?.refferalcode,
        status:data?.status,
        fee:data?.affiliatefee,
        action:data?._id,
        requestedDate:utcToLocalDateTimeConverter(data.createdAt)
    }))

    return (
        <div>
        <PageBreadcrumb route={["dashboard", "Affiliates"]} />
        <Space style={{ marginBottom: 24 }}>
        <Radio.Group value={tab} onChange={(e)=>setTab(e.target.value)}>
          <Radio.Button value="Approved">Approved</Radio.Button>
          <Radio.Button value="Requested">Requested</Radio.Button>
          <Radio.Button value="InReview">InReview</Radio.Button>
          <Radio.Button value="Rejected">Rejected</Radio.Button>
        </Radio.Group>
      </Space>
          {
              initialLoader?
              <div className="spin-center">
                  <Spin size="large" />
              </div>
              :
              <Table
              columns={columns}
              dataSource={tableContent}
              pagination={false}
              />
          }

          {
              loadMore?
              <div className="spin-center">
                 <Spin size="large" />
              </div>
              :
              <div style={{'text-align':'center','margin-top':"10px"}}>
                <Button onClick={()=>loadMoreInfluencer()} disabled={loadMore} >Load More Influencer</Button>
              </div> 
          }

        </div>
    );
};

export default Affiliate;
