import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { UTCToAsiaTimeConverter, UTCToTimeConverter } from "../../helper/helper";
import { IndividualTeacherAttendance } from "../../redux/actions/SessionAction";
import moment from "moment";
import {
  Button,
  Space,
  Spin,
  Row,
  Col,
  DatePicker,
  Card,
  Tag,
  message
} from "antd";
import { Table } from "ant-table-extensions";
import { DeleteRating } from "../../redux/actions/TeacherAction";
import { UTCtoLocalConverter } from "../../helper/TimeDate";
const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD";

const JoiningTime = ({ times }) => {
  var timeArray = [];

  if (times.constructor === Array) {
    if (times.length > 0) {
      timeArray = times;
    } else {
      timeArray = ["Absent"];
    }
  } else if (typeof times === String) {
    timeArray = [times];
  } else {
    timeArray = ["Absent"];
  }

  return (
    <>
      {timeArray.map((tag, i) => {
        let color = "green";
        if (tag === "Absent") {
          color = "volcano";
        }
        return (
          <Tag color={color} key={`et128wy2ye${i}`}>
            {tag.toUpperCase()}
          </Tag>
        );
      })}
    </>
  );
};

const TeacherAttandance = () => {

  let { id } = useParams();
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(
    moment(Date.now() - 7 * 24 * 3600 * 1000).format(dateFormat)
  );
  const [endDate, setEndDate] = useState(moment(Date.now()).format(dateFormat));
  const [loader, setLoader] = useState(false);
  const [attendance, setAttendance] = useState([]);

  useEffect(() => {
    setLoader(true);
    var body = {
      teacherId: id,
    };
    var param = {
      startDate: startDate,
      endDate: endDate,
    };
    var onSuccess = (res) => {
      setLoader(false);
      setAttendance(res.sessionReport);
    };
    var onError = (err) => {
      setLoader(false);
      console.log(err);
    };
    dispatch(IndividualTeacherAttendance(body, param, onSuccess, onError));
  }, []);

  const ratingDelete = (meetingNo) => {
    
    var onSuccess = (res) => {
      message.success(res.message)
      console.log(res.message)
    };
    var onError = (err) => {
      message.error(err)
      console.log(err);
    };
     DeleteRating(meetingNo, onSuccess, onError);
   
  }

  const columns = [
    { title: "No", width: 30, dataIndex: "no", key: "1" },
    { title: "Meeting No", width: 10, dataIndex: "meetingNo", key: "2" },
    { title: "Teacher Name", width: 40, dataIndex: "teacherName", key: "3" },
    { title: "Learner Name", width: 40, dataIndex: "learnerName", key: "4" },
    { title: "Session Date", width: 30, dataIndex: "sessionDate", key: "5" },
    { title: "Session Time", width: 20, dataIndex: "sessionTime", key: "6" },
    { title: "Duration", width: 15, dataIndex: "duration", key: "7" },
    {
      title: "Teacher Joining Time",
      width: 50,
      dataIndex: "teacherJoining",
      key: "7",
      render: (joiningtime) => <JoiningTime times={joiningtime} />,
    },
    { title: "Session Type", width: 20, dataIndex: "sessionType", key: "8" },
    { title: "Tech Failure", width: 20, dataIndex: "techFailure", key: "9" },
    { title: "Phone number", width: 20, dataIndex: "phonenum", key: "10" },
    { title: "Email", width: 20, dataIndex: "email", key: "11" },
    {
      title: "Delete Rating",
      key: "12",
      render: (record) => <Button onClick={() => ratingDelete(record.meetingNo)}>{loader?"": "Delete"}</Button>
    }
  ];

  function onPickDate(date, dateString) {
    setStartDate(dateString[0]);
    setEndDate(dateString[1]);
  }

  const absentIdentifier = (data) => {
    var convertedTime = UTCToAsiaTimeConverter(data.sessionTime);
    var splitedValue = convertedTime.split(":");
    var joiningTime =
      splitedValue[0] +
      ":" +
      String(Number(splitedValue[1]) + 2).padStart(2, "0");

    if (data.teacherJoining.constructor === Array) {
      if (data.teacherJoining.length > 0) {
        if (UTCToAsiaTimeConverter(data.teacherJoining[0]) > joiningTime) {
          return "yellow";
        } else {
          return "green";
        }
      } else {
        return "red";
      }
    } else {
      return "red";
    }
  };

  var data = attendance?.map((data, i) => ({
    key: i,
    no: i + 1,
    meetingNo: data.meetingNo,
    teacherName: data.teacher?.name,
    learnerName: data.learner?.name,
    sessionDate: UTCtoLocalConverter(`${data.sessionDate?.split("T")[0]}T${data.startTime}`),
    sessionTime: UTCToAsiaTimeConverter(data.startTime),
    duration: data.sessionDuration,
    teacherJoining: UTCToAsiaTimeConverter(data.speakerJoiningTime),
    sessionType: data.type,
    techFailure: data.isTechFailure ? "True" : "False",
    phonenum: data.learner
      ? data.learner.phoneNumber
        ? data.learner.phoneNumber
        : "N/A"
      : "N/A",
    email: data.learner
      ? data.learner.email
        ? data.learner.email
        : "N/A"
      : "N/A",
  }));

  const teacherAttendance = () => {
    setLoader(true);
    var body = {
      teacherId: id,
    };
    var params = {
      startDate: startDate,
      endDate: endDate,
    };
    var onSuccess = (response) => {
      setAttendance(response.sessionReport);
      setLoader(false);
    };
    var onError = (error) => {
      console.log(error);
      setLoader(false);
    };
    dispatch(IndividualTeacherAttendance(body, params, onSuccess, onError));
  };

  return <>
    <Card style={{ marginBottom: "1rem" }}>
      <Row>
        <Col span={12}>
          <Space>
            <RangePicker
              onChange={onPickDate}
              defaultValue={[
                moment(startDate, dateFormat),
                moment(endDate, dateFormat),
              ]}
              format={dateFormat}
            />
            <Button
              type="primary"
              onClick={() => teacherAttendance()}
              loading={loader}
            >
              Search
            </Button>
          </Space>
        </Col>
      </Row>
    </Card>
    {loader ? (
      <div className="spin-center">
        <Spin size="large" />
      </div>
    ) : (
      <Table
        columns={columns}
        dataSource={data}
        scroll={{ x: 2000 }}
        pagination={{
          pageSize: 100,
        }}
        rowClassName={(record, index) => absentIdentifier(record)}
        exportable
        searchable
      />
    )}
  </>;
};

export default TeacherAttandance;
