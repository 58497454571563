import React from 'react';
import { useSelector } from 'react-redux';
import { PieChart, Pie, Tooltip, ResponsiveContainer, Cell } from 'recharts';
import { UTCToAsiaTimeConverter } from "../../helper/helper";

const PieChartComponent = () => {
    
    const stats = useSelector((state) => state.AdminReducer.timeSlotStats);
    const COLORS = ['#1abc9c', '#2ecc71', '#3498db', '#9b59b6','#16a085','#f1c40f','#e67e22','#e74c3c','#c0392b','#f39c12','#8e44ad','#2c3e50','#5758BB','#833471','#B53471','#1B1464'];
    console.log(stats,"STATS")
     const data =stats?.teachers.map((data, i) => ({
        name:UTCToAsiaTimeConverter(data._id.startTime).split(':')[0]+" - "+UTCToAsiaTimeConverter(data.endTime).split(':')[0],
        value:data.teacherCount,
        fill:COLORS[i % COLORS.length]
    })).sort((a, b) => (a.name > b.name) - (a.name < b.name))



const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};


    return (
        <>
        <PieChart width={500} height={300}>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius={150}
            fill="#8884d8"
            dataKey="value"
          />
          <Tooltip />
        </PieChart>
      </>
    )
}

export default PieChartComponent



