import React, { useState, useEffect } from "react";
import { Table, Tag, Card, message, Spin, Avatar, Col, Row, Button } from "antd";
import { StarFilled, FieldTimeOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import PageBreadcrumb from "../../component/PageBreadcrumb";
import { GetAllTeacher, SearchTeacher } from "../../redux/actions/UserAction";
import Search from "antd/lib/input/Search";
import { Link } from "react-router-dom";
import AddTeacher from "../../component/Teacher profile/AddTeacher";
import { groupHours } from "../../helper/helper";
import Modal from "antd/lib/modal/Modal";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment-timezone";

const dateFormat = "YYYY-MM-DD";

const WorkingHourBreakdown = ({ workingHours }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <>
            <Button onClick={showModal}>
                {workingHours.totalHour} Hr <FieldTimeOutlined style={{ color: "#096dd9" }} />
            </Button>
            <Modal title={`${workingHours.totalHour} Hr breakdown`} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} footer={false}>
                {/* <p><strong>Total kids timing :</strong>{workingHours.totalKidsHour} Hr</p> */}
                <p><strong>Monday:</strong>{workingHours.Monday}  Hr</p>
                <p><strong>Tuesday:</strong>{workingHours.Tuesday} Hr</p>
                <p><strong>Wednesday:</strong>{workingHours.Wednesday} Hr</p>
                <p><strong>Thursday:</strong>{workingHours.Thursday} Hr</p>
                <p><strong>Friday:</strong>{workingHours.Friday} Hr</p>
                <p><strong>Saturday:</strong>{workingHours.Saturday} Hr</p>
                <p><strong>Sunday:</strong>{workingHours.Sunday} Hr</p>
            </Modal>
        </>
    );
};


const AllTeacher = () => {
    const dispatch = useDispatch();
    const teacherList = useSelector(state => state.AdminReducer.teacher)
    const getAllTeacherLoader = useSelector(state => state.AdminReducer.getAllTeacherLoader)

    const [page, setPage] = useState(1);
    const [loadMore, setLoadMore] = useState(false);
    const [loadComplete, setLoadComplete] = useState(false);
    const [teacher, setTeacher] = useState(teacherList ? teacherList : []);
    const [startDate, setStartDate] = useState(moment().subtract(1, "days"));
    const [endDate, setEndDate] = useState(moment());

    const loadNewTeacher = (data) => {
        setTeacher([...teacher, data])
    }

    useEffect(() => {
        setTeacher(teacherList)
    }, [getAllTeacherLoader])
    
    // useEffect(() => {
    //     console.log(teacher)
    //     setLoadMore(true);
    //     const data = {
    //         page: page,
    //         limit: 30,
    //     };
    //     const onSuccess = (data) => {
    //         console.log(data)
    //         setTeacher([...teacher, data.teacherData]);
    //         setLoadMore(false);
    //     };
    //     const onError = (error) => {
    //         console.log(error);
    //         setLoadMore(false);
    //     };
    //     dispatch(GetAllTeacher(data, onSuccess, onError));
    // }, []);

    const loadMoreTeacher = () => {
        setLoadMore(true);
        const data = {
            page: page + 1,
            limit: 20,
            startDate: `${moment(startDate).format(dateFormat)}:00:00`,
            endDate: `${moment(endDate).format(dateFormat)}:24:00`,
        };
        const onSuccess = (data) => {

          console.log(data)
            if (data.length > 0) {
                setTeacher([...teacher, ...data]);
                setPage(page + 1);
            }
            setLoadMore(false);
        };
        const onError = (msg) => {
            console.log(msg);
            setLoadMore(false);
        };
        dispatch(GetAllTeacher(data, onSuccess, onError))
    }
    console.log("load",loadMore);

    function isNumeric(n) {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }

    const onSearch = (value) => {
        if (!value) return;
        var data = {};
        if (isNumeric(value)) {
            data["phoneNumber"] = value;
            data["name"] = value;
        } else {
            data["name"] = value;
        }

        const onSuccess = (res) => {
            setTeacher(res);
        };

        const onError = (msg) => {
            message.error(msg, 8);
        };

        dispatch(SearchTeacher(data, onSuccess, onError));
    };

    const columns = [
        {
          title: "Image",
          dataIndex: "image",
          key: "image",
          width: 60,
          render: (text,record) =>  <Avatar size={60} src={`//images.weserv.nl/?url=${text}&h=60&w=60`} />
        },
        {
          title: "Name",
          dataIndex: "name",
          key: "name",
          width: 60,
          render: (text,record) => <Link to={`/teacher/profile/${record.key}?tab=Details`}>{text}</Link>,
        },
        {
          title: "Status",
          dataIndex: "status",
          key: "status",
          width: 100,
          render: (text) => <Tag color="blue">{text}</Tag>,
        },
        {
          title: "About",
          dataIndex: "about",
          key: "about",
          width: 100,
          render: (text,record) => <p className="ellipse two-lines">{text}</p>
    
        },
        {
          title: "Phone Number",
          dataIndex: "number",
          key: "number",
          width: 60,
        },
        {
          title: "Rating",
          dataIndex: "rating",
          key: "rating",
          width: 20,
          sorter: (val1, val2) => val1.rating - val2.rating,
          render: (text)=> <p>{text} <StarFilled style={{color:"#ff8f00"}}/></p>
        },
        {
          title: "Skills Tags",
          dataIndex: "skillTags",
          key: "skillTags",
          width: 300,
          render: (skillTags) => (
            <>
            {skillTags?.map((skillTag) => (
              <Tag color="green" key={skillTag}>
                    {skillTag.toUpperCase()}
                </Tag>
            ))}
            </>
          ),
        },
        {
          title: "Total hour given",
          dataIndex: "totalHour",
          key: "totalHour",
          width: 80,
          sorter: (val1, val2) => val1.totalHour.totalHour - val2.totalHour.totalHour,
          render: (text)=> <WorkingHourBreakdown workingHours={text} />
        },
        {
          title: "Tags",
          key: "tags",
          dataIndex: "tags",
          width: 250,
          render: (tags) => (
            <>
              {tags.map((tag) => {
                let color = "green";
                if (tag === "KidSession" || tag === "KidDemo") {
                  color = "purple";
                } else if (tag === "Disabled") {
                  color = "magenta";
                } else if (tag === "Youtube") {
                  color = "volcano";
                }
                return (
                  <Tag color={color} key={tag}>
                    {tag.toUpperCase()}
                  </Tag>
                );
              })}
            </>
          ),
        },
      ];
    
      const data = teacher?.map((data, i) => {
        var teacherTags = [];
        if (data.isDemo) {
          teacherTags.push("Demo");
        }
        if (data.isDisabled) {
          teacherTags.push("Disabled");
        }
        if (data.isKidDemo) {
          teacherTags.push("KidDemo");
        }
        if (data.isKidSession) {
          teacherTags.push("KidSession");
        }
        if (data.isYoutubeConsentSigned) {
          teacherTags.push("Youtube");
        }
    
        return {
          key: data?._id,
          image:data?.image,
          name: data?.name,
          status: data?.status,
          about: data?.description?data.description:"Not available",
          number: data?.phoneNumber,
          rating: data?.rating,
          totalHour:data?.noOfHours?groupHours(data.noOfHours):0,
          tags: teacherTags,
          skillTags: data?.tags
        };
      });

    console.log(data)

    return (
        <div>
            <PageBreadcrumb route={["dashboard", "Teachers"]} />

            <Card title="Search Teacher">
                <Row>
                    <Col span={12}>
                        <Search
                            placeholder="Search by Phone Number/Name"
                            onSearch={onSearch}
                            enterButton
                            style={{ width: 300 }}
                        />
                    </Col>
                    <Col span={4}>
                        <AddTeacher loadNewTeacher={loadNewTeacher}/>
                  </Col>
                </Row>

            </Card>
            {
              getAllTeacherLoader ?
                <div className="spin-center">
                  <Spin size="large" />
                </div>
                :
                <Table
                columns={columns}
                dataSource={data}
                scroll={{ x: 1000 }}
                pagination={false}
               />
            }

            {
              loadMore?
              <div className="spin-center">
              <Spin size="large" />
            </div>
            :""
            }

          <div style={{'text-align':'center','margin-top':"10px"}}>
             <Button onClick={()=>loadMoreTeacher()} disabled={loadMore} >Load More Teacher</Button>
          </div>
            
 
        </div>
    );
};

export default AllTeacher;
