import { Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { GetAllYouTubers } from "../../redux/actions/UserAction";
import { Table } from "ant-table-extensions";

const YouTubersList = () => {
  const [loader, setLoader] = useState(false);
  const [youtuberList, setYoutuberList] = useState([]);
  const dispatch = useDispatch();
  const getYouTubersList = () => {
    setLoader(true);

    const onSuccess = (data) => {
      console.log(data);
      setYoutuberList(data?.youTuberList);
      setLoader(false);
    };
    const onError = (msg) => {
      console.log(msg);
      setLoader(false);
    };
    dispatch(GetAllYouTubers(onSuccess, onError));
  };
  useEffect(() => {
    getYouTubersList();
  }, []);

  const columns = [
    {
      title: "Sl. No",
      dataIndex: "id",
      key: "id",
      width: 10,
    },
    {
      title: "Learner Data",
      dataIndex: "Av",
      key: "Av",
      width: 20,
      render: (Av) => <p>{Av}</p>,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 20,
    },
    {
      title: "Phone Number",
      dataIndex: "phone",
      key: "phone",
      width: 20,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 250,
    },
    {
      title: "Youtube Channel",
      dataIndex: "channel",
      key: "channel",
      width: 100,
    },
  ];
  const data = [];
  youtuberList.map((each, i) => {
    var tableData = {
      id: i + 1,
      key: each._id,
      Av: each.learnerData.length >= 1 ? "Available" : "Not available",
      name: each.learnerData.length >= 1 ? each.learnerData[0]?.name : "NA",
      phone:
        each.learnerData.length >= 1 ? each.learnerData[0]?.phoneNumber : "NA",
      email: each.learnerData.length >= 1 ? each.learnerData[0]?.email : "NA",
      channel:each?.youtubeChannel
    };
    data.push(tableData);
  });

  return (
    <div>
      {!loader ? (
        <Table
          scroll={{ x: columns.length > 7 ? 2000 : 1400 }}
          dataSource={data}
          pagination={false}
          columns={columns}
          exportable
          searchable
        />
      ) : (
        <div className="spin-center">
          <Spin size="large" />
        </div>
      )}
    </div>
  );
};

export default YouTubersList;
