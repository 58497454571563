import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getInfluencer } from "../../redux/actions/UserAction";
import { Card, Row, Col, Affix, Table, Spin } from 'antd';


const AffiliateProfile = () => {
    const dispatch = useDispatch();
    let { id } = useParams();
    console.log(id)
    const [affiliateDetails, setAffiliateDetails] = useState([]);
    const [activePage, setActivePage] = useState(1);
    const [influencerDate, setInfluencerData] = useState(null);
    const [loadMore, setLoadMore] = useState(null);

    useEffect(() => {
        influencer(1)
      }, [])

    const influencer =(activePage) => {
        setLoadMore(true);
        const affiliate_data = {
            influencerId: id,

        };
        var params = {
            page:activePage,
            limit:100
          }
        const onSuccess = (data) => {
            setLoadMore(false);
            console.log(data.data);
            setInfluencerData(data.data)
            setAffiliateDetails([...data.data.affiliateData,...affiliateDetails]);
        };
        const onError = (data) => {
            setLoadMore(false);
        };
        dispatch(getInfluencer(affiliate_data,params, onSuccess,onError));
    }

    const onChangePagination = (page, pageSize) =>{
        if(page.current > activePage){
          influencer(page.current)
          setActivePage(page.current)
        }
      }
      const modifiedDate =(date) => {
        const incommingDate = date.split("T")
        return incommingDate[0]
        }

    var data = affiliateDetails?.map((data, i) => ({
        key: i,
        no: i + 1,
        name: data?.learner?.name,
        amount: data?.amount,
        date:  modifiedDate(data?.startDate),
        fee: Math.round(data?.amountWithoutGST * influencerDate?.influencerData?.affiliatefee * 0.01)          
    }))

    const columns = [
        { title: "No", width: 5, dataIndex: "no", key: "1" },
        { title: "Customer Name", width: 5, dataIndex: "name", key: "2" },
        { title: "Subscription Amount", width: 5, dataIndex: "amount", key: "3" },
        { title: "Subscription Date", width: 5, dataIndex: "date", key: "4" },
        { title: "Affiliate Fee", width: 5, dataIndex: "fee", key: "5" },

    ];

    return (

        <div className="text-center">
            <div className="site-card-wrapper">
                <Row gutter={16}>
                    <Col span={6}>
                        <Card title="Your Earning" bordered={false}>
                            {influencerDate?.influencerData?.earning}
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card title="Subscribed User" bordered={false}>
                            {influencerDate?.influencerData?.subscribedUser}
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card title="Demo Taken" bordered={false}>
                        {influencerDate?.demoBooked}
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card title="Signup User" bordered={false}>
                        {influencerDate?.signupUser}
                        </Card>
                    </Col>
                </Row>
            </div>
            <div style={{marginTop:"20px"}}>

                {
                    loadMore?
                    <div className="spin-center">
                            <Spin size="large" />
                        </div>
                        :
                        <Table
                
                        columns={columns}
                        dataSource={data}
                        scroll={{ x: 1450 }}
                        onChange={onChangePagination}
                        pagination={{
                            pageSize: 100,
                            total:influencerDate?.influencerData?.subscribedUser,
                            defaultCurrent: activePage
                        }}
        
                    />
                }
  
            </div>

        </div >
    )
}

export default AffiliateProfile;