import {
  Button,
  Card,
  Col,
  DatePicker,
  message,
  Row,
  Select,
  Space,
  Spin
} from "antd";
import { Table } from "ant-table-extensions";

import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PageBreadcrumb from "../component/PageBreadcrumb";
import { GetAllDemoTransaction } from "../redux/actions/StatisticsAction";
import { SendBookSessionNotification } from "../redux/actions/UserAction";

import { convertUTC, LocalToutc, utcToLocalDateConverter, utcToLocalDateTimeConverter } from "../helper/TimeDate";

import { Option } from "antd/lib/mentions";
import Text from "antd/lib/typography/Text";

const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD";

const DemoTransactions = () => {
  const dispatch = useDispatch();
  var filteredDemoTransaction_;
  const [startDate, setStartDate] = useState(moment().subtract(1, "days"));
  const [endDate, setEndDate] = useState(moment());
  const [demoTransaction, setDemoTransaction] = useState([]);
  const [page, setPage] = useState(1);
  const [loadMore, setLoadMore] = useState(false);
  const [notificationLoader, setNotificationLoader] = useState(false);
  const [filter, setFilter] = useState("All");
  const [loader, setLoader] = useState(false)
  var filteredDemoTransaction_ ;
  const changeFilter = (val) => {
    setFilter(val);
  }
  function onPickDate(date, dateString) {
    setStartDate(dateString[0]);
    setEndDate(dateString[1]);
  }

  useEffect(() => {
    fetchDemoTransactions()
  }, [filter])


  const fetchDemoTransactions = () => {
    setLoader(true)
    const data = {
      startDate: `${moment(startDate).format(dateFormat)}:00:00`,
      endDate: `${moment(endDate).format(dateFormat)}:24:00`
    };

    console.log(data);

    const onSuccess = (res) => {
      setDemoTransaction(res)
      console.log(res);
      setLoader(false)
    };

    const onError = (err) => {
      message.error(err, 8);
      setLoader(false)
    };

    dispatch(GetAllDemoTransaction(data, onSuccess, onError));
  };

  const loadMoreTransaction = () => {
    setLoadMore(true);
    const data = {
      startDate: `${moment(startDate).format(dateFormat)}:00:00`,
      endDate: `${moment(endDate).format(dateFormat)}:24:00`
    };
    const onSuccess = (data) => {

      console.log(data)
      if (data.length > 0) {
        setDemoTransaction([...demoTransaction, ...data]);
        setPage(page + 1);
      }
      setLoadMore(false);
    };
    const onError = (msg) => {
      console.log(msg);
      setLoadMore(false);
    };
    dispatch(GetAllDemoTransaction(data, onSuccess, onError))
  }
  
  const notifyUser = (name,email,phone) =>{

    if(!phone && !email){
      message.error("Phone number & email required !")
      return
    }

    var payload = {
      name:name,
      email:email,
      phoneNumber:`+91${phone}`,
      subject:"English"
    }

    setNotificationLoader(phone)
    const onSuccess = () =>{
     setNotificationLoader(false)
      message.success("Notification sent successfully")
    }

    const onError = (err) =>{
         setNotificationLoader(false)
      message.error(err)
    }

    SendBookSessionNotification(payload,onSuccess,onError)
  }

  const columns = [
    {
      title: "Serial No.",
      dataIndex: "key",
      key: "key",
    },
    {
      title: "Learner name",
      dataIndex: "learnerName",
      key: "learnerName",
    },
    {
      title: "Mobile no",
      dataIndex: "number",
      key: "number",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "User registered",
      dataIndex: "accountExist",
      key: "accountExist",
    },
    {
      title: "Demo booked",
      dataIndex: "demoBooked",
      key: "demoBooked",
    },
    {
      title: "Send notification",
      dataIndex: "sendNotification",
      key: "sendNotification",
      render: (_,{demoBooked,learnerName,number,email})=>(<Button disabled={demoBooked !== "Not booked" || notificationLoader === number} onClick={()=>notifyUser(learnerName,email,number)}>{notificationLoader === number?"Sending...":"Send notification"}</Button>)
     },
     {
      title: "TXN time",
      dataIndex: "txnTime",
      key: "txnTime",
    },
    {
      title: "Signup time",
      dataIndex: "signupTime",
      key: "signupTime",
    },
    {
      title: "Booking time",
      dataIndex: "bookingTime",
      key: "bookingTime",
    },
    {
      title: "Session Date",
      dataIndex: "sessionDate",
      key: "sessionDate",
    },
    {
      title: "Payment method",
      dataIndex: "paymentMethod",
      key: "paymentMethod",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Order ID",
      dataIndex: "RZP_orderId",
      key: "RZP_orderId",
    },
    {
      title: "Payment ID",
      dataIndex: "RZP_paymentId",
      key: "RZP_paymentId",
    }
  ];
  filteredDemoTransaction_ = demoTransaction?.filter(
    (res) => res?.demoBooked === filter
  );
  var txnData = (filter!=="All"?filteredDemoTransaction_:demoTransaction)?.map((data, i) => {
    console.log(data.bookingTime)
    data["key"] = i+1
    data["demoBooked"] = String(data.demoBooked)

    data["txnTime"] = utcToLocalDateTimeConverter(data.txnTime)
    data["sessionDate"] = data.sessionDate !== "Demo not Booked"?utcToLocalDateConverter(data.sessionDate):"Demo not Booked"

    data["bookingTime"] =  data.bookingTime === "Demo Not booked" ? "Demo Not booked":convertUTC(data.bookingTime)
    data["signupTime"] = utcToLocalDateTimeConverter(data.sugnupDate)
    return data;
  });


  const bookingIdentifier = (data) => {
    if (data.demoBooked === "Not booked") {
      return "red";
    } else {
      return "green";
    }
  };

  return (
    <div>
      <PageBreadcrumb route={["dashboard", "Teachers Attendance"]} />

      <Card title="Filter Session" style={{ marginBottom: "1rem" }}>
        <Row>
          <Col span={12}>
            <Space>
              <RangePicker
                onChange={onPickDate}
                defaultValue={[
                  moment(startDate, dateFormat),
                  moment(endDate, dateFormat),
                ]}
                format={dateFormat}
              />
              <Button
                type="primary"
                onClick={() => {
                  setDemoTransaction(null);
                  fetchDemoTransactions();
                }}
              >
                Search
              </Button>
            </Space>
          </Col>
          <Col span={3}>
            <Text type="secondary">Filter</Text>
              <Select defaultValue="All" style={{ width: 220 }} onChange={changeFilter}>
                <Option value="Demo booked">Demo booked</Option>
                <Option value="Not booked">Demo not booked</Option>
                <Option value="All">Show all</Option>
              </Select>
          </Col>
          {txnData && <Col span={10}>
            <Text type="secondary">{filter} count : </Text>

              {txnData.length}


          </Col>}

        </Row>
      </Card>

      {!demoTransaction || loader ? (
        <div className="spin-center">
          <Spin size="large" />
        </div>
      ) : (
        <>
          <Table
            columns={columns}
            scroll={{ x: 2000 }}
            dataSource={txnData}
            pagination={false}
            rowClassName={(record, index) => bookingIdentifier(record)}
            exportable
            searchable />

          {
            loadMore ?
              <div className="spin-center">
                <Spin size="large" />
              </div>
              : ""
          }
          <div style={{ 'text-align': 'center', 'margin-top': "10px" }}>
            <Button onClick={() => loadMoreTransaction()} disabled={loadMore} >Load More Transactions</Button>
          </div>
        </>
      )}
    </div>
  );
};

export default DemoTransactions;