import { Content } from "antd/lib/layout/layout";
import moment from "moment";
import "moment-timezone";

export const TimeSpliter = (mytime, duration) => {
  var splited = mytime.split("to", 2);
  const a = parseInt(splited[0]);
  const b = parseInt(splited[1]);
  var val = 0;
  const arr = [];
  const arr2 = [];
  arr2.push(`${a + ":" + "00"}`);
  for (var i = 0; i < 4; i++) {
    val = val + parseInt(duration);
    arr.push(val);
    // console.log(arr);
    // console.log(arr2);
    if (arr[i] !== 60) {
      arr2.push(`${a + ":" + arr[i]}`);
    } else {
      arr2.push(`${b + ":" + "00"}`);
    }
    console.log(arr2);
  }
  return arr2;
};

export const TimingSlot = (val, num) => {
  const time = val.split(":");
  const h = parseInt(time[0]);
  const m = parseInt(time[1]) + num;
  return `${h}:${m}`;
};

export const getDate = (date) => {
  var today = new Date(date);
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();
  today = yyyy + "-" + mm + "-" + dd;
  return today;
};

export const formatAMPM = (date) => {
  var time = date.split(":", 2);
  var hours = time[0];
  var minutes = time[1];

  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;

  return strTime;
};

export const meetingTime = (time, duration) => {
  var splited = time.split(":", 2);
  const k = parseInt(splited[0]);
  const minute = parseInt(splited[1]);
  const endminute = parseInt(splited[1]) + duration;

  const starttime = k + ":" + minute;
  const endtime = k + ":" + endminute;

  var meetingTime;

  var p = endtime.split(":");
  if (p[1] === "60") {
    meetingTime = {
      start: time,
      end: `${parseInt(p[0]) + 1}:00`,
    };
  } else {
    meetingTime = {
      start: time,
      end: endtime,
    };
  }
  return meetingTime;
};

export const UTCToAsiaTimeConverter = (hr) => {
  moment.tz.setDefault("UTC");
  if (hr.constructor === Array) {
    if (hr.length > 0) {
      var timeArr = [];
      hr.map((val) => {
        var time = moment(val, "HH:mm").toDate();
        var t = moment(time).tz("Asia/Kolkata").format("HH:mm");
        timeArr.push(t);
      });
      return timeArr;
    } else {
      return [];
    }
  } else {
    var time = moment(hr, "HH:mm").toDate();
    var t = moment(time).tz("Asia/Kolkata").format("HH:mm");
    return t;
  }
};

export const upcomingTimeValidator = (utcDate,utcTime) => {
  moment.tz.setDefault("UTC");
  const splittedDate = utcDate.split("T")
// Combine UTC date and time
const utcDateTime = `${splittedDate[0]} ${utcTime}`;
console.log(utcDateTime)
// Convert to Asia/Kolkata timezone
const kolkataDateTime = moment.utc(utcDateTime).tz('Asia/Kolkata');
const formattedDate = kolkataDateTime.format('YYYY-MM-DD HH:mm:ss')

const currentDateTime = moment.utc().tz('Asia/Kolkata');
currentDateTime.add(30, 'minutes');
const formattedCurrentDateTime = currentDateTime.format('YYYY-MM-DD HH:mm:ss')
return formattedDate > formattedCurrentDateTime 
};

export const UTCToAsiaTimeConverter12hr = (hr) => {
  moment.tz.setDefault("UTC");
  if (hr.constructor === Array) {
    if (hr.length > 0) {
      var timeArr = [];
      hr.map((val) => {
        var time = moment(val, "HH:mm").toDate();
        var formatString = "hh:mm A";
        var t = moment(time).tz("Asia/Kolkata").format(formatString);
        timeArr.push(t);
      });
      return timeArr;
    } else {
      return [];
    }
  } else {
    var time = moment(hr, "HH:mm").toDate();
    var formatString = "hh:mm A";
    var t = moment(time).tz("Asia/Kolkata").format(formatString);
    return t;
  }
};

export const roleValidator = (accessRoles) => {
  const user = JSON.parse(localStorage.getItem("ADMIN"));
  if (user) {
    var userRole = user.roles;
    var returnVal
    for(var i=0;i<accessRoles.length;i++){
      if(userRole.includes(accessRoles[i])){
        returnVal= true
        break
      }
    }
    return returnVal;
  } else {
    return true;
  }
};


function isNumber(char) {
  if (typeof char !== "string") {
    return false;
  }
  if (char.trim() === "") {
    return false;
  }
  return !isNaN(char);
}

const convertKeyVal = (array) => {
  var obj = {};
  var key = array[0].replace(/[0-9]/g, '');
  obj[key] = array[1];
  return obj;
};

function retnum(str) { 
    var num = str.replace(/[^0-9]/g, ''); 
    return parseInt(num,10); 
}

export const groupByNumber = (arr) => {

  const ret = Object.entries(arr).reduce((acc, val) => {
    var [key, value] = val;
    let char = String(retnum(key));
    
    if (isNumber(char)) {
      acc[char] = [].concat(acc[char] || [], convertKeyVal(val),convertKeyVal(["videoNumber",Number(char)]));
    }
    return acc;
  }, {});

  const res = Object.keys(ret).map((el) => (
     Object.assign({}, ...ret[el])
  ));

  return res;
};

export const formatTags = (tagStr) => {
  var splitedTags = tagStr.split(',')
  var tagList = [];
  splitedTags.map(data=>{
      var tagObj = {};
      tagObj['tag']=data
      tagList.push(tagObj)
  })

  return tagList;
}

export const joinTags = (tagArr) =>{
  if(tagArr.length>0){
    var tags = [];

    tagArr.map(data=>{
      tags.push(data.tag)
    })
    return tags.join(',')
  }else{
    return null
  }
}


export var formatPopulateData = (videoArray) =>{
  var updatedObj = {};
  videoArray.map((data,i)=>{
          
          var obj = {};
      
          Object.entries(data).map(res=>{
            var [key, value] = res;
             if(key !== "_id" && key !== "videoNumber" && key !== "courseId" && key !== "__v"){
                var newKey = `${key}${i+1}`;
                obj[newKey] = value;
              }
          })
 
          updatedObj = {...updatedObj,...obj}
         
      })
      return updatedObj;
  }

  export var formatPaneData = (panesData,formData) =>{
    
    var updatedArr = [];
    var formPanesArr = groupByNumber(formData)

    panesData.map((data,i)=>{
      
      var newData = data.content?.props?.data
      if(newData){
      var obj = {};
            
      Object.entries(newData).map(res=>{
        var [key, value] = res;
          if(key !== "_id" && key !== "videoNumber" && key !== "courseId" && key !== "__v"){
            var newKey = `${key}${i+1}`;
            obj[newKey] = value;
            console.log("mylog", newKey, obj);
          }
      })

      updatedArr.push(obj)
    }
    })

    if(updatedArr.length > 0){
      var newUpdatedArr = groupByNumber(Object.assign({}, ...updatedArr))
   
      console.log("mylognewUpdatedArr", newUpdatedArr, formPanesArr);

      var newArr = newUpdatedArr.concat(formPanesArr)
      console.log(newArr)
   
       const ids = newArr.map(o => o.videoNumber)
       const filteredPanes = newArr.filter(({videoNumber}, index) => !ids.includes(videoNumber, index + 1))
       console.log("mylogfiltered", ids, filteredPanes);
      return filteredPanes
    }

    return formPanesArr
  }

  export const groupBySessionNumber = (arr) => {

    const ret = Object.entries(arr).reduce((acc, val) => {
      var [key, value] = val;
      let char = String(retnum(key));
      
      if (isNumber(char)) {
        acc[char] = [].concat(acc[char] || [], convertKeyVal(val),convertKeyVal(["sessionNumber",Number(char)]));
      }
      return acc;
    }, {});
  
    const res = Object.keys(ret).map((el) => (
       Object.assign({}, ...ret[el])
    ));
  
    return res;
  };
  
export var formatPopulateVideoSessionData = (videoArray) =>{
  var updatedObj = {};
  videoArray.map((data,i)=>{
          
          var obj = {};
      
          Object.entries(data).map(res=>{
            var [key, value] = res;
              if(key !== "_id" && key !== "sessionNumber" && key !== "__v" && key!=="subject" && key!=="duration"){
                var newKey = `${key}${i+1}`;
                obj[newKey] = value;
              }
          })
  
          updatedObj = {...updatedObj,...obj}
          
      })
      return updatedObj;
}

export var formatVideoSessionPaneData = (panesData,formData) =>{
    
  var updatedArr = [];
  var formPanesArr = groupBySessionNumber(formData)

  panesData.map((data,i)=>{
    
    var newData = data.content?.props?.data
    if(newData){
    var obj = {};
          
    Object.entries(newData).map(res=>{
      var [key, value] = res;
      if(key !== "_id" && key !== "sessionNumber" && key !== "__v" && key!=="subject" && key!=="duration"){
        var newKey = `${key}${i+1}`;
          obj[newKey] = value;
          console.log("mylog", newKey, obj);
        }
    })

    updatedArr.push(obj)
  }
  })

  if(updatedArr.length > 0){
    var newUpdatedArr = groupBySessionNumber(Object.assign({}, ...updatedArr))
 
    console.log("mylognewUpdatedArr", newUpdatedArr, formPanesArr);

    var newArr = newUpdatedArr.concat(formPanesArr)
    console.log(newArr)
 
     const ids = newArr.map(o => o.sessionNumber)
     const filteredPanes = newArr.filter(({sessionNumber}, index) => !ids.includes(sessionNumber, index + 1))
     console.log("mylogfiltered", ids, filteredPanes);
    return filteredPanes
  }

  return formPanesArr
} 

 export const groupHours = (hours) =>{
    var totalHour = 0;
    var totalKidsHour = 0;
    var Monday, Tuesday, Wednesday, Thursday, Friday,Saturday, Sunday ;
    Monday = Tuesday = Wednesday = Thursday = Friday = Saturday = Sunday =0;


    console.log(hours)

    hours.map((data)=>{

       totalHour += data.noOfHour
       if(data._id.day === 'Sunday'){Sunday+=data.noOfHour}
       if(data._id.day === 'Monday'){Monday+=data.noOfHour}
       if(data._id.day === 'Tuesday'){Tuesday+=data.noOfHour}
       if(data._id.day === 'Wednesday'){Wednesday+=data.noOfHour}
       if(data._id.day === 'Thursday'){Thursday+=data.noOfHour}
       if(data._id.day === 'Friday'){Friday+=data.noOfHour}
       if(data._id.day === 'Saturday'){Saturday+=data.noOfHour}

      //  if(data._id.kids){}{totalKidsHour+=data.noOfHour}

    })
    console.log({totalHour,totalKidsHour,Monday, Tuesday, Wednesday, Thursday, Friday,Saturday, Sunday})
    return {totalHour,totalKidsHour,Monday, Tuesday, Wednesday, Thursday, Friday,Saturday, Sunday};
  }

  export const getDevice = () => {
    let details = navigator.userAgent;
    let regexp = /android|iphone|kindle|ipad/i;
    let isMobileDevice = regexp.test(details);
    if (isMobileDevice) {
      return "M-WEB";
    } else {
      return "WEB";
    }
  };

  export const numberToOrdinal = (number) => {
    const suffixes = ['th', 'st', 'nd', 'rd'];
    const remainder = number % 100;
  
    // Check for special cases (11th, 12th, 13th)
    if (11 <= remainder && remainder <= 13) {
      return number + 'th';
    } else {
      // Use the last digit to determine the suffix
      const suffix = suffixes[Math.min(remainder % 10, 3)];
      return number + suffix;
    }
  }