import React,{useState,useEffect} from 'react'
import { useDispatch } from "react-redux";
import { Form, Input, Tabs, Card, Table, Modal, Button } from "antd";
import PageBreadcrumb from "../component/PageBreadcrumb";
import {GetLearnerNotes} from '../redux/actions/UserAction'

const { TabPane } = Tabs;

const SessionDocs = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();

  const dispatch = useDispatch();

  useEffect(() => {
      dispatch(GetLearnerNotes())
  }, [])
  

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const columns = [
    {
      title: "Sl no",
      dataIndex: "no",
      key: "no",
    },
    {
      title: "Note Link",
      dataIndex: "note",
      key: "note",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Session",
      dataIndex: "session",
      key: "session",
    },
  ];

  const data = [
    {
      key: "1",
      no: 1,
      note: "session doc 1",
      session: 1,
    },
    {
      key: "2",
      no: 2,
      note: "session doc 2",
      session: 2,
    },
  ];

  return (
    <div>
      <Modal
        title="Basic Modal"
        visible={isModalVisible}
        closable={true}
        footer={null}
        onCancel={handleCancel}
      >
        <Form
          form={form}
          layout="vertical"
        //   initialValues={{
        //     requiredMark,
        //   }}
        //   onValuesChange={onRequiredTypeChange}
        //   requiredMark={requiredMark}
        >
          <Form.Item
            label="Document Link"
            required
          >
            <Input placeholder="Document link" />
          </Form.Item>
          <Form.Item
            label="Session No"
            required
          >
            <Input placeholder="Session No" />
          </Form.Item>
          <Button type="primary" block>Submit</Button>
        </Form>
      </Modal>

      <PageBreadcrumb route={["dashboard", "Session Document"]} />
      <Card>
        <Tabs defaultActiveKey="1" centered>
          <TabPane tab="Learner Document" key="1">
            <Button type="primary" onClick={showModal}>
              Add Learner Doc
            </Button>
            <Table
              columns={columns}
              style={{ marginTop: 10 }}
              bordered
              pagination={false}
              dataSource={data}
            />
          </TabPane>
          <TabPane tab="Teacher Document" key="2">
            <Button type="primary" onClick={showModal}>
              Add Teacher Doc
            </Button>
            <Table
              columns={columns}
              style={{ marginTop: 10 }}
              bordered
              pagination={false}
              dataSource={data}
            />
          </TabPane>
        </Tabs>
      </Card>
    </div>
  );
};

export default SessionDocs;
