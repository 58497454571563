import React, { useState, useEffect } from "react";
import { SearchOutlined } from '@ant-design/icons';
import {
  Table,
  Button,
  Space,
  Spin,
  Row,
  Col,
  Input,
  DatePicker,
  Card,
  Tag,
  Select,
  message,
  Typography,
  Modal,
  Form,
  Radio
} from "antd";
import moment from "moment";
import { useDispatch } from "react-redux";
import PageBreadcrumb from "../../component/PageBreadcrumb";
import { GetMeetingRecording, markSessionAttendence_, markSessionAttendence__, TeacherAttendance } from "../../redux/actions/SessionAction";
import { UTCToAsiaTimeConverter } from "../../helper/helper";

const dateFormat = "YYYY-MM-DD";
const { Option } = Select;
const { Text } = Typography;

const httpValidator = (url) =>{
  if (!/^https?:\/\//i.test(url)) {
   return'https://' + url;
  }else{
    return url
  }
}

const JoiningTime = ({ times }) => {
  var timeArray = [];

  if (times.constructor === Array) {
    if (times.length > 0) {
      timeArray = times;
    } else {
      timeArray = ["Absent"];
    }
  } else if (typeof times === String) {
    timeArray = [times];
  } else {
    timeArray = ["Absent"];
  }

  return (
    <>
      {timeArray.map((tag, i) => {
        let color = "green";
        if (tag === "Absent") {
          color = "volcano";
        }
        return (
          <Tag color={color} key={`et128wy2ye${i}`}>
            {tag.toUpperCase()}
          </Tag>
        );
      })}
    </>
  );
};

const SessionAttendance = () => {
  const dispatch = useDispatch();
  const [date, setDate] = useState(moment(new Date()).format(dateFormat));
  const [totalSession, setTotalSession] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [sessionType, setSessionType] = useState("All");
  const [sessionFor, setSessionFor] = useState("Adult");
  const [teacherJoin, setTeacherJoin] = useState("All");
  const [subject, setSubject] = useState("English");
  const [searchField, setSearchField] = useState(null);
  const [loader, setLoader] = useState(false);
  const [attendance, setAttendance] = useState([]);
  const [recordingLoader, setRecordingLoader] = useState(false);
  const [learneratt, setlearneratt] = useState(false);
  const [att, setatt] = useState(false);
  const [openModal, setOpenModal] = useState();
  const [form] = Form.useForm();
  const [searchQuery, setSearchQuery] = useState("");

  const handleInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleEnterKey = (e) => {
    if (e.key === "Enter") {
      setAttendance([]);
      setActivePage(1);
      teacherAttendance(date, 1, "reset", searchQuery);
    }
  };

  useEffect(() => {
    teacherAttendance(date, 1);
  }, []);
  const openAttendancePopup = (id) => {
    if (openModal !== id) {
      setOpenModal(id);
    } else {
      setOpenModal();
    }
  }
  const recording = (id) => {
    setRecordingLoader(id);
    const data = {
      meetingId: id,
    };
    const onSuccess = (data) => {
      setRecordingLoader(false);
      if(!data?.uuid){
        const playUrl = data.recording_files[0].play_url;
        window.open(playUrl, '_blank');
        // const newWindow = window.open('', '_blank');
      
        // const videoHtml = `
        //   <html>
        //     <head>
        //       <title>Recording</title>
        //     </head>
        //     <body style="margin:0">
        //       <video controls autoplay style="width:100vw; height:100vh;">
        //         <source src="${data.recording_files[0].play_url}" type="video/mp4">
        //         Your browser does not support the video tag.
        //       </video>
        //     </body>
        //   </html>
        // `;
    
        // newWindow.document.write(videoHtml);
        // newWindow.document.close();
        }else{
        window.open(data.recording_files[0].play_url, '_blank');
        }
    };
    const onError = (err) => {
      setRecordingLoader(false);
      message.error(err, 8);
    };
    dispatch(GetMeetingRecording(data, onSuccess, onError));
    
  };
  const markAttendance = (id, sessionTimeUTC) => {
    
    if ( window.confirm('Are you sure you want to mark session attended ?')) {
      // Save it!
      var data = {
        sessionId:id,
        joiningTime:sessionTimeUTC,
        forLearner: learneratt,
        absent: att,
      };

      var onSuccess = (response) => {
        const session = attendance.filter(session => session._id === id)
        if(session){
          session[0]["speakerJoiningTime"]=[sessionTimeUTC]
        }
        setAttendance(attendance)
        setLoader(true);
        setTimeout(()=>{setLoader(false);}, 1000);
        message.success(response);
        setOpenModal();//close the popup
        setlearneratt(false);
        setatt(false);
      }
        
      var onError = (error) => {
        message.error(error)
      };

      dispatch(markSessionAttendence__(data, onSuccess, onError));

    } else {
      // Do nothing!
      console.log('Cancel');
    }
  };

  const openMeetingRecording = (url) => {
    if(url!=="na"){
      window.open(url, '_blank');
    }else{
      message.error('recording not found', 8);
    }
  }

  const columns = [
    { title: "No", width: 10, dataIndex: "no", key: "1" },
    { title: "Meeting No", width: 30, dataIndex: "meetingNo", key: "2" },
    { title: "Subscription No", width: 20, dataIndex: "subscriptionCount", key: "2x5" },
    { title: "Teacher Name", width: 30, dataIndex: "teacherName", key: "3" },
    { title: "Learner Name", width: 30, dataIndex: "learnerName", key: "4" },
    { title: "Session Date", width: 30, dataIndex: "sessionDate", key: "5" },
    { title: "Session Time", width: 20, dataIndex: "sessionTime", key: "6" },
    { title: "Duration", width: 15, dataIndex: "duration", key: "7" },
    {
      title: "Teacher Joining Time",
      width: 50,
      dataIndex: "teacherJoining",
      key: "7",
      render: (joiningtime) => <JoiningTime times={joiningtime} />,
    },
    {
      title: "Learner Joining Time",
      width: 50,
      dataIndex: "learnerJoining",
      key: "8",
      render: (joiningtime) => <JoiningTime times={joiningtime} />,
    },
    { title: "Session Type", width: 20, dataIndex: "sessionType", key: "9" },
    { title: "Tech Failure", width: 20, dataIndex: "techFailure", key: "10" },
    { title: "Phone number", width: 20, dataIndex: "phonenum", key: "11" },
    { title: "Email", width: 30, dataIndex: "email", key: "12" },
    { title: "Teacher device", width: 60, dataIndex: "teacherDevice", key: "13" },
    {
      title: "Recording",
      key: "meetingRecording",
      fixed: "right",
      width: 20,
      render: ({ meetingNo,meetingRecording }) => (
        <>
        {
        meetingRecording === null?
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a  onClick={()=>recording(meetingNo)}>
            {recordingLoader === meetingNo?"Fetching...":"Recording"}
          </a>:
          // eslint-disable-next-line react/jsx-no-target-blank
          <a onClick={()=>openMeetingRecording(meetingRecording)}>
            Recording    
          </a>
        }
        </>
      ),
    },
    {
      title:"Mark Attendance", 
      width:40, 
      key:"14",
      fixed: "right",
      render:({ sessionId, teacherJoining, sessionTimeUTC})=>(
        <>
        <Modal
            title="Mark session attendance"
            visible={openModal === sessionId}
            closable={true}
            footer={null}
            onCancel={() => {
              setOpenModal();
              setlearneratt(false);
              setatt(false);
            }}
          >
            <Form form={form} layout="vertical">
              <Form.Item label="Mark for" name="learner">
                <Radio.Group onChange={(e) => setlearneratt(e.target.value)} value={learneratt}>
                  <Space direction="vertical">
                    <Radio value={true}>Learner</Radio>
                    <Radio value={false}>Tutor</Radio>
                  </Space>
                </Radio.Group>
                
              </Form.Item>
              <Form.Item label="Mark as " name="session">
              <Radio.Group onChange={(e) => setatt(e.target.value)} value={att}>
                  <Space direction="vertical">
                    <Radio value={true}>Absent</Radio>
                    <Radio value={false}>Present</Radio>
                  </Space>
                </Radio.Group>
                
              </Form.Item>
              <Button
                type="primary"
                block
                onClick={()=>markAttendance(sessionId, sessionTimeUTC)}
              >
                Mark and close
              </Button>
            </Form>
          </Modal>
        <Button disabled={teacherJoining.constructor === Array && teacherJoining.length>0 ?true:(typeof(teacherJoining)=== String ?true:false)} onClick={() => openAttendancePopup(sessionId)}>Mark</Button>
        </>
      )
    },
  ];

  function onPickDate(date, dateString) {
    setDate(dateString);
  }
  const onChangeSessionType = (value) => {
    setSessionType(value);
  };

  const onChangePagination = (page, pageSize) => {
    if (page.current > activePage) {
      teacherAttendance(date, page.current);
      setActivePage(page.current);
    }
  };

  const absentIdentifier = (data) => {
    var convertedTime = UTCToAsiaTimeConverter(data.sessionTime);
    var splitedValue = convertedTime.split(":");
    var joiningTime =
      splitedValue[0] +
      ":" +
      String(Number(splitedValue[1]) + 2).padStart(2, "0");

    if (data.teacherJoining.constructor === Array) {
      if (data.teacherJoining.length > 0) {
        if (UTCToAsiaTimeConverter(data.teacherJoining[0]) > joiningTime) {
          return "yellow";
        } else if (data.techFailure === "True") {
          return "red";
        } else {
          return "green";
        }
      } else if (data.techFailure === "True") {
        return "red";
      } else {
        return "red";
      }
    } else {
      return "red";
    }
  };

  var data = attendance?.map((data, i) => ({
    key: i,
    no: i + 1,
    sessionId:data?._id,
    meetingNo: data.meetingNo,
    subscriptionCount:data?.subscriptionCount,
    teacherName: data.teacher?.name,
    learnerName: data.learner?.name,
    sessionDate: moment(new Date(data.sessionDate)).format(dateFormat),
    sessionTime: UTCToAsiaTimeConverter(data.startTime),
    sessionTimeUTC: data.startTime,
    duration: data.sessionDuration,
    teacherJoining: UTCToAsiaTimeConverter(data.speakerJoiningTime?.slice(0, 2)),
    learnerJoining: UTCToAsiaTimeConverter(data.learnerJoiningTime?.slice(0, 2)),
    sessionType: data.type,
    techFailure: data.isTechFailure ? "True" : "False",
    isSessionAttended: data?.isSessionAttended,
    phonenum: data.learner
      ? data.learner.phoneNumber
        ? data.learner.phoneNumber
        : "N/A"
      : "N/A",
    email: data.learner
      ? data.learner.email
        ? data.learner.email
        : "N/A"
      : "N/A",
      teacherDevice:data?.teacherDeviceDetails,
      meetingRecording: data.meetingRecording ? data.meetingRecording!=="na"
      ? `https://clapingo-testing.netlify.app/recordings?link=${httpValidator(
          data.meetingRecording
        )}` : "na" 
      : null,
  }));

  const teacherAttendance = (date, activePage, reset, searchQuery) => {
    setLoader(true);
    var data = {
      date: date,
      subject:subject,
      forKids:sessionFor === "Kids"?true:false,
    };

    if (sessionType !== "All") {
      data["sessiontype"] = sessionType;
    }
    if (teacherJoin !== "All") {
      data["absent"] = true;
    }

    if(searchQuery){
      data["searchField"] = searchQuery;
    }

    var params = {
      page: activePage,
      limit: 80,
    };
    var onSuccess = (response) => {
      if (reset) {
        setAttendance(response.session);
      } else {
        setAttendance([...attendance, ...response.session]);
      }

      setTotalSession(Number(response.totalSession));
      setLoader(false);
    };
    var onError = (error) => {
      console.log(error);
      setLoader(false);
    };
    dispatch(TeacherAttendance(data, params, onSuccess, onError));
  };

  return (
    <div>
      <PageBreadcrumb route={["dashboard", "Teachers Attendance"]} />

      <Card title="Filter Session" style={{ marginBottom: "1rem" }}>

        <Row>
        <Col span={3}>
            <Text type="secondary">Subject</Text>
              <Select defaultValue="English" style={{ width: 120 }} onChange={setSubject}>
                <Option value="English">English</Option>
                <Option value="IELTS">IELTS</Option>
              </Select>
          </Col>
          <Col span={3}>
              <Text type="secondary">Session type</Text>
              <Select defaultValue="All" style={{ width: 120 }} onChange={onChangeSessionType}>
                <Option value="All">All</Option>
                <Option value="Demo">Demo</Option>
                <Option value="Regular">Regular</Option>
              </Select>
          </Col>

          <Col span={3}>
            <Text type="secondary">Session for</Text>
              <Select defaultValue="Adult" style={{ width: 120 }} onChange={setSessionFor}>
                <Option value="Kids">Kids</Option>
                <Option value="Adult">Adult</Option>
              </Select>
          </Col>

          <Col span={3}>
            <Text type="secondary">Filter</Text>
              <Select defaultValue="All" style={{ width: 120 }} onChange={setTeacherJoin}>
                <Option value="Absent">Absent teachers</Option>
                <Option value="All">Show all</Option>
              </Select>
          </Col>

          <Col span={10} style={{"marginTop":"1.3rem"}}>
            <Space>
              <DatePicker
                onChange={onPickDate}
                defaultValue={moment(new Date(), dateFormat)}
                format={dateFormat}
              />
              <Input
              placeholder="Teacher name/meeting no" 
              onChange={handleInputChange}
              onKeyPress={handleEnterKey}
               />

              <Button
                type="primary"
                onClick={() => {
                  setAttendance([]);
                  setActivePage(1);
                  teacherAttendance(date, 1, "reset", searchQuery);
                }}
              >
                <SearchOutlined />
                Search
              </Button>
            </Space>
          </Col>
        </Row>
      </Card>

      {loader ? (
        <div className="spin-center">
          <Spin size="large" />
        </div>
      ) : (
        <Table
          columns={columns}
          dataSource={data}
          scroll={{ x: 2600 }}
          onChange={onChangePagination}
          pagination={{
            pageSize: 80,
            total: totalSession,
            defaultCurrent: activePage,
          }}
          rowClassName={(record, index) => absentIdentifier(record)}
        />
      )}
    </div>
  );
};

export default SessionAttendance;
