import moment from 'moment';
import {getTimezone} from 'countries-and-timezones';
import 'moment-timezone';
const setup = JSON.parse(localStorage.getItem("SETUP"));
// Load the full build.
var _ = require('lodash');

const userTimeZone = moment.tz.guess(true)
const zoneDetails = getTimezone(userTimeZone)

if(userTimeZone){
const locationdata = {
  country: zoneDetails.countries[0],
  timezone: zoneDetails.name,
};

localStorage.setItem("TIMEZONE", JSON.stringify(locationdata));
}

export const converter = (date) =>{
  moment.tz.setDefault(time_zone.timezone);
  var utcDate = moment(date).tz("UTC").format('YYYY-MM-DD');
  return utcDate
}

export const convertToUTCMomentObj = (date) => {
  moment.tz.setDefault(time_zone.timezone);
  var utcDate = moment(date).tz("UTC");
  return utcDate
}

export const convertUTC = (date) =>{
  moment.tz.setDefault("UTC");
  var utcDate = moment(date).tz(time_zone.timezone).format('YYYY-MM-DDTHH:mm');
  return utcDate
}

export const localToUTCDate = (date) => {
  //set agenda schedule as per user local time
  moment.tz.setDefault('Asia/Calcutta');
  let date_time = moment(date).tz("UTC").toDate();
  return date_time
};

export const getUtcDate = (date,time) =>{
  date = getAvailableTeacherSate(date)
  moment.tz.setDefault('UTC');
  var dateTime =moment(time,"HH:mm").toDate()
  var localTime = moment(dateTime).tz(time_zone.timezone).format('HH:mm');
  // console.log(`${date}T${localTime}:00`)
  // console.log(converter(`${date}T${localTime}:00`))
  return converter(`${date}T${localTime}:00`)
}

const time_zone = JSON.parse(localStorage.getItem("TIMEZONE"));
if(time_zone){
moment.tz.setDefault(time_zone.timezone);
}

export const validTimeConverter = (str) => {
  var time = str.split(":", 2);
  var hours = time[0];
  var minutes = time[1];
  minutes = minutes < 10 ? "0" + minutes : minutes;
  hours = hours < 10 ? "0" + hours : hours;
  var Time = hours + ":" + minutes;
  return Time;
};

export const TimeValidator = (str) => {
  var time = str.split(":");
  var hours = time[0];
  var minutes = time[1];
  if(minutes.length === 1 && hours.length === 1){
    var Time = "0"+hours + ":" + "0"+minutes;
  }
  else if(minutes.length === 1){
    var Time = hours + ":" + "0"+minutes;
  }else if(hours.length === 1){
    var Time = "0"+hours + ":" + minutes;
  }else{
    var Time = hours + ":" + minutes;
  }
  return Time;
};

export const formatAMPM = (sessiontime) => {

  moment.tz.setDefault('UTC');
  var time =moment(sessiontime,"HH:mm").toDate()
  var convertedTime = moment(time).tz(time_zone.timezone).format('HH:mm A');
  return convertedTime;
};

export const hhformatAMPM = (sessiontime) => {
  var splitedTime = sessiontime.split("-")
  var time1 =moment(splitedTime[0],"HH").toDate()
  var time2 =moment(splitedTime[1],"HH").toDate()
  var convertedTime1 = moment(time1).format('hh A');
  var convertedTime2 = moment(time2).format('hh A');
  return `${convertedTime1} - ${convertedTime2}`;
};

export const formatSpeakerProfileTimeslot = (sessiontime) =>{

    var splitedTime = sessiontime.split("-")
    var time1 =moment(splitedTime[0],"HH:mm").toDate()
    var time2 =moment(splitedTime[1],"HH:mm").toDate()
    var convertedTime1 = moment(time1).format('hh:mm A');
    var convertedTime2 = moment(time2).format('hh:mm A');
    return `${convertedTime1} - ${convertedTime2}`;
  
}

export const slotFormatAMPM = (sessiontime) => {
  var splitedTime = sessiontime.split("-")
  moment.tz.setDefault('UTC');
  var convertedTime1 = moment(splitedTime[0], ["HH:mm"]).format("hh:mm A");
  var convertedTime2 = moment(splitedTime[1], ["HH:mm"]).format("hh:mm A");

  return `${convertedTime1}-${convertedTime2}`;
};

export const overlapUTCtimeConverter = (splitedTime) => {

  moment.tz.setDefault('UTC');
  var time =moment(splitedTime,"HH:mm").toDate()
  var convertedTime = moment(time).tz(time_zone.timezone).format('HH:mm');
  return convertedTime;
};

export const localToutcTimeConverter = (usertime) => {
  moment.tz.setDefault(time_zone.timezone);
  var time =moment(usertime,"HH:mm").toDate()
  console.log("check time: ",time);
  var convertedTime = moment(time).tz("UTC").format('HH:mm');
  console.log("check convTime: ",convertedTime);
  return convertedTime;
};


export const localToutcTimeConverters = (localTime, localTimeZone, targetTimeZone = 'UTC') => {
  moment.tz.setDefault(localTimeZone);
  var time = moment(localTime, "h:mma");
  var convertedTime = time.clone().tz(targetTimeZone);
  const formattedUtcTime = convertedTime.format('HH:mm');
  return formattedUtcTime;
};


export const currentTimeUtc = () => {
  moment.tz.setDefault(time_zone.timezone);
  var time =moment().toDate()
  var convertedTime = moment(time).tz("UTC").format('HH:mm');
  return convertedTime;
};

export const currentTimeLocal = () => {
  moment.tz.setDefault(time_zone.timezone);
  var time =moment().toDate()
  var convertedTime = moment(time).tz("UTC").format('HH:mm');
  return convertedTime;
};

export const UTCtoLocalConverter = (date) => {
  moment.tz.setDefault('UTC');
  var time =moment(date).toDate()
  var convertedTime = moment(time).tz(time_zone.timezone).format('YYYY-MM-DD');
  console.log("check", convertedTime)
  return convertedTime;
};

export const UTCToLocalMomentObj = (date) => {
  moment.tz.setDefault('UTC');
  var time =moment(date).toDate()
  var convertedTime = moment(time).tz(time_zone.timezone);
  return convertedTime;
};

export const utcToLocal = (date,endTime) => {
  //set agenda schedule as per user local time
  moment.tz.setDefault('UTC');
  let date_time = moment(date + " " + endTime,"YYYY-MM-DD HH:mm").toDate();
  return date_time
};

export const LocalToutc = (date,time) => {
  var datee = moment(date).format('YYYY-MM-DD');
var dateTime = moment(datee + ' ' + time, 'YYYY-MM-DD HH:mm').toDate();
console.log("convTime", dateTime)
  return dateTime;
};

export const formatTimeslot = (sessiontime) => {
  const splited = sessiontime.split("-")
  moment.tz.setDefault('UTC');
  var time1 =moment(splited[0],"HH:mm").toDate()
  var convertedTime1 = moment(time1).tz(time_zone.timezone).format('hh:mm');
  var time2 =moment(splited[1],"HH:mm").toDate()
  var convertedTime2 = moment(time2).tz(time_zone.timezone).format('hh:mm');
  return convertedTime1+'-'+convertedTime2;
};

export const formatTimeSlotV2 = (sessiontime) => {
  const splited = sessiontime.split("-")
  moment.tz.setDefault('UTC');
  var time1 =moment(splited[0],"HH:mm").toDate()
  var convertedTime1 = moment(time1).tz(time_zone.timezone).format('HH:mm');
  var time2 =moment(splited[1],"HH:mm").toDate()
  var convertedTime2 = moment(time2).tz(time_zone.timezone).format('HH:mm');
  return convertedTime1+'-'+convertedTime2;
};

export const formatTimeslotAMPM = (sessiontime) => {
  const splited = sessiontime.split("-")
  moment.tz.setDefault('UTC');
  var time1 =moment(splited[0],"HH:mm").toDate()
  var convertedTime1 = moment(time1).tz(time_zone.timezone).format('hh:mm A');
  var time2 =moment(splited[1],"HH:mm").toDate()
  var convertedTime2 = moment(time2).tz(time_zone.timezone).format('hh:mm A');
  return convertedTime1+'-'+convertedTime2;
};

export const getDateutc = (date) => {
  var today = new Date(date);
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();
  today = dd + "-" + mm + "-" + yyyy ;
  return today;
};

export const getDate = (date) => {
  if(date){
  const incomingDate = date.split("T")
  return incomingDate[0]
  }
};

export const utcToLocalDateConverter = (date,endTime) => {
  //set agenda schedule as per user local time
  moment.tz.setDefault('UTC');
  let datetimeA = moment(date + " " + endTime,"YYYY-MM-DD HH:mm").toDate();
  var timeLocal =moment(datetimeA).tz(time_zone.timezone).format('YYYY-MM-DD')
  return timeLocal
};

export const utcToLocalDateTimeConverter = (date) => {
  //set agenda schedule as per user local time
  moment.tz.setDefault('UTC');
  let datetimeA = moment(date ,"YYYY-MM-DD HH:mm").toDate();
  var timeLocal =moment(datetimeA).tz(time_zone.timezone).format('YYYY-MM-DD hh:mm a')
  return timeLocal
};

export const utcToLocalTimeConverter = (date) => {
  moment.tz.setDefault('UTC');
  let datetimeA = moment(date ,"YYYY-MM-DD HH:mm").toDate();
  var timeLocal =moment(datetimeA).tz(time_zone.timezone).format('hh:mm a')
  return timeLocal
};


export const getAvailableTeacherSate = (date) => {
  // moment.tz.setDefault(time_zone.timezone);
  const t2 = moment.utc(date).tz(time_zone.timezone).format('YYYY-MM-DD');
  return t2;
};


export const dayRemaining = (date0,date01) => {
  var date1 = new Date();
  var date2 = new Date(date01);

  // To calculate the time difference of two dates
  var Difference_In_Time = date2.getTime() - date1.getTime();
    
  // To calculate the no. of days between two dates
  var Difference_In_Days = Math.round(Difference_In_Time / (1000 * 3600 * 24));
  // alert(Difference_In_Days)
  return Difference_In_Days
};

//if val = 0 return tiday val=1 return tommo
export function genDate(val) {
  var today = new Date(
    new Date().toLocaleString("en-US", { timeZone: "Asia/Calcutta" })
  );
  var dd = String(today.getDate() + val).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();
  today = yyyy + "-" + mm + "-" + dd;
  return today;
}

export const genDemoDate = (str) => {
  var splited = str.split("to", 2);
  const a = parseInt(splited[0]);
  const b = parseInt(splited[1]);
  var val = 0;
  const arr = [];
  const arr2 = [];
  arr2.push(`${a + ":" + "00"}`);
  for (var i = 0; i < 4; i++) {
    val = val + 15;
    arr.push(val);
    console.log(arr);
    arr2.push(`${a + ":" + arr[i]}`);
    return arr2;
  }
};

export const downcounter = (date) => {
  var end = new Date(date);

  var _second = 1000;
  var _minute = _second * 60;
  var _hour = _minute * 60;
  var _day = _hour * 24;
  var timer;

  function showRemaining() {
    var now = new Date();
    var distance = end - now;
    if (distance < 0) {
      clearInterval(timer);
      document.getElementById("countdown").innerHTML = "Expired";

      return;
    }
    var days = Math.floor(distance / _day);
    var hours = Math.floor((distance % _day) / _hour);
    var minutes = Math.floor((distance % _hour) / _minute);

    if (document.getElementById("countdown")) {
      document.getElementById("countdown").innerHTML = days + "days ";
      document.getElementById("countdown").innerHTML += hours + "hrs ";
      document.getElementById("countdown").innerHTML += minutes + "mins ";
    }
  }

  timer = setInterval(showRemaining, 1000);
};

export const availableDate = (i) => {

  var date = new Date();
  date.setHours(date.getHours()+ parseInt(setup?setup.bookingLag:"2"))//+4
  var week = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  var month = date.getMonth();
  var day = date.getDate();
  var year = date.getFullYear();

  // console.log(`${year+'-'+day+'-'+month}`)

  var date2 = new Date(year, month, day + i);

  return {
    formated: `${
      date2.getMonth() +
      1 +
      "/" +
      date2.getDate() +
      "(" +
      week[date2.getDay()] +
      ")"
    }`,
    month: moment(date2).format('MMM'),
    dateNo:date2.getDate(), //String(date2.getDate()).padStart(2,'0')
    date: date2,
    weekDay:week[date2.getDay()]
  };
};

// console.log(date.getDate())


export const textDott = (text, count) => {
  return text.slice(0, count) + (text.length > count ? "..." : "");
}

export const speakerDayTime = (date,weeklyTimes) =>{
  var week = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  var dayTime = weeklyTimes[week[new Date(date).getDay()]];
  return dayTime
}

export const availableHrDataFormater = (data) =>{
    
  data.map((res,i)=>{
    if(res._id.startTime !== "N/A"){
    moment.tz.setDefault("UTC");
    var time =moment(`${res._id.startTime} ${res._id.day}`,'HH:mm dddd').toDate();
    var t1 = moment(time).tz('Asia/Kolkata').format('HH:mm');
    //end time
    var time2 =moment(`${res._id.endTime} ${res._id.day}`,'HH:mm dddd').toDate();
    var t2 = moment(time2).tz('Asia/Kolkata').format('HH:mm');
    //day
    var convertedDay = moment(time).tz('Asia/Kolkata').format("dddd")

    res._id.startTime = t1
    res._id.endTime = t2
    res._id.day = convertedDay
    res._id["timeSlot"] = `${res._id.startTime} - ${res._id.endTime}`
    }else{
      console.log("N/A")
    }
  })
  
  const groupByDay = _.mapValues(_.groupBy(data, '_id.day'),list => list.map(day => _.omit(day, '_id.day')));
  return groupByDay

  }

export const bookedHrDataFormater = (data) =>{
  data.map((res)=>{
    moment.tz.setDefault("UTC");
    const splitedDate = res._id.sessionDate.split("T")
    var day =moment(`${splitedDate[0]} ${res._id.endTime}`).tz('Asia/Kolkata').format("dddd");
    var startTime = moment(`${splitedDate[0]}T${res._id.startTime}`);
    var endTime = moment(`${splitedDate[0]}T${res._id.endTime}`);

    const diff = endTime.diff(startTime,'minutes')

    res._id["duration"] = diff
    res._id["day"] = day
    res._id.startTime = moment(startTime).tz('Asia/Kolkata').format('HH:mm');
    res._id.endTime = moment(endTime).tz('Asia/Kolkata').format('HH:mm');
    res._id["timeSlot"] = `${res._id.startTime} - ${res._id.endTime}`

  })

  const groupByDay = _.mapValues(_.groupBy(data, '_id.day'),list => list.map(day => _.omit(day, '_id.day')));
  return groupByDay

}

const isOverlapping = (splittedStartTime, splittedEndTime, bookedSessionStartTime, bookedSessionEndTime) => {
  return splittedStartTime <= bookedSessionEndTime && bookedSessionStartTime <= splittedEndTime;
};

export const bookedHrByTimeslot = (timeslot,bookedSession) =>{
  var bookedHr = 0;

  if(!bookedSession){
    return bookedHr
  }

  const splitedSlot = timeslot?.split(" - ")

  splitedSlot && bookedSession.map(session =>{
    // console.log({
    //   splittedStartTime:splitedSlot[0],
    //   splittedEndTime:splitedSlot[1],
    //   bookedSessionStartTime:session._id.startTime,
    //   bookedSessionEndTime:session._id.endTime,
    //   reserved:isOverlapping(splitedSlot[0],splitedSlot[1],session._id.startTime,session._id.endTime)
    // })
    if(isOverlapping(splitedSlot[0],splitedSlot[1],session._id.startTime,session._id.endTime)){
      const splitedDate = session._id.sessionDate.split("T")
      var startTime = moment(`${splitedDate[0]}T${session._id.startTime}`);
      var endTime = moment(`${splitedDate[0]}T${session._id.endTime}`);
      const diff = endTime.diff(startTime,'minutes')
      bookedHr += diff * session.teachers.length
    }

  })


  return Number.isInteger(bookedHr / 60)?(bookedHr / 60):(bookedHr / 60).toFixed(2)
}

  export const calculateTotalHr = (data) =>{
    var total = 0;
    for(var i = 0;i < data.length; i++){
      const count = data[i]
      total += count.totalHr
    }
    return total
  }

  export const calculateBookedHr = (data) =>{
    var total = 0;
    if(data){
      for(var i = 0;i < data.length; i++){
        const count = data[i]
        total += Number(count._id.duration) * count.totalHr
      }
    }
    return Number.isInteger(total / 60)?(total / 60):(total / 60).toFixed(2);  
  }

  export const zeroPadding = (time) =>{
    const splitedTime = time.split(":")
    const start = String(splitedTime[0]).padStart(2, "0");
    const end = String(splitedTime[1]).padStart(2, "0");
    return `${start}:${end}`
  }