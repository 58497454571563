import { Tabs } from 'antd';
import React, { useState } from 'react';
import {DndProvider, DropTarget, useDrag, useDrop} from "react-dnd";
import { HTML5Backend } from 'react-dnd-html5-backend';

const {TabPane} = Tabs;

const DraggableTab = ({ index, title, onTabDrag, children, ...restProps}) => {
    const [{ isDragging }, drag] = useDrag(() => ({
      type: 'TAB',
      item: { index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }));
  
    const [{ canDrop, isOver }, drop] = useDrop(() => ({
      accept: 'TAB',
      drop: (item, monitor) => {
        if (item.index !== index) {
          onTabDrag(item.index, index);
        }
      },
      collect: (monitor) => ({
        canDrop: monitor.canDrop(),
        isOver: monitor.isOver(),
      }),
    }));
  
    const isActive = canDrop && isOver;
  
    return (
      <div ref={drop}>
        <TabPane
          ref={drag}
          tab={title}
          key={index}
          className={isActive ? 'drag-active' : ''}
          {...restProps}
        >
          {children}
        </TabPane>
      </div>
    );
  };

  const HOC = () => {
    const CustomTabPane = ({tab, key, children, ...restProps}) => {
        return <TabPane key={key} tab={tab} {...restProps}>
            {children}
        </TabPane>
    }
    return CustomTabPane;
  }

  const NewTabPane = HOC();
  

const NewDraggableTabs = ({children, onEdit, panes, setPanes}) => {
    console.log(panes);
    const [order, setOrder] = useState([]);
    const [activeKey, setActiveKey] = useState('1');


    const moveTabNode = (dragKey, hoverKey) => {
        const newOrder = order.slice();    
        children.forEach(children, c => {
          if (newOrder.indexOf(c.key) === -1) {
            newOrder.push(c.key);
          }
        });

    const dragIndex = newOrder.indexOf(dragKey);
    const hoverIndex = newOrder.indexOf(hoverKey);

    newOrder.splice(dragIndex, 1);
    newOrder.splice(hoverIndex, 0, dragKey);

    setOrder(newOrder);
  };

  const handleTabDrag = (dragIndex, hoverIndex) => {
    const dragPane = panes[dragIndex];
    const newPanes = [...panes];
    newPanes.splice(dragIndex, 1);
    newPanes.splice(hoverIndex, 0, dragPane);
    setPanes(newPanes);
  };


  

    
    
  return (
    <DndProvider backend={HTML5Backend}>
    <Tabs
      type="editable-card"
      onChange={(key)=>setActiveKey(key)}
      activeKey={activeKey}
      onEdit={onEdit}
    >
      {panes.map((pane, index) => (
        
        <DraggableTab
          key={pane.key}
          index={index}
          title={pane.title}
          onTabDrag={handleTabDrag}
          tab={pane.title}
        >
        {pane.content}
        </DraggableTab>
      ))}
    </Tabs>
  </DndProvider>

  )
}

{/* <DraggableTab
          key={pane.key}
          index={index}
          title={pane.title}
          onTabDrag={handleTabDrag}
          tab={pane.title}
        >
        {pane.content}
        </DraggableTab> */}

        {/* <NewTabPane key={pane.key} tab={pane.title}>
            {pane.content}
        </NewTabPane> */}

export default NewDraggableTabs