import axios from "axios";

axios.defaults.headers.common["platform"] = "AdminWeb";
// eslint-disable-next-line import/no-anonymous-default-export
export default {
    Courses(url = `${process.env.REACT_APP_BASE_URL}`) {
        return {
            addEditCourse: (data) => axios.post(url+`/api/courses/addOrEditCourse`,data),
            getAllCourse: (page,limit) => axios.get(url+`/api/courses/getAllCourses?page=${page}&limit=${limit}`),
            getACourse: (data) => axios.post(url+`/api/courses/getCourseData`,data),
            deleteCourse: (data) => axios.post(url+`/api/courses/deleteCourse`,data),
            GetSubscriptionBundle : () => axios.get(url+`/api/courses/getSubscriptionBundle`),
            addToSubscriptionBundle: (data) => axios.post(url+`/api/courses/addToSubscriptionBundle`,data),
            removeFromSubscriptionBundle: (data) => axios.post(url+`/api/courses/removeFromSubscriptionBundle`,data),
        }
    }
}
