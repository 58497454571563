import React, { useEffect } from "react";
import { Card, Col, Row, Avatar, Button, Spin } from "antd";
import PageBreadcrumb from "../../component/PageBreadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { GetAllLearner, GetSubscribedUser } from "../../redux/actions/UserAction";
const { Meta } = Card;

const SubscribedUsers = () => {
  const dispatch = useDispatch();
  const subscribedUser = useSelector(
    (state) => state.AdminReducer.subscribedUser
  );

  useEffect(() => {
    dispatch(GetAllLearner());
  }, []);

  return (
    <div className="site-card-wrapper">
      <PageBreadcrumb route={["dashboard", "Subscribed users"]} />
      <Row gutter={16}>
        {subscribedUser ? (
          subscribedUser.map((data, i) => (
            <Col span={8} key={i} style={{ marginTop: 13 }}>
              <Card
                actions={[
                  <Button className="button1" block>
                    <Link
                      to={{
                        pathname: "/subscriptiondetails",
                        details: {
                          id:data._id,
                          phone:data.phoneNumber,
                          name:data.name
                        },
                      }}
                      style={{ color: "#fff" }}
                    >
                       Details
                    </Link>
                  </Button>,
                ]}
              >
                <Meta
                  avatar={
                    <Avatar
                      size={64}
                      src="https://img1a.flixcart.com/www/linchpin/fk-cp-zion/img/profile-pic-male_4811a1.svg"
                    />
                  }
                  title={data.name}
                  description={data.phoneNumber}
                />
              </Card>
            </Col>
          ))
        ) : (
          <Col span={24}>
            <div className="spin-center">
              <Spin size="large" />
            </div>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default SubscribedUsers;
