import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Space,
  Spin,
  Row,
  Col,
  message,
  DatePicker,
  Card,
  Tag,
  Select,
  Typography
} from "antd";
import moment from "moment";
import { useDispatch } from "react-redux";
import PageBreadcrumb from "../../component/PageBreadcrumb";
import { GetMeetingRecording, TeacherAttendance } from "../../redux/actions/SessionAction";
import { UTCToAsiaTimeConverter } from "../../helper/helper";

const dateFormat = "YYYY-MM-DD";
const { Option } = Select;
const { Text } = Typography;

const JoiningTime = ({ times }) => {
  var timeArray = [];

  if (times.constructor === Array) {
    if (times.length > 0) {
      timeArray = times;
    } else {
      timeArray = ["Absent"];
    }
  } else if (typeof times === String) {
    timeArray = [times];
  } else {
    timeArray = ["Absent"];
  }

  return (
    <>
      {timeArray.map((tag, i) => {
        let color = "green";
        if (tag === "Absent") {
          color = "volcano";
        }
        return (
          <Tag color={color} key={`et128wy2ye${i}`}>
            {tag.toUpperCase()}
          </Tag>
        );
      })}
    </>
  );
};

const httpValidator = (url) =>{
  if (!/^https?:\/\//i.test(url)) {
   return'https://' + url;
  }else{
    return url
  }
}

const LearnerSessionAttendance = () => {
  const dispatch = useDispatch();
  const [date, setDate] = useState(moment(new Date()).format(dateFormat));
  const [totalSession, setTotalSession] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [sessionType, setSessionType] = useState("All");
  const [learnerJoin, setLearnerJoin] = useState("All");
  const [loader, setLoader] = useState(false);
  const [attendance, setAttendance] = useState([]);
  const [subject, setSubject] = useState("English");
  const [sessionFor, setSessionFor] = useState("Adult");
  const [recordingLoader, setRecordingLoader] = useState(false);

  useEffect(() => {
    teacherAttendance(date, 1);
  }, []);

  const recording = (id) => {
    setRecordingLoader(id);
    const data = {
      meetingId: id,
    };
    const onSuccess = (data) => {
      setRecordingLoader(false);
      if(!data?.uuid){
        const newWindow = window.open('', '_blank');
      
        const videoHtml = `
          <html>
            <head>
              <title>Recording</title>
            </head>
            <body style="margin:0">
              <video controls autoplay style="width:100vw; height:100vh;">
                <source src="${data.recording_files[0].play_url}" type="video/mp4">
                Your browser does not support the video tag.
              </video>
            </body>
          </html>
        `;
    
        newWindow.document.write(videoHtml);
        newWindow.document.close();
        }else{
          window.open(data.recording_files[0].play_url, '_blank');
          }
    };
    const onError = (err) => {
      setRecordingLoader(false);
      message.error(err, 8);
    };
    dispatch(GetMeetingRecording(data, onSuccess, onError));
    
  };

  const columns = [
    { title: "No", width: 10, dataIndex: "no", key: "1" },
    { title: "Meeting No", width: 30, dataIndex: "meetingNo", key: "2" },
    { title: "Teacher Name", width: 30, dataIndex: "teacherName", key: "3" },
    { title: "Learner Name", width: 30, dataIndex: "learnerName", key: "4" },
    { title: "Session Date", width: 30, dataIndex: "sessionDate", key: "5" },
    { title: "Session Time", width: 20, dataIndex: "sessionTime", key: "6" },
    { title: "Duration", width: 15, dataIndex: "duration", key: "7" },
    {
      title: "Learner Joining Time",
      width: 50,
      dataIndex: "learnerJoining",
      key: "8",
      render: (joiningtime) => <JoiningTime times={joiningtime} />,
    },
    { title: "Session Type", width: 20, dataIndex: "sessionType", key: "9" },
    { title: "Tech Failure", width: 20, dataIndex: "techFailure", key: "10" },
    { title: "Phone number", width: 20, dataIndex: "phonenum", key: "11" },
    { title: "Email", width: 30, dataIndex: "email", key: "12" },
    { title: "Learner device", width: 60, dataIndex: "learnerDevice", key: "13" },
    {
      title: "Recording",
      key: "meetingRecording",
      fixed: "right",
      width: 20,
      render: ({ meetingNo,meetingRecording }) => (
        <>
        {meetingRecording === null?
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
               <a onClick={()=>recording(meetingNo)}>
                 {recordingLoader === meetingNo?"Fetching...":"Recording"}
               </a>:
        <a href={meetingRecording} target="_blank">
          Recording    
        </a>
    }
        </>
      ),
    },
  ];

  function onPickDate(date, dateString) {
    setDate(dateString);
  }
  const onChangeSessionType = (value) => {
    setSessionType(value);
  };
  const setLearnerJoining = (e) => {
    setLearnerJoin(e.target.value);
  };

  const onChangePagination = (page, pageSize) => {
    if (page.current > activePage) {
      teacherAttendance(date, page.current);
      setActivePage(page.current);
    }
  };

  const absentIdentifier = (data) => {
    var convertedTime = UTCToAsiaTimeConverter(data.sessionTime);
    var splitedValue = convertedTime.split(":");
    var joiningTime =
      splitedValue[0] +
      ":" +
      String(Number(splitedValue[1]) + 2).padStart(2, "0");

    if (data.learnerJoining.constructor === Array) {
      if (data.learnerJoining.length > 0) {
        if (UTCToAsiaTimeConverter(data.learnerJoining[0]) > joiningTime) {
          return "yellow";
        } else if (data.techFailure === "True") {
          return "red";
        } else {
          return "green";
        }
      } else if (data.techFailure === "True") {
        return "red";
      } else {
        return "red";
      }
    } else {
      return "red";
    }
  };

  var data = attendance?.map((data, i) => ({
    key: i,
    no: i + 1,
    meetingNo: data.meetingNo,
    teacherName: data.teacher?.name,
    learnerName: data.learner?.name,
    kid:data.isKid?"True":"False",
    sessionDate: moment(new Date(data.sessionDate)).format(dateFormat),
    sessionTime: UTCToAsiaTimeConverter(data.startTime),
    duration: data.sessionDuration,
    learnerJoining: UTCToAsiaTimeConverter(data.learnerJoiningTime?.slice(0, 3)),
    sessionType: data.type,
    techFailure: data.isTechFailure ? "True" : "False",
    phonenum: data.learner
      ? data.learner.phoneNumber
        ? data.learner.phoneNumber
        : "N/A"
      : "N/A",
    email: data.learner
      ? data.learner.email
        ? data.learner.email
        : "N/A"
      : "N/A",
    learnerDevice:data?.learnerDeviceDetails,
    meetingRecording: data.meetingRecording
    ? `https://clapingo-testing.netlify.app/recordings?link=${httpValidator(data.meetingRecording)}`
    :null,
  }));

  const teacherAttendance = (date, activePage, reset) => {
    setLoader(true);
    var data = {
      date: date,
      subject:subject,
      forKids:sessionFor === "Kids"?true:false,
    };

    if (sessionType !== "All") {
      data["sessiontype"] = sessionType;
    }
    if (learnerJoin !== "All") {
      data["absentLearner"] = true;
    }

    var params = {
      page: activePage,
      limit: 80,
    };
    var onSuccess = (response) => {
      if (reset) {
        setAttendance(response.session);
      } else {
        setAttendance([...attendance, ...response.session]);
      }

      setTotalSession(Number(response.totalSession));
      setLoader(false);
    };
    var onError = (error) => {
      console.log(error);
      setLoader(false);
    };
    dispatch(TeacherAttendance(data, params, onSuccess, onError));
  };

  return (
    <div>
      <PageBreadcrumb route={["dashboard", "Learner Attendance"]} />

      <Card title="Filter Session" style={{ marginBottom: "1rem" }}>
      <Row>
        <Col span={3}>
            <Text type="secondary">Subject</Text>
              <Select defaultValue="English" style={{ width: 120 }} onChange={setSubject}>
                <Option value="English">English</Option>
                <Option value="IELTS">IELTS</Option>
              </Select>
          </Col>
          <Col span={3}>
              <Text type="secondary">Session type</Text>
              <Select defaultValue="All" style={{ width: 120 }} onChange={onChangeSessionType}>
                <Option value="All">All</Option>
                <Option value="Demo">Demo</Option>
                <Option value="Regular">Regular</Option>
              </Select>
          </Col>

          <Col span={3}>
            <Text type="secondary">Session for</Text>
              <Select defaultValue="Adult" style={{ width: 120 }} onChange={setSessionFor}>
                <Option value="Kids">Kids</Option>
                <Option value="Adult">Adult</Option>
              </Select>
          </Col>

          <Col span={3}>
            <Text type="secondary">Filter</Text>
              <Select defaultValue="All" style={{ width: 120 }} onChange={setLearnerJoin}>
                <Option value="Absent">Absent learner</Option>
                <Option value="All">Show all</Option>
              </Select>
          </Col>

          <Col span={6} style={{"marginTop":"1.3rem"}}>
            <Space>
              <DatePicker
                onChange={onPickDate}
                defaultValue={moment(new Date(), dateFormat)}
                format={dateFormat}
              />
              <Button
                type="primary"
                onClick={() => {
                  setAttendance([]);
                  setActivePage(1);
                  teacherAttendance(date, 1, "reset");
                }}
              >
                Search
              </Button>
            </Space>
          </Col>
        </Row>
      </Card>

      {loader ? (
        <div className="spin-center">
          <Spin size="large" />
        </div>
      ) : (
        <Table
          columns={columns}
          dataSource={data}
          scroll={{ x: 2400 }}
          onChange={onChangePagination}
          pagination={{
            pageSize: 80,
            total: totalSession,
            defaultCurrent: activePage,
          }}
          rowClassName={(record, index) => absentIdentifier(record)}
        />
      )}
    </div>
  );
};

export default LearnerSessionAttendance;
