import axios from "axios";

const admin = JSON.parse(localStorage.getItem("ADMIN"))

if(admin){
    axios.defaults.headers.common["x-access-token"] = admin.token;
}
axios.defaults.headers.common["platform"] = "ADMIN_WEB";
// if(timezone){
//     axios.defaults.headers.common["timezone"] = timezone?.timezone;
// }
// eslint-disable-next-line import/no-anonymous-default-export
export default {
    DemoPoolApi(url = `${process.env.REACT_APP_BASE_URL}/api`) {
        return {
            AddDemoPools: (data) => axios.post(url +"/schedule/addDemoPool",data),
            GetDemoPools: () =>axios.get(url+"/schedule/getDemoPool"),
            UpdateDemoPools: (data) => axios.put(url+"/schedule/updateDemoPool", data),
            getAvailableSlots: (data) => axios.post(url+"/teacher/getAvailableSlots", data),
            getMaxAvailableSlots: (localDate, timezone,demoConversionFilter) => axios.get(`${url}/schedule/getMaxAvailableDemoSlots?localDate=${localDate}&timezone=${timezone}&demoConversionFilter=${demoConversionFilter}`),
            getPoolDetails: (poolid) => axios.get(`${url}/schedule/getDemoPoolStats?poolId=${poolid}`)
           
        }
    }
}




