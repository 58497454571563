import * as constant from "../constants/Constant";

const initialState = {
  admin: {
    isLogin: false,
    token: null,
    details: null,
  },
  campaign: null,
  teacher: null,
  learner: null,
  subscribedUser: null,
  statistics: null,
  sessionDayWise: null,
  referralData: null,
  subscriptionAndRetention: null,
  timeSlotStats: null,
  popuarCitys:null,
  getAllTeacherLoader:true
};

export const AdminReducer = (state = initialState, action) => {
  switch (action.type) {
    case constant.ADMIN_LOGIN:
      return {
        ...state,
        admin: {
          isLogin: true,
          token: action.payload.token,
          details: {
            name: action.payload.name,
            number: action.payload.phoneNumber,
            role: action.payload.roles,
          },
        },
      };
    case constant.GET_ALL_TEACHER:
      return {
        ...state,
        getAllTeacherLoader:false,
        teacher: state.teacher===null ?action.payload : [...state.teacher, ...action.payload],
      };
    case constant.GET_ALL_LEARNER:
      return {
        ...state,
        learner: action.payload,
      };
    case constant.GET_ALL_SUBSCRIBED_USER:
      return {
        ...state,
        subscribedUser: action.payload,
      };
    case constant.STASTISTICS:
      return {
        ...state,
        statistics: action.payload,
      };
    case constant.SESSION_DAY_WISE:
      return {
        ...state,
        sessionDayWise: action.payload,
      };

    case constant.FETCH_REFERRAL_DATA:
      return {
        ...state,
        referralData: action.payload,
      };
    case constant.FETCH_SUBSCRIPTION_DATA:
      return {
        ...state,
        subscriptionAndRetention: action.payload,
      };

    case constant.FETCH_CAMPAIGN_DATA:
      return {
        ...state,
        campaign: action.payload,
      };

    case constant.SET_TIME_SLOT_DETAILS:
      return {
        ...state,
        timeSlotStats: action.payload,
      };

      case constant.GET_CITY_COUNT:
        return {
          ...state,
          popuarCitys: action.payload,
        };
    //default
    default:
      return state;
  }
};

