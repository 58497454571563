import React, { useState } from "react";
import {
  Modal,
  Button,
  Tabs,
  Form,
  Input,
  Row,
  Col,
  message,
  Select,
  InputNumber,
} from "antd";
import countries from "../../data/countries.json";
import callingcode from "../../data/callingCode.json";
import { useDispatch } from "react-redux";
import { AddUser } from "../../redux/actions/UserAction";
import { CreateAdmin } from "../../redux/actions/AuthAction";

const { TabPane } = Tabs;
const { Option } = Select;

const AddAdmin = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [callingCode, setCallingCode] = useState("+91");
  const [country, setCountry] = useState("IN");
  const [timezone, setTimeZone] = useState("Asia/Kolkata");
  const [loader, setLoader] = useState(false);

  const onFinish = (values) => {
    const token = JSON.parse(localStorage.getItem("TOKEN"));
    const newAdmindata = {
      name: values.name,
      password: values.password,
      phoneNumber: values.phoneNumber,
      roles: ["admin"],
      notificationid: token?.toString(),
    };
    createAdmin(newAdmindata);
  };
  const createAdmin = (data) => {
    const onSuccess = (res) => {
      message.success(res);
      setVisible(false);
    };
    const onError = (errmsg) => {
      message.error(errmsg);
      setVisible(false);
    };
    CreateAdmin(data, onSuccess, onError);
  };

  return (
    <>
      <Button type="primary" onClick={() => setVisible(true)}>
        Add Admin
      </Button>
      <Modal
        centered
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
        width={1000}
      >
        <Tabs defaultActiveKey="1" centered>
          <TabPane tab="Add admin" key="1">
            <Form
              form={form}
              layout="vertical"
              autoComplete="off"
              onFinish={onFinish}
            >
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="Name"
                    rules={[{ required: true }]}
                    tooltip="This is a required field"
                    name="name"
                  >
                    <Input placeholder="Add admin name" />
                  </Form.Item>
                  <Form.Item
                    label="Phone number"
                    rules={[{ required: true }]}
                    tooltip="This is a required field"
                    name="phoneNumber"
                  >
                    <Input placeholder="Add phone number" />
                  </Form.Item>

                  <Form.Item
                    label="Password"
                    rules={[{ required: true }]}
                    tooltip="This is a required field"
                    name="password"
                  >
                    <Input placeholder="Add password" />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item>
                <Button type="primary" htmlType="submit" loading={loader} block>
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </TabPane>
        </Tabs>
      </Modal>
    </>
  );
}

export default AddAdmin;
