import { Button, message, Radio, Space, Spin, Table, Tag, DatePicker } from "antd";
import Modal from "antd/lib/modal/Modal";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {  utcToLocalDateTimeConverter } from "../../helper/TimeDate";
import { GetAdminList } from "../../redux/actions/AuthAction";
import {
  GetMeetingRecording,
} from "../../redux/actions/SessionAction";

import {
  ChangeStatus,
  GetAllApprovalReq,
  SearchByName,
  SearchBySerialNum,
} from "../../redux/actions/UserAction";

const { RangePicker } = DatePicker;

const techIssueNotification = (requester,issue,meetingNo,serialNumber) =>{
  const webhookUrl = `${process.env.REACT_APP_BASE_URL}/yt-approval-tech-issue-alert`;

    const message = {
      text: `Hi <@U054UACML48> <@U037Y65LFA8>, 

    A new ticket has been raised by the admin. Please take a moment to review and address the request.

    *Ticket Details:*

      Requester: ${requester}
      Ticket Subject: ${issue}
      Meeting No: ${meetingNo}
      Serial No: ${serialNumber}

    Thank you :)
    --------------- x ---------------
    ` 
    };

    fetch(webhookUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(message),
    })
      .then(response => {
        if (!response.ok) {
          message.error(`HTTP error! Status: ${response.status}`)
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        console.log('Message sent successfully:', data);
      })
      .catch(error => {
        message.error("Error sending message to developer")
        console.error('Error sending message:', error);
      });

}


const StatusOptionModal = ({ requestId, allRequests, setAllRequests,editorList,requirement,reviewerList,meetingNo,serialNumber }) => {

  const admin = useSelector(state => state.AdminReducer.admin)

  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [value, setValue] = useState(null);
  const [videoURL, setVideoURL] = useState(null);
  const [editingRequirement, setEditingRequirement] = useState(requirement?requirement:"N/A");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [editor, setEditor] = useState();
  const [reviewer, setReviewer] = useState();
  const [learnerImg, setLearnerImg] = useState();
  const [teacherImg, setTeacherImg] = useState();
  const [videoThumbnail, setVideoThumbnail] = useState("")
  const [declineReason, setDeclineReason] = useState()
  const [otherDeclineReason, setOtherDeclineReason] = useState()
  const [onHoldReason, setOnHoldReason] = useState()
  const [technicalIssue, setTechnicalIssue] = useState()
  const [otherTechnicalReason, setOtherTechnicalReason] = useState()
  const [videoLink, setVideoLink] = useState("")

    // Update useEffect to pre-fill fields for "Approved" status
    useEffect(() => {
      if (value === "Approved" && allRequests?.length > 0) {
        const approvedRequest = allRequests?.find((req) => req._id === requestId);
        if (approvedRequest) {
          setVideoURL(approvedRequest?.approved_video_url || "");
          setLearnerImg(approvedRequest?.thumbnailImages?.learnerImage ||"");
          setTeacherImg(approvedRequest?.thumbnailImages?.teacherImage || "");
          setVideoThumbnail(approvedRequest.thumbnail || "");
          setVideoLink(approvedRequest?.approved_short_video_url || "")
        }
      }
    }, [value, allRequests, requestId]);


  const handleOk = () => {
    setSubmitLoading(requestId);
    var data = {
      requestId: requestId,
      status: value,
    };

    if (!value) {
      message.error("Please select status !", 8);
      return;
    }

    // Validation
    if(value === "Declined" && !declineReason){
      message.error("Please select declined reason !", 8);
      setSubmitLoading(false);
      return;
    }else if(value === "Declined" && declineReason === "Other Reasons" && !otherDeclineReason){
      setSubmitLoading(false);
      message.error("Please select declined reason !", 8);
      return;
    }else if (value === "Approved" && (!videoURL || !videoThumbnail || !learnerImg || !teacherImg)) {
      message.error("Approved Video link, Teacher thumbnail image , Learner thumbnail image and Video thumbnail are required please fill the missing field !", 8);
      setSubmitLoading(false);
      return;
    }else if (value === "Assign" && !reviewer) {
      message.error("Please Provide Explanation for Decline !", 8);
      setSubmitLoading(false);
      return;
    }

    if(value === "Declined"){
      data["declinedReason"] = otherDeclineReason ? otherDeclineReason : declineReason
    }

    if (value === "Assign") {
      data["reviewerId"] = reviewer;
      data["status"] = 'Assigned';
    }

    if (value === "Approved") {
      data["videoUrl"] = videoURL;
      data["learnerImage"] = learnerImg;
      data["teacherImage"] = teacherImg;
      data["thumbnail"] = videoThumbnail;
      data["shortVideoUrl"] = videoLink;
    }

    if (value === "InReview" ) {
      data["editorId"] = editor;
      data["editingRequirement"] = editingRequirement;
    }

    if (value === "TechnicalIssue" ) {
      data["ReviewerMessage"] = otherTechnicalReason?otherDeclineReason:technicalIssue;
    }

    if (value === "OnHold" ) {
      data["ReviewerMessage"] = onHoldReason;
    }

    const onSuccess = (msg) => {
      var filteredRequests = allRequests.filter((val) => val._id !== requestId);
      setAllRequests(filteredRequests);
      message.success(msg, 8);
      setIsModalVisible(false);
      setSubmitLoading(false);

      if (value === "TechnicalIssue" ) {
        techIssueNotification(
        admin.details.name,
        otherTechnicalReason?otherDeclineReason:technicalIssue,
        meetingNo,serialNumber)
      }
    };

    const onError = (err) => {
      message.error(err, 8);
      setSubmitLoading(false);
    };

    dispatch(ChangeStatus(data, onSuccess, onError));
  };

  return (
    <>
      <Button type="primary" onClick={()=>setIsModalVisible(true)}>
        Update Status
      </Button>
      <Modal
        title="Change status"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={()=>setIsModalVisible(false)}
        footer={[
          <Button
            key="submit"
            type="primary"
            loading={submitLoading}
            onClick={handleOk}
          >
            Submit
          </Button>,
          <Button key="cancel" type="primary" onClick={()=>setIsModalVisible(false)}>
            cancel
          </Button>
        ]}
      >
        <Radio.Group onChange={(e)=>setValue(e.target.value)} value={value}>
          <Space direction="vertical">
            <Radio value={"Assign"}>Assign reviewer</Radio>
            <Radio value={"InReview"}>InReview</Radio>
            <Radio value={"Approved"}>Approved</Radio>
            <Radio value={"Declined"}>Declined</Radio>
            <Radio value={"TechnicalIssue"}>Technical Issue</Radio>
            <Radio value={"OnHold"}>On Hold</Radio>
          </Space>
        </Radio.Group>

        {value === "Approved" ? (
          
          <div style={{ display: "flex",flexDirection:"column",marginTop:"1rem" }}>
            <label>Approved video link :</label>
            <input
              value={videoURL}
              onChange={(e) => setVideoURL(e.target.value)}
            />
            <label>Youtube Shorts link :</label>
            <input
              value={videoLink}
              onChange={(e) => setVideoLink(e.target.value)}
            />
            <label>Learner thumbnail image :</label>
            <input
              value={learnerImg}
              onChange={(e) => setLearnerImg(e.target.value)}
            />
            <label>Teacher thumbnail image :</label>
            <input
              value={teacherImg}
              onChange={(e) => setTeacherImg(e.target.value)}
            />
            <label>Video thumbnail :</label>
            <input
              value={videoThumbnail}
              onChange={(e) => setVideoThumbnail(e.target.value)}
            />
          </div>
        ) : 
        value === "InReview" ? (
          <div >
            <div style={{marginTop:"1rem"}}>
              <select onChange={(e)=>setEditor(e.target.value)} style={{width:"70%",padding:'6px'}}>
                <option>Select editor:</option>
                {editorList?.map((editor) => (
                  <option key={editor._id} value={editor._id}>{editor.name}</option>
                ))}
              </select>
            </div>
            <br />
            <label>Editing requirement :</label>

            <textarea
              style={{ width: "100%" }}
              value={editingRequirement}
              onChange={(e) => setEditingRequirement(e.target.value)}
            ></textarea>
          </div>
          
        ) :  
        value === "Assign" ? (
          <div style={{marginTop:"1rem"}}>
            <select onChange={(e)=>setReviewer(e.target.value)} style={{width:"70%",padding:'6px'}}>
              <option>Select reviewer:</option>
              {reviewerList?.map((reviewer) => (
                <option key={reviewer._id} value={reviewer._id}>{reviewer.name}</option>
              ))}
            </select>
          </div>
        ):value === "Declined" ? (
          <div style={{marginTop:"1rem"}}>
            <select onChange={(e)=>{
              setDeclineReason(e.target.value)
              if(e.target.value !== "Other Reasons"){
                setOtherDeclineReason(null)
              }
            }} style={{width:"70%",padding:'6px'}}>
              <option>Choose Reason for Video Decline :</option>
              <option  value="The camera is turned off by one of the speakers throughout the session.">Camera Off: The camera is turned off by one of the speakers throughout the session.</option>
              <option  value="Too many network glitches, resulting in short session.">Multiple Network Glitches: Too many network glitches, resulting in short session.</option>
              <option  value="Speakers using fancy or unprofessional filters.">Fancy Filters: Speakers using fancy or unprofessional filters.</option>
              <option  value="Inappropriate sitting, resulting in an unprofessional appearance.">Unprofessional sitting: Inappropriate sitting, resulting in an unprofessional appearance.</option>
              <option  value="Other Reasons">Other Reasons</option>
            </select>
            {
              declineReason === "Other Reasons"?
              <>
              <br/>
              <label>Provide Explanation for Decline :</label>
              <textarea
                style={{ width: "100%" }}
                value={otherDeclineReason}
                onChange={(e) => setOtherDeclineReason(e.target.value)}
              ></textarea>
              </>
              :""
            }
          </div>
        ): value === "TechnicalIssue" ? (
          <div style={{marginTop:"1rem"}}>
          <select onChange={(e)=>{
            setTechnicalIssue(e.target.value)
            if(e.target.value !== "Other Reasons"){
              setOtherTechnicalReason(null)
            }
          }} style={{width:"70%",padding:'6px'}}>
            <option>Choose Technical Issue :</option>
            <option  value="Video Not Downloading">Video Not Downloading</option>
            <option  value="Video Link Doesn’t Exist">Video Link Doesn’t Exist</option>
            <option  value="Video Not Opening">Video Not Opening</option>
            <option  value="Video Not Available">Video Not Available</option>
            <option  value="Other Reasons">Other Reasons</option>
          </select>
          {
            declineReason === "Other Reasons"?
            <>
            <br/>
            <label>Provide Explanation of the issue :</label>
            <textarea
              style={{ width: "100%" }}
              value={otherTechnicalReason}
              onChange={(e) => setOtherTechnicalReason(e.target.value)}
            ></textarea>
            </>
            :""
          }
        </div>
        ): value === "OnHold"?(
          <div >
            <label>On Hold Reason :</label>
            <textarea
              style={{ width: "100%" }}
              value={onHoldReason}
              onChange={(e) => setOnHoldReason(e.target.value)}
            ></textarea>
          </div>
        ):""
        }
      </Modal>
    </>
  );
};

const VideoApprovalRequest = () => {

  const dispatch = useDispatch();

  const [allRequests, setAllRequests] = useState([]);
  const [loading, setLoading] = useState(false);
  const [status, setStatue] = useState("Requested");
  const [loadMore, setLoadMore] = useState(false);
  const [page, setPage] = useState(1);
  const [reviewers, setReviewer] = useState();
  const [editors, setEditor] = useState();
  const [selectedReviewers, setSelectedReviewer] = useState(null);
  const [selectedEditors, setSelectedEditor] = useState(null);

  const [recordingLoader, setRecordingLoader] = useState(false);
  const [searchLoader, setSearchLoader] = useState(false);
  const [searchByNameLoader, setSearchByNameLoader] = useState(false);
  const [serialNumber, setSerialNumber] = useState();
  const [searchBy, setSearchBy] = useState("");
  const [searchName, setSearchName] = useState("");
  const [statusGroup, setStatusGroup] = useState([]);

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const selectDate = (value, dateString) => {
    setStartDate(dateString[0])
    setEndDate(dateString[1])
  };

  const recording = (id) => {

    setRecordingLoader(id);

    const data = {
      meetingId: id,
    };

    const onSuccess = (data) => {
      setRecordingLoader(false);
      if(!data?.uuid){
        const newWindow = window.open('', '_blank');
      
        const videoHtml = `
          <html>
            <head>
              <title>Recording</title>
            </head>
            <body style="margin:0">
              <video controls autoplay style="width:100vw; height:100vh;">
                <source src="${data.recording_files[0].play_url}" type="video/mp4">
                Your browser does not support the video tag.
              </video>
            </body>
          </html>
        `;
    
        newWindow.document.write(videoHtml);
        newWindow.document.close();
        }else{
          window.open(data.recording_files[0].play_url, '_blank');
          }
    };

    const onError = (err) => {
      setRecordingLoader(false);
      message.error(err, 8);
    };

    dispatch(GetMeetingRecording(data, onSuccess, onError));
  };

  const loadmore = () => {
    setLoadMore(true);

    var payload = {
      status:status,
      page:page+1,
      limit:20,
      reviewer:selectedReviewers,
      editor:selectedEditors
    }

    if(startDate && endDate){
      payload["startDate"]=startDate
      payload["endDate"]=endDate
    }

    const onSuccess = (data) => {
      if (data.allRequests.length > 0) {
        setAllRequests(allRequests.concat(data.allRequests));
        setPage(page + 1);
      }
      setStatusGroup(data.statusCounts)
      setLoadMore(false);
    };
    const onError = (msg) => {
        console.log(msg);
        setLoadMore(false);
    };
    dispatch(GetAllApprovalReq(payload, onSuccess, onError))
  }

  const fetchAllRequests = (status) => {
    setLoading(true);

    var payload = {
      status:status,
      page:page,
      limit:20,
      reviewer:selectedReviewers,
      editor:selectedEditors
    }

    if(startDate && endDate){
      payload["startDate"]=startDate
      payload["endDate"]=endDate
    }

    const onSuccess = (data) => {
      setAllRequests(data.allRequests);
      setStatusGroup(data.statusCounts)
      setLoading(false);
    };
    const onError = (error) => {
      setLoading(false);
      console.log(error);
    };

    dispatch(GetAllApprovalReq(payload, onSuccess, onError));
  };
  
  const getReviewerAndEditorList = () =>{
    const onSuccess = (res) =>{
      setReviewer(res.reviewer)
      setEditor(res.editor) 
    }
    const onError = (errmsg) =>{
      console.log(errmsg)
    }
    GetAdminList(onSuccess,onError)
  }

  useEffect(() => {
    fetchAllRequests("Requested");
    getReviewerAndEditorList()
  }, []);

  const columns = [
    {
      title: "Sl.No",
      dataIndex: "serialNumber",
      key: "serialNumber",
      width: 100
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 120,
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Phone Number",
      dataIndex: "phone",
      key: "phone",
      width: 100,
    },
     {
      title: "ReferalCode",
      dataIndex: "referalCode",
      key: "referalCode",
      width: 100,
    },
    {
      title: "Teacher Name",
      dataIndex: "teacherName",
      key: "teacherName",
      width: 100,
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      width: 80,
      render: (status) => (
        <>
          {status.map((tag) => {
            let color;
            if (tag === "Requested") {
              color = "red";
            } else if (tag === "InReview") {
              color = "orange";
            } else {
              color = "green";
            }

            return (
              <Tag color={color} key={tag}>
                {tag.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: "Youtube channel",
      key: "channel",
      dataIndex: "channel",
      width: 100,
      // eslint-disable-next-line react/jsx-no-target-blank
      render: (link) => (
        <a href={link} target="_blank">
          {link}
        </a>
      ),
    },
    {
      title: "Requested date",
      key: "requestedDate",
      dataIndex: "requestedDate",
      width: 100,
      sorter: (a, b) => a.requestedDate.localeCompare(b.requestedDate),
    },{
      title: "Activity status",
      key: "timeElapsed",
      dataIndex: "timeElapsed",
      width: 150,
      render: (timeElapsed) => (
        <>
        <Tag color={timeElapsed === "Needs attention" ?"red":"green"} key={timeElapsed}>
              {timeElapsed.toUpperCase()}
        </Tag>
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      fixed: "right",
      width: 180,
      render: ({ meetingRecording, meetingNo,editingRequirement, key, serialNumber }) => (
        <>
          <Space>
            <StatusOptionModal
              requestId={key}
              allRequests={allRequests}
              setAllRequests={setAllRequests}
              editorList={editors}
              reviewerList={reviewers}
              requirement={editingRequirement}
              meetingNo={meetingNo}
              serialNumber={serialNumber}
            />
            {meetingRecording === null ? (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <Button onClick={() => recording(meetingNo)}>
                {recordingLoader === meetingNo ? "Fetching..." : "Recording"}
              </Button>
            ) : (
              // eslint-disable-next-line react/jsx-no-target-blank
              <Button href={`https://clapingo-testing.netlify.app/recordings?link=${meetingRecording.includes('digitaloceanspaces') ? `https://${meetingRecording}` : meetingRecording}`} target="_blank">
                Recording
              </Button>
            )}
          </Space>
        </>
      ),
    },
  ];

  if (status !== "Requested") {
    columns.splice(5, 0, {
      title: "Reviewer ",
      key: "reviewer",
      dataIndex: "reviewer",
      width: 100,
    });
    columns.splice((columns.length-1), 0, {
      title: "Assigned date",
      key: "assignedDate",
      dataIndex: "assignedDate",
      width: 100,
    });
  }

  if(status === "InReview" || status === "Approved"){
    columns.splice(6, 0, {
      title: "Editing requirement ",
      key: "editingRequirement",
      dataIndex: "editingRequirement",
      width: 100,
    });
    columns.splice(7, 0, {
      title: "Editor",
      key: "editor",
      dataIndex: "editor",
      width: 100,
    });
    columns.splice(columns.length-1, 0, {
      title: "Reviewed date",
      key: "reviewedDate",
      dataIndex: "reviewedDate",
      width: 100,
    });
    
  }

  if(status === "Approved"){
    columns.splice(8, 0, {
      title: "Approved Video",
      key: "approvedVideo",
      dataIndex: "approvedVideo",
      width: 100,
      // eslint-disable-next-line react/jsx-no-target-blank
      render: (link) => (
        <a href={link} target="_blank">
          {link}
        </a>
      ),
    })

    columns.splice(8, 0, {
      title: "Video Thumbnail",
      key: "thumbnail",
      dataIndex: "thumbnail",
      width: 100,
      // eslint-disable-next-line react/jsx-no-target-blank
      render: (link) => (
        <a href={link} target="_blank">
          {link}
        </a>
      ),
    });

    columns.splice(columns.length-1, 0, {
      title: "Approved date",
      key: "approvedDate",
      dataIndex: "approvedDate",
      width: 100,
      sorter: (a, b) => a.approvedDate.localeCompare(b.approvedDate)
    });
  }

  if (status === "Declined") {
    columns.splice(columns.length-1,0,{
      title: "Declined Reason",
      key: "declinedReason",
      dataIndex: "declinedReason",
      width: 100,
    })
  }

  if (status === "TechnicalIssue") {
    columns.splice(1,0,{
      title: "Meeting no",
      key: "meetingNo",
      dataIndex: "meetingNo",
      width: 100,
    })
    columns.splice(columns.length-1,0,{
      title: "Tech Issue",
      key: "techIssue",
      dataIndex: "techIssue",
      width: 100,
    })
    columns.splice(columns.length-1,0,{
      title: "Issue raised at",
      key: "raisedAt",
      dataIndex: "raisedAt",
      width: 100,
    })
  }

  const data = [];
  allRequests?.map((res) => {
    var tableContent = {
      key: res._id,
      name: res.learnerData[0]?.name,
      serialNumber: res.serialNumber,
      phone: res.learnerData[0]?.phoneNumber,
      referalCode: res.learnerData[0]?.referal,
      teacherName: res.teacherData[0]?.name,
      status: [res.status],
      approvedVideo: res.approved_video_url ? res.approved_video_url : "N/A",
      thumbnail: res.thumbnail ? res.thumbnail : "N/A",
      channel: res.youtubeChannel ? res.youtubeChannel : "N/A",
      requestedDate: utcToLocalDateTimeConverter(res.createdAt),
      timeElapsed:  ((new Date() - new Date(utcToLocalDateTimeConverter(res.createdAt)))/(60*60*1000)) > 7 ?"Needs attention" :"Active"
    };

    if (res?.recording) {
      tableContent["meetingRecording"] = res?.recording;
      tableContent["meetingNo"] = res?.meetingNo;
    } else {
      tableContent["meetingRecording"] = null;
      tableContent["meetingNo"] = res?.meetingNo;
    }
    if (status === "Declined") {
      tableContent["declinedReason"] = res?.reviewerMessage;
    }
    if (status !== "Requested") {
        tableContent["reviewer"] = res?.reviewerData[0]?.name;
        tableContent["editingRequirement"] = res?.editingRequirement;
        tableContent["editor"] = res?.editorData[0]?.name;
        tableContent["assignedDate"] = res?.assignedDate?utcToLocalDateTimeConverter(res.assignedDate):"N/A";
        tableContent["reviewedDate"] = res?.reviewedDate?utcToLocalDateTimeConverter(res.reviewedDate):"N/A";
        tableContent["approvedDate"] = res?.approvedDate?utcToLocalDateTimeConverter(res.approvedDate):"N/A";
    }
    if(status === "TechnicalIssue"){
      tableContent["meetingNo"] = res?.meetingNo;
      tableContent["techIssue"] = res?.techIssue?.reason? res?.techIssue?.reason :"N/A";
      tableContent["raisedAt"] = res?.techIssue?.raisedDate?utcToLocalDateTimeConverter(res?.techIssue?.raisedDate):"N/A";
    }

    data.push(tableContent);
  });

  const onChange = (e) => {
    setStatue(e.target.value);
    fetchAllRequests(e.target.value);
  };

  useEffect(() => {
    if(selectedEditors || selectedReviewers){
      fetchAllRequests(status);
    }
  }, [selectedEditors,selectedReviewers])

  const searchSerialNumber = ()=>{
    setSearchLoader(true)
    const onSuccess = (res) =>{
      setSearchLoader(false)
      setAllRequests(res.allRequests)
      if(res.allRequests.length > 0){
        setStatue(res.allRequests[0]?.status)
      }else{
        message.error(`The request associated with the serial number ${serialNumber} was not found.`, 8);
      }
    }

    const onError = (msg) =>{
      setSearchLoader(false)
      message.error(msg, 8);
    }

    SearchBySerialNum(serialNumber,onSuccess,onError)
  }

  const searchByName = () => {
    if(!searchBy){message.error("Please select the learner/teacher option to search by name", 8); return;}
    if(!searchName?.trim()){message.error("Please enter the name to search", 8); return;}

    setSearchByNameLoader(true);

    const data = {
      status,
      type: searchBy,
      name: searchName
    }
    const onSuccess = (res) =>{
      setSearchByNameLoader(false)
      setAllRequests(res.allRequests)
    }

    const onError = (msg) =>{
      setSearchByNameLoader(false)
      message.error(msg, 8);
    }

    SearchByName(data, onSuccess, onError);
  }

  console.log({startDate,endDate})

  return (
    <div>
      {/* <Radio.Group onChange={onChange} value={status} defaultValue="Requested">
        <Radio.Button value="Requested">Requested</Radio.Button>
        <Radio.Button value="Assigned">Assigned</Radio.Button>
        <Radio.Button value="InReview">InReview</Radio.Button>
        <Radio.Button value="Approved">Approved</Radio.Button>
        <Radio.Button value="Declined">Declined</Radio.Button>
      </Radio.Group> */}
      <div>
      <select style={{marginLeft:"1rem",padding:"6px"}}  onChange={onChange} value={status} defaultValue="Requested">
         <option value={"Requested"}>Requested</option>
         <option value={"Assigned"}>Assigned</option>
         <option value={"InReview"}>InReview</option>
         <option value={"Approved"}>Approved</option>
         <option value={"Declined"}>Declined</option>
         <option value={"TechnicalIssue"}>Technical Issue</option>
         <option value={"OnHold"}>On Hold</option>
         </select>

      <select style={{marginLeft:"1rem",padding:"6px"}} onChange={(e)=>setSelectedEditor(e.target.value)}>
         <option selected disabled>Select editor:</option>
         <option value={"null"}>Reset</option>
         {editors?.map((editor) => (
           <option key={editor._id} value={editor._id}>{editor.name}</option>
         ))}
      </select>

      <select style={{marginLeft:"1rem",padding:"6px"}} onChange={(e)=>setSelectedReviewer(e.target.value)}>
         <option selected disabled>Select reviewer:</option>
         <option value={"null"}>Reset</option>
         {reviewers?.map((reviewer) => (
           <option key={reviewer._id} value={reviewer._id}>{reviewer.name}</option>
         ))}
      </select>

      <span style={{marginLeft:"1rem",padding:"6px"}}>
        <input type="number" className="search-input" placeholder="Search by Sl.No" value={serialNumber} onChange={(e)=>setSerialNumber(e.target.value)} />
        <Button type="primary" onClick={searchSerialNumber} loading={searchLoader}>Search</Button>
      </span>

      <select style={{marginLeft:"1rem",padding:"6px"}} onChange={(e)=>setSearchBy(e.target.value)}>
        <option selected disabled>Search By:</option>
        <option value="learner">Learner</option>
        <option value="teacher">Teacher</option>
      </select>

      <span style={{marginLeft:"1rem",padding:"6px"}}>
        <input type="text" className="search-input" placeholder="Search by Name" value={searchName} onChange={(e)=>setSearchName(e.target.value)} />
        <Button type="primary" onClick={searchByName} loading={searchByNameLoader}>Search</Button>
      </span>
</div>
<Space direction="horizontal" size={12}>
  
  {
    statusGroup?.map((data,i)=>(
      <div className="status-counts" key={i}>
        <p >{data.count}</p>
        <p className="title">{data.status?.replace(/([a-z])([A-Z])/g, '$1 $2')}</p>
      </div>
    ))
  }

  <Space size={0}>
    <RangePicker onChange={selectDate} defaultValue={[startDate, endDate]} />
    <Button type="primary" onClick={()=>fetchAllRequests(status)}>Search</Button>
  </Space>

</Space>

      {!loading ? (
        <Table
          scroll={{ x: columns.length >7?4000:1400 }}
          dataSource={data}
          pagination={false}
          columns={columns}
          // rowClassName ={(record) => urgency(record)}
        />
      ) : (
        <div className="spin-center">
          <Spin size="large" />
        </div>
      )}
       {
              loadMore?
              <div className="spin-center">
              <Spin size="large" />
            </div>
            :""
            }

          <div style={{'text-align':'center','margin-top':"10px"}}>
             <Button onClick={()=>loadmore()} disabled={loadMore} >Load More </Button>
          </div>
    </div>
  );
};

export default VideoApprovalRequest;
