//Date time setup
//subject and type dropdown [english, ielts, kids / regular, demo / ]
//get available teacher
//block host
//zoom api
//create meet api

import React, { useEffect, useState } from "react";
import moment from 'moment';
import { Link, useHistory, useLocation,useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { useSnackbar } from "react-simple-snackbar";
import { useDispatch, useSelector } from "react-redux";
import {
    availableDate,
    getAvailableTeacherSate,
    formatTimeslot,
    TimeValidator,
    overlapUTCtimeConverter,
    hhformatAMPM,
    getUtcDate,
} from "../../helper/TimeDate";
import { TimeSpliter } from "../../helper/planPrice";
// import makeToast from "../components/toster/TosterMessage";
import { AvailableTeacher, mostUsedTimeSlot } from "../../redux/actions/SessionAction";

import { CreateZoomMeeting } from "../../redux/actions/ZoomMeeting";
import { GetAvalableHost, CreateSession } from "../../redux/actions/SessionAction";
import Loder from "../../component/loder";
import { currentPlan } from "../../redux/actions/PlanAction";
// import emptyTeacher from "../assets/emptyTeacher.jpg";
// import TeacherTimingModal from "../components/TeacherTimingModal";
// import {
//   AddFavoriteSpeakers,
//   GetFavoriteSpeakers,
//   RemoveFavoriteSpeakers,
// } from "../redux/actions/SpeakersAction";
// import youtubeIcon from '../../src/assets/youtubeIcon.png'
// import YoutubeConcent from "../components/MessageModal/YoutubeConcent";
// import { getDevice } from "../helpers/PageHelper";
// import { initGA, initPixel } from "../helpers/CampaignEvents";


const timeInterval = [
    "00 - 01",
    "01 - 02",
    "02 - 03",
    "03 - 04",
    "04 - 05",
    "05 - 06",
    "06 - 07",
    "07 - 08",
    "08 - 09",
    "09 - 10",
    "10 - 11",
    "11 - 12",
    "12 - 13",
    "13 - 14",
    "14 - 15",
    "15 - 16",
    "16 - 17",
    "17 - 18",
    "18 - 19",
    "19 - 20",
    "20 - 21",
    "21 - 22",
    "22 - 23",
    "23 - 00",
];


const arr2 = [0, 1, 2, 3, 4, 5, 6];
const AssignDemo = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [weeklyDate, setWeeklyDate] = useState();
    const [activeDate, setActiveDate] = useState();
    const [teacher, setTeacher] = useState(null);

    const [timeinterval, setTimeinterval] = useState(null);
    const [splitedTime, setSplitedTime] = useState(null);
    const [startTime, setStartTime] = useState(null);
    const [startTimeUTC, setStartTimeUTC] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [endTimeUTC, setEndTimeUTC] = useState(null);
    const [exactTime, setExactTime] = useState(null);
    const [active, setActive] = useState(0);
    const [speakerId, setSpeakerId] = useState(0);
    const [duration, setDuration] = useState();
    const [loder, setLoder] = useState(false);
    const [visibleTime, setVisibleTime] = useState();
    const [availableTeacher, setAvailableTeacher] = useState();
    const [BookingLoder, setBookingLoder] = useState(false);
    const [previousTime, setPreviousTime] = useState();
    const [previousDate, setPreviousDate] = useState();
    const time_zone = JSON.parse(localStorage.getItem("TIMEZONE"));
    let { id } = useParams();
    // const { search } = useLocation();
    // const userId= new URLSearchParams(search).get("id");
    console.log(id)

    const intervaldate = new Date(
        new Date().toLocaleString("en-US", { timeZone: time_zone.timezone })
    );
    const LocalHostUser = JSON.parse(localStorage.getItem("USER"));
    var today = new Date();
    var isToday = String(today.getDate()).padStart(2, '0') == String(activeDate?.getDate()).padStart(2, '0')
    useEffect(() => {
        // console.log(activeDate)
        // dispatch(GetFavoriteSpeakers());
        setActiveDate(availableDate(0).date);
        var weeklyDate = [];
        arr2.map((data) => {
            weeklyDate.push(availableDate(data));
        });
        setWeeklyDate(weeklyDate);

        // const onSuccess = (data) => {
        //   console.log(data);
        // };
        // dispatch(currentPlan({ learnerId: LocalHostUser._id }, onSuccess));

        if (
            timeinterval !==
            intervaldate.getHours() +
            " - " +
            parseInt(intervaldate.getHours() + 1) ||
            !timeinterval
        ) {
            if (
                getAvailableTeacherSate(intervaldate) ===
                getAvailableTeacherSate(availableDate(0).date)
            ) {
                if (intervaldate.getHours() > 9) {
                    setTimeinterval(
                        intervaldate.getHours() +
                        " - " +
                        parseInt(intervaldate.getHours() + 1)
                    );
                }
            }
        }
    }, []);

    const result = availableTeacher
        ? availableTeacher.sessions.reduce((a, c) => {
            if (c.assignTeacher !== null || c.teacher !== null) {
                if (c.teacher) {
                    let key = c.teacher._id;
                    a[key] = a[key] || [];
                    a[key].push(c);
                } else if (c.assignTeacher) {
                    let key = c.assignTeacher._id;
                    a[key] = a[key] || [];
                    a[key].push(c);
                }
            }
            return a;
        }, {})
        : "";
    useEffect(() => {
        setTeacher(result);
    }, [availableTeacher, activeDate, timeinterval]);

    const blockTimeSlot = (slot) => {
        const date = new Date(
            new Date().toLocaleString("en-US", { timeZone: time_zone.timezone })
        );
        const splite = slot.split("-");
        var returnValue;

        if (getAvailableTeacherSate(date) === getAvailableTeacherSate(activeDate)) {
            if (date.getHours() > 9) {
                //+4
                if (
                    date.getHours() + 0 + ":" + date.getMinutes() >=
                    splite[0].trim() + ":" + "00"
                ) {
                    returnValue = "remove";
                }
            }
        }
        return returnValue;
    };

    const BookSession = (teacher) => {

        if (!startTime && !endTime) {
            return;
        }
        setBookingLoder(true);


        if (!activeDate && !exactTime) {
            console.log("error", "Date & Time required !! ");
        } else {
            const onSuccess = (host, blockid) => {
                createMeeting(teacher, host, blockid);
                setBookingLoder(false);
            };
            const onError = (msg) => {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: msg,
                });
                setBookingLoder(false);
            };

            dispatch(
                GetAvalableHost(
                    {
                        startTime: TimeValidator(startTime),
                        endTime: TimeValidator(endTime),
                        sessionDate: getUtcDate(activeDate, startTime),
                        isTimeUtc: true,
                    },
                    onSuccess,
                    onError
                )
            );
        }

    };

    const isOverlapping = (splittedStartTime, splittedEndTime, bookedSessionStartTime, bookedSessionEndTime) => {
        return splittedStartTime <= bookedSessionEndTime && bookedSessionStartTime <= splittedEndTime;
    };

    const AvailableOrNot = (data, start, end) => {

        var returnvalue;
        if (teacher) {
            // Check teacher booked session available in hashmap or not
            if (
                teacher[
                Object.keys(teacher)[Object.keys(teacher).indexOf(data._id._id)]
                ]
            ) {

                //Loop booked session & compare with splitted time 
                teacher[
                    Object.keys(teacher)[Object.keys(teacher).indexOf(data._id._id)]
                ].map((data) => {
                    if (data.isTimeUtc) {
                        const zeroPad = (num, places) => String(num).padStart(places, "0");
                        const validator = (time) => {
                            const splitedTime = time.split(":");
                            return (
                                zeroPad(splitedTime[0], 2) + ":" + zeroPad(splitedTime[1], 2)
                            );
                        };

                        if (
                            isOverlapping(
                                validator(start),
                                validator(end),
                                data.startTime,
                                data.endTime
                            )
                        ) {
                            returnvalue = "Reserved";
                        }
                    } else {
                        const startLocal = overlapUTCtimeConverter(start);
                        const endLocal = overlapUTCtimeConverter(end);

                        if (
                            isOverlapping(startLocal, endLocal, data.startTime, data.endTime)
                        ) {
                            returnvalue = "Reserved";
                        }
                    }
                });
            }
        }
        return returnvalue;
    };
    const zeroPad = (num, places) => String(num).padStart(places, "0");
    const validator = (time) => {
        const splitedTime = time.split(":");
        return zeroPad(splitedTime[0], 2) + ":" + zeroPad(splitedTime[1], 2);
    };
    const validateTimeSlot = (splitedTime, availableTeacherTime) => {
        var slotArray = [];

        splitedTime.map((val1) => {
            availableTeacherTime.map((data, i) => {
                if (
                    data.startTime <= validator(val1.start) &&
                    validator(val1.start) <= data.endTime
                ) {
                    if (
                        data.startTime <= validator(val1.end) &&
                        validator(val1.end) <= data.endTime
                    ) {
                        if (!slotArray.includes(val1.timeView)) {
                            slotArray.push(val1);
                        }
                    } else {
                        if (availableTeacherTime[i + 1]) {
                            if (
                                availableTeacherTime[i + 1].startTime <= validator(val1.end) &&
                                validator(val1.end) <= availableTeacherTime[i + 1].endTime
                            ) {
                                if (!slotArray.includes(val1.timeView)) {
                                    slotArray.push(val1);
                                }
                            }
                        }
                    }
                }
            });
        });

        return [...new Set(slotArray)];
    };

    const createMeeting = (teacher, HostData, blockid) => {
        setLoder(true);
        // setHideModal(true);

        const maindata = {
            userId: id,
            data: {
                topic: "Clapingo Demo Session",
                type: 2,
                start_time: `${getUtcDate(activeDate, startTime)}:00`,
                duration:15,
                schedule_for: id,
                timezone: "UTC",
                password: "clapingo",
                agenda: "Learn English",
                settings: {
                    host_video: true,
                    participant_video: true,
                    cn_meeting: false,
                    in_meeting: true,
                    join_before_host: true,
                    mute_upon_entry: true,
                    watermark: false,
                    use_pmi: false,
                    approval_type: 2,
                    auto_recording: "cloud",
                    registrants_email_notification: true,
                },
            },
        };

        const onSuccess = (data) => {
            if (data) {
                var meetingndata;
                // if (
                //     (LocalHostUser.isDemoPayed === true &&
                //         LocalHostUser.isTrialBooked === false) ||
                //     isTrialClass
                // ) {
                    meetingndata = {
                        meetingNo: String(data.id),
                        learner: LocalHostUser._id,
                        teacher_: teacher,
                        startTime: TimeValidator(startTime),
                        endTime: TimeValidator(endTime),
                        type: "Demo",
                        sessionDuration: "15min",
                        sessionDate: getUtcDate(activeDate, startTime),
                        meetingPassword: data.password,
                        startMeetingUrl: data.start_url,
                        joinMeetingUrl: data.join_url,
                        host: data.host_email,
                        isAdmin: false,
                        isWebsite: true,
                        blockHostId: blockid,
                        timezone: time_zone.timezone,
                        isTimeUtc: true,
                        source: "Admin"
                    };
                // } else {
                //     meetingndata = {
                //         meetingNo: String(data.id),
                //         learner: LocalHostUser._id,
                //         teacher_: teacher,
                //         startTime: TimeValidator(startTime),
                //         endTime: TimeValidator(endTime),
                //         type: "Regular",
                //         sessionDuration: `${duration}`,
                //         sessionDate: getUtcDate(activeDate, startTime),
                //         meetingPassword: data.password,
                //         startMeetingUrl: data.start_url,
                //         joinMeetingUrl: data.join_url,
                //         host: data.host_email,
                //         isAdmin: false,
                //         isWebsite: true,
                //         blockHostId: blockid,
                //         timezone: time_zone.timezone,
                //         isTimeUtc: true,
                //         source: getDevice()
                //     };
                // }

                const onSuccess = () => {
                    setLoder(false);

                    // if (
                    //     (LocalHostUser.isTrialBooked === false &&
                    //         LocalHostUser.isDemoPayed === true) ||
                    //     isTrialClass
                    // ) {
                        localStorage.setItem("DEMO_BOOKED", JSON.stringify(true));
                        localStorage.removeItem("DEMO_SESSION");
                        setLoder(false);
                        history.push("/dashboard");
                        Swal.fire("Demo Booked Successfully!", "", "success");
                        window.location.reload();
                    // } else {
                    //     Swal.fire("Session Booked Successfully!", "", "success");
                    //     history.push("/dashboard");
                    //     window.location.reload();
                    // }
                };
                const onError = (err) => {
                    setLoder(false);
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: err,
                    });
                };
                dispatch(CreateSession(meetingndata, onSuccess, onError));
            } else {
                setLoder(false);
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Unable to book meeting due to some technical reason please try again later",
                });
            }
        };
        const onError = () => {
            setLoder(false);
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Oops something went wrong !!",
            });
        };
        dispatch(CreateZoomMeeting(maindata, onSuccess, onError));
    };

    useEffect(() => {
        // if (LocalHostUser.isLearnerSubscribed) {
        //   if (UserPlan) {
        //     if (
        //       UserPlan?.plan?.classDuration === "25min" ||
        //       UserPlan?.plan?.classDuration === 25
        //     ) {
        //       setDuration(25);
        //     } else {
        //       setDuration(15);
        //     }
        //   }
        // } else {
          setDuration(15);
        // }
      });

    useEffect(() => {
        const localDate = new Date(
            new Date().toLocaleString("en-US", { timeZone: time_zone.timezone })
        );


        if (activeDate) {
            console.log(timeinterval)
            setAvailableTeacher(null);
            if (activeDate && timeinterval && duration) {
                console.log(timeinterval)
                var data = {
                    date: getAvailableTeacherSate(activeDate),
                    timeInterval: timeinterval,
                    isDemo: true,
                    timezone: time_zone.timezone,
                    // isYoutubeFiltered: showYoutubeTeacher
                };

                const onSuccess = (data) => {
                    console.log(data)
                    setStartTimeUTC(data.startTimeinUtc);
                    setEndTimeUTC(data.endtImeinUtc);
                    setAvailableTeacher(data);
                    setSplitedTime(
                        TimeSpliter(data.startTimeinUtc, data.endtImeinUtc, duration)
                    );
                    setPreviousTime(timeinterval);
                    setPreviousDate(activeDate)
                };
                const onError = () => {
                    console.log("error!! something went wrong");
                };

                if (!isToday && (previousDate !== activeDate)) {
                    console.log("IF 0")
                    setPreviousTime(timeinterval);
                    setPreviousDate(activeDate)
                    dispatch(AvailableTeacher({
                        ...data, timeInterval: "00 - 01"
                    }, onSuccess, onError));
                }
                else if (previousTime !== timeinterval) {
                    console.log("IF 1")
                    setPreviousTime(timeinterval);
                    setPreviousDate(activeDate)
                    dispatch(AvailableTeacher(data, onSuccess, onError));
                } else if (
                    getAvailableTeacherSate(activeDate) !==
                    getAvailableTeacherSate(localDate) &&
                    previousTime === "00 - 01"
                ) {
                    console.log("IF 2")
                    setPreviousTime(timeinterval);
                    setPreviousDate(activeDate)
                    dispatch(AvailableTeacher(data, onSuccess, onError));
                } else if (
                    getAvailableTeacherSate(activeDate) ===
                    getAvailableTeacherSate(localDate) &&
                    previousTime === "00 - 01"
                ) {
                    console.log("IF 3")
                    setPreviousTime(timeinterval);
                    setPreviousDate(activeDate)
                    dispatch(AvailableTeacher(data, onSuccess, onError));
                } else {
                    console.log("IF 4")
                    setPreviousTime(timeinterval);
                    setPreviousDate(activeDate)
                }
            }
        }
    }, [activeDate, timeinterval, duration]);


    console.log(availableTeacher)
    console.log("DATE", availableDate, "TIME INTERVAL", timeInterval, "DURATION", duration)
    return (
        <>
            <div>
                <p>Select date:</p>

                <div className="d-flex overflow-auto">
                    {weeklyDate
                        ? weeklyDate.map((data, i) => (
                            <button
                                id={`date${i}`}
                                className={active === i ? "active" : ""}
                                onClick={() => {
                                    setActiveDate(data.date);
                                    setActive(i);
                                }}
                                key={data.date}
                            >
                                <div>
                                    {data.weekDay}<br /><span style={{ fontSize: "24px" }}>{data.dateNo}</span><br />{data.month}
                                </div>
                            </button>
                        ))
                        : ""}
                </div>

            </div>
            <div className="my-auto date_dropdown">
                <p>Select time:</p>
                <select
                    onChange={(e) => {
                        setTimeinterval(e.target.value);
                    }}
                    className="select mx-auto "
                    id="idselecttime"
                    style={{ display: "block" }}
                    value={timeinterval}
                >
                    <option
                        value={visibleTime}
                        selected
                        id={`${visibleTime}`}
                    // style={{ display: "none" }}
                    >
                        {visibleTime ? hhformatAMPM(visibleTime) : ""}
                    </option>
                    {timeInterval.map((data, i) => (
                        <option
                            className={
                                getAvailableTeacherSate(intervaldate) ===
                                    getAvailableTeacherSate(activeDate)
                                    ? blockTimeSlot(data)
                                    : ""
                            }
                            value={data}
                            key={i}
                        >
                            {hhformatAMPM(data)}
                        </option>
                    ))}
                </select>
            </div>

            <div className="row">
                {availableTeacher ? (
                    availableTeacher.teachers.length > 0 ? (
                        availableTeacher.teachers.map((data, i) => (
                            <>
                                {data ? (
                                    <div
                                        className={`col-md-4 `}
                                        key={i}
                                    >
                                        <div className="card">
                                            <div class="speaker_img_container">
                                                <div className="d-flex">
                                                    <div >
                                                        <a
                                                            style={{ cursor: "pointer" }}>
                                                            <img
                                                                className="teacher_image mx-auto"
                                                                onClick={() => history.push(`/speaker-profiles/${data?._id?._id}`)}
                                                                src={data?._id?.image ? `https://images.weserv.nl/?url=${data._id.image}&w=150&h=150&output=webp` : ""}
                                                                alt="icon"
                                                            />
                                                        </a>
                                                    </div>

                                                    <div className="ml-3">
                                                        {/* <Link
                                    to={`/speaker-profiles/${data?._id?._id}`}
                                    className="name_tag"
                                  >
                                    {data?._id?.name}
                                  </Link> */}
                                                        <div className="name_tag">
                                                            {data?._id?.name}
                                                        </div>

                                                        <div className="more-detail" >
                                                            { }
                                                            {/* <Link to={`/speaker-profiles/${data._id._id}`} style={{ color: "#4A6EE0" }} className="view-speaker">More details</Link> */}
                                                            <>


                                                                {/* <MyVerticallyCenteredModal
                                        // show={modalShow}
                                        id={data._id._id}
                                        // onHide={() => setModalShow(false)}
                                        
                                      /> */}
                                                            </>

                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                            {/* <div className="d-flex flex-wrap">
                              {splitedTime
                                ? getTimeSlots(splitedTime, data)
                                : "Loading..."}
                            </div>

                            <TeacherTimingModal
                              id="speakerbookid"
                              validateTimeSlot={validateTimeSlot}
                              data={data}
                              AvailableOrNot={AvailableOrNot}
                              splitedTime={splitedTime ? splitedTime : ""}
                              exactTime={exactTime}
                              setExactTime={setExactTime}
                              setEndTime={setEndTime}
                              setStartTime={setStartTime}
                              BookSession={BookSession}
                              BookingLoder={BookingLoder}
                              setHideModal={setHideModal}
                              hideModal={hideModal}
                            /> */}
                                            <p className="mt-2 mb-1 ">Available Slots:</p>
                                            <div className="slot-button">
                                                {

                                                    splitedTime
                                                        ? validateTimeSlot(splitedTime ? splitedTime : [], data.timings).map((val, i) => (
                                                            <div className="m-1 ">
                                                                <button
                                                                    key={i}
                                                                    id={`time${i}`}
                                                                    className={exactTime === val.timeView && data._id._id == speakerId ? "active" : ""}
                                                                    onClick={
                                                                        AvailableOrNot(data, val.start, val.end) === "Reserved"
                                                                            ? ""
                                                                            : () => {
                                                                                setExactTime(val.timeView);
                                                                                setStartTime(val.start);
                                                                                setEndTime(val.end);
                                                                                setSpeakerId(data._id._id)
                                                                            }
                                                                    }
                                                                >
                                                                    {AvailableOrNot(data, val.start, val.end) === "Reserved"
                                                                        ? "Reserved"
                                                                        : formatTimeslot(val.timeView)}
                                                                </button>
                                                            </div>
                                                        ))
                                                        : ""}

                                            </div>
                                            <div className="d-flex justify-content-center">
                                                <button
                                                    id="bookmodalid"
                                                    className="book_now mt-3"

                                                    disabled={speakerId == data._id._id ? false : true}

                                                    onClick={() => {
                                                        BookSession(data._id._id)
                                                    }}
                                                >

                                                    {BookingLoder && speakerId == data._id._id ? "Booking.." : "Book Now"}

                                                </button>

                                            </div>
                                        </div>

                                    </div>
                                ) : (
                                    "Technical issue"
                                )}
                            </>
                        ))
                    ) : (
                        <div className="col-12 text-center">
                            <p>No speakers available</p>
                        </div>
                    )
                ) : (
                    <div className="col-md-12 mt-5 mb-5">
                        <Loder message="Fetching Teachers ..." />
                    </div>
                )}
            </div>
        </>
    );
};

export default AssignDemo;
