import { Card, DatePicker, Select } from 'antd';
import React, { useState } from 'react'
import Chart from '../../component/charts/mongodb/Chart';
import { currentTimeUtc } from '../../helper/TimeDate';
const { RangePicker } = DatePicker;
const { Option } = Select;

const DropDown = ({options,defaultValue,handleChange}) =>{
    return (
        <Select defaultValue={defaultValue} style={{ width: 120 }} onChange={handleChange}>
            {
                options.map((data,i)=>(
                    <Option value={data} key={i}>{data}</Option>
                ))
            }
      </Select>
    )
}

const SessionCharts = () => {

    const [attandanceDayFilter, setAttandanceDayFilter] = useState(30)
    const [attandanceDayFilter2, setAttandanceDayFilter2] = useState(30)
    const [type, setType] = useState("Demo")

    const ATTENDANCE_FILTER = { 
      sessionDate : { $gte: { $date: new Date(new Date() - attandanceDayFilter * 24 * 60 * 60 * 1000).getTime()}},
      endTime:{$lte:currentTimeUtc()},
      type:type
    }; 

    const DEMO_PAYED_NOT_BOOKED_FILTER = { 
        createdAt : { $gte: { $date: new Date(new Date() - attandanceDayFilter2 * 24 * 60 * 60 * 1000).getTime()}}
      }; 


  return (
    <div>
      {/* <RangePicker onChange={datePicker}  defaultValue={[moment(firstday, dateFormat), moment(lastday, dateFormat)]} format={dateFormat} /> */}
      <Card>
          <div style={{display:"flex"}}>
            <div style={{display:"flex",flexDirection:"column"}}>
                <p>Number of days to show:</p>
                <DropDown options={[7,15,30,60,90]} defaultValue={30} handleChange={(val)=>setAttandanceDayFilter(val)}/>
            </div>
            <div style={{display:"flex",flexDirection:"column",marginLeft:"30px"}}>
                <p>Type :</p>
                <DropDown options={["Demo","Regular"]} defaultValue={"Demo"} handleChange={(val)=>setType(val)}/>
            </div>
          </div>
        <Chart height={'80vh'} width={'100%'} filter={ATTENDANCE_FILTER} chartId={'62cd1c37-d694-4c13-80ac-a13c4a551dc8'}/>
      </Card>

      <Card>
          <div style={{display:"flex"}}>
          <div style={{display:"flex",flexDirection:"column"}}>
              <p>Number of days to show:</p>
              <DropDown options={[7,15,30,60,90]} defaultValue={30} handleChange={(val)=>setAttandanceDayFilter2(val)}/>
          </div>
          </div>
        <Chart height={'80vh'} width={'100%'} filter={DEMO_PAYED_NOT_BOOKED_FILTER} chartId={'62cd6053-6bc4-4294-837b-15b9091d7d3d'}/>
      </Card>

      <Card>
        <Chart height={'80vh'} width={'100%'} filter={DEMO_PAYED_NOT_BOOKED_FILTER} chartId={'62cfb358-713e-44b7-8a50-6a557b5e95fa'}/>
      </Card>
    </div>
  )
}

export default SessionCharts