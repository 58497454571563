import api from "../apis/StatisticsApis";
import * as constant from "../constants/Constant";

export const DateRangeSessionGraph = (data,onSuccess,onError) => (dispatch) => {
  api
    .StatisticsApis().dateRangeSessionGraph(data)
    .then((res) => {
        // dispatch({
        //     type:constant.GET_ALL_TEACHER,
        //     payload:res.data.teachers
        // })
        onSuccess()
    })
    .catch((err) => {
      console.log(err);
      onError()
    });
};

export const DailySubscriptionReport = (data,onSuccess,onError) => (dispatch) => {
  api
    .StatisticsApis().dailySubscriptionReport(data)
    .then((res) => {
       
        dispatch({
            type:constant.STASTISTICS,
            payload:res.data.subscriptionReport
        })
        onSuccess()
    })
    .catch((err) => {
      console.log(err);
      onError()
    });
};

export const SessionTrafficDayWise = (data,onSuccess,onError) => (dispatch) => {
  api
    .StatisticsApis().sessionTraficDaywise(data)
    .then((res) => {
       
        dispatch({
            type:constant.SESSION_DAY_WISE,
            payload:res.data
        })
        onSuccess()
    })
    .catch((err) => {
      console.log(err);
      onError()
    });
};

export const SubscriptionRetentionRatio = () => (dispatch) => {
  api
    .StatisticsApis().subscriptionRetentionRatio()
    .then((res) => {
      
        dispatch({
            type:constant.FETCH_SUBSCRIPTION_DATA,
            payload:res.data
        })
        
    })
    .catch((err) => {
      console.log(err);
    });
};

export const ReferralAnalytics = () => (dispatch) => {
  api
    .StatisticsApis().referralAnalytics()
    .then((res) => {
      
        dispatch({
            type:constant.FETCH_REFERRAL_DATA,
            payload:res.data
        })
    })
    .catch((err) => {
      console.log(err);
    });
};

export const TeacherTimeslotsStats = (data,onSuccess,onError) => (dispatch) => {
  api
    .StatisticsApis().teacherTimeslotsStats(data)
    .then((res) => {
      if(onSuccess){
        onSuccess('Time slots successfully fetched :)')
      }
        dispatch({
            type:constant.SET_TIME_SLOT_DETAILS,
            payload:res.data
        })
    })
    .catch((err) => {
      if(onError){
        onError("Something wen's wrong !")
      }
      console.log(err);
    });
};

export const PopularCity = () => (dispatch) => {
  api
    .StatisticsApis().popularCity()
    .then((res) => {
        dispatch({
            type:constant.GET_CITY_COUNT,
            payload:res.data.cityCount
        })
    })
    .catch((err) => {
      console.log(err);
    });
};


export const GetAllDemoTransaction = (data,onSuccess,onError) => (dispatch) => {
  api
    .StatisticsApis().allDemoTransaction(data)
    .then((res) => {
      console.log(res)
      onSuccess(res.data.paymentDetails);
    })
    .catch((err) => {
      console.log(err.response)
      onError(err.response.data.message);
    });
};
