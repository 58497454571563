import React, { useEffect, useState } from 'react'
import PageBreadcrumb from '../../component/PageBreadcrumb'
import { useDispatch } from 'react-redux'
import { GetAffiliatePayoutRequests, GetLearnerPayoutRequests, SendAffiliatePayout, SendLearnerPayout } from '../../redux/actions/UserAction';
import { Button, Radio, Space, Spin, Table, message } from 'antd';
import { UTCtoLocalConverter } from '../../helper/TimeDate';
import { Link } from "react-router-dom";

const Payouts = () => {
  const [payoutRequests, setPayoutRequests] = useState([]);
  const [totalRequest, setTotalRequest] = useState(0);
  const [loader, setLoader] = useState(true);
  const [tab, setTab] = useState("Requested");
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  useEffect(()=>{
    getLearnerPayoutRequests(1);
  },[tab])

  const getLearnerPayoutRequests = (page) => {
    setLoader(true);
    const payload = {
        status: tab,
        page: page, 
        limit: 10
    };
    const onSuccess = (data)=> {
        setCurrentPage(page);
        setTotalRequest(data.totalPayoutsCount)
        if(page>1)
        setPayoutRequests([...payoutRequests, ...data?.allAffiliatePayouts])
        else setPayoutRequests(data?.allAffiliatePayouts)
        setLoader(false);
    }
    const onError =(err) => {
        message.error(err);
        setLoader(false);
    }
    dispatch(GetAffiliatePayoutRequests(payload, onSuccess, onError))
  }

  const sendLearnerPayout = ({influencerId, payoutId}) => {
    const payload = {
      influencerId,
        payoutId
    };
    const onSuccess = (data)=> {
        message.success(data.message);
        let payoutReqArr = [...payoutRequests];
        payoutReqArr = payoutReqArr.map((el)=>{
            if(el._id===payoutId) el.status="Withdrawn"; 
            return el;
        });
        setPayoutRequests(payoutReqArr);
    }
    const onError =(err) => {
        message.error(err);
    }
    dispatch(SendAffiliatePayout(payload, onSuccess, onError))
  }

  const columns = [
    { title: "No", width: 30, dataIndex: "no", key: "1" },
    { title: "Name", width: 80, dataIndex: "name", key: "2", render: (name,record) => <Link to={`/learner/profile/${record.key}`}>{name}</Link>, },
    { title: "Phone No", width: 50, dataIndex: "phoneNumber", key: "3" },
    { title: "Email", width: '15%', dataIndex: "email", key: "4" },
    { title: "Start date", width: 50, dataIndex: "startDate", key: "5" },
    { title: "End date", width: 50, dataIndex: "endDate", key: "6" },
    { title: "status", width: 50, dataIndex: "status", key: "7" },
    { title: "Approved amount", width: 50, dataIndex: "approvedAmount", key: "8" },
    {
        title: "Action",
        width: 50,
        dataIndex: "action",
        key: "9",
        render: (action) => (
            <div style={{ display: "flex", gap: 8 }}>
                <Button onClick={() => sendLearnerPayout(action)}>Transfer</Button>
            </div>
        ),
    },
  ];

  const data = payoutRequests?.map((data, i) => ({
    key: data._id,
    no: i + 1,
    name: data.influencer?.name ? data.influencer.name : "N/A",
    phoneNumber: data.learner?.phone ? data.learner.phone : "N/A" ,
    email: data.learner?.email,
    startDate: UTCtoLocalConverter(data.startDate),
    endDate: UTCtoLocalConverter(data.endDate),
    approvedAmount: Math.round(data.approvedAmount),
    status: data.status,
    action: {
      influencerId: data.influencer?.id,
        payoutId: data._id
    }
  }));

  console.log(payoutRequests, "PAYOUT REQUEST" )

  const onChangePagination = (page, pageSize) => {
    getLearnerPayoutRequests(page.current);
  };

  return (
    <div>
        <PageBreadcrumb route={["dashboard", "Affiliate Payout"]} />
        <Space style={{ marginBottom: 24 }}>
        <Radio.Group value={tab} onChange={(e)=>setTab(e.target.value)}>
          <Radio.Button value="Requested">Requested</Radio.Button>
          <Radio.Button value="Withdrawn">Withdrawn</Radio.Button>
        </Radio.Group>
      </Space>

        {loader ? (
        <div className="spin-center">
          <Spin size="large" />
        </div>
      ) : (
        <Table
          columns={columns}
          dataSource={data}
          scroll={{ x: 1300 }}
          onChange={onChangePagination}
          pagination={{
            pageSize: 10,
            total: totalRequest,
            current: currentPage
          }}
        />
      )}
    </div>
  )
}

export default Payouts