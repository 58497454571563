// Import necessary components from Ant Design
import React, { useEffect, useState } from "react";
import { Card, Col, Empty, Form, Input, Row, message, Spin, Typography } from "antd";
import TextArea from "antd/lib/input/TextArea";

// Import custom components
import PageBreadcrumb from "../../component/PageBreadcrumb";
import AddUpdateModule from "../../component/CurriculumComponents/AddUpdateModule";
import AddUpdateCertificate from "../../component/CurriculumComponents/AddUpdateCertificate";
import ModuleList from "../../component/CurriculumComponents/ModulesList";
import CertificateList from "../../component/CurriculumComponents/CertificateList";
import { useDispatch, useSelector } from "react-redux";
import { GetCurriculum } from "../../redux/actions/CurriculumAction";
import { useParams } from "react-router-dom";

const { Title } = Typography;

const CurriculumDetails = () => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [curriculumName, setcurriculumName] = useState("")
  const [curriculumDescription, setcurriculumDescription] = useState("")

  const [loader, setLoader] = useState({
    curriculumLoading: false,
  });

  const curriculum = useSelector(
    (state) => state.CurriculumReducer.curriculumDetails
  );

  useEffect(() => {
      setLoader({ ...loader, curriculumLoading: true });
      const onSuccess = (res) => {
          setLoader({ ...loader, curriculumLoading: false });
          setcurriculumName(res?.curriculum?.name)
          setcurriculumDescription(res?.curriculum?.description)
          form.setFieldsValue({
            name: res?.curriculum?.name,
            description: res?.curriculum?.description,
          });
      };
      const onError = (msg) => {
        message.error(msg)
        setLoader({ ...loader, curriculumLoading: false });
      };
      GetCurriculum(id, onSuccess, onError, dispatch);
  }, []);

  console.log("mylogcur", curriculum);


  return (
    <div>
      {/* Page Breadcrumb */}
      <PageBreadcrumb
        route={["dashboard", "Curriculums", "Interview preparation"]}
      />

      <Card>
        {loader.curriculumLoading  ? (
                <Spin tip="Loading" size="small">
                <div className="content" />
              </Spin>
        ) : (
          <Form
            form={form}
            layout="vertical"
          >
            <Form.Item
              label={`Curriculum Title`}
              name={`name`}
              rules={[{ required: true, message: "Please add video title !" }]}
            >
              <Input placeholder="Curriculum title" />
            </Form.Item>

            {/* Curriculum Description */}
            <Form.Item label="Curriculum Description" name={`description`}>
              <TextArea rows={2} />
            </Form.Item>
          </Form>
        )}
      </Card>

      {/* Certificte Section */}
        <Card>
        <Row gutter={16}>
          <Col flex="auto">
            <Title level={4}>Certificate</Title>
          </Col>
          <Col>
            <div style={{ textAlign: "right" }}>
              <AddUpdateCertificate curriculumId={id} />
            </div>
          </Col>
        </Row>

        {/* Certificate List Component */}
        {curriculum?.modules?.length > 0 ? (
          <CertificateList />
          
        ) : (
          <Empty
            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
            imageStyle={{
              height: 60,
            }}
            description={<span>No certificate created for <strong>{curriculum?.name}</strong></span>}
          />
        )}
      </Card>

      {/* Modules Section */}
      <Card>
        <Row gutter={16}>
          <Col flex="auto">
            <Title level={4}>Modules</Title>
          </Col>
          <Col>
            <div style={{ textAlign: "right" }}>
              <AddUpdateModule curriculumId={id} />
            </div>
          </Col>
        </Row>

        {/* Module List Component */}
        {curriculum?.modules?.length > 0 ? (
           <ModuleList  />
          
        ) : (
          <Empty
            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
            imageStyle={{
              height: 60,
            }}
            description={<span>No module created for <strong>{curriculum?.name}</strong></span>}
          />
        )}
      </Card>
    </div>
  );
};

export default CurriculumDetails;
