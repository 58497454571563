import React from "react";

const Loder = ({ message }) => {
  return (
    <div className="d-flex flex-column align-items-center justify-content-center">
      <div className="row">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
      {message ? (
        <div className="row">
          <strong>{message}</strong>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Loder;
