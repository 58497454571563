import React from 'react';
import { useSelector } from 'react-redux';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';



const BarChartComponent = () => {

    const stats = useSelector((state) => state.AdminReducer.sessionDayWise);


    const data = [
        {
          name: 'Sunday',
          day: stats?.Sunday,
          amt: stats?.Sunday,
        },
        {
          name: 'Monday',
          day: stats?.Monday,
          amt: stats?.Monday,
        },
        {
          name: 'Tuesday',
          day: stats?.Tuesday,
          amt: stats?.Tuesday,
        },
        {
          name: 'Wednesday',
          day: stats?.Wednesday,
          amt: stats?.Wednesday,
        },
        {
          name: 'Thursday',
          day: stats?.Thursday,
          amt: stats?.Thursday,
        },
        {
          name: 'Friday',
          day: stats?.Friday,
          amt: stats?.Friday,
        },
        {
          name: 'Saturday',
          day: stats?.Saturday,
          amt: stats?.Saturday,
        },
      ];

    return (
        <BarChart
          width={450}
          height={300}
          data={data}
          margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="day" fill="#8884d8" />
        </BarChart>
    
    )
}

export default BarChartComponent
