import React, { useState, useEffect } from "react";
import { SearchOutlined } from '@ant-design/icons';
import {Table,Button,Space,Spin,Row,Col, DatePicker,Card,Tag,Radio} from "antd";
import moment from "moment";
import { useDispatch } from "react-redux";
import PageBreadcrumb from "../../component/PageBreadcrumb";
import { TeacherCanceledSession } from "../../redux/actions/SessionAction";
import { UTCToAsiaTimeConverter } from "../../helper/helper";

const dateFormat = "YYYY-MM-DD";
const { RangePicker } = DatePicker;

const JoiningTime = ({ times }) => {
  var timeArray = [];
   
  if (times.constructor === Array) {
    if (times.length > 0) {
      timeArray = times;
    } else {
      timeArray = ["Absent"];
    }
  } else if (typeof times === String) {
    timeArray = [times];
  } else {
    timeArray = ["Absent"];
  }

  return (
    <>
      {timeArray.map((tag, i) => {
        let color = "green";
        if (tag === "Absent") {
          color = "volcano";
        }
        return (
          <Tag color={color} key={`et128wy2ye${i}`}>
            {tag.toUpperCase()}
          </Tag>
        );
      })}
    </>
  );
};

const CanceledSession = () => {
    const dispatch = useDispatch();
    const [startDate, setStartDate] = useState(moment().format(dateFormat))
    const [endDate, setEndDate] = useState(moment().add(7, 'days').format(dateFormat))
    const [loader, setLoader] = useState(false);
    const [canceledSession, setCanceledSession] = useState([]);
    const [sessioncancelCount, setSessioncancelCount] = useState([]);
    const [missingteachersessionCount, setMissingTeachersessionCount] = useState([]);
    const [table, setTable] = useState(1)//by default table 1
    
    
    const columns = [
      { title: "No", width: 10, dataIndex: "no", key: "1" },
      { 
        title: "Meeting No", 
        width: 30, 
        dataIndex: "meetingNo", 
        key: "2" ,
        render: (meetingNo,{canceled,techFailure}) =>{
          return <p>{meetingNo} {canceled === "True"?"(Canceled)":""} {techFailure === "True"?"(Tech failure)":""} </p>
        }
    },
      { title: "Canceled By", width: 30, dataIndex: "canceledBy", key: "13" },
      { title: "Teacher Name", width: 30, dataIndex: "teacherName", key: "3" },
      { title: "Learner Name", width: 30, dataIndex: "learnerName", key: "4" },
      { title: "Session Date", width: 30, dataIndex: "sessionDate", key: "5" },
      { title: "Session Time", width: 20, dataIndex: "sessionTime", key: "6" },
      { title: "Duration", width: 15, dataIndex: "duration", key: "7" },
      { title: "Teacher Joining Time",
        width: 50,dataIndex: "teacherJoining",key: "7",
        render: (joiningtime) => <JoiningTime times={joiningtime} />,
      },
      {
        title: "Learner Joining Time",
        width: 50,
        dataIndex: "learnerJoining",
        key: "8",
        render: (joiningtime) => <JoiningTime times={joiningtime} />,
      },
      { title: "Session Type", width: 20, dataIndex: "sessionType", key: "9" },
      { title: "Tech Failure", width: 20, dataIndex: "techFailure", key: "10" },
      { title: "Phone number", width: 20, dataIndex: "phonenum", key: "11" },
      { title: "Canceled", width: 20, dataIndex: "canceled", key: "12" },
    ]

    const columns2 = [
      { title: "Name", width: 10, dataIndex: "name", key: "1" },
      { 
        title: "Total cancelled sessions", 
        width: 30, 
        dataIndex: "tot_cancelled_sessions", 
        key: "2" ,
        sorter: (a, b) => a.tot_cancelled_sessions + b.tot_cancelled_sessions,
    }
    ] 
    
    const columns3=[
      { title: "Name", width: 10, dataIndex: "name", key: "3" },
      { 
        title: "Total Missing sessions", 
        width: 30, 
        dataIndex: "tot_missing_sessions", 
        key: "3" ,
        sorter: (a, b) => a.tot_missing_sessions + b.tot_missing_sessions,
    }
    ] 

  
    function onPickDate(date, dateString) {
      setStartDate(dateString[0])
      setEndDate(dateString[1])
    }
  
    const absentIdentifier = (data) => {
      console.log(data)
      var convertedTime = UTCToAsiaTimeConverter(data.sessionTime);
      var splitedValue = convertedTime.split(":");
      var joiningTime =
        splitedValue[0] +
        ":" +
        String(Number(splitedValue[1]) + 2).padStart(2, "0");
  
      if (data.teacherJoining.constructor === Array) {
        if (data.canceled === "True") {
          return "yellow";
        }else if (data.teacherJoining.length > 0) {
          if (data.techFailure === "True") {
            return "red";
          } else {
            return "green";
          }
        } else if (data.techFailure === "True") {
          return "red";
        } else {
          return "red";
        }
      } else {
        return "red";
      }
    };


    const teacherCanceledSession = (startDate, endDate, reset) => {
      setLoader(true);
      var data = {
        startDate: startDate,
        endDate:endDate,
        timezone: "Asia/Kolkata"
      };
    
      var onSuccess = (response) => {
        if (reset) {
            setCanceledSession(response.sessions);
            setSessioncancelCount(response.canceledSessionCount);
            setMissingTeachersessionCount(response.missingTeacherSessionCount);
        } else {
            setCanceledSession([...canceledSession, ...response.sessions]);
            setSessioncancelCount([...sessioncancelCount, ...response.canceledSessionCount])
            setSessioncancelCount([...missingteachersessionCount, ...response.missingTeacherSessionCount])
            
        }

        setLoader(false);
      };
      var onError = (error) => {
        console.log(error);
        setLoader(false);
      };
      dispatch(TeacherCanceledSession(data, onSuccess, onError));
    };
  
    useEffect(() => {
      teacherCanceledSession(startDate,endDate,'true')
    }, [])
    

    var data = canceledSession?.map((data, i) => ({
      key: i,
      no: i + 1,
      meetingNo: data.meetingNo,
      canceledBy:data.cancelBy?.name,
      teacherName: data.teacher?.name,
      learnerName: data.learner?.name,
      sessionDate: moment(new Date(data.sessionDate)).format(dateFormat),
      sessionTime: UTCToAsiaTimeConverter(data.startTime),
      duration: data.sessionDuration,
      teacherJoining: UTCToAsiaTimeConverter(data.speakerJoiningTime?.slice(0, 2)),
      learnerJoining: UTCToAsiaTimeConverter(data.learnerJoiningTime?.slice(0, 2)),
      sessionType: data.type,
      techFailure: data.isTechFailure ? "True" : "False",
      phonenum: data.learner
        ? data.learner.phoneNumber
          ? data.learner.phoneNumber
          : "N/A"
        : "N/A",
      canceled:data.isCancel? "True" : "False"
    }));

    var data2 = sessioncancelCount?.map((data, i)=>({
      key:i,
      name:(data?.teacherName).length>0?data?.teacherName.map((teachers, j)=>(<p>{teachers}</p>)):(<p>{"-"}</p>),
      tot_cancelled_sessions:data?.total
    }))


    var data3= missingteachersessionCount?.map((data, i)=>({
      key:i,
      name:(data?.teacherName).length>0?data?.teacherName.map((teachers, j)=>(<p>{teachers}</p>)):(<p>{"-"}</p>),
      tot_missing_sessions:data?.total
    }))





    const onChange = (e) => {
      setTable(parseInt(e.target.value));
    };
    return (
      <div>
        <PageBreadcrumb route={["dashboard", "Teacher canceled session"]} />
  
         <Card title="Filter Session" style={{ marginBottom: "1rem" }}>
  
          <Row>
  
            <Col span={10} style={{"marginTop":"1.3rem"}}>
              <Space>
              <RangePicker
                onChange={onPickDate}
                defaultValue={[moment(startDate, dateFormat), moment(endDate, dateFormat)]}
                format={dateFormat}
              />

                <Button
                  type="primary"
                  onClick={() => {
                    setCanceledSession([]);
                    setSessioncancelCount([]);
                    teacherCanceledSession(startDate,endDate, "reset");
                  }}
                >
                  <SearchOutlined />
                  Search
                </Button>
              </Space>
            </Col>
          </Row>
        </Card>
        <Radio.Group onChange={onChange} defaultValue="1">
          <Radio.Button value="1 ">All Cancelled Sessions</Radio.Button>
          <Radio.Button value="2">Total Cancelled Sessions</Radio.Button>
          <Radio.Button value="3">Total Missing Sessions</Radio.Button>
        </Radio.Group>
        {loader ? (
          <div className="spin-center">
            <Spin size="large" />
          </div>
        ) : (
          
            table === 1?
            <Table
            columns={columns}
            dataSource={data}
            scroll={{ x: 2600 }}
            pagination={false}
            rowClassName={(record, index) => absentIdentifier(record)}
          />: (table === 2)?
          <Table
            columns={columns2}
            dataSource={data2}
            scroll={{ x: 0 }}
            pagination={false}
          />:
          <Table
          columns={columns3}
          dataSource={data3}
          scroll={{ x: 0 }}
          pagination={false}
        />
        )}
      </div>
    );
  };

export default CanceledSession