import * as constant from "../constants/Constant";

const initialState = {
  session: null,
  upcoming: null,
  past: null,
  userFeedback:null,
  
};

export const SessionReducer = (state = initialState, action) => {
  switch (action.type) {
    
    case constant.UPCOMING_SESSION:
      return {
        ...state,
        upcoming: action.payload,
      };
    case constant.PAST_SESSION:
      return {
        ...state,
        past: action.payload,
      };
    case constant.GET_ALL_SESSION:
      console.log(action.payload);
      return {
        ...state,
        session: action.payload,
      };
      case constant.USER_FEEDBACK:
        console.log(action.payload);
        return {
          ...state,
          userFeedback: {
            ratingData: Object.values(action.payload.ratingData),
            totalRating:action.payload.totalRating
          },
        };


      
    case constant.DELETE_SESSION:
      console.log(action.payload);
      return {
        ...state,
        session: state.session.filter(x => x._id !== action.payload),
      };

    case "TEACHER_SWAPPED":
      console.log(action.payload);

      const updatedSession = state.upcoming.map(meeting => {
        if (meeting.meetingNo === action.payload) {
          // If the meeting number matches, add the isSessionSwapped field
          return {
            ...meeting,
            isSessionSwapped: true
          };
        }
        return meeting;
      });
    
      console.log(updatedSession)


      return {
        ...state,
        upcoming: updatedSession
      };

    default:
      return state;
  }
};
