import React, { useEffect, useState } from "react";
import { Card, Col, Row, Button, message, Spin, Modal } from "antd";
import PageBreadcrumb from "../../component/PageBreadcrumb";
import {
  EditOutlined,
  EllipsisOutlined,
  PlusOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { GetAllCourse, DeleteACourse } from "../../redux/actions/CoursesAction";
import { useDispatch } from "react-redux";

const AllCourses = () => {
  const { Meta } = Card;

  const history = useHistory();
  const dispatch = useDispatch();

  const [corses, setCourses] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true)
    const onSuccess = (data) => {
      console.log(data)
      setLoading(false)
      setCourses(data);
    };

    const onError = (error) => {
      setLoading(false)
      message.error(error, 8);
    };

    dispatch(GetAllCourse(1, 100, onSuccess, onError));
  }, []);

  // delete course
  const deleteACourse = (id) =>{
    Modal.confirm({
      title: "Are you sure you want to delete this",
      onOk : () => {
        setLoading(true)
      const onSuccess = (msg) =>{
        setLoading(false)
        message.success(msg)
      }
      const onError = (msg) =>{
        message.error(msg)
      }
  
     DeleteACourse({courseId: id},onSuccess,onError)
    }
    })
      
  }

  return (
    <div>
      <PageBreadcrumb route={["dashboard", "Courses"]} />

{
  !loading?
  <div className="site-card-wrapper">
  <Row gutter={16} align="middle">
    {corses?.filter(data => data.status!=="Deleted").map((data, i)=> {
      return (
        <Col span={8} key={i}>
          <Card
            cover={
              <img
                alt="example"
                src={data.thumbnail?data.thumbnail:"https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png"}
              />
            }
            actions={[
              <EditOutlined key="edit" onClick={()=>history.push(`/add-course?action=Edit&id=${data._id}`)}/>,
              <DeleteOutlined onClick={() => deleteACourse(data._id)}/>,
              <EllipsisOutlined key="ellipsis" />
            ]}
          >
            <Meta
              title={data.courseName}
              description={data.courseDesc}
            />
          </Card>
        </Col>
      );
    })}

    <Col>
      <Button
        type="dashed"
        onClick={() => history.push("/add-course")}
        style={{ width: "300px", height: "20rem" }}
        icon={<PlusOutlined />}
      >
        Add course
      </Button>
    </Col>
  </Row>
</div>
:
<div className="spin-center">
<Spin size="large" />
</div>
}

    </div>
  );
};

export default AllCourses;
