import React from "react";
import logo from "../assets/logowhite.svg";
import { Link } from "react-router-dom";
import { Layout, Menu, Image } from "antd";
import {
  PieChartOutlined,
  LogoutOutlined,
  UserAddOutlined,
  AlertOutlined,
  CheckSquareOutlined,
  TeamOutlined,
  VideoCameraOutlined,
  YoutubeOutlined,
  BookOutlined,
  TransactionOutlined,
  FileProtectOutlined
} from "@ant-design/icons";
import { roleValidator } from "../helper/helper";
import * as access from "../routes/RoleBasedAccess";
const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

const Sidenav = ({ children }) => {

  const logout = () => {
    localStorage.clear();
    window.location.reload();
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        style={{
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          left: 0,
        }}
        trigger={null}
        collapsible
        collapsed={false}
      >
        <div className="logo">
          <Image src={logo} alt="logo" />
        </div>
        <Menu theme="dark" mode="inline" defaultSelectedKeys={["1"]}>
          {roleValidator(access.home) ? (
            <Menu.Item key="1" icon={<PieChartOutlined />}>
              <Link to="/">Dashboard</Link>
            </Menu.Item>
          ) : (
            ""
          )}
          {roleValidator(access.teacherMenu) ? (
            <SubMenu
              key="7899877098"
              icon={<UserAddOutlined />}
              title="Teacher"
            >
              {roleValidator(access.allTeacher) ? (
                <Menu.Item key="68729132626">
                  <Link to="/teacher/all-teacher">Teachers management</Link>
                </Menu.Item>
              ) : (
                ""
              )}

              {roleValidator(access.allTeacher) ? (
                <Menu.Item key="68729132684">
                  <Link to="/teacher/demo-to-conversion">Demo conversion</Link>
                </Menu.Item>
              ) : (
                ""
              )}

              {roleValidator(access.teacherSessionAttendance) ? (
                <Menu.Item key="6236263623">
                  <Link to="/teacher/session-attendance">
                    Session Attendance
                  </Link>
                </Menu.Item>
              ) : (
                ""
              )}

              {roleValidator(access.teacherSessionAttendance) ? (
                <Menu.Item key="6236263623675">
                  <Link to="/teacher/canceled-session">
                    Canceled Session
                  </Link>
                </Menu.Item>
              ) : (
                ""
              )}

              {roleValidator(access.teacherSessionAttendance) ? (
                <Menu.Item key="6233675">
                  <Link to="/teacher/earning-report">
                    Earning Report
                  </Link>
                </Menu.Item>
              ) : (
                ""
              )}



              {roleValidator(access.downloadSessionreport) ? (
                <Menu.Item key="68729132666">
                  <Link to="/download-sessionreport">Attendance report</Link>
                </Menu.Item>
              ) : (
                ""
              )}

              {roleValidator(access.disputes) ? (
                <Menu.Item key="137767666532145g2">
                  <Link to="/teacher/alldisputes">Disputes</Link>
                </Menu.Item>
              ) : (
                ""
              )}

              {roleValidator(access.teacherConcern) ? (
                <Menu.Item>
                  <Link to="/teacher/teacher-concern">Teacher Occupancy Concern</Link>
                </Menu.Item>
              ) : (
                ""
              )}

              {roleValidator(access.resourceUpload) ? (
                <Menu.Item>
                  <Link to="/teacher/resource-upload">Resourse Upload</Link>
                </Menu.Item>
              ) : (
                ""
              )}

              {roleValidator(access.resourceUpload) ? (
                <Menu.Item key="123456789987">
                  <Link to="/teacher/applications">Teacher Applications</Link>
                </Menu.Item>
              ) : (
                ""
              )}

              {roleValidator(access.resourceUpload) ? (
                <Menu.Item key="123456789988">
                  <Link to="/teacher/tech-issues">Tech Issues</Link>
                </Menu.Item>
              ) : (
                ""
              )}

            </SubMenu>
          ) : (
            ""
          )}
          {roleValidator(access.adminAccess) ? (
            <SubMenu
              key="6bd55994-b00f-4701-81b5-c23da71aeb5b"
              icon={<TeamOutlined />}
              title="Admin"
            >

              {roleValidator(access.adminAccess) ? (
                <Menu.Item key="40370e82-935c-4d21-9a1d-28cb1ec3cbfb">
                  <Link to="/admin/all-admin">Admin List</Link>
                </Menu.Item>

              ) : (
                ""
              )}
              {roleValidator(access.adminAccess) ? (
                <Menu.Item key="40370e82-935c-4d21-9a1d-28cb1ec3cbfc">
                  <Link to="/admin/demo-pool">Demo Pool</Link>
                </Menu.Item>

              ) : (
                ""
              )}

              {roleValidator(access.adminAccess) ? (
                <Menu.Item key="40370e82-935c-4d21-9a1d-28cb1ec3cbfdngh">
                  <Link to="/admin/userStories">User Stories</Link>
                </Menu.Item>

              ) : (
                ""
              )}
              {roleValidator(access.adminAccess) ? (
                <Menu.Item key="40370e82-935c-4d21-9a1d-28cb1eagthsgd">
                  <Link to="/admin/session-allotment">Sessions Allotment</Link>
                </Menu.Item>

              ) : (
                ""
              )}

              {roleValidator(access.adminAccess) &&
                <Menu.Item key="40370e82-935c-4sdfghjkjhgf-28cb1eagthsgd">
                  <Link to="/admin/demo-sessions">Swap Session</Link>
                </Menu.Item>

              }
            </SubMenu>
          ) : (
            ""
          )}
          {roleValidator(access.learnerMenu) ? (
            <SubMenu
              key="6bd55994-b00f-4701-81b5-c23da71aeb5a"
              icon={<TeamOutlined />}
              title="Learner"
            >
              {roleValidator(access.learnerSessionAttendance) ? (
                <Menu.Item key="40370e82-935c-4r662-9a1d-28cb1ec3cbfd">
                  <Link to="/learner/session-attendance">
                    Session Attendance
                  </Link>
                </Menu.Item>
              ) : (
                ""
              )}
              {roleValidator(access.allLearner) ? (
                <Menu.Item key="40370e82-935c-4d21-9a1d-28cb1ec3cbfd">
                  <Link to="/learner/all-learner">Learner List</Link>
                </Menu.Item>
              ) : (
                ""
              )}
              {roleValidator(access.learnerPayout) ? (
                <Menu.Item key="40370e82-935c-4d21-9a1d-28cb1ec3cbfe">
                  <Link to="/learner/learner-payout">Learner Payout</Link>
                </Menu.Item>
              ) : (
                ""
              )}
              {roleValidator(access.subscriptionList) ? (
                <Menu.Item key="40370e82-935c-4d21-9a1d-28cb1ec3cbfg">
                  <Link to="/learner/subscriptionList">Subscription List</Link>
                </Menu.Item>
              ) : (
                ""
              )}
            </SubMenu>
          ) : (
            ""
          )}
          {roleValidator(access.sessionMenu) ? (
            <SubMenu
              key="6bd55994-b11f-77845-81b56789-71aeb5a"
              icon={<VideoCameraOutlined />}
              title="Session"
            >
              {roleValidator(access.sessionRequest) ? (
                <Menu.Item key="40370e82-7eisehfius-9a1d-28cb1ec3cbfd">
                  <Link to="/session/session-request">Session Request</Link>
                </Menu.Item>
              ) : (
                ""
              )}
              {roleValidator(access.upcoming) ? (
                <Menu.Item key="40370e82-7eisehfius-9a1d-28cb1ec3cbfd">
                  <Link to="/session/session-request">Session Request</Link>
                </Menu.Item>
              ) : (
                ""
              )}
              {roleValidator(access.upcoming) ? (
                <Menu.Item key="40370e82-7668-7eisehfius-9a1d-28cb1ec3cbfd">
                  <Link to="/session/upcoming">Upcoming sessions</Link>
                </Menu.Item>
              ) : (
                ""
              )}
              {roleValidator(access.past) ? (
                <Menu.Item key="40370e82-7668-384286-9a1d-28cb1ec3cbfd">
                  <Link to="/session/past">Past sessions</Link>
                </Menu.Item>
              ) : (
                ""
              )}

              {roleValidator(access.past) ? (
                <Menu.Item key="40370e82-766-678ijh890bajj-hhs78ajsn">
                  <Link to="/session/canceled">Canceled sessions</Link>
                </Menu.Item>
              ) : (
                ""
              )}

              {roleValidator(access.past) ? (
                <Menu.Item key="40370e82-7668-384286-9a1d-28cb1ec76">
                  <Link to="/session/session-score">Session score</Link>
                </Menu.Item>
              ) : (
                ""
              )}

              {roleValidator(access.analytics) ? (
                <Menu.Item key="40370e82-7668-3842">
                  <Link to="/session/analytics">Session analytics</Link>
                </Menu.Item>
              ) : (
                ""
              )}

              {roleValidator(access.videoSessions) ? (
                <Menu.Item key="40370e82-7668-3843">
                  <Link to="/session/video-sessions">Video Sessions</Link>
                </Menu.Item>
              ) : (
                ""
              )}

            </SubMenu>
          ) : (
            ""
          )}


          {roleValidator(access.campaignMenu) ? (
            <SubMenu
              key="6bd55994-b11f-4701-81b5-c23da71aeb5a"
              icon={<AlertOutlined />}
              title="campaign"
            >
              <Menu.Item key="40370e82-7668-4r662-9a1d-28cb1ec3cbfd">
                <Link to="/campaign/utm-conversion">UTM Conversion</Link>
              </Menu.Item>
              <Menu.Item key="40370e82-4r662-9a1d-28cb1ec3cbfd">
                <Link to="/campaign/analytics">UTM Conversion analytics</Link>
              </Menu.Item>
            </SubMenu>
          ) : (
            ""
          )}

          {roleValidator(access.demoTransactionAccess) ? (
            <Menu.Item key="1377676665321442" icon={<TransactionOutlined />}>
              <Link to="/demo-transactions">Demo transaction</Link>
            </Menu.Item>
          ) : (
            ""
          )}

          {roleValidator(access.courseAccess) ? (
            <SubMenu
              key="6bd55994-b11f-4701-81b5-c23da71aeb54c"
              icon={<BookOutlined />}
              title="Courses"
            >
              <Menu.Item key="1377676654" icon={<BookOutlined />}>
                <Link to="/courses">Courses</Link>
              </Menu.Item>
              <Menu.Item key="1377676655" icon={<BookOutlined />}>
                <Link to="/courseBundle">Course Bundle </Link>
              </Menu.Item>
            </SubMenu>

          ) : (
            ""
          )}

          {roleValidator(access.curriculumAccess) ? (
            <SubMenu
              key="6bd55994-b11f-4701-c23da71aeb54c"
              icon={<FileProtectOutlined />}
              title="Curriculum"
            >
              <Menu.Item key="1377676654djgcuag" icon={<BookOutlined />}>
              <Link to="/curriculum">Curriculums</Link>
            </Menu.Item>
            </SubMenu>
            
          ) : (
            ""
          )}

          {roleValidator(access.videoApproval) ? (
            <SubMenu
              key="6bd55994-b11f-4701-81b5-c23da71aeb5c"
              icon={<YoutubeOutlined />}
              title="YouTube"
            >
              <Menu.Item key="137767666478" icon={<YoutubeOutlined />}>
                <Link to="/video-approval">Video approval </Link>
              </Menu.Item>
              <Menu.Item key="190gklga67" icon={<YoutubeOutlined />}>
                <Link to="/YouTubers">YouTuber list </Link>
              </Menu.Item>
            </SubMenu>

          ) : (
            ""
          )}

          {roleValidator(access.userfeedback) ? (
            <Menu.Item key="13" icon={<CheckSquareOutlined />}>
              <Link to="/userfeedback">User Feedback </Link>
            </Menu.Item>
          ) : (
            ""
          )}

          {roleValidator(access.generatePaymentLink) ? (
            <Menu.Item key="134" icon={<CheckSquareOutlined />}>
              <Link to="/paymentlinks">Payment Links </Link>
            </Menu.Item>
          ) : (
            ""
          )}

          {roleValidator(access.affiliate) ? (
            <SubMenu
              key="6bd55994-b11f-4701-81b5-c23da71aeb6c"
              icon={<TeamOutlined />}
              title="Affiliates"
            >
              <Menu.Item key="1377676664" icon={<TeamOutlined />}>
                <Link to="/affiliate">Update Status</Link>
              </Menu.Item>
              <Menu.Item key="1377676665" icon={<TeamOutlined />}>
                <Link to="/payouts">Payouts</Link>
              </Menu.Item>
            </SubMenu>
          ) : (
            ""
          )}
          {roleValidator(access.coupons) ? (
            <Menu.Item key="6" icon={<TeamOutlined />}>
              <Link to="/coupons">Coupons</Link>
            </Menu.Item>
          ) : (
            ""
          )}

          <Menu.Item key="15" onClick={logout} icon={<LogoutOutlined />}>
            Logout
          </Menu.Item>
        </Menu>
      </Sider>
      <>
        <Header className="site-layout-background" style={{ padding: 0 }} />
        <Layout className="site-layout" style={{ marginLeft: "15%" }}>
          <Content style={{ margin: "0 16px" }}>
            <div
              className="site-layout-background"
              style={{ padding: 24, minHeight: 360 }}
            >
              {children}
            </div>
          </Content>
          <Footer style={{ textAlign: "center" }}>Clapingo ©{new Date().getFullYear()}</Footer>
        </Layout>
      </>
    </Layout>
  );
};

export default Sidenav;
