import React,{useState,useEffect} from "react";
import { Table, Button, Space, Spin,Tag, message } from "antd";
// import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PageBreadcrumb from "../PageBreadcrumb";
import { getDate } from "../../helper/helper";
import { DeleteSession, PastDemo, UpcomingDemo,PastRegularSessionAdmin,UpcomingRegularSessionAdmin } from "../../redux/actions/SessionAction";

const SessionTable = () => {

  const dispatch = useDispatch()
  const [filterSession,setFilterSession] = useState(null)
  const [loder,setLoder] = useState(null)

  const Session = useSelector((state) => state.SessionReducer.session);
  const upcomingDemosession = useSelector((state) => state.SessionReducer.upcomingDemo);
  const pastDemosession = useSelector((state) => state.SessionReducer.pastDemo);
  const Teacher = useSelector((state) => state.AdminReducer.teacher);

  useEffect(() => {
    if(Session){
      const data = {
        date: getDate(new Date()),
        type: "Demo",
      };
      // dispatch(UpcomingDemo(data));
      // dispatch(PastDemo(data));
    setFilterSession(Session)
    }
  }, [Session])

const UpcomingRegularSession = () =>{
  setFilterSession(null)

  const data = {
    date: getDate(new Date()),
    type: "Regular",
  };

  const onSuccess = (data) =>{
    setFilterSession(data)
  }
  const onError = (msg) =>{
    setFilterSession(null)
    message.error(msg)
  }
  // dispatch(UpcomingRegularSessionAdmin(data,onSuccess,onError))
}
const PastRegularSession = () =>{
  setFilterSession(null)

  const data = {
    date: getDate(new Date()),
    type: "Regular",
  };

  const onSuccess = (data) =>{
    setFilterSession(data)
  }
  const onError = (msg) =>{
    setFilterSession(null)
    message.error(msg)
  }
  // dispatch(PastRegularSessionAdmin(data,onSuccess,onError))
}

const UpcomingDemoSessions = () =>{
  setFilterSession(upcomingDemosession)
}
const PastDemoSessions = () =>{
  setFilterSession(pastDemosession)
}
const clear = () =>{
  setFilterSession(Session)
}

const TeacherName = (id) => {
  var name;
  
  Teacher.filter((data) => {
    if (data._id === id) {
      console.log(data.name);
      name = data.name;
    }
  });
  return name;
};


  const deleteSession = (id) =>{
    if (window.confirm('Are you sure you want to Delete this Session from the database?')) {
      setLoder(id)
      const onSuccess = (msg) =>{
        setLoder(null)
        message.success(msg)
      }
      const onError = (msg) =>{
        setLoder(null)
        message.error(msg)
      }
  
     dispatch(DeleteSession({id:id},onSuccess,onError))
    } else {
      // Do nothing!
      console.log('Thing was not saved to the database.');
    }
  }

  const columns = [
    // {
    //   title: "Assign",
    //   width: 100,
    //   dataIndex: "Assign",
    //   key: "Assign",
    //   fixed: "left",
    // },
    {
      title: "Delete",
      width: 100,
      dataIndex: "Delete",
      key: "Delete",
      fixed: "left",
    },
    { title: "Meeting No", width: 150, dataIndex: "Meeting_No", key: "1" },
    { title: "Host", width: 170, dataIndex: "Host", key: "2" },
    { title: "Learner", width: 150, dataIndex: "Learner", key: "3" },
    { title: "Teacher", width: 150, dataIndex: "Teacher", key: "4" },
    { title: "Start time", width: 150, dataIndex: "Start_time", key: "5" },
    { title: "End time", width: 150, dataIndex: "End_time", key: "6" },
    { title: "Date", width: 150, dataIndex: "Date", key: "7" },
    {
      title: "Duration",
      width: 150,
      dataIndex: "Duration",
      key: "9",
      filters: [
        { text: "15", value: "15" },
        { text: "25", value: "25" },
      ],
    },
    {
      title: "Type",
      width: 150,
      dataIndex: "Type",
      key: "10",
      filters: [
        { text: "Demo", value: "Demo" },
        { text: "Regular", value: "Regular" },
      ],
    },
    {
      title: "Session Attended",
      width: 150,
      dataIndex: "isSessionAttended",
      key: "11",
      render: (tags) => (
        <>
          {tags.map((tag) => {
            let color = tag == "yes" ? "geekblue" : "volcano";
            if (tag === "loser") {
              color = "volcano";
            }
            return (
              <Tag color={color} key={tag}>
                {tag.toUpperCase()}
              </Tag>
            );
          })}
        </>
      )
    },
    {
      title: "Meeting Cancel",
      width: 150,
      dataIndex: "isCancel",
      key: "12",
      render: (tags) => (
        <>
          {tags.map((tag) => {
            let color = tag == "yes" ? "geekblue" : "volcano";
            if (tag === "loser") {
              color = "volcano";
            }
            return (
              <Tag color={color} key={tag}>
                {tag.toUpperCase()}
              </Tag>
            );
          })}
        </>
      )
    },

    {
      title: "Meeting Password",
      width: 150,
      dataIndex: "Meeting_password",
      key: "13",
    },
    {
      title: "Teacher Feedback",
      width: 300,
      dataIndex: "Teacher_feedback",
      key: "14",
    },
  ];

  const data = filterSession
    ? filterSession.map((data,i) => ({
        key: data._id,
        // Assign: <Button type="primary">Update</Button>,
        Delete: <Button type="danger" loading={loder === data._id?true:false} onClick={() => deleteSession(data._id)}>Delete</Button>,
        Meeting_No: data.meetingNo,
        Host: data.host,
        Learner: data.learner?data.learner.name:"Not available",
        Teacher: data.teacher || data.assignTeacher ? data.assignTeacher? TeacherName(data.assignTeacher._id?data.assignTeacher._id:data.assignTeacher) :  TeacherName(data.teacher._id): "Not available",
        Start_time: data.startTime,
        End_time: data.endTime,
        Date: getDate(data.sessionDate),
        Duration: data.sessionDuration,
        Type: data.type,
        isSessionAttended: data.isSessionAttended ? ["yes"] : ["No"],
        isCancel: data.isCancel ? ["yes"] : ["No"],
        Meeting_password: data.meetingPassword,
        Teacher_feedback: data.teacherFeedback?data.teacherFeedback:"Not available",
      }))
    : "";

  return (
    <>
      <PageBreadcrumb route={["dashboard", "session"]} />
      <Space style={{ marginBottom: 16 }}>
        {/* <Button type="primary" onClick={() => {}}>
          <Link to="/createsession">Create Session</Link>
        </Button> */}
        <Button onClick={UpcomingDemoSessions}>Upcoming Demo session</Button>
        <Button onClick={UpcomingRegularSession}>Upcoming Regular Session</Button>
        <Button onClick={PastDemoSessions}>Past Demo session</Button>
        <Button onClick={PastRegularSession}>Past Regular Session</Button>
        <Button onClick={clear}>Clear filter</Button>
      </Space>

      {filterSession ? (
        <Table
          columns={columns}
          dataSource={data.reverse()}
          scroll={{ x: 1300 }}
          pagination={{
            defaultPageSize: 40,
            showSizeChanger: true,
            pageSizeOptions: ["80", "120", "160"],
          }}
        />
      ) : (
        <div className="spin-center">
          <Spin size="large" />
        </div>
      )}
    </>
  );
};

export default SessionTable;
