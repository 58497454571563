import React, { useState } from "react";
import PageBreadcrumb from "../../component/PageBreadcrumb";
import { ResourcesUpload } from "../../redux/actions/AuthAction";
import { message , Button, Card, Input, Form} from "antd";

const ResourceUpload = () => {

    const [selectedFile, setSelectedFile] = useState(null);
    const [loader, setLoader] = useState(false);
    const [documentsName, setDocumentsName] = useState("");
    const [form] = Form.useForm();
    const inputStyle = {
      width: '300px',
      height: '40px', 
    };
  
    const inputStyleUpload = {
      width: '500px',
      height: '40px', 
    };
  
  

    // selected the file to upload
    const handleFileSelect = (e) => {
      if (e.target.files[0]) {
          // console.log("file:", e.target.files);
          setSelectedFile(e.target.files[0]);
      }
      };

    // Using fetch to get the presigned using and upload the file in S3
    const handleUpload = () => {
      setLoader(true);
        const data = {
            fileName: selectedFile.name,
            fileType: selectedFile.type,
            documentName: documentsName,
         }
        const onSuccess = async (res) => {
          const presignedURL = res.data?.data?.presignedurl
          console.log("url", presignedURL)

          const response = await fetch(presignedURL, {
            method: 'PUT',
            body: selectedFile,
            headers: {
              'Content-Type': selectedFile.type,
            },
          });

          if (response.ok) {
            message.success('File uploaded successfully!');
            setLoader(false);
            setSelectedFile(null)
            window.location.reload();
          } else {
            message.error('Failed to upload file:', response.statusText);
          }

        };
        const onError = (errmsg) => {
          message.error(errmsg);
          setLoader(false);
        };
        ResourcesUpload(data, onSuccess, onError);
      };

      const isButtonDisabled = !selectedFile || !documentsName
    
    return (
        <>
        <PageBreadcrumb route={["dashboard", "Resource Upload"]} />
        <Card>
          <Form>
        <div className="resource-container">
          <div className="resource-heading">Tutor Training Resource Upload</div>
          <div className="resource-input">
          <Form.Item
                label="Document Name"
                rules={[{ required: true }]}
                tooltip="This is a required field"
                name="documentsName"
              >
          <Input style={inputStyle} placeholder="Enter document Name" onChange={e=>setDocumentsName(e.target.value)}/>
          </Form.Item>
        </div>
        <Form.Item
                rules={[{ required: true }]}
                tooltip="This is a required field"
              >
        <div className="resource-upload">
        <Input type="file" onChange={handleFileSelect} style={inputStyleUpload}/>
        </div>
        </Form.Item>

        <Button onClick={handleUpload} disabled={isButtonDisabled} loading={loader}>{loader? "Uploading" : "Upload"}</Button>
        </div>
        </Form>
        </Card>
        </>
        
    )
}

export default ResourceUpload