import React, { useEffect, useState } from "react";
import { Row, Col, Image, Card, Form, Input, Button, message } from "antd";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  AdminLogin,
  AskOTP,
  resendOTP,
  verifyOTP,
} from "../redux/actions/AuthAction";

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [verifyLoader, setVerifyLoader] = useState(false);
  const Admin = useSelector((state) => state.AdminReducer.admin);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOTP] = useState("");
  const [isOTPRequested, setIsOTPRequested] = useState(false);
  const [verifyButtonDisabled, setVerifyButtonDisabled] = useState(true);

  console.log("admin", Admin)
  useEffect(()=>{
    if(Admin.isLogin === true){
      history.push('/')
    }
  })

  const askOTP = () => {
    if (!phoneNumber.trim()) {
      message.error("Please enter your phone number.");
      return;
    }
    setLoader(true);
    const data = {
      phoneNumber: phoneNumber,
    };
    const onSuccess = (msg) => {
      message.success(msg);
      setIsOTPRequested(true);
      setLoader(false);
    };
    const onError = (msg) => {
      message.error(msg);
      setLoader(false);
    };
    dispatch(AskOTP(data, onSuccess, onError));
  };

  const ResendOTP = () => {
    if (!phoneNumber.trim()) {
      message.error("Please enter your phone number.");
      return;
    }
    setLoader(true);
    const data = {
      phoneNumber: phoneNumber,
    };
    const onSuccess = (msg) => {
      message.success(msg);
      setIsOTPRequested(true);
      setLoader(false);
    };
    const onError = (msg) => {
      message.error(msg);
      setLoader(false);
    };
    dispatch(resendOTP(data, onSuccess, onError));
  };

  const VerifyOTP = () => {
    if (!otp.trim()) {
      message.error("Please enter the OTP.");
      return;
    }
    setVerifyLoader(true);
    const data = {
      OTP: otp,
      phoneNumber: phoneNumber,
      notificationid: "123458888",
    };
    const onSuccess = (msg) => {
      message.success(msg);
      history.push("/");
      window.location.reload()
      setVerifyLoader(false);
    };
    const onError = (msg) => {
      setVerifyLoader(false)
      message.error(msg,8)
    };
    dispatch(verifyOTP(data, onSuccess, onError));
  };

  return (
    <>
      <Row>
        <Col span={14}>
          <Image
            style={{ width: "100%", height: "100vh" }}
            src="https://images.pexels.com/photos/1181325/pexels-photo-1181325.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
          />
        </Col>
        <Col span={10} style={{ backgroundColor: "#34495e" }}>
          <div margin={10}>
            <Card
              style={{
                marginTop: "30%",
              }}
            >
              <Form
                layout="vertical"
                name="basic"
                initialValues={{
                  remember: true,
                }}
              >
                <Form.Item
                  label="Number"
                  name="phoneNumber"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your Number!",
                    },
                  ]}
                >
                  <Input
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    type="number"
                  />
                </Form.Item>

                {isOTPRequested && (
                  <Form.Item label="OTP">
                    <Input
                      value={otp}
                      onChange={(e) => setOTP(e.target.value)}
                      type="number"
                    />
                  </Form.Item>
                )}

                {!isOTPRequested ? (
                  <Form.Item>
                    <Button
                      type="primary"
                      loading={loader}
                      onClick={askOTP}
                      block
                    >
                      {loader ? "Loading" : "Request OTP"}
                    </Button>
                  </Form.Item>
                ) : (
                  <Form.Item>
                    <Button onClick={ResendOTP} block style={{marginBottom:"2rem"}}>
                    {loader ? "Loading" : "Resend OTP"}
                    </Button>
                    <Button
                      type="primary"
                      loading={verifyLoader}
                      onClick={VerifyOTP}
                      block
                    >
                      {verifyLoader ? "Verifying" :"Verify OTP"}
                    </Button>
                  </Form.Item>
                )}
              </Form>
            </Card>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Login;
