import api from "../apis/sessinnApi";
import * as constant from "../constants/Constant";
import { getDevice } from "../../helper/helper";

export const AssignTeacher = (data, onSuccess, onError) => (dispatch) => {
  api
    .SessinApis()
    .assignTeacher(data)
    .then((res) => {
      console.log(res.data.message);
      onSuccess(res.data.message);
    })
    .catch((err) => {
      console.log(err);
      onError("Somthing Went wrong");
    });
};


export const StudentUpcomingSession = (data,onSuccess,onError) => (dispatch) => {
  api
    .SessinApis().studentUpcomingSession(data)
    .then((res) => {
        onSuccess(res.data)
    })
    .catch((err) => {
      console.log(err.response);
      onError(err)
    });
};

export const AddBacklogSessions = (data,onSuccess,onError) => dispatch => {
  console.log(data)
  api.SessinApis().addBacklogSessions(data)
      .then(res => {
        console.log(res)
        onSuccess(res.data)
        //   dispatch({
        //     type: constant.TEACHER_PAST_SESSION,
        //     payload: res.data
        // })
      })
      .catch(err => {
        if (err.code === 'ECONNABORTED'){
          onError("Timeout");
        }else{
        console.log(err.response);
        onError(err.response.data.message);
        }
        })
}

export const ExtendWeeklyLimit = (data,onSuccess,onError) => dispatch => {
  console.log(data)
  api.SessinApis().extendWeeklyLimit(data)
      .then(res => {
        console.log(res)
        onSuccess(res.data)
        //   dispatch({
        //     type: constant.TEACHER_PAST_SESSION,
        //     payload: res.data
        // })
      })
      .catch(err => {
        if (err.code === 'ECONNABORTED'){
          onError("Timeout");
        }else{
        console.log(err.response);
        onError(err.response.data.message);
        }
        })
}

export const ExtendSubscriptionLimit = (data,onSuccess,onError) => dispatch => {
  console.log(data)
  api.SessinApis().extendSubscriptionLimit(data)
      .then(res => {
        console.log(res)
        onSuccess(res.data)
        //   dispatch({
        //     type: constant.TEACHER_PAST_SESSION,
        //     payload: res.data
        // })
      })
      .catch(err => {
        if (err.code === 'ECONNABORTED'){
          onError("Timeout");
        }else{
        console.log(err.response);
        onError(err.response.data.message);
        }
        })
}



export const AvailableTeacher = (data,onSuccess,onError) => {
  console.log(data)
  api.SessinApis().availableTeacher(data)
      .then((res) => {
        onSuccess(res.data)
        //   dispatch({
        //     type: constant.TEACHER_PAST_SESSION,
        //     payload: res.data
        // })
        console.log("check",res.data)
      })
      .catch(err => {
        if (err.code === 'ECONNABORTED'){
          onError("Timeout");
        }else{
        console.log(err.response);
        onError(err.response.data.message);
        }
        })
}

//GET AVALABLE HOST
export const GetAvalableHost =
  (data, onSuccess, onError, token) => (dispatch) => {
    // console.log(data)
    api
      .SessinApis()
      .getAvalableHost(data, token)
      .then((res) => {
        console.log(res);
        onSuccess(res.data.host.hostName, res.data.blockHostId);
      })
      .catch(err => {
        if (err.code === 'ECONNABORTED'){
          onError("Timeout");
        }else{
        console.log(err.response);
        onError(err.response.data.message);
        }
        })
         
}


export const StudentPastSession = (data,onSuccess,onError) => (dispatch) => {
  api
    .SessinApis().studentPastSession(data)
    .then((res) => {
      console.log(res)
        onSuccess(res.data)
    })
    .catch((err) => {
      console.log(err.response);
      onError(err)
    });
};


export const GetAllSessions = () => (dispatch) => {
  api
    .SessinApis()
    .getSessions()
    .then((res) => {
      // console.log(res.data.teacherData)
      dispatch({
        type: constant.GET_ALL_SESSION,
        payload: res.data.teacherData,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const FetchUpcomingStudentSession =
  (data, onSuccess, onError) => (dispatch) => {
    api
      .SessinApis()
      .fetchUpcomingStudentSession(data)
      .then((res) => {
        dispatch({
          type: constant.GET_ALL_SESSION,
          payload: res.data.sessions,
        });
        onSuccess(res.data.sessions);
      })
      .catch((err) => {
        console.log(err.response.data.message);
        onError(err.response.data.message);
      });
  };

export const FetchPastStudentSession =
  (data, onSuccess, onError) => (dispatch) => {
    api
      .SessinApis()
      .fetchPastStudentSession(data)
      .then((res) => {
        onSuccess(res.data.sessions);
      })
      .catch((err) => {
        console.log(err);
        onError(err.response.data.message);
      });
  };

export const Upcoming = (data) => (dispatch) => {
  api
    .SessinApis()
    .fetchupcomingsessionadmin(data)
    .then((res) => {
      console.log(res.data);
      dispatch({
        type: constant.UPCOMING_SESSION,
        payload: res.data.sessions,
      });
    })
    .catch((err) => {
      console.log(err.response);
    });
};

export const Past = (data, onSuccess, onError) => (dispatch) => {
  api
    .SessinApis()
    .fetchpastsessionadmin(data)
    .then((res) => {
      console.log(res.data);
      dispatch({
        type: constant.PAST_SESSION,
        payload: res.data.sessions,
      });
      if (onSuccess) {
        onSuccess();
      }
    })
    .catch((err) => {
      console.log(err);
      if (onError) {
        onError();
      }
    });
};

export const FetchAllDisputes = (onSuccess, onError, resolved, closed) => (dispatch) => {
    api
      .SessinApis()
      .fetchAllDisputes(resolved, closed)
      .then((res) => {
        onSuccess(res.data.disputedSession);
      })
      .catch((err) => {
        console.log(err);
        onError();
      });
};



export const ResolveDispute =
  (disputeId, onSuccess, onError) => (dispatch) => {
    api
      .SessinApis()
      .resolveDispute(disputeId)
      .then((res) => {
        console.log(res);
        onSuccess(res.data.message);
      })
      .catch((err) => {
        console.log(err);
        onError(err.response.data.message);
      });
  };

  export const RejectDispute =
  (disputeId, rejectReason, onSuccess, onError) => (dispatch) => {
    api
      .SessinApis()
      .rejectDispute(disputeId, rejectReason)
      .then((res) => {
        console.log(res);
        onSuccess(res.data.message);
      })
      .catch((err) => {
        console.log(err);
        onError(err.response.data.message);
      });
  };

export const TeacherCanceledSession = (data, onSuccess, onError) => (dispatch) => {
  api
    .SessinApis()
    .fetchTeacherCanceledSession(data)
    .then((res) => {
      console.log(res.data);
      onSuccess(res.data);
    })
    .catch((err) => {
      console.log(err);
        onError(err.response.data.message);
      
    });
};

export const GetMeetingRecording = (data, onSuccess, onError) => (dispatch) => {
  api
    .SessinApis()
    .getMeetingRecording(data)
    .then((res) => {
      onSuccess(res.data.meeting);
    })
    .catch((err) => {
      console.log(err);
      onError(err.response.data.message);
    });
};

export const AddKidsSession = (data, onSuccess, onError) => (dispatch) => {
  console.log("Here")
  api
    .SessinApis()
    .addKidsSession(data)
    .then((res) => {
      onSuccess(res);
    })
    .catch((err) => {
      console.log(err);
      onError(err.response.data.message);
    });
};


export const GetAvailableHost = (data, loding, onSuccess) => (dispatch) => {
  loding(true);
  api
    .SessinApis()
    .getAvailableHost(data)
    .then((res) => {
      loding(false);
      onSuccess(res.data.host);
    })
    .catch((err) => {
      loding(false);
      console.log(err);
    });
};

export const CreateZoomMeeting = (data, onSuccess, onError) => (dispatch) => {
  api
    .SessinApis()
    .createZoomMeeting(data)
    .then((res) => {
      onSuccess(res.data.meeting);
      // console.log(res.data.meeting)
    })
    .catch((err) => {
      console.log(err);
      onError(err.response.data.message);
    });
};

//get youtube editors
export const getYtEditors = ( onSuccess, onError) => (dispatch) => {
  api
    .SessinApis()
    .getYtEditors()
    .then((res) => {
      onSuccess(res.data);
      console.log("getytsuccess")
      console.log(res.data);
    })
    .catch((err) => {
      console.log(err.response.data.message);
      onError(err.response.data.message);
    });
};

export const CreateSession = (data, onSuccess, onError) => (dispatch) => {
  api
    .SessinApis()
    .createSession(data)
    .then((res) => {
      onSuccess();
      console.log(res.data);
    })
    .catch((err) => {
      console.log(err.response.data.message);
      onError(err.response.data.message);
    });
};

export const DeleteSession = (data, onSuccess, onError) => (dispatch) => {
  api
    .SessinApis()
    .deleteSession(data)
    .then((res) => {
      onSuccess(res.data.message);
      console.log(res.data);
      dispatch({
        type: constant.DELETE_SESSION,
        payload: data.id,
      });
    })
    .catch((err) => {
      console.log(err.response.data.message);
      onError(err.response.data.message);
    });
};

export const TeacherAttendance =
  (data, params, onSuccess, onError) => async (disaptch) => {
    try {
      console.log(data, params);
      var result = await api.SessinApis().teacherAttendance(data, params);
      if (result.status === 200) {
        onSuccess(result.data);
      }
    } catch (error) {
      onError(error);
    }
  };

export const IndividualTeacherAttendance =
  (data, params, onSuccess, onError) => async (disaptch) => {
    try {
      console.log(data, params);
      var result = await api
        .SessinApis()
        .individualTeacherAttendance(data, params);
      if (result.status === 200) {
        onSuccess(result.data);
      }
    } catch (error) {
      onError(error);
    }
  };

export const GenerateWeeklysessionReport =
  (data, onSuccess, onError) => async (disaptch) => {
    try {
      var result = await api.SessinApis().generateWeeklysessionreport(data);
      onSuccess(result.data);
    } catch (error) {
      onError(error.response.data.message);
    }
  };

  export const learnerReportUploader = (data, onSuccess, onError) => {
    api.SessinApis().learnerReportUpload(data)
    .then((res) => {
        onSuccess(res)
        console.log(res)
      })
      .catch((err) => {
        console.log(err.response);
        onError(err.response.data?.message);
      });
  };
  export const GetAllSubscriptions = async(data, onSuccess, onError) => {
    try {
      var result = await api.SessinApis().getSubscriptionsByDateRange(data);
      onSuccess(result.data);
    } catch (error) {
      onError(error.response.data.message);
    }
  };

  export const GetSessionReport = async(data, onSuccess, onError) => {
    try {
      var result = await api.SessinApis().getSessionByDateRange(data);
      onSuccess(result.data);
    } catch (error) {
      onError(error.response.data.message);
    }
  };

  export const GetDemopaidnotbookedReport = async(data, onSuccess, onError) => {
    try {
      var result = await api.SessinApis().getDemopaidnotbookedByDateRange(data);
      onSuccess(result.data);
    } catch (error) {
      onError(error.response.data.message);
    }
  };

  export const GetSubscriptionReport = async(data, onSuccess, onError) => {
    try {
      var result = await api.SessinApis().getSubscriptionByDateRange(data);
      onSuccess(result.data);
    } catch (error) {
      onError(error.response.data.message);
    }
  };

  export const GetSubscriptionAuditingReport = async(data, onSuccess, onError) => {
    try {
      var result = await api.SessinApis().getSubscriptionAuditingReport(data);
      onSuccess(result.data);
    } catch (error) {
      onError(error.response.data.message);
    }
  };

  export const GetBacklogSessionReport = async(data, onSuccess, onError) => {
    try {
      var result = await api.SessinApis().getBacklogSessionReport(data);
      onSuccess(result.data);
    } catch (error) {
      onError(error.response.data.message);
    }
  };

  export const Getp2pUTMReferralReport = async(data, onSuccess, onError) => {
    try {
      var result = await api.SessinApis().getp2pUTMReferralReport(data);
      onSuccess(result.data);
    } catch (error) {
      onError(error.response.data.message);
    }
  };


  export const GetSlotDetailsReport = async(data, onSuccess, onError) => {
    try {
      var result = await api.SessinApis().getSlotDetailsReport(data);
      onSuccess(result.data);
    } catch (error) {
      onError(error.response.data.message);
    }
  };


  export const GetTeacherworkinghourReport = async(data, onSuccess, onError) => {
    try {
      var result = await api.SessinApis().getTeacherworkinghourByDateRange(data);
      onSuccess(result.data);
    } catch (error) {
      onError(error.response.data.message);
    }
  };

  export const GetTeacherEarningsReport = async(data, onSuccess, onError) => {
    try {
      var result = await api.SessinApis().getTeacherEarningsReport(data);
      onSuccess(result.data);
    } catch (error) {
      onError(error.response.data.message);
    }
  };

  export const GetteacherConversionStatsReport = async(data, onSuccess, onError) => {
    try {
      var result = await api.SessinApis().getteacherConversionStatsReport(data);
      onSuccess(result.data);
    } catch (error) {
      onError(error.response.data.message);
    }
  };
   
  export const GetDemoCancelReport = async(data, onSuccess, onError) => {
    try {
      var result = await api.SessinApis().getDemoCancelReport(data);
      onSuccess(result.data);
    } catch (error) {
      onError(error.response.data.message);
    }
  };

  export const GetSignupSuccessReport = async(data, onSuccess, onError) => {
    try {
      var result = await api.SessinApis().getSignupSuccessReport(data);
      onSuccess(result.data);
    } catch (error) {
      onError(error.response.data.message);
    }
  };

   export const GetcurrentWorkingHourReport = async(data, onSuccess, onError) => {
    try {
      var result = await api.SessinApis().getcurrentWorkingHourReport(data);

      onSuccess(result.data);
    } catch (error) {
      console.log(error);
      onError(error.response.data.message);
    }
  };

   export const getDemoSessionRecordingReport = async(data, onSuccess, onError) => {
    try {
      var result = await api.SessinApis().getDemoSessionRecordingReport(data);
      onSuccess(result.data);
    } catch (error) {
      console.log(error);
      onError(error.response.data.message);
    }
  };
  
    export const GetSessionWiseConversionReport = async(data, onSuccess, onError) => {
    try {
      var result = await api.SessinApis().getsessionWiseConversionReport(data);
      onSuccess(result.data);
    } catch (error) {
      onError(error.response.data.message);
    }
  };

  export const GetP2PDetailsReport = async(data, onSuccess, onError) => {
    try {
      var result = await api.SessinApis().getp2pDetailsReport(data);
      onSuccess(result.data);
    } catch (error) {
      onError(error.response.data.message);
    }
  };

  export const GetP2PSessionWiseReport = async(data, onSuccess, onError) => {
    try {
      var result = await api.SessinApis().getP2PSessionWiseReport(data);
      onSuccess(result.data);
    } catch (error) {
      onError(error.response.data.message);
    }
  };
  

  export const GetP2PCallDetailsReport = async(data, onSuccess, onError) => {
    try {
      var result = await api.SessinApis().getp2pCallDetailsReport(data);
      onSuccess(result.data);
    } catch (error) {
      onError(error.response.data.message);
    }
  };

  export const GetSessionAutoSwapReport = async(data, onSuccess, onError) => {
    try {
      var result = await api.SessinApis().getSessionAutoSwapReport(data);
      onSuccess(result.data);
    } catch (error) {
      onError(error.response.data.message);
    }
  };

  


  export const GetDemoLeadReport = async(data, onSuccess, onError) => {
    try {
      var result = await api.SessinApis().getDemoLeadReport(data);
      onSuccess(result.data);
    } catch (error) {
      onError(error.response.data.message);
    }
  };
  export const GetbacklogAndQualityMarkRepor = async(data, onSuccess, onError) => {
    try {
      var result = await api.SessinApis().getbacklogAndQualityMarkReport(data);
      onSuccess(result.data);
    } catch (error) {
      onError(error.response.data.message);
    }
  };

  export const GetAllGeneratedReport = async(data, onSuccess, onError) => {
    try {
      var result = await api.SessinApis().getAllReports(data);
      onSuccess(result.data);
    } catch (error) {
      onError(error);
    }
  };



  export const GetListBlockhostByDateRange = (data, onSuccess, onError) => async (disaptch) => {
    try {
      var result = await api.SessinApis().getListBlockhostByDateRange(data);
      onSuccess(result.data);
    } catch (error) {
      onError(error.response.data.message);
    }
  };

  export const GetSessionScore = (data, onSuccess, onError) => async (disaptch) => {
    try {
      var result = await api.SessinApis().sessionScore(data);
      onSuccess(result.data);
    } catch (error) {
      onError(error.response.data.message);
    }
  };

  export const ApprovedTeacherPayout = async(data, onSuccess, onError) => {
    try {
      var result = await api.SessinApis().approvedTeacherPayout(data);
      onSuccess(result.data);
    } catch (error) {
      onError(error.response.data.message);
    }
  };
export const markSessionAttendence_ = (data, onSuccess, onError) => async (disaptch) => {
  try {
    var result = await api.SessinApis().markSessionAttendence(data);
    onSuccess(result.data.message);
  } catch (error) {
    onError(error);
  }
};
export const markSessionAttendence__ = (data, onSuccess, onError) => async (disaptch) => {
  try {
    var result = await api.SessinApis().markSessAttendence(data);
    onSuccess(result.data.message);
  } catch (error) {
    onError(error);
  }
};
export const markSessionQualityIssue_ = (data, onSuccess, onError) => async (disaptch) => {
  try {
    var result = await api.SessinApis().markSessionQualityIssue(data);
    onSuccess(result.data.message);
  } catch (error) {
    onError(error);
  }
};
  
export const AddEditVideoSession = (data,onSuccess,onError) => (dispatch) => {
  api
    .SessinApis().addOrUpdateVideoSession(data)
    .then((res) => {
      console.log(res.data)
        onSuccess(res.data.message)
    })
    .catch((err) => {
      console.log(err.response);
      onError(err.response.data.message)
    });
};

export const CancelSession = (data,onSuccess,onError) => {
  api
    .SessinApis().cancelSession(data)
    .then((res) => {
      console.log(res.data.message)
        onSuccess(res.data.message)
    })
    .catch((err) => {
      console.log(err.message);
      onError(err.message)
    });
};


export const CreateASession = (data,onSuccess,onError)=>{

      api
    .SessinApis().createASession(data)
    .then((res) => {
      onSuccess(res)
      console.log("hiii")
    })        
    .catch((err) => {
      console.log(err.response);
      onError(err.response.data.message)
    });
}

export const GetSessionAllotmentData = (data,onSuccess,onError) => {
  api
    .SessinApis().getSessionAllotment(data)
    .then((res) => {
      // console.log(res.data)
      onSuccess(res.data)
    })
    .catch((err) => {
      console.log(err);
      onError(err.response?.data?.message)
    });
};

export const GetSwapAvailableTeacher = (data,onSuccess,onError) => {
  api
    .SessinApis().getSwapAvailableTeacher(data)
    .then((res) => {
      // console.log(res.data)
      onSuccess(res.data)
    })
    .catch((err) => {
      console.log(err);
      onError(err.response?.data?.message)
    });
};

export const SwapTeacher = (data,onSuccess,onError) => {
  api
    .SessinApis().swapTeacher(data)
    .then((res) => {
      // console.log(res.data)
      onSuccess(res.data)
    })
    .catch((err) => {
      console.log(err);
      onError(err.response?.data?.message)
    });
};

export const FetchCancelledSessions = (data,onSuccess,onError) => {
  api
    .SessinApis().fetchCancelledSessions(data)
    .then((res) => {
      // console.log(res.data)
      onSuccess(res.data)
    })
    .catch((err) => {
      console.log(err);
      onError(err.response?.data?.message)
    });
};

export const GetPseudoSlots = (onSuccess,onError) => {
  api
    .SessinApis().getPseudoSlots()
    .then((res) => {
      // console.log(res.data)
      onSuccess(res.data)
    })
    .catch((err) => {
      console.log(err);
      onError(err.response?.data?.message)
    });
};

export const CancelPseudoSlot = (data,onSuccess,onError) => {
  api
    .SessinApis().cancelPseudoSlot(data)
    .then((res) => {
      // console.log(res.data)
      onSuccess(res.data)
    })
    .catch((err) => {
      console.log(err);
      onError(err.response?.data?.message)
    });
};