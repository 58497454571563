import React, { useState, useEffect } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import PageBreadcrumb from "../../component/PageBreadcrumb";
import {
  Typography,
  Card,
  Avatar,
  Divider,
  Button,
  Table,
  Tag,
  message,
  Alert,
  Spin,
} from "antd";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
  GetUserSchedule,
  FindLearnerSubscription,
} from "../../redux/actions/UserAction";
import { getDate } from "../../helper/helper";

const { Title } = Typography;
const { Meta } = Card;

const SubscriptionDetails = (props) => {
  const dispatch = useDispatch();
  const [subscription, setSubscription] = useState(null);
  const [schedule, setSchedule] = useState(null);
  const userDetails = JSON.parse(localStorage.getItem('USER_DETAILS'))

  console.log(userDetails);

  useEffect(() => {
    if(!userDetails){
    localStorage.setItem("USER_DETAILS",JSON.stringify(props.location.details))
    }
  }, [])

  useEffect(() => {
    const subSuccess = (data) => {
      console.log(data);
      setSubscription(data);
    };
    const subError = () => {
      message.error("Somthing went wrong!");
    };
    const schSuccess = (data) => {
      console.log(data);
      setSchedule(data);
    };
    const schError = () => {
      message.error("Somthing went wrong!");
    };
    const data = {
      learnerId: props.location.details?props.location.details.id:userDetails.id,
    };
    dispatch(FindLearnerSubscription(data, subSuccess, subError));
    dispatch(GetUserSchedule(data, schSuccess, schError));
  }, []);

  const columns = [
    {
      title: "Preferrable Days",
      dataIndex: "day",
      key: "day",
      render: (day) => (
        <>
          {day
            ? day.map((tag) => {
                let color = "geekblue";
                return (
                  <Tag color={color} key={tag}>
                    {tag}
                  </Tag>
                );
              })
            : ""}
        </>
      ),
    },
    {
      title: "Preferrable Time",
      dataIndex: "time",
      key: "time",
    },
    {
      title: "Start Date",
      dataIndex: "strtdate",
      key: "strtdate",
    },
    {
      title: "End Date",
      key: "enddate",
      dataIndex: "enddate",
    },
    {
      title: "Frequency",
      key: "frequency",
      dataIndex: "frequency",
    },
    {
      title: "Course Name",
      key: "coursename",
      dataIndex: "coursename",
    },
  ];

  const data = [
    {
      key: "1",
      day: schedule ? schedule.preferrableDays : "",
      time: schedule ? schedule.preferrableTimeSlot : "",
      strtdate: subscription ? getDate(subscription.startDate) : "",
      enddate: subscription ? getDate(subscription.endDate) : "",
      frequency: subscription ? `${subscription.plan.frequency} days/week` : "",
      coursename: subscription ? subscription.plan.courseName : "",
    },
  ];

  return (
    <div className="subscription_details">
      <PageBreadcrumb
        route={["dashboard", "Subscribed users", "Subscription details"]}
      />
      <Title level={3}>
        {" "}
        <Link to="/subscribedusers">
          <ArrowLeftOutlined />
        </Link>{" "}
        User Subscription Details
      </Title>
      <Card>
        <div className="card-head">
          <Card bordered={false} style={{ width: "70%" }}>
            <Meta
              avatar={
                <Avatar
                  size={68}
                  src="https://img1a.flixcart.com/www/linchpin/fk-cp-zion/img/profile-pic-male_4811a1.svg"
                />
              }
              title={<Title level={4}> {props.location.details?props.location.details.name:userDetails.name} </Title>}
              description={
                <Title level={5} type="secondary">
                  +91 {props.location.details?props.location.details.phone:userDetails.phone}
                </Title>
              }
            />
          </Card>
          <div style={{display:'flex',flexDirection:'column',width: "50%",}}>
            <Button
              className="button1"
              block
              style={{ width: "20rem", margin: "auto" }}
            >
              <Link
                to={{
                  pathname: "/assignsession",
                  details: {
                    id: props.location.details?props.location.details.id:userDetails.id,
                    phone: props.location.details?props.location.details.phone:userDetails.phone,
                    name: props.location.details?props.location.details.name:userDetails.name,
                  },
                  subscription: subscription,
                }}
                style={{ color: "#fff" }}
              >
                Assign Session
              </Link>
            </Button>

            <Button
              className="button1"
              block
              style={{ width: "20rem", margin: "auto" }}
            >
              <Link
                to={{
                  pathname: "/subscribedusersession",
                  details: {
                    id: props.location.details?props.location.details.id:userDetails.id,
                    phone: props.location.details?props.location.details.phone:userDetails.phone,
                    name: props.location.details?props.location.details.name:userDetails.name,
                  },
                  subscription: subscription,
                }}
                style={{ color: "#fff" }}
              >
                Sessions Details
              </Link>
            </Button>
          </div>
        </div>

        <Divider />

        {subscription === null || schedule === null ? (
          <div className="spin-center">
            <Spin size={"large"} />
          </div>
        ) : (
          <>
            {!subscription ? (
              <Alert
                message="Subscription plan expired"
                type="warning"
                showIcon
                closable
              />
            ) : (
              ""
            )}

            <Table
              columns={columns}
              style={{ marginTop: 10 }}
              bordered
              pagination={false}
              dataSource={data}
            />
          </>
        )}
      </Card>
    </div>
  );
};

export default SubscriptionDetails;
