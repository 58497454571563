import { Card, Space, DatePicker, Button, Table, Spin } from "antd";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PageBreadcrumb from "../../component/PageBreadcrumb";
import { GetSessionScore } from "../../redux/actions/SessionAction";

const dateFormat = "YYYY-MM-DD";
const { RangePicker } = DatePicker;

const SessionFeedbackScore = () => {

    const dispatch = useDispatch();

  const [startDate, setStartDate] = useState(moment().subtract(14, "days"));
  const [endDate, setEndDate] = useState(moment());
  const [sessionFeedbackScore, setSessionFeedbackScore] = useState(null);

  function onPickDate(date, dateString) {
    setStartDate(dateString[0]);
    setEndDate(dateString[1]);
  }

  useEffect(() => {
    fetchFeedback()
  }, [])

  const fetchFeedback = () =>{
    const searchData = {
        start:`${moment(startDate).format(dateFormat)}:00:00`,
        end:`${moment(endDate).format(dateFormat)}:24:00`,
    }

    const onSuccess = (data) =>{
      setSessionFeedbackScore(data.sessionScoreList)
    }

    const onError = (message) =>{
        console.log(message);
  }

  dispatch(GetSessionScore(searchData,onSuccess,onError))
  }
  

  const dataSource = sessionFeedbackScore?.map((data,i)=>{
      return{
          key:i,
          name:data.learnerId.name,
          number:data.learnerId.phoneNumber,
          ageAndGrade:`age : ${data.learnerId.age} & grade : ${data.learnerId.grade}`,
          teacherName:data.teacherId.name,
          teacherNumber:data.teacherId.phoneNumber,
          meetingNo:data.sessionId.sessionCount,
          sessionDate:moment(new Date(data.sessionId.sessionDate)).format('YYYY-MM-DD'),
          sessionScore:data.score
      }
  })

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Number",
      dataIndex: "number",
      key: "number",
    },
    {
      title: "Age & Grade",
      dataIndex: "ageAndGrade",
      key: "ageAndGrade",
    },
    {
      title: "Teacher name",
      dataIndex: "teacherName",
      key: "teacherName",
    },
    {
      title: "Teacher number",
      dataIndex: "teacherNumber",
      key: "teacherNumber",
    },
    {
      title: "Meeting no",
      dataIndex: "meetingNo",
      key: "meetingNo",
    },
    {
      title: "Session date",
      dataIndex: "sessionDate",
      key: "sessionDate",
    },
    {
      title: "Session score",
      dataIndex: "sessionScore",
      key: "sessionScore",
      render: (sessionScore) => (
        <ul>
          {sessionScore.map((item) => (
            <li>{item.evaluationPoint} : {item.rating}</li>
          ))}
        </ul>
      ),
    },
  ];

  return (
    <div>
      <PageBreadcrumb route={["Session", "Kids sessions score"]} />
      {
          sessionFeedbackScore?
          <Card
          title={
            <Space>
              <RangePicker
                onChange={onPickDate}
                defaultValue={[
                  moment(startDate, dateFormat),
                  moment(endDate, dateFormat),
                ]}
                format={dateFormat}
              />
              <Button
                type="primary"
                onClick={() => {
                  // setDemoTransaction(null);
                  fetchFeedback();
                }}
              >
                Search
              </Button>
            </Space>
          }
          style={{ marginBottom: "1rem" }}
        >
          <Table
            dataSource={dataSource}
            columns={columns}
            scroll={{ x: 1300 }}
            pagination={false}
          />
          ;
        </Card>
        :
        <div className="spin-center">
        <Spin size="large" />
      </div>
      }

    </div>
  );
};

export default SessionFeedbackScore;
