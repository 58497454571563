export const ADMIN_LOGIN = "ADMIN_LOGIN"
export const UPCOMING_SESSION = "UPCOMING_DEMO_SESSION"
export const PAST_SESSION = "PAST_DEMO_SESSION"
export const GET_ALL_TEACHER = "GET_ALL_TEACHER"
export const GET_ALL_SUBSCRIBED_USER = "GET_ALL_SUBSCRIBED_USER"
export const GET_ALL_LEARNER = "GET_ALL_LEARNER"
export const GET_ALL_SESSION = "GET_ALL_SESSION"
export const DELETE_SESSION = "DELETE_SESSION"
export const STASTISTICS = "STASTISTICS"
export const SESSION_DAY_WISE = "SESSION_DAY_WISE"
export const FETCH_REFERRAL_DATA = "FETCH_REFERRAL_DATA"
export const FETCH_SUBSCRIPTION_DATA = "FETCH_SUBSCRIPTION_DATA"
export const FETCH_CAMPAIGN_DATA = "FETCH_CAMPAIGN_DATA"
export const SET_TIME_SLOT_DETAILS = "SET_TIME_SLOT_DETAILS"
export const GET_CITY_COUNT = "GET_CITY_COUNT"
export const USER_FEEDBACK = "USER_FEEDBACK"
export const STUDENT_PAST_SESSION = "STUDENT_PAST_SESSION"
export const STUDENT_UPCOMING_SESSION = "STUDENT_UPCOMING_SESSION"