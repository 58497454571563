import React, { useEffect } from "react";
import Home from "./pages/Home";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import AuthRouter from "./routes/AuthRouter";
import Session from "./pages/Session";
import "./styles/index.scss";
import * as access from './routes/RoleBasedAccess.js'
import * as helper from './helper/TimeDate' 

import { useDispatch } from "react-redux";
import { AdminStats, refresh } from "./redux/actions/AuthAction";

import CreateSession from "./component/Session_Components/CreateSession";
import SubscribedUsers from "./pages/subscribed/SubscribedUsers";
import AssignSession from "./pages/subscribed/AssignSession";
import SubscriptionDetails from "./pages/subscribed/SubscriptionDetails";
// import DemoRequests from "./pages/Demo/DemoReRequests";
// import AssignDemo from "./pages/Demo/AssignDemo";
import Login from "./pages/Login";
import AllLearner from "./pages/learner/AllLearner";
import Coupons from "./pages/Coupons";
import AllTeacher from "./pages/teacher/AllTeacher";
import AllTeacherCopy from "./pages/teacher/AllTeacherCopy";
import Affiliate from "./pages/Affiliate/Affiliate";
import AffiliateProfile from "./pages/Affiliate/AffiliateProfile";
import SessionDetails from "./pages/subscribed/SessionDetails";
import SessionDocs from "./pages/SessionDocs";
import UserFeedback from "./pages/userFeedback";
import TeacherQuery from "./pages/TeacherQuery";
import PlanList from "./pages/PlanList";
import { getDate } from "./helper/helper";
import SessionAttendance from "./pages/teacher/SessionAttendance";
import { DailySubscriptionReport, PopularCity, DateRangeSessionGraph, ReferralAnalytics, SessionTrafficDayWise, SubscriptionRetentionRatio, TeacherTimeslotsStats } from "./redux/actions/StatisticsAction";
import AttendanceReport from "./pages/reports/AttendanceReport";
import TeacherProfile from "./pages/teacher/TeacherProfile";
import LearnerSessionAttendance from "./pages/learner/LearnerSessionAttendance";
import TimeSlotsGraphDetails from "./pages/teacher/TimeSlotsGraphDetails";
import PastSession from './pages/session/PastSession'
import UpcomingSession from './pages/session/UpcomingSession'
import { Past, Upcoming } from "./redux/actions/SessionAction";
import { Feedback, GetAllTeacher } from "./redux/actions/UserAction";
import VideoApproval from "./pages/VideoApproval";
import AllCourses from "./pages/Courses/AllCourses";
import AddCourse from "./pages/Courses/AddCourse";
import CourseBundle from "./pages/Courses/CourseBundle";
import DemoTransactions from "./pages/DemoTransactions";
import DemoToConversion from "./pages/teacher/DemoToConversion";
import SessionFeedbackScore from "./pages/session/SessionFeedbackScore";
import CanceledSession from "./pages/teacher/CanceledSession";
import LearnerCanceledSession from "./pages/session/CanceledSession.js";
import TeachersEarning from "./pages/teacher/TeachersEarning";
import LearnerProfile from "./pages/learner/LearnerProfile";
import AssignDemo from "./pages/learner/AssignDemo";
import ConversionData from "./pages/Campaign/ConversionData";
import SessionCharts from "./pages/session/SessionCharts";
import UTMCharts from "./pages/Campaign/UTMCharts";
import AllAdmin from "./pages/admin/AllAdmin";
import DemoPool from "./pages/admin/DemoPool";
import WorkingHr from "./pages/admin/WorkingHr";
import YouTubers from "./pages/YouTubers";
import allDisputes from "./pages/allDisputes/AllDisputes";
import TeacherConcern from './pages/teacher/TeacherConcern';
import moment from "moment-timezone";
import Payouts from "./pages/Affiliate/Payouts";
import LearnerPayout from "./pages/learner/LearnerPayout";
import ResourceUpload from "./pages/teacher/ResourceUpload";
import VideoSessions from "./pages/session/VideoSessions";
import TeacherApplications from "./pages/teacher/TeacherApplications";
import UsersStories from "./pages/admin/UserStories"
import ScheduleSession from "./pages/learner/scheduleSession";
import Conversion from "./pages/admin/Conversion";
import TechIssues from "./pages/teacher/TechIssues.js";
import Curriculums from "./pages/Curriculum/Curriculums.js";
import CurriculumDetails from "./pages/Curriculum/CurriculumDetails.js";
import DemoSession from "./pages/admin/DemoSessions.js";
import SubscriptionList from "./pages/learner/SubscriptionList.js";
import PaymentLink from "./pages/PaymentLink.js";
import PseudoSessionRequest from "./pages/session/PsuedoSessionRequest.js";
import ModuleDetails from "./pages/Curriculum/ModuleDetails.js";

function App() {
  const dispatch = useDispatch();
  // const history = useHistory();
  const Admin = JSON.parse(localStorage.getItem("ADMIN"));
  useEffect(() => {
    var curr = new Date();
    var first = curr.getDate();
    var last = first - 30;
    var firstday = new Date(curr.setDate(first)).toUTCString();
    var lastday = new Date(curr.setDate(last)).toUTCString();
    if (Admin) {
      dispatch(refresh(Admin));
      // dispatch(GetAllTeacher());
      // dispatch(GetAlllearner());
      // dispatch(GetSubscribedUser());
      const onSuccess = () => {
        console.log('success')
      };
      const onErr = () => {
        console.log('error')
      };

      dispatch(
        DailySubscriptionReport({ startDate: getDate(lastday), endDate: getDate(firstday) }, onSuccess, onErr)
      );
      dispatch(SessionTrafficDayWise({ startDate: getDate(lastday), endDate: getDate(firstday) }, onSuccess, onErr))

      dispatch(Upcoming({ date: getDate(new Date()), page: 1, limit: 500, timezone: "Asia/Kolkata" }))
      dispatch(Past({ date: getDate(new Date()), type: "Regular", page: 1, limit: 50, timezone: "Asia/Kolkata" }))

      dispatch(ReferralAnalytics())
      dispatch(SubscriptionRetentionRatio())
      dispatch(PopularCity())
      dispatch(Feedback({ ratingPerPage: 200, page: 1 }));

      dispatch(TeacherTimeslotsStats({
        day: new Date().toLocaleString('en-us', { weekday: 'long' }),
        time: "00:00"
      }))

      dispatch(GetAllTeacher({ page: 1, limit: 20, startDate: helper.convertToUTCMomentObj(moment().startOf('month')).format("YYYY-MM-DD"), endDate: helper.convertToUTCMomentObj(moment()).format("YYYY-MM-DD")}))

    }
  }, [Admin]);
//comment
  return (
    <BrowserRouter>
      <Switch>
        <AuthRouter path="/" exact access={access.home} component={Home} />
        <AuthRouter path="/session" exact access={access.session} component={Session} />
        <AuthRouter path="/download-sessionreport" exact access={access.downloadSessionreport} component={AttendanceReport} />
        <AuthRouter path="/createsession" exact access={access.createsession} component={CreateSession} />
        <AuthRouter path="/subscribedusers" exact access={access.subscribedusers} component={SubscribedUsers} />
        <AuthRouter path="/assignsession" exact access={access.assignsession} component={AssignSession} />
        <AuthRouter
          path="/subscriptiondetails"
          exact
          access={access.subscriptiondetails}
          component={SubscriptionDetails}
        />
        <AuthRouter
          path="/subscribedusersession"
          exact
          access={access.subscribedusersession}
          component={SessionDetails}
        />
        {/* <AuthRouter path="/demoRerequests" exact component={DemoRequests} /> */}
        {/* <AuthRouter path="/assigndemo" exact component={AssignDemo} /> */}

        <AuthRouter path="/alllearners" access={access.alllearners} exact component={AllLearner} />
        <AuthRouter path="/coupons" exact access={access.coupons} component={Coupons} />
        <AuthRouter path="/affiliate" exact access={access.affiliate} component={Affiliate} />
        <AuthRouter path="/payouts" exact access={access.affiliate} component={Payouts} />

        <AuthRouter
          exact
          access={access.affiliateProfile}
          path="/affiliate-profile/:id"
          component={AffiliateProfile}
        />
        {/* Curriculum */}
        <AuthRouter path="/curriculum" exact access={access.allTeacher} component={Curriculums} />
        <AuthRouter path="/curriculum/:id" exact access={access.allTeacher} component={CurriculumDetails} />
        <AuthRouter path="/curriculum/module/:id" exact access={access.allTeacher} component={ModuleDetails} />
        {/* Teacher */}
        <AuthRouter path="/teacher/all-teacher" exact access={access.allTeacher} component={AllTeacherCopy} />
        <AuthRouter path="/teacher/profile/:id" exact access={access.teacherProfile} component={TeacherProfile} />
        <AuthRouter path="/learner/profile/:id" exact access={access.teacherProfile} component={LearnerProfile} />
        <AuthRouter path="/learner/profile/demo/:id" exact access={access.teacherProfile} component={AssignDemo} />
        <AuthRouter path="/teacher/session-attendance" exact access={access.teacherSessionAttendance} component={SessionAttendance} />
        <AuthRouter path="/time-slots-details" exact access={access.teacherTimeSlotsDetails} component={TimeSlotsGraphDetails} />
        <AuthRouter path="/teacher/demo-to-conversion" exact access={access.allTeacher} component={DemoToConversion} />
        <AuthRouter path="/teacher/canceled-session" exact  access={access.past} component={CanceledSession} />
        <AuthRouter path="/teacher/earning-report" exact  access={access.past} component={TeachersEarning} />
        <AuthRouter path="/teacher/teacher-concern" exact  access={access.past} component={TeacherConcern} />
        <AuthRouter path="/teacher/resource-upload" exact  access={access.past} component={ResourceUpload} />
        <AuthRouter path="/teacher/applications" exact  access={access.past} component={TeacherApplications} />
        <AuthRouter path="/teacher/tech-issues" exact  access={access.past} component={TechIssues} />
        {/* Admin */}
        <AuthRouter path="/admin/all-admin" exact access={access.adminAccess} component={AllAdmin} />
        <AuthRouter path="/admin/demo-pool" exact access={access.adminAccess} component={DemoPool} />
        <AuthRouter path="/admin/workinghr" exact access={access.adminAccess} component={WorkingHr} />
        <AuthRouter path="/admin/userStories" exact access={access.adminAccess} component={UsersStories} />
        <AuthRouter path="/admin/session-allotment" exact access={access.adminAccess2} component={Conversion} />
        <AuthRouter path="/admin/demo-sessions" exact access={access.adminAccess2} component={DemoSession} />

        {/* Learner */}
        <AuthRouter path="/learner/all-learner" exact access={access.allLearner} component={AllLearner} />
        <AuthRouter path="/learner/session-attendance" exact  access={access.learnerSessionAttendance} component={LearnerSessionAttendance} />
        <AuthRouter path="/learner/learner-payout" exact access={access.learnerPayout} component={LearnerPayout} />
        <AuthRouter path="/learner/schedule-session/:id" exact access={access.scheduleSession} component={ScheduleSession} />
        <AuthRouter path="/learner/subscriptionList" exact access={access.subscriptionList} component={SubscriptionList} />
        {/* session */}
        <AuthRouter path="/session/upcoming" exact  access={access.upcoming} component={UpcomingSession} />
        <AuthRouter path="/session/canceled" exact access={access.past} component={LearnerCanceledSession} />
        <AuthRouter path="/session/past" exact  access={access.past} component={PastSession} />
        <AuthRouter path="/session/session-score" exact  access={access.past} component={SessionFeedbackScore} />
        <AuthRouter path="/session/analytics" exact  access={access.analytics} component={SessionCharts} />
        <AuthRouter path="/session/video-sessions" exact  access={access.videoSessions} component={VideoSessions} />
        <AuthRouter path="/session/session-request" exact  access={access.sessionRequest} component={PseudoSessionRequest} />
        

        {/* All Disputes */}
        <AuthRouter path="/teacher/alldisputes" exact component={allDisputes} access={access.disputes} />

        {/* Campaign  */}
        <AuthRouter path="/campaign/utm-conversion" exact  access={access.utmConversion} component={ConversionData} />
        <AuthRouter path="/campaign/analytics" exact  access={access.analytics} component={UTMCharts} />
        
        {/* <AuthRouter path="/campaign/utm-code/:code" exact  access={access.utmCodeDetails} component={UTMCodeData} /> */}

        <AuthRouter path="/demo-transactions" exact  access={access.demoTransactionAccess} component={DemoTransactions} />

        {/* Video approval */}
        <AuthRouter path="/video-approval" exact access={access.videoApproval} component={VideoApproval} />
        <AuthRouter path="/YouTubers" exact access={access.videoApproval} component={YouTubers} />

        {/* Courses */}
        <AuthRouter path="/courses" exact access={access.courseAccess} component={AllCourses} />
        <AuthRouter path="/add-course" exact access={access.courseAccess} component={AddCourse} />
        <AuthRouter path="/coursebundle" exact access={access.courseAccess} component={CourseBundle} />

        <AuthRouter path="/sessiondoc" exact access={access.sessiondoc} component={SessionDocs} />
        <AuthRouter path="/userfeedback" exact access={access.userfeedback} component={UserFeedback} />
        <AuthRouter path="/teacherquery" exact access={access.teacherquery} component={TeacherQuery} />
        <AuthRouter path="/planlist" exact access={access.planlist} component={PlanList} />
        <AuthRouter path="/paymentlinks" exact access={access.generatePaymentLink} component={PaymentLink} />
        <Route path="/login" exact component={Login} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;