import React, { useEffect, useState } from "react";
import PageBreadcrumb from "../../component/PageBreadcrumb";
import { Button, Modal, Radio, Space, Spin, Table, Tag, message } from "antd";
import {
  getTeacherApplications,
  updateTeacherApplicationStatus,
} from "../../redux/actions/TeacherAction";

const ChangeStatus = ({
  currentStatus,
  applicationId,
  GetTeacherApplications,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [updatedStatus, setUpdatedStatus] = useState(currentStatus);
  const [changeStatusLoader, setChangeStatusLoader] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const hideModal = () => {
    setIsModalVisible(false);
  };

  const updateApplicationStatus = () => {
    setChangeStatusLoader(true);
    var payload = {
      applicationId: applicationId,
      action: updatedStatus,
    };

    const onSuccess = (msg) => {
      message.success(msg, 8);
      setChangeStatusLoader(false);
      setIsModalVisible(false);
      GetTeacherApplications();
    };

    const onError = (err) => {
      message.error(err, 8);
      setChangeStatusLoader(false);
    };
    updateTeacherApplicationStatus(payload, onSuccess, onError);
  };

  return (
    <>
      <Button
        type="primary"
        onClick={showModal}
        disabled={currentStatus === "completed"}
      >
        Edit
      </Button>
      <Modal
        title="Change status"
        visible={isModalVisible}
        onCancel={hideModal}
        footer={[]}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <label>Status :</label>
          <select
            onChange={(e) => setUpdatedStatus(e.target.value)}
            style={{ width: "200px" }}
            value={updatedStatus}
          >
            <option value="hold">Hold</option>
            <option value="rejected">Rejected</option>
            <option value="approved">Approved</option>
            <option value="completed">Completed</option>
          </select>
        </div>
        <Button
          type="primary"
          style={{ marginTop: "6px" }}
          onClick={() => updateApplicationStatus()}
        >
          {changeStatusLoader ? "Loading..." : "Update"}
        </Button>
      </Modal>
    </>
  );
};

const TeacherApplications = () => {
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("requested");
  const [teacherApplications, setTeacherApplications] = useState([]);

  const GetTeacherApplications = () => {
    setLoading(true);
    const onSuccess = (data) => {
      const applications = addKeyToData(data);
      setLoading(false);
      setTeacherApplications(applications);
    };

    const onError = (msg) => {
      setLoading(false);
      message.error(msg);
    };
    getTeacherApplications(status, onSuccess, onError);
  };

  useEffect(() => {
    GetTeacherApplications();
  }, [status]);

  const addKeyToData = (data) => {
    return data.map((item, index) => ({
      ...item,
      key: String(index + 1),
    }));
  };

  function formatTimestampToDDMMYY(timestamp) {
    var dateObject = new Date(timestamp);
    var options = {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    };
    var formattedDate = dateObject.toLocaleDateString("en-GB", options);
    return formattedDate;
  }
  const tagColour = {
    rejected: "red",
    hold: "yellow",
    requested: "grey",
    approved: "blue",
    completed: "green",
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "fullName",
      width: 110,
      sorter: (a, b) => a.fullName - b.fullName,
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Phone Number",
      dataIndex: "phone",
    },
    {
      title: "Date of birth",
      dataIndex: "dateOfBirth",
    },
    {
      title: "Current Job Profile",
      dataIndex: "currentJobProfile",
    },
    {
      title: "Strengths",
      dataIndex: "strengths",
      render: (_, { strengths }) => (
        <>
          {strengths.map((strength) => {
            return (
              <Tag color="blue" key={strength}>
                {strength}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: "Age Group to teach",
      dataIndex: "ageGroupToTeach",
      filters: [
        {
          text: "Learners under 14",
          value: "Learners under 14",
        },
        {
          text: "Learners above 14",
          value: "Learners above 14",
        },
        {
          text: "Learners of all ages",
          value: "Learners of all ages",
        },
      ],
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.ageGroupToTeach.startsWith(value),
    },
    {
      title: "About Yourself",
      dataIndex: "aboutYourself",
      render: (text) => (
        <a href={text} target="_blank" rel="noopener noreferrer">
          {text}
        </a>
      ),
    },
    {
      title: "Video Link",
      dataIndex: "videoLink",
      render: (text) => (
        <a href={text} target="_blank" rel="noopener noreferrer">
          {text}
        </a>
      ),
    },
    {
      title: "Referral Source",
      dataIndex: "referralSource",
    },
    {
      title: "Application submitted on",
      dataIndex: "createdAt",
      sorter: (a, b) => a.createdAt - b.createdAt,
      render: (text) => <>{formatTimestampToDDMMYY(text)}</>,
    },
    {
      title: "Status",
      dataIndex: "status",
      fixed: "right",
      width: 100,
      render: (status) => <Tag color={tagColour[status]}>{status}</Tag>,
    },
    {
      title: "Resume",
      dataIndex: "resume",
      fixed: "right",
      width: 150,
      render: (text) => (
        <a href={text} target="_blank" rel="noopener noreferrer">
          {text}
        </a>
      ),
    },
    {
      title: "Action",
      dataIndex: "_id",
      fixed: "right",
      width: 100,
      render: (_id) => (
        <ChangeStatus
          currentStatus={status}
          applicationId={_id}
          GetTeacherApplications={GetTeacherApplications}
        />
      ),
    },
  ];
  return (
    <>
      <PageBreadcrumb route={["dashboard", "Teacher Applications"]} />
      <Space style={{ marginBottom: 24 }}>
        <Radio.Group value={status} onChange={(e) => setStatus(e.target.value)}>
          <Radio.Button value="requested">Requested</Radio.Button>
          <Radio.Button value="hold">Hold</Radio.Button>
          <Radio.Button value="rejected">Rejected</Radio.Button>
          <Radio.Button value="approved">Approved</Radio.Button>
          <Radio.Button value="completed">Completed</Radio.Button>
        </Radio.Group>
      </Space>
      {loading ? (
        <div className="spin-center">
          <Spin size="large" />
        </div>
      ) : (
        <Table
          columns={columns}
          dataSource={teacherApplications}
          scroll={{ x: 2600 }}
          pagination={{
            defaultPageSize: 40,
            showSizeChanger: true,
            pageSizeOptions: ["80", "120", "160"],
          }}
        />
      )}
    </>
  );
};

export default TeacherApplications;
