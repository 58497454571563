import React from "react";
import { Route, Redirect } from "react-router-dom";
import Sidenav from "../component/Sidenav";
import { roleValidator } from "../helper/helper";

const admin = JSON.parse(localStorage.getItem("ADMIN"));
const AuthRouter = ({ component: Component,access:access, ...rest }) => (
  <>
    <Sidenav >
      <Route
        {...rest}
        render={(props) =>{
          if(admin){
            if(roleValidator(access)){
              return <Component {...props} />
            }else{
              return <h1 className="text-center">Not allow !</h1>
            }
          }else{
            return <Redirect
              to={{
                pathname: "/login",
              }}
            />
          }
        }
        }
      />
    </Sidenav>
  </>
);

export default AuthRouter;
