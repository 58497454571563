import React, { useState, useEffect } from "react";
import { Table } from "ant-table-extensions";
import { Spin, message, Tag, Row, Col, Card, Button, Modal } from "antd";
import { useDispatch } from "react-redux";
import PageBreadcrumb from "../component/PageBreadcrumb";
import { DeleteCoupon, getAllCoupons } from "../redux/actions/UserAction";
import AddCoupon from "../component/AddCoupon";
import { GetAllCourse } from "../redux/actions/CoursesAction";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

const Coupons = () => {
  const dispatch = useDispatch();
  var limitPerPage = 80;
  const [loader, setLoader] = useState(false);
  const [learners, setLearners] = useState([]);
  const [courses, setCourses] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState("");

  useEffect(() => {
    getLearner(1, true);
  }, []);

  useEffect(() => {
    if (courses.length === 0) {
      const onSuccess = (data) => {
        setCourses(data);
      };

      const onError = (error) => {
        message.error(error, 8);
      };

      dispatch(GetAllCourse(1, 100, onSuccess, onError));
    }
  }, []);

  function convertToTimeZoneDate(date) {
    const dateInUTC = dayjs.utc(date);
    const dateInTimeZone = dateInUTC.tz("Asia/Kolkata");
    return dateInTimeZone.format("YYYY-MM-DD");
  }

  const deleteCoupon = (couponId) => {
    const onSuccess = (data) => {
      getLearner(1, true);
      message.success(data.message, 8);
    }
    const onError = (err) => {
      message.error(err, 8);
    }
    dispatch(DeleteCoupon({couponId}, onSuccess, onError));
  }

  const columns = [
    { title: "No", width: 30, dataIndex: "no", key: "1" },
    { title: "Coupon Name", width: 50, dataIndex: "name", key: "2" },
    {
      title: "Status",
      width: 30,
      dataIndex: "status",
      key: "21",
      render: (status) => (
        <Tag color={status ? "volcano" : "green"}>
          {status ? "DISABLED" : "ENABLED"}
        </Tag>
      ),
    },
    { title: "Discount", width: 50, dataIndex: "discount", key: "3" },
    { title: "Max Use", width: 50, dataIndex: "max", key: "4" },
    { title: "For Kids", width: 50, dataIndex: "kids", key: "5" },
    { title: "For Adults", width: 50, dataIndex: "adults", key: "6" },
    { title: "Subject", width: 50, dataIndex: "sub", key: "7" },
    { title: "Occasion", width: 50, dataIndex: "occ", key: "8" },
    { title: "Description", width: 50, dataIndex: "desc", key: "9" },
    { title: "Start", width: 50, dataIndex: "start", key: "10" },
    { title: "End", width: 50, dataIndex: "end", key: "11" },
    { title: "Coupon Type", width: 60, dataIndex: "couponValidFor", key: "12" },
    {
      title: "Action",
      key: "edit",
      width: 50,
      fixed: "right",
      render: (data) => (
        <div style={{display: 'flex', gap: 2}}>
          <AddCoupon name="Edit coupon" value={data} courses={courses} getLearner={getLearner}/>

          <Modal
            title="Are you sure you want to delete this coupon ?"
            visible={isModalVisible === data.key}
            onCancel={() => {
              setIsModalVisible("");
            }}
            footer={[]}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                onClick={() => {
                  deleteCoupon(data.key);
                }}
              >
                Yes
              </Button>
              <Button
                onClick={() => {
                  setIsModalVisible("");
                }}
              >
                No
              </Button>
            </div>
          </Modal>
          <Button type="primary" onClick={()=>setIsModalVisible(data.key)}>
            Delete
          </Button>
        </div>
      ),
    },
  ];

  const data = learners?.map((data, i) => ({
    key: data._id,
    edit: data,
    status: data?.isDisable,
    no: i + 1,
    name: data.coupon,
    discount: data.discount,
    max: data.maximumUse,
    kids: data.forKids.toString(),
    adults: data.forAdults.toString(),
    sub: data.subject,
    occ: data.occasion,
    desc: data.desc,
    start: convertToTimeZoneDate(data.startDate),
    end: convertToTimeZoneDate(data.endDate),
    cartAmount: data.cartAmount,
    couponValidFor: data.couponValidFor,
  }));

  const getLearner = (activePage, reset) => {
    setLoader(true);
    var data = {
      page: activePage,
      limit: limitPerPage,
    };

    var onSuccess = (response) => {
      if (reset) {
        setLearners(response.data.allOffers);
      } else {
        setLearners([...learners, ...response.data.allOffers]);
      }
      setLoader(false);
    };
    var onError = (error) => {
      console.log(error);
      message.success(error, 8);
      setLoader(false);
    };
    dispatch(getAllCoupons(data, onSuccess, onError));
  };

  return (
    <div>
      <PageBreadcrumb route={["Coupons"]} />
      <Card style={{ marginBottom: "1rem" }}>
        <Row justify="space-between">
          <Col span={4}>
            <AddCoupon courses={courses} getLearner={getLearner}/>
          </Col>
        </Row>
      </Card>

      {loader ? (
        <div className="spin-center">
          <Spin size="large" />
        </div>
      ) : (
        <Table
          columns={columns}
          dataSource={data}
          scroll={{ x: 2000 }}
          pagination={{
            pageSize: 80,
          }}
          exportable
          searchable
        />
      )}
    </div>
  );
};

export default Coupons;
