import React, { useState } from "react";
import { CloseOutlined } from "@ant-design/icons";
import { Button, Card, Form, Input, Row, Col, Typography, Space } from "antd";
import { numberToOrdinal } from "../../helper/helper";

const Assessment = ({ onAssessmentChange }) => {
  const [form] = Form.useForm();

  const handleAssessmentChange = () => {
    const assessmentData = form.getFieldsValue()['items'].map((item) => ({
      question: item.name,
      answers: item.list.map((answer) => answer),
      correctAnswer: item.correctAnswer,
      score: item.score
    }));
    onAssessmentChange(assessmentData);
  };

  return (
    <Form
      form={form}
      name="dynamic_form_complex"
      autoComplete="off"
      initialValues={{
        items: [{}],
      }}
      onValuesChange={handleAssessmentChange}
    >
      <Form.List name="items">
        {(fields, { add, remove }) => (
          <div
            style={{
              display: "flex",
              rowGap: 8,
              flexDirection: "column",
            }}
          >
            {fields.map((field) => (
              <Card
                size="small"
                key={field.key}
                title={`QUESTION ${field.name + 1}`}
                extra={
                  <CloseOutlined
                    onClick={() => {
                      remove(field.name);
                    }}
                  />
                }
                style={{
                    borderColor:"#6E8BE6",
                    borderRadius:"10px",
                    padding:"4px"
                }}
              >
                <Form.Item label="Question" name={[field.name, "name"]}>
                  <Input />
                </Form.Item>

                {/* Nest Form.List */}
                <Form.Item label="Answers">
                  <Form.List name={[field.name, "list"]}>
                    {(subFields, subOpt) => (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          rowGap: 16,
                        }}
                      >
                        <Row gutter={[16, 16]}>
                          {subFields.map((subField) => (
                            <Col span={12} key={subField.key}>
                              <Space>
                                <Form.Item
                                  noStyle
                                  name={[subField.name]}
                                >
                                  <Input placeholder={numberToOrdinal(subField.name+1)} />
                                </Form.Item>
                                <CloseOutlined
                                  onClick={() => {
                                    subOpt.remove(subField.name);
                                  }}
                                />
                              </Space>
                            </Col>
                          ))}
                        </Row>
                        <Button
                          type="dashed"
                          onClick={() => subOpt.add()}
                          block
                        >
                          + Add Answers
                        </Button>
                      </div>
                    )}
                  </Form.List>
                </Form.Item>

                <Form.Item label="Correct Answer" name={[field.name, "correctAnswer"]}>
                  <Input />
                </Form.Item>

                <Form.Item label="Score" name={[field.name, "score"]}>
                  <Input type="number" />
                </Form.Item>


              </Card>
            ))}

            <Button type="dashed" onClick={() => add({ name: "", list: [], correctAnswer: "" })} block>
              + Add Question
            </Button>
          </div>
        )}
      </Form.List>


      {/* <Form.Item noStyle shouldUpdate>
        {() => (
          <Typography>
            <pre>{JSON.stringify(form.getFieldsValue(), null, 2)}</pre>
          </Typography>
        )}
      </Form.Item> */}
    </Form>
  );
};

export default Assessment;
