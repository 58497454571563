import React, { useEffect, useState } from "react";
import { Tabs, Button, Space, message, Row, Col, DatePicker, Card, List, Input, Select,Form,Typography } from "antd";
import { useDispatch } from "react-redux";
import PageBreadcrumb from "../../component/PageBreadcrumb";
import { GenerateWeeklysessionReport, GetSessionReport, GetDemopaidnotbookedReport, GetSubscriptionReport, GetAllGeneratedReport, GetbacklogAndQualityMarkRepor, GetTeacherworkinghourReport, GetListBlockhostByDateRange, GetTeacherEarningsReport, GetteacherConversionStatsReport,GetDemoCancelReport,GetSignupSuccessReport,GetcurrentWorkingHourReport,GetSessionWiseConversionReport,GetDemoLeadReport,getDemoSessionRecordingReport, GetSubscriptionAuditingReport, GetSlotDetailsReport, GetBacklogSessionReport, GetP2PDetailsReport,GetP2PSessionWiseReport, GetP2PCallDetailsReport,Getp2pUTMReferralReport,learnerReportUploader,GetSessionAutoSwapReport } from "../../redux/actions/SessionAction";
import { CSVLink } from "react-csv";
import moment from "moment-timezone";
import { roleValidator } from "../../helper/helper";
import { FilterOutlined } from '@ant-design/icons';


const { Text } = Typography;

const { RangePicker } = DatePicker;
const { Option } = Select;
const dateFormat = 'YYYY-MM-DD';

const { TabPane } = Tabs;

const AttendanceReport = () => {
    const dispatch = useDispatch();
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [loader, setLoader] = useState(false);
    const [subLoader, setSubLoader] = useState(false);
    const [reportData, setReportData] = useState(null);
    const [allReportData, setAllReportData] = useState(null);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(50);

    const [sessionEmails, setSessionEmails] = useState([]);
    const [demopaidEmails, setDemopaidEmails] = useState([]);
    const [workingHourEmails, setWorkingHourEmails] = useState([]);
    const [sessionWiseConversionEmails, setSessionWiseConversionEmails] = useState([]);
    const [demoLeadEmails, setDemoLeadEmails] = useState([]);
    
    const [subscriptionEmails, setSubscriptionEmails] = useState([]);
    const [subscriptionAuditingEmails, setSubscriptionAuditingEmails] = useState([]);
    const [backlogSessionEmails, setBacklogSessionEmails] = useState([]);
    const [slotsDetailsReportEmails, setSlotDetailsReportEmails] = useState([]);
    const [teacherworkinghourEmails, setTeacherWorkinghourEmails] = useState([]);
    const [earningReportEmails, setEarningReportEmails] = useState([]);
    const [conversionReportEmails, setConversionReportEmails] = useState([]);
    const [signupSuccessEmails, setSignupSuccessEmails] = useState([]);
    const [demoCancelEmails, setdemoCancelEmails] = useState([]);
    const [demoSessionRecordingEmails, setdemoSessionRecordingEmails] = useState([]);

    const [backlogEmails, setBacklogEmails] = useState([]);
    const [blockhostData, setBlockhostData] = useState([]);

    const [selectedType, setSelectedType] = useState();
    const [selectedSubject, setSelectedSubject] = useState();
    const [selectedForKids, setSelectedForKids] = useState();
    const [subscriptionType, setSubscriptionType] = useState("");
    const [subject, setSubject] = useState("")
    const [cancelBy, setCancelBy] = useState(null)
    const [file, setFile] = useState(null);
    const [filterValue, setFilterValue] = useState('');
    const user = JSON.parse(localStorage.getItem("ADMIN"));

    function onPickDate(date, dateString) {
        setStartDate(dateString[0]);
        setEndDate(dateString[1]);
    }
     function onPickDate1(date,dateString) {
         setStartDate(dateString);
    }
    function generateReport() {
        setLoader(true);
        var data = {
            startDate: startDate,
            endDate: endDate
        };

        const onSuccess = async (res) => {
            setLoader(false);
            console.log(res);
            window.open(res.download_url);
            message.success(res.message, 8);
        };

        const onError = (msg) => {
            setLoader(false);
            message.error(msg, 8);
        };

        dispatch(GenerateWeeklysessionReport(data, onSuccess, onError));
    }
    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };
    const handleUpload = () => {
        if (!file) {
            message.error('Please select a file before uploading.');
            return;
        }

        setLoader(true);
        
        const onSuccess = () => {
            setLoader(false);
            message.success('File uploaded successfully!');
        };

        const onError = (error) => {
            setLoader(false);
            message.error(`File upload failed: ${error.message}`);
        };

        const formData = new FormData();
        formData.append('csvFile', file);

        learnerReportUploader(formData, onSuccess, onError);
    };

    const generateSessionReport = () => {
        setSubLoader(true);

        // Check if emails is truthy and a string, otherwise provide a default value


        const emailList = sessionEmails && typeof sessionEmails === "string" ? sessionEmails.split(",") : [];



        var payload = {
            startDate: startDate,
            endDate: endDate,
            email: emailList,
        };

        if(selectedType){
            payload["type"]=selectedType
        }
        if(selectedSubject){
            payload["subject"]=selectedSubject
        }
        if(selectedForKids){
            payload["forKids"]=selectedForKids
        }

        const onSuccess = (data) => {
            setSubLoader(false);
            console.log(data);

            message.success(data.message)


        };
        const onError = (error) => {
            setSubLoader(false);
            message.error(error, 8);
        };
        GetSessionReport(payload, onSuccess, onError);
    };

    const generateDemopaidnotbookedReport = () => {
        setSubLoader(true);

         // Check if emails is truthy and a string, otherwise provide a default value


         const emailList = demopaidEmails && typeof demopaidEmails === "string" ? demopaidEmails.split(",") : [];



        const payload = {
            startDate: startDate,
            endDate: endDate,
            email: emailList,

        };
        const onSuccess = (data) => {
            setSubLoader(false);
            console.log(data);
            message.success(data.message)

        };
        const onError = (error) => {
            setSubLoader(false);
            message.error(error, 8);
        };
        GetDemopaidnotbookedReport(payload, onSuccess, onError);
    };

    const generatebacklogAndQualityMarkReport = () => {
        setSubLoader(true);

         // Check if emails is truthy and a string, otherwise provide a default value


         const emailList = backlogEmails && typeof backlogEmails === "string" ? backlogEmails.split(",") : [];



        const payload = {
            ...(filterValue && { type: filterValue }), 
            startDate: startDate,
            endDate: endDate,
            email: emailList,

        };
        const onSuccess = (data) => {
            setSubLoader(false);
            console.log(data);
            message.success(data.message)

        };
        const onError = (error) => {
            setSubLoader(false);
            message.error(error, 8);
        };
        GetbacklogAndQualityMarkRepor(payload, onSuccess, onError);
    };

    const generateSubscriptionReport = () => {
        setSubLoader(true);

         // Check if emails is truthy and a string, otherwise provide a default value


         const emailList = subscriptionEmails && typeof subscriptionEmails === "string" ? subscriptionEmails.split(",") : [];



        const payload = {
            startDate: startDate,
            endDate: endDate,
            email: emailList,
        };

        if(subscriptionType === "Curriculum") {
           payload.isCurriculum = true
          }
      
          if(subscriptionType === "Non-Curriculum") {
           payload.nonCurriculum = true
          }
      
          if(subscriptionType === "Peer Subscription") {
           payload.isPeerSubscription = true
          }
      
          if(subject === "English"){
           payload.subject = "English"
          }
      
          if(subject === "IELTS"){
           payload.subject = "IELTS"
          }
        const onSuccess = (data) => {
            setSubLoader(false);
            message.success(data.message)
            if (data)
                console.log(data);

        };
        const onError = (error) => {
            setSubLoader(false);
            message.error(error, 8);
        };
        GetSubscriptionReport(payload, onSuccess, onError);
    };

    const generateBacklogSessionReport = () => {
        setSubLoader(true);

         // Check if emails is truthy and a string, otherwise provide a default value


         const emailList = backlogSessionEmails && typeof backlogSessionEmails === "string" ? backlogSessionEmails.split(",") : [];



        const payload = {
            startDate: startDate,
            endDate: endDate,
            email: emailList,
        };

        if(subscriptionType === "Curriculum") {
           payload.isCurriculum = true
          }
      
          if(subscriptionType === "Non-Curriculum") {
           payload.nonCurriculum = true
          }
      
          if(subscriptionType === "Peer Subscription") {
           payload.isPeerSubscription = true
          }
      
          if(subject === "English"){
           payload.subject = "English"
          }
      
          if(subject === "IELTS"){
           payload.subject = "IELTS"
          }
        const onSuccess = (data) => {
            setSubLoader(false);
            message.success(data.message)
            if (data)
                console.log(data);

        };
        const onError = (error) => {
            setSubLoader(false);
            message.error(error, 8);
        };
        GetBacklogSessionReport(payload, onSuccess, onError);
    };

    const generateSubscriptionAuditingReport = () => {
        setSubLoader(true);

         // Check if emails is truthy and a string, otherwise provide a default value


         const emailList = subscriptionAuditingEmails && typeof subscriptionAuditingEmails === "string" ? subscriptionAuditingEmails.split(",") : [];



        const payload = {
            startDate: startDate,
            endDate: endDate,
            email: emailList,
            isVerified: "true"
        };

        const onSuccess = (data) => {
            setSubLoader(false);
            message.success(data.message)
            if (data)
                console.log(data);

        };
        const onError = (error) => {
            setSubLoader(false);
            message.error(error, 8);
        };
        GetSubscriptionAuditingReport(payload, onSuccess, onError);
    };


    const generateSlotDetailsReport = () => {
        setSubLoader(true);

         // Check if emails is truthy and a string, otherwise provide a default value


         const emailList = slotsDetailsReportEmails && typeof slotsDetailsReportEmails === "string" ? slotsDetailsReportEmails.split(",") : [];



        const payload = {
            startDate: startDate,
            endDate: endDate,
            email: emailList,
        };

        const onSuccess = (data) => {
            setSubLoader(false);
            message.success(data.message)
            if (data)
                console.log(data);

        };
        const onError = (error) => {
            setSubLoader(false);
            message.error(error, 8);
        };
        GetSlotDetailsReport(payload, onSuccess, onError);
    };

    const generateteacherworkinghourReport = () => {
        setSubLoader(true);

         // Check if emails is truthy and a string, otherwise provide a default value


         const emailList = teacherworkinghourEmails && typeof teacherworkinghourEmails === "string" ? teacherworkinghourEmails.split(",") : [];



        const payload = {
            startDate: startDate,
            endDate: endDate,
            email: emailList,
        };
        const onSuccess = (data) => {
            setSubLoader(false);
            message.success(data.message)
            if (data)
                console.log(data);

        };
        const onError = (error) => {
            setSubLoader(false);
            message.error(error, 8);
        };
        GetTeacherworkinghourReport(payload, onSuccess, onError);
    };

    const generateEarningReport = () => {
        setSubLoader(true);

         // Check if emails is truthy and a string, otherwise provide a default value


         const emailList = earningReportEmails && typeof earningReportEmails === "string" ? earningReportEmails.split(",") : [];



        const payload = {
            startDate: startDate,
            endDate: endDate,
            email: emailList,
        };
        const onSuccess = (data) => {
            setSubLoader(false);
            message.success(data.message)
            if (data)
                console.log(data);

        };
        const onError = (error) => {
            setSubLoader(false);
            message.error(error, 8);
        };
        GetTeacherEarningsReport(payload, onSuccess, onError);
    };

    const generateConversionStatsReport = () => {
        setSubLoader(true);

         // Check if emails is truthy and a string, otherwise provide a default value


         const emailList = conversionReportEmails && typeof conversionReportEmails === "string" ? conversionReportEmails.split(",") : [];



        const payload = {
            startDate: startDate,
            endDate: endDate,
            email: emailList,
        };
        const onSuccess = (data) => {
            setSubLoader(false);
            message.success(data.message)
            if (data)
                console.log(data);

        };
        const onError = (error) => {
            setSubLoader(false);
            message.error(error, 8);
        };
        GetteacherConversionStatsReport(payload, onSuccess, onError);
    };

    const generateDemoCancelReport = () => {
        setSubLoader(true);

        // Check if emails is truthy and a string, otherwise provide a default value
        const emailList = demoCancelEmails && typeof demoCancelEmails === "string" ? demoCancelEmails.split(",") : [];
        const payload = {
            startDate: startDate,
            endDate: endDate,
            email: emailList,
        };
        if(cancelBy){
            payload["cancelBy"]=cancelBy
        }
        const onSuccess = (data) => {
            setSubLoader(false);
            message.success(data.message)
            if (data)
                console.log(data);

        };
        const onError = (error) => {
            setSubLoader(false);
            message.error(error, 8);
        };
        GetDemoCancelReport(payload, onSuccess, onError);
    };

    const generateSignupSuccessReport = () => {
        setSubLoader(true);

         // Check if emails is truthy and a string, otherwise provide a default value
         const emailList = signupSuccessEmails && typeof signupSuccessEmails === "string" ? signupSuccessEmails.split(",") : [];
        const payload = {
            startDate: startDate,
            endDate: endDate,
            email: emailList,
        };
        const onSuccess = (data) => {
            setSubLoader(false);
            message.success(data.message)
            if (data)
                console.log(data);

        };
        const onError = (error) => {
            setSubLoader(false);
            message.error(error, 8);
        };
        GetSignupSuccessReport(payload, onSuccess, onError);
    };

     const generateCurrentWorkingHourReport = () => {
        setSubLoader(true);

         // Check if emails is truthy and a string, otherwise provide a default value


         const emailList = workingHourEmails && typeof workingHourEmails === "string" ? workingHourEmails.split(",") : [];



        const payload = {
            email: emailList,
        };
        if(selectedSubject){
            payload["subject"]=selectedSubject
        }
        if(selectedForKids){
            payload["forKids"]=selectedForKids
        }
        const onSuccess = (data) => {
            setSubLoader(false);
            message.success(data.message)
            if (data)
                console.log(data);

        };
        const onError = (error) => {
            setSubLoader(false);
            message.error(error, 8);
        };
        
        GetcurrentWorkingHourReport(payload, onSuccess, onError);
    };

     const getSessionWiseConversionReport = () => {
        setSubLoader(true);

         // Check if emails is truthy and a string, otherwise provide a default value


         const emailList = sessionWiseConversionEmails && typeof sessionWiseConversionEmails === "string" ? sessionWiseConversionEmails.split(",") : [];



        const payload = {
            startDate: startDate,
            endDate: endDate,
            email: emailList,
            timezone:"Asia/Kolkata"
        };
        const onSuccess = (data) => {
            setSubLoader(false);
            message.success(data.message)
            if (data)
                console.log(data);

        };
        const onError = (error) => {
            setSubLoader(false);
            message.error(error, 8);
        };
        GetSessionWiseConversionReport(payload, onSuccess, onError);
    };


    const getP2PReport = () => {
        setSubLoader(true);
        const payload = {
            startDate: startDate,
            endDate: endDate,
            timezone:"Asia/Kolkata"
        };
        const onSuccess = (data) => {
            setSubLoader(false);
            message.success(data.message)
            if (data)
                console.log(data);

        };
        const onError = (error) => {
            setSubLoader(false);
            message.error(error, 8);
        };
        GetP2PDetailsReport(payload, onSuccess, onError);
    };

    const getSessionAutoSwapReport= () => {
        setSubLoader(true);
        const payload = {
            startDate: startDate,
            endDate: endDate,
            timezone:"Asia/Kolkata"
        };
        const onSuccess = (data) => {
            setSubLoader(false);
            message.success(data.message)
            if (data)
                console.log(data);

        };
        const onError = (error) => {
            setSubLoader(false);
            message.error(error, 8);
        };
        GetSessionAutoSwapReport(payload, onSuccess, onError);
    };

    
    const getP2PCallReport = () => {
        setSubLoader(true);
        const payload = {
            startDate: startDate,
            endDate: endDate,
            timezone:"Asia/Kolkata"
        };
        const onSuccess = (data) => {
            setSubLoader(false);
            message.success(data.message)
            if (data)
                console.log(data);

        };
        const onError = (error) => {
            setSubLoader(false);
            message.error(error, 8);
        };
        GetP2PCallDetailsReport(payload, onSuccess, onError);
    };

    const getP2PSessionWiseReport = () => {
        setSubLoader(true);
        const payload = {
            startDate: startDate,
            endDate: endDate,
            timezone:"Asia/Kolkata"
        };
        const onSuccess = (data) => {
            setSubLoader(false);
            message.success(data.message)
            if (data)
                console.log(data);

        };
        const onError = (error) => {
            setSubLoader(false);
            message.error(error, 8);
        };
        GetP2PSessionWiseReport(payload, onSuccess, onError);
    };

    const getp2pUTMReferralReport = () => {
        setSubLoader(true);

         // Check if emails is truthy and a string, otherwise provide a default value


         const emailList = backlogSessionEmails && typeof backlogSessionEmails === "string" ? backlogSessionEmails.split(",") : [];



        const payload = {
            startDate: startDate,
            endDate: endDate,
            email: emailList,
        };

      
         
        const onSuccess = (data) => {
            setSubLoader(false);
            message.success(data.message)
            if (data)
                console.log(data);

        };
        const onError = (error) => {
            setSubLoader(false);
            message.error(error, 8);
        };
        Getp2pUTMReferralReport(payload, onSuccess, onError);
    };

   const getDemoLeadReport = () => {
        setSubLoader(true);

         // Check if emails is truthy and a string, otherwise provide a default value



         const emailList = demoLeadEmails && typeof demoLeadEmails === "string" ? demoLeadEmails.split(",") : [];




        const payload = {
            localDate: startDate,
            email: emailList,
            timezone:"Asia/Kolkata"
        };
        const onSuccess = (data) => {
            setSubLoader(false);
            message.success(data.message)
            if (data)
                console.log(data);

        };
        const onError = (error) => {
            setSubLoader(false);
            message.error(error, 8);
        };
        
        GetDemoLeadReport(payload, onSuccess, onError);
    };
       const generateDemoSessionRecordingReport = () => {
        setSubLoader(true);
        // Check if emails is truthy and a string, otherwise provide a default value
         const emailList = earningReportEmails && typeof earningReportEmails === "string" ? earningReportEmails.split(",") : [];

         const payload = {
            startDate: startDate,
            endDate: endDate,
            email: emailList,
        };
        const onSuccess = (data) => {
            setSubLoader(false);
            message.success(data.message)
            if (data)
                console.log(data);

        };
        const onError = (error) => {
            setSubLoader(false);
            message.error(error, 8);
        };
        getDemoSessionRecordingReport(payload, onSuccess, onError);
    };
    useEffect(() => {
        setSubLoader(true);
        setSubLoader(true);
        const payload = {
            page,
            limit,
        };
        const onSuccess = (data) => {
            setSubLoader(false);
            console.log(data)
            setAllReportData(data.getAllGeneratedReportDetails);
            setPage(page + 1);
        };
        const onError = (error) => {
            setSubLoader(false);
            message.error(error, 8);
        };
        GetAllGeneratedReport(payload, onSuccess, onError);
    }, []);


    const handleLoadMore = () => {
        setSubLoader(true);
        const payload = {
            page,
            limit,
        };
        const onSuccess = (data) => {
            console.log(data)
            if (data.message == "No Report  Exist") {
                setSubLoader(false);
                message.error('No report exist')
                return
            }
            setSubLoader(false);
            setAllReportData([...allReportData, ...data.getAllGeneratedReportDetails]);
            setPage(page + 1);
        };
        const onError = (error) => {
            setSubLoader(false);
            message.error(error, 8);
        };
        GetAllGeneratedReport(payload, onSuccess, onError);
    };

    const generateBlockhostCountData = () => {
        setSubLoader(true);
        const payload = {
            startDate,
            endDate
        }
        const onSuccess = (data) => {
            setBlockhostData(data?.maxTimeSlots);
            setSubLoader(false);
        };
        const onError = (error) => {
            setSubLoader(false);
            message.error(error, 8);
        };
        dispatch(GetListBlockhostByDateRange(payload, onSuccess, onError));
    }
    return (

        
        <div>
            <PageBreadcrumb route={["dashboard", "Download Session report"]} />

            <Tabs defaultActiveKey="1">
                <TabPane tab="Generate Report" key="1">
                    <Card title="Generate Session Report" style={{ marginBottom: "1rem" }}>
                        <Row>
                            <Col span={12}>
                                <Space>

                                    <RangePicker onChange={onPickDate} format={dateFormat} style={{ width: "300px" }} />

                                    <Input
                                        placeholder="Enter emails (separated by commas)"
                                        value={sessionEmails}
                                        onChange={(e) => setSessionEmails(e.target.value)}
                                        style={{ width: "300px" }}
                                    />
         <Select style={{ width: "120px" }} 
         placeholder="Demo/Regular"
         onChange={(value) => setSelectedType(value)}
         value={selectedType}
         >
            <Option value="Demo">Demo</Option>
            <Option value="Regular">Regular</Option>
          </Select>

          <Select style={{ width: "120px" }} 
          placeholder="Subject"
          onChange={(value) => setSelectedSubject(value)}
          value={selectedSubject}
          >
            <Option value="English">English</Option>
            <Option value="IELTS">IELTS</Option>
          </Select>

          <Select style={{ width: "120px" }} placeholder="For Kids"
                      onChange={(value) => setSelectedForKids(value)}
                      value={selectedForKids}
          >
            <Option value={true}>Yes</Option>
            <Option value={false}>No</Option>
          </Select>
                                    <Button type="primary" onClick={() => generateSessionReport()} loading={subLoader}>
                                        Generate report
                                    </Button>
                                </Space>
                            </Col>
                        </Row>

                        {reportData ? (
                            <CSVLink
                                data={reportData}
                                filename={`${startDate}-to-${endDate}.csv`}
                                className="button primary"
                                target="_blank"
                                onClick={() => setReportData(false)}
                            >
                                Download
                            </CSVLink>
                        ) : (
                            ""
                        )}
                    </Card>

                    {<Card title="Generate Demo Paid Not Booked Report" style={{ marginBottom: "1rem" }}>
                        <Row>
                            <Col span={12}>
                                <Space>

                                    <RangePicker onChange={onPickDate} format={dateFormat} style={{ width: "300px" }} />

                                    <Input
                                        placeholder="Enter emails (separated by commas)"


                                        value={demopaidEmails}
                                        onChange={(e) => setDemopaidEmails(e.target.value)}



                                        style={{ width: "300px" }}

                                    />
                                    <Button type="primary" onClick={() => generateDemopaidnotbookedReport()} loading={subLoader}>
                                        Generate report
                                    </Button>
                                </Space>
                            </Col>
                        </Row>

                        {reportData ? (
                            <CSVLink
                                data={reportData}
                                filename={`${startDate}-to-${endDate}.csv`}
                                className="button primary"
                                target="_blank"
                                onClick={() => setReportData(false)}
                            >
                                Download
                            </CSVLink>
                        ) : (
                            ""
                        )}
                    </Card>}

                    {roleValidator(['admin', 'superadmin', 'subadmin', 'marketing', 'telecaller']) ? 
                        <Card title="Generate Subscription Report" style={{ marginBottom: "1rem" }}>
                            <Row>
                                <Col span={12}>
                                    <Space>

                                        <RangePicker onChange={onPickDate} format={dateFormat} style={{ width: "300px" }} />

                                        <Input
                                            placeholder="Enter emails (separated by commas)"


                                            value={subscriptionEmails}
                                            onChange={(e) => setSubscriptionEmails(e.target.value)}



                                            style={{ width: "300px" }}

                                        />
                                        <Select
                                        placeholder="Subscription Type"
                                        style={{ width: 120 }}
                                        onChange={(value) => setSubscriptionType(value)}
                                        >
                                        <option value="Curriculum">Curriculum</option>
                                        <option value="Non-Curriculum">Non-Curriculum</option>
                                        <option value="Peer Subscription">Peer Subscription</option>
                                        </Select>
                                        <Select
                                        placeholder="Subject"
                                        style={{ width: 120 }}
                                        onChange={(value) => setSubject(value)}
                                        >
                                        <option value="English">English</option>
                                        <option value="IELTS">IELTS</option>
                                        </Select>
                                        <Button type="primary" onClick={() => generateSubscriptionReport()} loading={subLoader}>
                                            Generate report
                                        </Button>
                                    </Space>
                                </Col>
                            </Row>

                            {reportData ? (
                                <CSVLink
                                    data={reportData}
                                    filename={`${startDate}-to-${endDate}.csv`}
                                    className="button primary"
                                    target="_blank"
                                    onClick={() => setReportData(false)}
                                >
                                    Download
                                </CSVLink>
                            ) : (
                                ""
                            )}
                        </Card>
                     : null}

                    {roleValidator(['admin', 'superadmin', 'subadmin', 'marketing'])
                    ?
                    <>
                    <Card title="Generate Subscription Auditing Report" style={{ marginBottom: "1rem" }}>
                        <Row>
                            <Col span={12}>
                                <Space>

                                    <RangePicker onChange={onPickDate} format={dateFormat} style={{ width: "300px" }} />

                                    <Input
                                        placeholder="Enter emails (separated by commas)"


                                        value={subscriptionEmails}
                                        onChange={(e) => setSubscriptionAuditingEmails(e.target.value)}



                                        style={{ width: "300px" }}

                                    />
                                    <Button type="primary" onClick={() => generateSubscriptionAuditingReport()} loading={subLoader}>
                                        Generate report
                                    </Button>
                                </Space>
                            </Col>
                        </Row>

                        {reportData ? (
                            <CSVLink
                                data={reportData}
                                filename={`${startDate}-to-${endDate}.csv`}
                                className="button primary"
                                target="_blank"
                                onClick={() => setReportData(false)}
                            >
                                Download
                            </CSVLink>
                        ) : (
                            ""
                        )}
                    </Card>


                    <Card title="Generate Backlog Session Report" style={{ marginBottom: "1rem" }}>
                        <Row>
                            <Col span={12}>
                                <Space>

                                    <RangePicker onChange={onPickDate} format={dateFormat} style={{ width: "300px" }} />

                                    <Input
                                        placeholder="Enter emails (separated by commas)"


                                        value={backlogSessionEmails}
                                        onChange={(e) => setBacklogSessionEmails(e.target.value)}



                                        style={{ width: "300px" }}

                                    />
                                    <Button type="primary" onClick={() => generateBacklogSessionReport()} loading={subLoader}>
                                        Generate report
                                    </Button>
                                </Space>
                            </Col>
                        </Row>

                        {reportData ? (
                            <CSVLink
                                data={reportData}
                                filename={`${startDate}-to-${endDate}.csv`}
                                className="button primary"
                                target="_blank"
                                onClick={() => setReportData(false)}
                            >
                                Download
                            </CSVLink>
                        ) : (
                            ""
                        )}
                    </Card>


                    <Card title="Generate Slot Details Report" style={{ marginBottom: "1rem" }}>
                        <Row>
                            <Col span={12}>
                                <Space style={{marginBottom:"1rem"}}>

                                    <RangePicker onChange={onPickDate} format={dateFormat} style={{ width: "300px" }} />

                                    <Input
                                        placeholder="Enter emails (separated by commas)"


                                        value={subscriptionEmails}
                                        onChange={(e) => setSlotDetailsReportEmails(e.target.value)}



                                        style={{ width: "300px" }}

                                    />
                                    <Button type="primary" onClick={() => generateSlotDetailsReport()} loading={subLoader}>
                                        Generate report
                                    </Button>
                                </Space>
                                <span style={{fontSize:"0.8rem"}}>Note: Please select same date for both start and end date</span>
                            </Col>
                        </Row>

                        {reportData ? (
                            <CSVLink
                                data={reportData}
                                filename={`${startDate}-to-${endDate}.csv`}
                                className="button primary"
                                target="_blank"
                                onClick={() => setReportData(false)}
                            >
                                Download
                            </CSVLink>
                        ) : (
                            ""
                        )}
                    </Card>

                    <Card title="Generate Backlog And Quality Mark Report" style={{ marginBottom: "1rem" }}>
                        <Row>
                            <Col span={12}>
                                <Space>
                                    <RangePicker onChange={onPickDate} format="YYYY-MM-DD" style={{ width: "300px" }} />

                                    <Input
                                        placeholder="Enter emails (separated by commas)"
                                        value={backlogEmails}
                                        onChange={(e) => setBacklogEmails(e.target.value)}
                                        style={{ width: "300px" }}
                                    />

                                    <Space align="center">
                                        <FilterOutlined style={{ fontSize: '16px', color: '#1890ff' }} />
                                       
                                        <Select
                                            placeholder="Select"
                                            style={{ width: 120 }}
                                            value={filterValue}
                                            onChange={(value) => setFilterValue(value)}
                                        >
                                            <Option value="">None</Option>
                                            <Option value="Demo">Demo</Option>
                                            <Option value="Regular">Regular</Option>
                                        </Select>
                                    </Space>

                                    <Button type="primary" onClick={() => generatebacklogAndQualityMarkReport()} loading={subLoader}>
                                        Generate report
                                    </Button>
                                </Space>
                            </Col>
                        </Row>

                        {reportData ? (
                            <CSVLink
                                data={reportData}
                                filename={`${startDate}-to-${endDate}.csv`}
                                className="button primary"
                                target="_blank"
                                onClick={() => setReportData(false)}
                            >
                                Download
                            </CSVLink>
                        ) : (
                            ""
                        )}
                    </Card>

                    
                    <Card title="Generate P2P Detailed Report" style={{ marginBottom: "1rem" }}>
                        <Row>
                            <Col span={12}>
                                <Space>

                                    <RangePicker onChange={onPickDate} format={dateFormat} style={{ width: "300px" }} />
                                    <Button type="primary" onClick={() => getP2PReport()} loading={subLoader}>
                                        Generate report
                                    </Button>
                                </Space>
                            </Col>
                        </Row>

                        {reportData ? (
                            <CSVLink
                                data={reportData}
                                filename={`${startDate}-to-${endDate}.csv`}
                                className="button primary"
                                target="_blank"
                                onClick={() => setReportData(false)}
                            >
                                Download
                            </CSVLink>
                        ) : (
                            ""
                        )}
                    </Card>

                    <Card title="Generate Session AutoSwap Report" style={{ marginBottom: "1rem" }}>
                        <Row>
                            <Col span={12}>
                                <Space>

                                    <RangePicker onChange={onPickDate} format={dateFormat} style={{ width: "300px" }} />
                                    <Button type="primary" onClick={() => getSessionAutoSwapReport()} loading={subLoader}>
                                        Generate report
                                    </Button>
                                </Space>
                            </Col>
                        </Row>

                        {reportData ? (
                            <CSVLink
                                data={reportData}
                                filename={`${startDate}-to-${endDate}.csv`}
                                className="button primary"
                                target="_blank"
                                onClick={() => setReportData(false)}
                            >
                                Download
                            </CSVLink>
                        ) : (
                            ""
                        )}
                    </Card>


                    <Card title="Generate P2P Call Detailed Report" style={{ marginBottom: "1rem" }}>
                        <Row>
                            <Col span={12}>
                                <Space>

                                    <RangePicker onChange={onPickDate} format={dateFormat} style={{ width: "300px" }} />
                                    <Button type="primary" onClick={() => getP2PCallReport()} loading={subLoader}>
                                        Generate report
                                    </Button>
                                </Space>
                            </Col>
                        </Row>

                        {reportData ? (
                            <CSVLink
                                data={reportData}
                                filename={`${startDate}-to-${endDate}.csv`}
                                className="button primary"
                                target="_blank"
                                onClick={() => setReportData(false)}
                            >
                                Download
                            </CSVLink>
                        ) : (
                            ""
                        )}
                    </Card>

                    <Card title="Generate P2P Session Wise Report" style={{ marginBottom: "1rem" }}>
                        <Row>
                            <Col span={12}>
                                <Space>

                                    <RangePicker onChange={onPickDate} format={dateFormat} style={{ width: "300px" }} />
                                    <Button type="primary" onClick={() => getP2PSessionWiseReport()} loading={subLoader}>
                                        Generate report
                                    </Button>
                                </Space>
                            </Col>
                        </Row>

                        {reportData ? (
                            <CSVLink
                                data={reportData}
                                filename={`${startDate}-to-${endDate}.csv`}
                                className="button primary"
                                target="_blank"
                                onClick={() => setReportData(false)}
                            >
                                Download
                            </CSVLink>
                        ) : (
                            ""
                        )}
                    </Card>

                    <Card title="Generate P2P UTM Referral Report" style={{ marginBottom: "1rem" }}>
                        <Row>
                            <Col span={12}>
                                <Space>

                                    <RangePicker onChange={onPickDate} format={dateFormat} style={{ width: "300px" }} />
                                    <Button type="primary" onClick={() => getp2pUTMReferralReport()} loading={subLoader}>
                                        Generate report
                                    </Button>
                                </Space>
                            </Col>
                        </Row>

                        {reportData ? (
                            <CSVLink
                                data={reportData}
                                filename={`${startDate}-to-${endDate}.csv`}
                                className="button primary"
                                target="_blank"
                                onClick={() => setReportData(false)}
                            >
                                Download
                            </CSVLink>
                        ) : (
                            ""
                        )}
                    </Card>

                    <Card title="Generate Teacher Working Hour Report" style={{ marginBottom: "1rem" }}>
                        <Row>
                            <Col span={12}>
                                <Space>

                                    <RangePicker onChange={onPickDate} format={dateFormat} style={{ width: "300px" }} />

                                    <Input
                                        placeholder="Enter emails (separated by commas)"


                                        value={teacherworkinghourEmails}
                                        onChange={(e) => setTeacherWorkinghourEmails(e.target.value)}



                                        style={{ width: "300px" }}

                                    />
                                    <Button type="primary" onClick={() => generateteacherworkinghourReport()} loading={subLoader}>
                                        Generate report
                                    </Button>
                                </Space>
                            </Col>
                        </Row>

                        {reportData ? (
                            <CSVLink
                                data={reportData}
                                filename={`${startDate}-to-${endDate}.csv`}
                                className="button primary"
                                target="_blank"
                                onClick={() => setReportData(false)}
                            >
                                Download
                            </CSVLink>
                        ) : (
                            ""
                        )}
                    </Card>
                    <Card title="Generate Teacher Earnings Report" style={{ marginBottom: "1rem" }}>
                        <Row>
                            <Col span={12}>
                                <Space>

                                    <RangePicker onChange={onPickDate} format={dateFormat} style={{ width: "300px" }} />

                                    <Input
                                        placeholder="Enter emails (separated by commas)"


                                        value={earningReportEmails}
                                        onChange={(e) => setEarningReportEmails(e.target.value)}



                                        style={{ width: "300px" }}

                                    />
                                    <Button type="primary" onClick={() => generateEarningReport()} loading={subLoader}>
                                        Generate report
                                    </Button>
                                </Space>
                            </Col>
                        </Row>

                        {reportData ? (
                            <CSVLink
                                data={reportData}
                                filename={`${startDate}-to-${endDate}.csv`}
                                className="button primary"
                                target="_blank"
                                onClick={() => setReportData(false)}
                            >
                                Download
                            </CSVLink>
                        ) : (
                            ""
                        )}
                    </Card>
                    
                    <Card title="Generate Teacher Conversion Stats Report" style={{ marginBottom: "1rem" }}>
                        <Row>
                            <Col span={12}>
                                <Space>

                                    <RangePicker onChange={onPickDate} format={dateFormat} style={{ width: "300px" }} />

                                    <Input
                                        placeholder="Enter emails (separated by commas)"


                                        value={conversionReportEmails}
                                        onChange={(e) => setConversionReportEmails(e.target.value)}



                                        style={{ width: "300px" }}

                                    />
                                    <Button type="primary" onClick={() => generateConversionStatsReport()} loading={subLoader}>
                                        Generate report
                                    </Button>
                                </Space>
                            </Col>
                        </Row>

                        {reportData ? (
                            <CSVLink
                                data={reportData}
                                filename={`${startDate}-to-${endDate}.csv`}
                                className="button primary"
                                target="_blank"
                                onClick={() => setReportData(false)}
                            >
                                Download
                            </CSVLink>
                        ) : (
                            ""
                        )}
                    </Card>

                    <Card title="Generate Current WorkingHour Report" style={{ marginBottom: "1rem" }}>
                        <Row>
                            <Col span={12}>
                                <Space>

                                    
                                    <Input
                                        placeholder="Enter emails (separated by commas)"
                                        value={workingHourEmails}
                                        onChange={(e) => setWorkingHourEmails(e.target.value)}
                                        style={{ width: "300px" }}

                                    />
                                    <Select style={{ width: "120px" }} 
                                    placeholder="Subject"
                                    onChange={(value) => setSelectedSubject(value)}
                                    value={selectedSubject}
                                    >
                                        <Option value="English">English</Option>
                                        <Option value="IELTS">IELTS</Option>
                                    </Select>

                                    <Select style={{ width: "120px" }} placeholder="For Kids"
                                                onChange={(value) => setSelectedForKids(value)}
                                                value={selectedForKids}
                                    >
                                        <Option value={true}>Yes</Option>
                                        <Option value={false}>No</Option>
                                    </Select>
                                    <Button type="primary" onClick={() => generateCurrentWorkingHourReport()} loading={subLoader}>
                                        Generate report
                                    </Button>
                                </Space>
                            </Col>
                        </Row>

                        {reportData ? (
                            <CSVLink
                                data={reportData}
                                className="button primary"
                                target="_blank"
                                onClick={() => setReportData(false)}
                            >
                                Download
                            </CSVLink>
                        ) : (
                            ""
                        )}
                    </Card>
                    <Card title="Generate Demo Cancel Report" style={{ marginBottom: "1rem" }}>
                                    <Row>
                                        <Col span={12}>
                                            <Space>
                                            <RangePicker onChange={onPickDate} format={dateFormat} style={{ width: "300px" }} />


                                                <Input
                                                    placeholder="Enter emails (separated by commas)"
                                                    value={demoCancelEmails}
                                                    onChange={(e) => setdemoCancelEmails(e.target.value)}
                                                    style={{ width: "300px" }}

                                                />
                                                <Select style={{ width: "120px" }}
                                                    placeholder="CancelBy"
                                                    onChange={(value) => setCancelBy(value)}
                                                    value={cancelBy}
                                                >
                                                    <Option value="Teacher">Teacher</Option>

                                                </Select>


                                                <Button type="primary" onClick={() => generateDemoCancelReport()} loading={subLoader}>
                                                    Generate report
                                                </Button>
                                            </Space>
                                        </Col>
                                    </Row>

                                    {reportData ? (
                                        <CSVLink
                                            data={reportData}
                                            className="button primary"
                                            target="_blank"
                                            onClick={() => setReportData(false)}
                                        >
                                            Download
                                        </CSVLink>
                                    ) : (
                                        ""
                                    )}
                    </Card>
                    <Card title="Generate Signup Success Report" style={{ marginBottom: "1rem" }}>
                                    <Row>
                                        <Col span={12}>
                                            <Space>
                                                <RangePicker onChange={onPickDate} format={dateFormat} style={{ width: "300px" }} />


                                                <Input
                                                    placeholder="Enter emails (separated by commas)"
                                                    value={signupSuccessEmails}
                                                    onChange={(e) => setSignupSuccessEmails(e.target.value)}
                                                    style={{ width: "300px" }}

                                                />



                                                <Button type="primary" onClick={() => generateSignupSuccessReport()} loading={subLoader}>
                                                    Generate report
                                                </Button>
                                            </Space>
                                        </Col>
                                    </Row>

                                    {reportData ? (
                                        <CSVLink
                                            data={reportData}
                                            className="button primary"
                                            target="_blank"
                                            onClick={() => setReportData(false)}
                                        >
                                            Download
                                        </CSVLink>
                                    ) : (
                                        ""
                                    )}
                    </Card>

                      <Card title="Generate Session Wise Conversion Report" style={{ marginBottom: "1rem" }}>
                        <Row>
                            <Col span={12}>
                                <Space>

                                    <RangePicker onChange={onPickDate} format={dateFormat} style={{ width: "300px" }} />

                                    <Input
                                        placeholder="Enter emails (separated by commas)"


                                        value={sessionWiseConversionEmails}
                                        onChange={(e) => setSessionWiseConversionEmails(e.target.value)}



                                        style={{ width: "300px" }}

                                    />
                                    <Button type="primary" onClick={() => getSessionWiseConversionReport()} loading={subLoader}>
                                        Generate report
                                    </Button>
                                </Space>
                            </Col>
                        </Row>

                        {reportData ? (
                            <CSVLink
                                data={reportData}
                                filename={`${startDate}-to-${endDate}.csv`}
                                className="button primary"
                                target="_blank"
                                onClick={() => setReportData(false)}
                            >
                                Download
                            </CSVLink>
                        ) : (
                            ""
                        )}
                    </Card>

                      <Card title="Generate DemoLead  Report" style={{ marginBottom: "1rem" }}>
                        <Row>
                            <Col span={12}>
                                <Space>
                                    <DatePicker onChange={onPickDate1} format={dateFormat} style={{ width: "300px" }} />
                                   
                                    <Input
                                        placeholder="Enter emails (separated by commas)"

                                        value={demoLeadEmails}
                                        onChange={(e) => setDemoLeadEmails(e.target.value)}

                                        style={{ width: "300px" }}

                                    />
                                    <Button type="primary" onClick={() => getDemoLeadReport()} loading={subLoader}>
                                        Generate report
                                    </Button>
                                </Space>
                            </Col>
                        </Row>

                        {reportData ? (
                            <CSVLink
                                data={reportData}
                                filename={`${startDate}-to-${endDate}.csv`}
                                className="button primary"
                                target="_blank"
                                onClick={() => setReportData(false)}
                            >
                                Download
                            </CSVLink>
                        ) : (
                            ""
                        )}
                    </Card>
                    
                     <Card title="Generate demo Session Recording Report" style={{ marginBottom: "1rem" }}>
                        <Row>
                            <Col span={12}>
                                <Space>

                                    <RangePicker onChange={onPickDate} format={dateFormat} style={{ width: "300px" }} />

                                    <Input
                                        placeholder="Enter emails (separated by commas)"
                                        value={demoSessionRecordingEmails}
                                        onChange={(e) => setdemoSessionRecordingEmails(e.target.value)}
                                        style={{ width: "300px" }}
                                    />
                                    <Button type="primary" onClick={() => generateDemoSessionRecordingReport()} loading={subLoader}>
                                        Generate report
                                    </Button>
                                </Space>
                            </Col>
                        </Row>

                        {reportData ? (
                            <CSVLink
                                data={reportData}
                                filename={`${startDate}-to-${endDate}.csv`}
                                className="button primary"
                                target="_blank"
                                onClick={() => setReportData(false)}
                            >
                                Download
                            </CSVLink>
                        ) : (
                            ""
                        )}
                    </Card>
                  
                    <Card title="Upload failed-payment csv file" style={{ marginBottom: "1rem" }}>
                        
                        <Form>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Form.Item
                                    name="upload"
                                    rules={[{ required: true, message: 'Please upload a file' }]}
                                    tooltip="This is a required field"
                                    style={{ flex: 0.85, marginRight: '16px' }}
                                >
                                    <Input type="file" onChange={handleFileChange} />
                                </Form.Item>
                                <Form.Item style={{ flex: 0.15 }}>
                                    <Button type="primary" onClick={handleUpload} loading={loader}>
                                        {loader ? "Uploading" : "Upload"}
                                    </Button>
                                </Form.Item>
                            </div>
                        </Form>
                        <span style={{ fontSize: "0.8rem" }}>
                        Note: The CSV file must include a column name <b>phoneNumber</b>.
                     </span>                
                    </Card>
                    <Card title="Most Booked Time Slot" style={{ marginBottom: "1rem" }}>
                        <Row>
                            <Col span={12}>
                                <Space>

                                    <RangePicker onChange={onPickDate} format={dateFormat} style={{ width: "300px" }} />

                                    <Button type="primary" onClick={() => generateBlockhostCountData()} loading={subLoader}>
                                        Generate Data
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                        {blockhostData[0]?.date && 
                        <>
                            <br/>
                            <div><strong>Date: </strong>{blockhostData[0]?.date}</div>
                            <div><strong>Slot: </strong>{blockhostData[0]?.slots[0]?.slots}</div>
                            <div><strong>Host count: </strong>136{blockhostData[0]?.slots[0]?.count}</div>
                        </>}
                    </Card>
                    </>
                    : null}
                </TabPane>
                <TabPane tab="Download Report" key="2">
                    <Card title="Download Reports" style={{ marginBottom: "1rem" }}>



                        {allReportData && allReportData.length > 0 ? (
                            <>
                                <List
                                    itemLayout="horizontal"
                                    dataSource={allReportData}
                                    renderItem={(report) => (
                                        <List.Item>
                                            <List.Item.Meta
                                                title={report.reportType}
                                                description={
                                                    <>
                                                        <div>Generated at: {moment.utc(report.createdAt).local().format("DD MMM YY LT")}</div>
                                                    </>
                                                }
                                            />
                                            <Button type="primary" href={report.awsReportLink} download={report.reportName}>
                                                Download
                                            </Button>
                                        </List.Item>
                                    )}
                                />
                                <div style={{ textAlign: "center", margin: "1rem 0" }}>
                                    <Button type="primary" onClick={handleLoadMore} loading={subLoader}>
                                        Load More
                                    </Button>
                                </div>
                            </>
                        ) : (
                            <p>No reports available</p>
                        )}
                    </Card>

                </TabPane>
            </Tabs>


        </div>

    );
};

export default AttendanceReport;