import React,{useEffect} from 'react'
import { useDispatch,useSelector } from "react-redux";
import SessionTable from '../component/Session_Components/Table'
import { GetAllSessions } from '../redux/actions/SessionAction';

const Session = () => {

    const dispatch = useDispatch();

    // useEffect(() => {
    //     dispatch(GetAllSessions())
    // }, [useDispatch])
    

    return (
        <div>
            <SessionTable/>
        </div>
    )
}

export default Session
