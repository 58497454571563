import React, { useEffect, useState } from "react";
import SideDrawer from "./SideDrawer";
import { Button, Divider, Form, Input, InputNumber, Select, message } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { CreateCertificate, CreateModule } from "../../redux/actions/CurriculumAction";
import { useDispatch, useSelector } from "react-redux";
import TopicsList from "./TopicsList";

const EditCertificate = ({ curriculumId, certificateData}) => {
  const [form] = Form.useForm();
  const [certificateCreated, setCertificateCreated] = useState();
  const curriculum = useSelector(
    (state) => state.CurriculumReducer.curriculumDetails
  );

  const [learner15MinMaterial, setLearner15MinMaterial] = useState();
  const [learner25MinMaterial, setLearner25MinMaterial] = useState();
  const [tutor15MinMaterial, setTutor15MinMaterial] = useState();
  const [tutor25MinMaterial, setTutor25MinMaterial] = useState();
  const [isValid, setIsValid] = useState(true);

  const handleNotesChange = (e) => {
    const fieldName = e.target.name;
  
    const isValidPdfLink = /\.pdf$/.test(e.target.value.toLowerCase());
  
    switch (fieldName) {
      case "learner15MinMaterial":
        setLearner15MinMaterial(e.target.value);
        break;
      case "learner25MinMaterial":
        setLearner25MinMaterial(e.target.value);
        break;
      case "tutor15MinMaterial":
        setTutor15MinMaterial(e.target.value);
        break;
      case "tutor25MinMaterial":
        setTutor25MinMaterial(e.target.value);
        break;
      default:
        break;
    }
  
    setIsValid(isValidPdfLink);
  };

  useEffect(() => {
    if (certificateData) {
      form.setFieldsValue({
        certificateImage: certificateData.certificateImage,
        certificateName: certificateData.certificateName,
        certificateLink: certificateData.certificateLink,
        activationPeriod: certificateData.activationPeriod,
        level: certificateData.level,
        learner15MinMaterial: certificateData.sessionMaterial?.learner15MinMaterial,
        learner25MinMaterial: certificateData.sessionMaterial?.learner25MinMaterial,
        tutor15MinMaterial: certificateData.sessionMaterial?.tutor15MinMaterial,
        tutor25MinMaterial: certificateData.sessionMaterial?.tutor25MinMaterial
      });
      setCertificateCreated(true);
    }
  }, [certificateData]);


  const createCertificate = () => {
    try {
      if (!isValid) {
        message.error('Please add notes links with .pdf extension.');
        return;
      }
    form
      .validateFields()
      .then((values) => {
        console.log(values);
        values["curriculumId"] = curriculumId;
        values["certificateId"] = certificateData?._id
        const onSuccess = (res) => {
          setCertificateCreated(true);
          message.success(res.message);
        };
        const onError = (msg) => {
          message.error(msg);
        };
        CreateCertificate(values, onSuccess, onError);
      })
    } catch (errorInfo) {
        console.log("Validation Failed:", errorInfo);
      };
  };


  return (
    <SideDrawer
    buttonType={"link"}
    buttonText={"Edit"}
    drawerTitle={"Edit"}
    >
      <Form form={form} layout="vertical">
        {/* <Form.Item
          label={`Serial Number`}
          name={`serialNo`}
          rules={[
            { required: true, message: "Please add a serial number!" },
            { type: 'number', message: 'Serial number must be a number' },
          ]}
        >
          <InputNumber placeholder="Serial Number" />
        </Form.Item> */}

        <Form.Item
          label="Certificate Image"
          name={`certificateImage`}
          rules={[
            { required: true, message: "Please add certificate Image !" },
          ]}
        >
          <Input placeholder="Certificate Image" />
        </Form.Item>

        <Form.Item
          label={`Certificate Name`}
          name={`certificateName`}
          rules={[{ required: true, message: "Please add certificate name !" }]}
        >
        <Input placeholder="Certificate Name" />
        </Form.Item>
        
        <Form.Item
          label="Certificate Link"
          name={`certificateLink`}
          rules={[
            { required: true, message: "Please add certificate link !" },
          ]}
        >
          <Input placeholder="Certificate Link" />
        </Form.Item>
        <Form.Item
          label="Activation Period"
          name={`activationPeriod`}
          rules={[
            { required: true, message: "Please add activation link!" },
          ]}
        >
          <Input placeholder="Activation Period" />
        </Form.Item>

        <Form.Item
          label="Select Level"
          name={`level`}
          rules={[{ required: true, message: "Please select a level !" }]}
        >
          <Select>
            <Select.Option value="Level 1">Level 1</Select.Option>
            <Select.Option value="Level 2">Level 2</Select.Option>
            <Select.Option value="Level 3">Level 3</Select.Option>
            <Select.Option value="Level 4">Level 4</Select.Option>
            <Select.Option value="Level 5">Level 5</Select.Option>
            <Select.Option value="Level 6">Level 6</Select.Option>
            <Select.Option value="Level 7">Level 7</Select.Option>
            <Select.Option value="Level 8">Level 8</Select.Option>
            <Select.Option value="Level 9">Level 9</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
            label={`Learner 15 Min Note`}
            name="learner15MinMaterial"

            rules={[{ required:false, message: "Please add learner note !" }]}

          >
            <Input placeholder="Learner's 15 Min Note" onChange={handleNotesChange} type="text" />
          </Form.Item>

          <Form.Item
            label={`Learner 25 Min Note`}
            name="learner25MinMaterial"

            rules={[{ required:false, message: "Please add learner note !" }]}

          >
            <Input placeholder="Learner's 25 Min Note" onChange={handleNotesChange} type="text" />
          </Form.Item>

          <Form.Item
            label={`Tutor 15 Min Note`}
            name="tutor15MinMaterial"

            rules={[{ required:false, message: "Please add tutor note !" }]}

          >
            <Input placeholder="Tutor's 15 Min Note" onChange={handleNotesChange} type="text" />
          </Form.Item>

          
          <Form.Item
            label={`Tutor 25 Min Note`}
            name="tutor25MinMaterial"

            rules={[{ required:false, message: "Please add tutor note !" }]}

          >
            <Input placeholder="Tutor's 25 Min Note" onChange={handleNotesChange} type="text" />
          </Form.Item>


        {certificateData ? (
          <div style={{ textAlign: "right" }}>
            <Button type="primary" onClick={createCertificate}>
              Confirm
            </Button>
          </div>
        ) : (
          ""
        )}
      </Form>
    </SideDrawer>
  );
};

export default EditCertificate;