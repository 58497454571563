import React, { useState } from "react";
import {
  Modal,
  Button,
  Tabs,
  Form,
  Input,
  Row,
  Col,
  message,
  Select,
  InputNumber,
} from "antd";
import countries from "../../data/countries.json";
import callingcode from "../../data/callingCode.json";
import { useDispatch, useSelector } from "react-redux";
import { AddUser } from "../../redux/actions/UserAction";
import { addAdminLog } from "../../redux/actions/TeacherAction";

const { TabPane } = Tabs;
const { Option } = Select;

const AddLearner = () => {

  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [callingCode, setCallingCode] = useState("+91");
  const [country, setCountry] = useState("IN");
  const [timezone, setTimeZone] = useState("Asia/Kolkata");
  const [loader, setLoader] = useState(false);
  const Admin = useSelector((state) => state.AdminReducer.admin);

  const onFinish = (values) => {
    if(values.age && values.grade){
        addUser(values,true)
    }else{
        addUser(values)
    }
  };

 

  const addUser = (data,isKid) =>{
    console.log("ENTER")
    setLoader(true)
    var userData = {
        phoneNumber:data.phoneNumber,
        countryCode:callingCode,
        password:data.password,
        email:data.email,
        name:data.name,
        country:country,
        timezone:timezone,
        kid:false, 
        parent:true
    }

    const onSuccess=()=>{
        
       if(isKid){
        delete userData['password'];
        delete userData['parent'];
        userData["name"] = data.kidName
        userData["kid"] = true
        userData["age"] = data.age
        userData["grade"] = data.grade
        console.log(userData)
        const payload = {
          role: Admin?.details?.role,
          admin: Admin?.details?.name,
          activity: "AddLearner",
          payload: userData
        }
        var onLogSuccess = (res) => {
          console.log("res",res)
        }
        var onLogError = (msg) => {
          message.error(msg);
        }
        addAdminLog(payload, onLogSuccess, onLogError)
        const onSuccess=()=>{
            message.success("Parent & kid created successfully", 8);
            const payload = {
              role: Admin?.details?.role,
              admin: Admin?.details?.name,
              activity: "AddLearner",
              payload: userData
            }
            var onLogSuccess = (res) => {
              console.log("res",res)
            }
            var onLogError = (msg) => {
              message.error(msg);
            }
            addAdminLog(payload, onLogSuccess, onLogError)
            form.resetFields();
            setVisible(false)
            setLoader(false)
            
        }
        const onError= (msg) =>{
          console.log("INNER ERROR")
            setLoader(false)
            message.error(msg, 8);
            message.success("Parent account created successfully .", 8);
            message.error("unable to create kid account", 8);
        }
        dispatch(AddUser(userData,onSuccess,onError))
       }else{
           
           message.success("Learner created successfully", 8);
           form.resetFields();
           setVisible(false)
           setLoader(false)
       }
    }

    const onError = (msg) =>{
      console.log("UPPER ERROR",msg)
      if(msg=="Learner Already Exists."){
        if(isKid){
          delete userData['password'];
          delete userData['parent'];
          userData["name"] = data.kidName
          userData["kid"] = true
          userData["age"] = data.age
          userData["grade"] = data.grade
          console.log(userData)
          const onSuccess=()=>{
              message.success("Parent & kid created successfully", 8);
              form.resetFields();
              setVisible(false)
              setLoader(false)
          }
          const onError= (msg) =>{
            console.log("INNER ERROR")
              setLoader(false)
              message.error(msg, 8);
              message.success("Parent account created successfully .", 8);
              message.error("unable to create kid account", 8);
          }
          dispatch(AddUser(userData,onSuccess,onError))
          return
         }else{
             
             message.success("Learner created successfully", 8);
             form.resetFields();
             setVisible(false)
             setLoader(false)
             return
         }
      }
      setLoader(false)
      message.error(msg, 8);
    }

    console.log(userData)
     dispatch(AddUser(userData,onSuccess,onError))
  }

  const prefixSelector = (
    <Form.Item name="countryCode" noStyle>
      <Select showSearch style={{ width: 70 }} defaultValue={callingCode} value={callingCode} onChange={value=>setCallingCode(value)}>
        {callingcode.map((data) => {
          return (
            <Option value={data.dial_code} key={data.dial_code}>
              {data.dial_code}
            </Option>
          );
        })}
      </Select>
    </Form.Item>
  );

  return (
    <>
      <Button type="primary" onClick={() => setVisible(true)}>
        Add learner
      </Button>
      <Modal
        centered
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
        width={1000}
      >
        <Tabs defaultActiveKey="1" centered>
          <TabPane tab="Add learner" key="1">
            <Form form={form} layout="vertical" autoComplete="off" onFinish={onFinish}>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="Phone number"
                    rules={[{ required: true }]}
                    tooltip="This is a required field"
                    name="phoneNumber"
                  >
                    <Input
                      addonBefore={prefixSelector}
                      placeholder="Add phone number"
                    />
                  </Form.Item>
                  <Form.Item
                    label="Email"
                    rules={[{ required: true }]}
                    tooltip="This is a required field"
                    name="email"
                  >
                    <Input placeholder="Add email" />
                  </Form.Item>
                  <Form.Item
                    label="Password"
                    rules={[{ required: true }]}
                    tooltip="This is a required field"
                    name="password"
                  >
                    <Input placeholder="Add password" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Name"
                    rules={[{ required: true }]}
                    tooltip="This is a required field"
                    name="name"
                  >
                    <Input placeholder="Add username" />
                  </Form.Item>

                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item
                        name="country"
                        label="Country"
                        
                        tooltip="This is a required field"
                      >
                        <Select showSearch defaultValue={country} value={country} onChange={value=>setCountry(value)}>
                          {countries.map((data) => {
                            return (
                              <Option
                                value={data.country_code}
                                key={data.country_code}
                              >
                                {data.country_code}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Timezone"
                       
                        tooltip="This is a required field"
                        name="timezone"
                      >
                        <Select showSearch defaultValue={timezone} value={timezone} onChange={value=>setTimeZone(value)}>
                          {countries.map((data) => {
                            return (
                              <Option
                                value={data.timezones[0]}
                                key={data.timezones[0]}
                              >
                                {data.timezones[0]}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Form.Item>
                <Button type="primary" htmlType="submit" loading={loader} block>
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </TabPane>
          <TabPane tab="Add Kids" key="2">
          <Form form={form} layout="vertical" autoComplete="off" onFinish={onFinish}>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="Phone number"
                    rules={[{ required: true }]}
                    tooltip="This is a required field"
                    name="phoneNumber"
                  >
                    <Input
                      addonBefore={prefixSelector}
                      placeholder="Add phone number"
                    />
                  </Form.Item>
                  <Form.Item
                    label="Email"
                    rules={[{ required: true }]}
                    tooltip="This is a required field"
                    name="email"
                  >
                    <Input placeholder="Add email" />
                  </Form.Item>
                  <Form.Item
                    label="Password"
                    rules={[{ required: true }]}
                    tooltip="This is a required field"
                    name="password"
                  >
                    <Input placeholder="Add password" />
                  </Form.Item>
                  <Form.Item
                    label="Parent Name"
                    rules={[{ required: true }]}
                    tooltip="This is a required field"
                    name="name"
                  >
                    <Input placeholder="Parent username" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Kid name"
                    rules={[{ required: true }]}
                    tooltip="This is a required field"
                    name="kidName"
                  >
                    <Input placeholder="Kid username" />
                  </Form.Item>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item
                        name="age"
                        label="Age"
                        rules={[{ required: true }]}
                        tooltip="This is a required field"
                      >
                         <InputNumber min={5} max={16} placeholder="Kid age" style={{width:'12rem'}}/>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      
                    <Form.Item
                        name="grade"
                        label="Grade"
                        rules={[{ required: true }]}
                        tooltip="This is a required field"
                        
                      >
                         <InputNumber min={1} max={10} placeholder="Kid grade" style={{width:'12rem'}}/>
                      </Form.Item>
                   
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item
                        name="country"
                        label="Country"
                        tooltip="This is a required field"
                      >
                        <Select showSearch defaultValue={country} value={country} onChange={value=>setCountry(value)} >
                          {countries.map((data) => {
                            return (
                              <Option
                                value={data.country_code}
                                key={data.country_code}
                              >
                                {data.country_code}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Timezone"
                        
                        tooltip="This is a required field"
                        name="timezone"
                      >
                        <Select showSearch defaultValue={timezone} value={timezone} onChange={value=>setTimeZone(value)}>
                          {countries.map((data) => {
                            return (
                              <Option
                                value={data.timezones[0]}
                                key={data.timezones[0]}
                              >
                                {data.timezones[0]}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Form.Item>
                <Button type="primary" htmlType="submit" loading={loader} block>
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </TabPane>
        </Tabs>
      </Modal>
    </>
  );
};

export default AddLearner;
