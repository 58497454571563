import React, { useState, useEffect } from "react";
import { Table, Button, Spin, message, Modal, Radio } from "antd";
import PageBreadcrumb from "../../component/PageBreadcrumb";
import { CancelPseudoSlot, CreateASession, GetPseudoSlots } from "../../redux/actions/SessionAction";
import { getDevice, UTCToAsiaTimeConverter } from "../../helper/helper";
import moment from "moment";
import SelectTeacher from "../../component/Session_Components/SelectTeacher";
import { getTeacherList_ } from "../../redux/actions/TeacherAction";

const PseudoSessionRequest = () => {
  const [visible, setVisible] = useState(false);
  const [loader, setLoader] = useState(false);
  const [bookingLoader, setBookingLoader] = useState(false);
  const [teachersList, setTeachersList] = useState([]);
  const [loadingTeacherList, setLoadingTeacherList] = useState(true);
  const [cancelLoader, setCancelLoader] = useState(false);
  const [data, setData] = useState([]);
  const [selectedData, setSelectedData] = useState({});
  
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [selectedCancellationReason, setSelectedCancellationReason] = useState("");
  const [cancelSessionId, setCancelSessionId] = useState(null);

  const cancellationReasons = [
    "Requested by Learner",
    "Teacher not available",
   
  ];

  const columns = [
    { title: "No", width: 10, dataIndex: "no", key: "1" },
    { title: "Learner Name", width: 30, dataIndex: "learnerName", key: "4" },
    { title: "Number", width: 30, dataIndex: "phoneNumber", key: "41" },
    { title: "Session Date", width: 30, dataIndex: "sessionDate", key: "5" },
    { title: "Session Time", width: 20, dataIndex: "sessionTime", key: "6" },
    {
      title: "Action",
      width: 50,
      dataIndex: "action",
      key: "8",
      render: (data) => (
        <>
          {loadingTeacherList ? (
            <p>Loading...</p>
          ) : (
            <Button type="primary" onClick={() => {
              setVisible(true)
              setSelectedData(data)
              }}>
            Book
          </Button>
          )}
          <Button
            type="error"
            className="ml-2"
            onClick={() => showCancelModal(data._id)}
            loading={cancelLoader === data._id}
            disabled={cancelLoader === data._id}
          >
            Cancel
          </Button>
        </>
      ),
    },
  ];

  const bookRequestedSession = (sessionData,teacherId) => {
    if (!teacherId) {
      message.error("Please select a teacher");
      return;
    }
    setBookingLoader(sessionData._id)
    var payload = {
      learner: sessionData?.learner.id,
      teacher_: teacherId,
      startTime: sessionData.startTime
        ?.split("T")[1]
        .split(".")[0]
        .split(":")
        .slice(0, 2)
        .join(":"),
      endTime: sessionData.endTime
        ?.split("T")[1]
        .split(".")[0]
        .split(":")
        .slice(0, 2)
        .join(":"),
      sessionDate: sessionData.sessionDateTime?.split("T")[0],
      isAdmin: true,
      isWebsite: true,
      timezone: "Asia/Kolkata",
      subject: "English",
      source: getDevice(),
      sessionDuration: "15min",
      forKids: false,
      type: "Demo",
      psuedoSlotId:sessionData?._id
    };

    const onSuccess = (res) => {
      setBookingLoader(false)
      setVisible(false)
      message.success(res.data.message)
      setData(data.filter((request) => request.action._id !== sessionData?._id))
    };
    const onError = (err) => {
      setBookingLoader(false)
      setVisible(false)
      message.error(err)
    };

    CreateASession(payload, onSuccess,onError)
  };

  const showCancelModal = (sessionId) => {
    setCancelSessionId(sessionId);
    setCancelModalVisible(true);
  };

  const cancelRequestedSession = () => {
    if (!selectedCancellationReason) {
      message.error("Please select a cancellation reason");
      return;
    }

    const payload = {
      psuedoBookingId: cancelSessionId,
      cancellationReason: selectedCancellationReason
    };

    setCancelLoader(cancelSessionId);

    const onSuccess = (res) => {
      message.success(res?.message);
      setCancelLoader(false);
      setData(data.filter((request) => request.action._id !== cancelSessionId));
      setCancelModalVisible(false);
    };

    const onError = (err) => {
      setCancelLoader(false);
      message.error(err);
      setCancelModalVisible(false);
    };

    CancelPseudoSlot(payload, onSuccess, onError);
  };

  useEffect(() => {
    setLoadingTeacherList(true);
    const onSuccess = (data) => {
      const formattedData = data.activeSlots?.map((request, i) => {
        const startTime = request?.startTime
          ?.split("T")[1]
          .split(".")[0]
          .split(":")
          .slice(0, 2)
          .join(":");
        const endTime = request?.endTime
          ?.split("T")[1]
          .split(".")[0]
          .split(":")
          .slice(0, 2)
          .join(":");
        return {
          no: i,
          learnerName: request?.learner?.name,
          sessionDate: moment(new Date(request.sessionDateTime)).format(
            "YYYY-MM-DD"
          ),
          sessionTime:
            UTCToAsiaTimeConverter(startTime) +
            " - " +
            UTCToAsiaTimeConverter(endTime),
          phoneNumber:request?.learner?.phoneNumber,
          action: request,
        };
      });
      setData(formattedData);
      setLoadingTeacherList(false);
    };
    const onError = (err) => {
      message.error(err);
      setLoadingTeacherList(false);
    };

    GetPseudoSlots(onSuccess, onError);
  }, []);

  useEffect(() => {
    setLoader(true)
    const onSuccess = (res) => {
        setLoader(false)
      const formattedData = res?.map((data, i) => {
        return {
          value: data?.name,
          label: data?.name,
          id: data?._id,
        };
      });
      setTeachersList(formattedData);
    };
    const onError = (err) => {
        setLoader(false)
      message.error(err);
    };
    getTeacherList_(
      { page: 1, limit: 1000, subject: "English" },
      onSuccess,
      onError
    );
  }, []);

  return (
    <div>
      <PageBreadcrumb route={["dashboard", "Teacher canceled session"]} />

      {loader ? (
        <div className="spin-center">
          <Spin size="large" />
        </div>
      ) : (
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
        />
      )}
      <SelectTeacher 
          teachersList={teachersList} 
          bookRequestedSession={bookRequestedSession}
          sessionData={selectedData}
          bookingLoader={bookingLoader}
          visible={visible} 
          setVisible={setVisible}
      />
      <Modal
        title="Cancel Session"
        visible={cancelModalVisible}
        onOk={cancelRequestedSession}
        onCancel={() => setCancelModalVisible(false)}
        okText="Confirm Cancellation"
        cancelText="Back"
      >
        <p>Please select a reason for cancellation:</p>
        <Radio.Group
          onChange={(e) => setSelectedCancellationReason(e.target.value)}
          value={selectedCancellationReason}
        >
          {cancellationReasons.map((reason) => (
            <Radio key={reason} value={reason}>
              {reason}
            </Radio>
          ))}
        </Radio.Group>
      </Modal>
    </div>
  );
};

export default PseudoSessionRequest;
