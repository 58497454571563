import React, { useEffect, useState } from 'react'
import { message, Space, Spin, Table, Tag,Button,Modal,Input } from 'antd';
import { useParams } from "react-router";
import { GetPayouts, UpdatePayouts, WithdrawAmount } from '../../redux/actions/TeacherAction';
import { getDate } from '../../helper/helper';
import { utcToLocalDateTimeConverter } from '../../helper/TimeDate';

const EditPayoutsModal = ({isModalVisible,setIsModalVisible,id}) =>{

  const [paoutId, setPayoutId] = useState(null)
  const [amount, setAmount] = useState(0)
  const [btnLoad, setBtnLoad] = useState(false)
  
  const showModal = (id) => {
    setIsModalVisible(true);
    setPayoutId(id)
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const editPayout = (payoutId) =>{
    var data = {
      payoutId:payoutId,
      amount:amount
    }
    console.log(data)
    setBtnLoad(true)
    const onSuccess = (msg) =>{
      setBtnLoad(false)
      message.success(msg)
      setIsModalVisible(false)
    }

    const onError = (err) =>{
      setBtnLoad(false)
      message.error(err)
    }

    UpdatePayouts(
      data,onSuccess,onError
    )
  }

    return(
      <>
        <Button disabled={true} onClick={()=>showModal(id)}>Edit</Button>
        {
          paoutId?
          <Modal title={paoutId} visible={isModalVisible} onCancel={handleCancel} footer={[]}>
          <form style={{display:"flex"}}>
               <Input placeholder="Payout amount" onChange={(e)=>setAmount(e.target.value)} type='number' required/>
               <Button type='primary' onClick={()=>editPayout(paoutId)} disabled={btnLoad}>Edit Payout</Button>
          </form>
          
        </Modal>
        :""
        }
    </>
    )
}

const Payouts = () => {

    let { id } = useParams();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [loader,setLoader] = useState(false);
    const [payout,setPayout] = useState([]);
    const [withdrawLoader, setWithdrawLoader] = useState(-1111);

    useEffect(() => {
       setLoader(true)
        const onSuccess = (data) =>{
            setPayout(data.payoutsData)
            setLoader(false)
        }

        const onError = (msg) =>{
            message.error(msg)
            setLoader(false)
        }

        GetPayouts(id,onSuccess,onError)
    }, [])
    
    const withdrawPayoutAmont = (payoutId) =>{

      setWithdrawLoader(payoutId)
      const onSuccess = (res) => {
        const newPayouts = payout.map((payout)=>{
          setWithdrawLoader(-1111)
          if(payout._id === payoutId){
            payout.status = "Withdrawn"
          }
          return payout;
        });
        
        setPayout(newPayouts)
        message.success('Amount successfully withdrawn !')
      };
      const onError = (msg) => {
          message.error(msg);
          setWithdrawLoader(-1111)
      };
      WithdrawAmount({payoutId:payoutId},onSuccess,onError)
    }

const columns = [
  {
    title: 'Created At',
    dataIndex: 'createdAt',
    key: 'createdAt'
  },
  {
    title: 'Approved Amount',
    dataIndex: 'approvedAmount',
    key: 'approvedAmount'
  },
  {
    title: 'Start Date',
    dataIndex: 'startDate',
    key: 'startDate',
  },
  {
    title: 'End Date',
    dataIndex: 'endDate',
    key: 'endDate',
  },
  {
    title: 'Status',
    key: 'status',
    dataIndex: 'status',
    render: (_, { status, key }) => (
      <>
        {status.map((tag) => {
          let color;

          if (tag === 'Withdrawn') {
            color = 'green';
          }else if (tag === 'Approved') {
            color = 'geekblue';
          }

          return (
            <>
            {
              tag === "Approved"?
              <button onClick={()=>withdrawPayoutAmont(key)}>{withdrawLoader===key?"Tranferring...":"Transfer"}</button>
            :<Tag color={color} key={tag}>
              {tag.toUpperCase()}
             </Tag>}
             </>
          );
        })}
      </>
    ),
  },
  {
    title: 'Action',
    key: 'action',
    render: ({key}) => (
      <Space size="middle">
        <EditPayoutsModal id={key} isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible} />
        {/* <a>Details</a> */}
      </Space>
    ),
  },
];
const data = payout?.map((data)=>({
  key: data._id,
  createdAt: utcToLocalDateTimeConverter(data.createdAt),
  approvedAmount: data.approvedAmount,
  startDate: utcToLocalDateTimeConverter(data.startDate),
  endDate: utcToLocalDateTimeConverter(data.endDate),
  status: [data.status],
}))


  return (
    <>
        {loader ? (
      <div className="spin-center">
        <Spin size="large" />
      </div>
    ) : (
    <Table columns={columns} dataSource={data} />
    )}
    </>
  )
}

export default Payouts
